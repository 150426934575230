import { G } from '@mobily/ts-belt';
import { LinkStates, PersistedSyncLink } from '@netinsight/management-app-common-api';
import { useSingleLinkMetrics } from '../../hooks/metrics';
import { useLinkSelectedProfileIndices } from '../../hooks/links';

export const getCombinedLinkDetailMetrics = ({
  data,
  selectedProfileIndices,
  metrics: { rtts = {}, linkTimeErrors = {}, linkStable = {}, nodeStable = {}, linkState = {} } = {} as any,
}: {
  data: PersistedSyncLink;
  metrics: ReturnType<typeof useSingleLinkMetrics>['data'];
  selectedProfileIndices: Exclude<ReturnType<typeof useLinkSelectedProfileIndices>['data'], undefined>;
}) => {
  const pathDiffsByProfileIndex = new Map<number, number>(
    data.profiles?.map(({ index, delayDifference }) => [index, delayDifference]),
  );
  const profileIndexA = selectedProfileIndices[data.endpointA.node];
  const profileIndexB = selectedProfileIndices[data.endpointB.node];
  const pathDiffA = G.isNotNullable(profileIndexA) ? pathDiffsByProfileIndex.get(profileIndexA) : undefined;
  const pathDiffB = G.isNotNullable(profileIndexB) ? pathDiffsByProfileIndex.get(profileIndexB) : undefined;
  const rttA = rtts?.[data.id]?.[data.endpointA?.node];
  const rttB = rtts?.[data.id]?.[data.endpointB?.node];
  const linkTimeErrorA = linkTimeErrors?.[data.id]?.[data.endpointA?.node];
  const linkTimeErrorB = linkTimeErrors?.[data.id]?.[data.endpointB?.node];
  const linkStableA = linkStable?.[data.id]?.[data.endpointA.node];
  const linkStableB = linkStable?.[data.id]?.[data.endpointB.node];
  const endpointAStable = nodeStable?.[data.endpointA.node];
  const endpointBStable = nodeStable?.[data.endpointB.node];

  return {
    rtt: rttA ?? rttB,
    linkTimeError: linkTimeErrorA ?? linkTimeErrorB,
    profileIndex: profileIndexA ?? profileIndexB,
    pathDiff: pathDiffA ?? pathDiffB,
    linkStable: G.isNotNullable(linkStableA) && G.isNotNullable(linkStableB) ? linkStableA && linkStableB : undefined,
    linkState: linkState?.[data.id],
    syncActive: G.isNotNullable(linkState?.[data.id])
      ? linkState[data.id] === LinkStates.TimestampsSentInBothDirections
      : undefined,
    endpointAStable: G.isNotNullable(endpointAStable) ? endpointAStable === true : undefined,
    endpointBStable: G.isNotNullable(endpointBStable) ? endpointBStable === true : undefined,
  };
};

export type CombinedLinkDetailMetrics = Partial<ReturnType<typeof getCombinedLinkDetailMetrics>>;
