import React, { FunctionComponent } from 'react';
import { Control } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import { ClockAccuracySchema, ClockVarianceSchema, TimeSourceSchema } from '@netinsight/crds-timetransfer';
import {
  getSelectOptionsFromSchema,
  TextField,
  useFormStyles,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import type { PtpReceiverConfigFormValues } from './PtpReceiverConfigForm';

export const PtpReceiverClockQuality: FunctionComponent<{
  control: Control<PtpReceiverConfigFormValues>;
  index: number;
}> = ({ index, control }) => {
  const formStyles = useFormStyles();
  const { field: clockAccuracySelectProps } = useTextFieldController({
    control,
    name: `instances.${index}.clockAccuracy`,
    label: 'Clock accuracy',
    schema: ClockAccuracySchema,
  });

  const { field: clockVarianceInputProps } = useTextFieldController({
    control,
    name: `instances.${index}.clockVariance`,
    label: 'Clock variance',
    placeholder: 'Auto',
    schema: ClockVarianceSchema,
  });

  const { field: timeSourceSelectProps } = useTextFieldController({
    control,
    name: `instances.${index}.timeSource`,
    label: 'Time source',
    schema: TimeSourceSchema,
  });
  return (
    <>
      <Typography component="h6" variant="h6">
        Clock Quality
      </Typography>
      <div className={formStyles.formGrid}>
        <TextField fullWidth {...clockAccuracySelectProps}>
          {getSelectOptionsFromSchema(ClockAccuracySchema)}
        </TextField>
        <TextField fullWidth {...clockVarianceInputProps} />
        <TextField fullWidth {...timeSourceSelectProps}>
          {getSelectOptionsFromSchema(TimeSourceSchema)}
        </TextField>
      </div>
    </>
  );
};
