import { Reducer } from 'react';
import { G } from '@mobily/ts-belt';
import {
  NODE_GRAPH_TOGGLES,
  NodeGraphHighlight,
  NodeGraphState,
  NodeGraphToggleKey,
  NodeGraphToggles,
} from '@netinsight/management-app-common-api';
import { GraphEdgeData, GraphNodeData } from '../../../utils/node-graph';
import { DEFAULT_GRAPH_HEIGHT, DEFAULT_GRAPH_ID, DEFAULT_GRAPH_WIDTH } from '../../../constants/node-graph';

export type LocalNodeGraphState = NodeGraphState & {
  id: string;
  selectedNodes: GraphNodeData[];
  selectedEdges: GraphEdgeData[];
  rightBarOpened: boolean;
  leftBarOpened: boolean;
  tracingTargetNodeId?: string;
};

export type NodeGraphAction =
  | {
      type: 'background';
      imageUrl?: string;
    }
  | {
      type: 'highlight';
      highlight: NodeGraphHighlight;
    }
  | {
      type: 'load';
      state: NodeGraphState;
      id: string;
    }
  | {
      type: 'menu';
      open?: boolean;
    }
  | {
      type: 'name';
      name: string;
    }
  | {
      type: 'node-position';
      nodeId: string;
      position: cytoscape.Position;
    }
  | {
      type: 'resize';
      width: number;
      height: number;
    }
  | {
      type: 'sidebar';
      open?: boolean;
    }
  | {
      type: 'select';
      nodes: GraphNodeData[];
      edges: GraphEdgeData[];
    }
  | {
      type: 'sync-regions';
      syncRegions: string[];
    }
  | {
      type: 'toggle';
      toggles: NodeGraphToggles;
    }
  | {
      type: 'trace';
      nodeId: string | undefined;
    };

export const DefaultToggles: Record<NodeGraphToggleKey, boolean> = {
  [NODE_GRAPH_TOGGLES.Background]: true,
  [NODE_GRAPH_TOGGLES.BackgroundScaling]: false,
  [NODE_GRAPH_TOGGLES.EdgeBundling]: false,
  [NODE_GRAPH_TOGGLES.NodeLabels]: true,
  [NODE_GRAPH_TOGGLES.ParentNodes]: true,
  [NODE_GRAPH_TOGGLES.SelectedEdgeLabels]: true,
};

const getInitialLocalState = () => ({
  rightBarOpened: false,
  leftBarOpened: false,
  tracingTargetNodeId: undefined,
  selectedEdges: [],
  selectedNodes: [],
});

export const getInitialNodeGraphState = (): LocalNodeGraphState => ({
  id: DEFAULT_GRAPH_ID,
  width: DEFAULT_GRAPH_WIDTH,
  height: DEFAULT_GRAPH_HEIGHT,
  toggles: { ...DefaultToggles },
  backgroundImageUrl: undefined,
  zoom: 1,
  pan: {
    x: 0,
    y: 0,
  },
  syncRegions: [],
  ...getInitialLocalState(),
});

export const reducer: Reducer<LocalNodeGraphState, NodeGraphAction> = (state, action): LocalNodeGraphState => {
  switch (action.type) {
    case 'background':
      return {
        ...state,
        backgroundImageUrl: action.imageUrl,
        toggles: { ...state.toggles, [NODE_GRAPH_TOGGLES.Background]: G.isNotNullable(action.imageUrl) },
      };
    case 'highlight':
      return { ...state, highlight: action.highlight };
    case 'resize':
      return { ...state, width: action.width, height: action.height };
    case 'sidebar':
      return { ...state, rightBarOpened: G.isNotNullable(action.open) ? action.open : !state.rightBarOpened };
    case 'load':
      return {
        ...getInitialLocalState(),
        ...action.state,
        toggles: {
          ...action.state.toggles,
          [NODE_GRAPH_TOGGLES.Background]: G.isNullable(action.state.toggles?.[NODE_GRAPH_TOGGLES.Background])
            ? G.isNotNullable(action.state.backgroundImageUrl)
            : action.state.toggles?.[NODE_GRAPH_TOGGLES.Background],
        },
        id: action.id,
      };
    case 'menu':
      return { ...state, leftBarOpened: G.isNotNullable(action.open) ? action.open : !state.leftBarOpened };
    case 'name':
      return { ...state, name: action.name };
    case 'node-position':
      return { ...state, nodePositions: { ...state.nodePositions, [action.nodeId]: action.position } };
    case 'select':
      return { ...state, selectedEdges: action.edges, selectedNodes: action.nodes };
    case 'sync-regions':
      return { ...state, syncRegions: action.syncRegions };
    case 'toggle':
      return { ...state, toggles: { ...state.toggles, ...action.toggles } };
    case 'trace':
      return { ...state, tracingTargetNodeId: action.nodeId };
    default:
      return { ...state };
  }
};
