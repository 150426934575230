import React, { FunctionComponent, useCallback } from 'react';
import { Control } from 'react-hook-form';
import { Button, FormControl } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/CloseOutlined';
import {
  buttonPropsFromPermission,
  PermissionResult,
  TextField,
  useFormStyles,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import { DnsDefinition, DnsSchema } from '@netinsight/node-manager-schema';

export type DnsConfigEntryProps = {
  control: Control<DnsDefinition>;
  index: number;
  label: string;
  onRemove: (index: number) => void;
  permission: PermissionResult;
};

export const DnsConfigEntry: FunctionComponent<DnsConfigEntryProps> = ({
  control,
  label,
  index,
  onRemove,
  permission,
}) => {
  const styles = useFormStyles();

  const handleRemove = useCallback(() => {
    onRemove(index);
  }, [onRemove, index]);

  const { field: nameServerFieldProps } = useTextFieldController({
    control,
    name: `nameservers.${index}`,
    label: label,
    schema: DnsSchema.shape.nameservers.element,
  });

  return (
    <FormControl component="fieldset" className={styles.formRow} data-testid={`name-server-${index}`}>
      <TextField {...nameServerFieldProps} />
      <Button
        variant="outlined"
        color="default"
        size="small"
        onClick={handleRemove}
        endIcon={<RemoveIcon />}
        data-testid={`btn-remove-name-server-${index}`}
        {...buttonPropsFromPermission(permission)}
      >
        Remove
      </Button>
    </FormControl>
  );
};
