import React, { useCallback } from 'react';
import { InfoCard, Progress, ResponseErrorPanel } from '@backstage/core-components';
import {
  useNodeManagerConfig,
  usePermission,
  useNodeManagerConfigUpdate,
} from '@netinsight/management-app-common-react';
import { DnsConfigForm, DnsConfigFormProps } from './DnsConfigForm';

export const DnsConfig = ({ nodeId }: any) => {
  const {
    data: config,
    isLoading,
    error,
  } = useNodeManagerConfig(nodeId, {
    revalidateOnFocus: false,
  });
  const { trigger: uploadConfig, permission } = useNodeManagerConfigUpdate(nodeId);
  const { isLoading: isLoadingPermission, ...canSubmitPermission } = usePermission(permission);
  const handleFormSubmit = useCallback<DnsConfigFormProps['onSubmit']>(
    async dnsConfig => {
      await uploadConfig(cfg => ({
        ...cfg,
        dns: dnsConfig,
      }));
    },
    [uploadConfig],
  );
  if (error) {
    return <ResponseErrorPanel error={error} />;
  } else if (isLoading || isLoadingPermission) {
    return <Progress />;
  } else if (config) {
    return (
      <InfoCard title="DNS config" titleTypographyProps={{ variant: 'h5' }}>
        <DnsConfigForm
          config={config.dns ?? { nameservers: [] }}
          onSubmit={handleFormSubmit}
          permission={canSubmitPermission}
        />
      </InfoCard>
    );
  }
  return null;
};
