/* eslint-disable no-nested-ternary */
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Table, TableColumn, TableProps, Link } from '@backstage/core-components';
import { Button, makeStyles, Typography, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import HelpIcon from '@material-ui/icons/Help';
import LinkActiveIcon from '@material-ui/icons/Schedule';
import WarningIcon from '@material-ui/icons/Warning';
import { A, F, G, pipe } from '@mobily/ts-belt';
import {
  PersistedSyncLinkQueryResultItem,
  PersistedSyncLinkQueryResultItemEndpoint,
} from '@netinsight/management-app-common-api';
import {
  DurationFormatter,
  InfoTooltip,
  isNullableOrEmpty,
  useBackendTableState,
  useGrafanaDashboards,
  TableStateHandler,
  GrafanaLink,
  usePermission,
  buttonPropsFromPermission,
} from '@netinsight/management-app-common-react';
import { LinkEndpointStableIcon } from '../common';
import { useLinkTableQueryCallback, useTTLinkCreate } from '../../../hooks/time-transfer';
import { useSyncRegions } from '../../../hooks/sync';
import { getSyncRegionsLookupForTable } from '../../../utils/sync';

type LinkRow = PersistedSyncLinkQueryResultItem;
type LinkEndpointWithMetrics = PersistedSyncLinkQueryResultItemEndpoint;

const useStyles = makeStyles(
  theme => ({
    tableToolbarButtonContainer: {
      margin: theme.spacing(1, 2.5, 1, 1),
      ['& > * + *']: {
        marginLeft: theme.spacing(1),
      },
    },
    syncRegionCell: {
      whiteSpace: 'pre-wrap',
    },
  }),
  { name: 'Neti' },
);

const formatMetric = (value?: number) => {
  if (G.isNullable(value) || isNaN(value)) {
    return '-';
  }
  return DurationFormatter.fromSeconds(value).toMicroSeconds(3);
};

const LinkOptionStatus = ({ isWarning, isChecked }: { isWarning?: boolean; isChecked?: boolean }) => {
  const theme = useTheme();
  if (isWarning) {
    return <WarningIcon fontSize="small" titleAccess="Warning" style={{ fill: theme.palette.warning.main }} />;
  }
  if (isChecked) {
    return <CheckIcon fontSize="small" titleAccess="Yes" style={{ fill: theme.palette.success.main }} />;
  }
  return null;
};

const LinkEndpointStatusCell: FunctionComponent<{ status?: boolean }> = ({ status }) => {
  const theme = useTheme();
  return typeof status === 'boolean' ? (
    status ? (
      <LinkActiveIcon style={{ color: theme.palette.success.main }} aria-label="Active" />
    ) : (
      <LinkActiveIcon style={{ color: theme.palette.error.main }} aria-label="Inactive" />
    )
  ) : (
    <HelpIcon style={{ color: theme.palette.grey[500] }} />
  );
};

const LinkEndpointNameCell: FunctionComponent<{ endpoint: LinkEndpointWithMetrics; linkId: string }> = ({
  endpoint,
}) => {
  return (
    <div style={{ display: 'block' }}>
      <LinkEndpointStableIcon status={endpoint.nodeStable} />
      <Typography variant="body1" component={Link} to={`/nodes/info/${endpoint.node}`}>
        {endpoint.nodeName ?? endpoint.node}
      </Typography>
    </div>
  );
};

const cellStyle = {
  padding: '0.5rem 0.25rem',
};
const headerStyle = {
  ...cellStyle,
};

const columns: TableColumn<LinkRow>[] = [
  {
    title: 'Link name',
    editable: 'never',
    cellStyle: {
      ...cellStyle,
      minWidth: 240,
      paddingLeft: '0.5rem',
    },
    headerStyle,
    field: 'name',
    sorting: true,
    render: rowData => (
      <Link
        variant="body1"
        to={`/network/links/${rowData.id}`}
        data-link-id={rowData.id}
        data-endpointa-node-id={rowData.endpointA.node}
        data-endpointa-node-name={rowData.endpointA.nodeName}
        data-endpointa-interface={rowData.endpointA.iface}
        data-endpointa-vlan-id={rowData.endpointA.vlanId}
        data-endpointa-port={rowData.endpointA.port}
        data-endpointa-node-stable={rowData.endpointA.nodeStable}
        data-endpointb-node-id={rowData.endpointB.node}
        data-endpointb-node-name={rowData.endpointB.nodeName}
        data-endpointb-interface={rowData.endpointB.iface}
        data-endpointb-vlan-id={rowData.endpointB.vlanId}
        data-endpointb-port={rowData.endpointB.port}
        data-endpointb-node-stable={rowData.endpointB.nodeStable}
        data-link-stable={rowData.linkStable}
        data-sync-active={rowData.syncActive}
        data-testid="btn-link-name"
      >
        {isNullableOrEmpty(rowData.name) ? rowData.id : rowData.name}
      </Link>
    ),
  },
  {
    title: 'Endpoint A',
    editable: 'never',
    cellStyle: {
      ...cellStyle,
      minWidth: 200,
    },
    headerStyle,
    field: 'endpointA.nodeName',
    sorting: true,
    hidden: true,
    render: rowData => <LinkEndpointNameCell endpoint={rowData.endpointA} linkId={rowData.id} />,
  },
  {
    title: 'Endpoint B',
    editable: 'never',
    cellStyle: {
      ...cellStyle,
      minWidth: 200,
    },
    headerStyle,
    sorting: true,
    hidden: true,
    field: 'endpointB.nodeName',
    render: rowData => <LinkEndpointNameCell endpoint={rowData.endpointB} linkId={rowData.id} />,
  },
  {
    title: 'Link stable',
    editable: 'never',
    cellStyle: {
      ...cellStyle,
      width: 32,
      maxWidth: 32,
      textAlign: 'center',
    },
    headerStyle: {
      ...headerStyle,
      textAlign: 'center',
    },
    field: 'linkStable',
    sorting: true,
    lookup: { true: 'Stable', false: 'Unstable' },
    render: rowData => <LinkEndpointStableIcon status={rowData.linkStable} />,
  },
  {
    title: (
      <>
        Sync active <InfoTooltip text="Indicates if sync packets are sent over the link" />
      </>
    ),
    editable: 'never',
    cellStyle: {
      ...cellStyle,
      width: 32,
      textAlign: 'center',
    },
    headerStyle: {
      ...headerStyle,
      textAlign: 'center',
    },
    field: 'syncActive',
    sorting: true,
    lookup: { true: 'Active', false: 'Inactive' },
    render: rowData => <LinkEndpointStatusCell status={rowData.syncActive} />,
  },
  {
    title: (
      <>
        <span style={{ lineHeight: '2rem' }}>Time error </span>
        <InfoTooltip text="Absolute value of the link time error (shown when data are available and the link has a selected profile)" />
      </>
    ),
    editable: 'never',
    cellStyle: {
      ...cellStyle,
      minWidth: 150,
    },
    headerStyle,
    sorting: true,
    type: 'numeric',
    field: 'timeError',
    filtering: false,
    render: rowData => formatMetric(rowData.timeError),
  },
  {
    title: (
      <>
        <span style={{ lineHeight: '2rem' }}>RTT </span>
        <InfoTooltip text="Round trip time (shown when data are available and the link has a selected profile)" />
      </>
    ),
    editable: 'never',
    cellStyle: {
      ...cellStyle,
      minWidth: 150,
    },
    headerStyle,
    sorting: true,
    type: 'numeric',
    field: 'rtt',
    filtering: false,
    render: rowData => formatMetric(rowData.rtt),
  },
  {
    title: (
      <>
        Delay difference{' '}
        <InfoTooltip text="Path delay difference (shown when data are available and the link has a selected profile)" />
      </>
    ),
    editable: 'never',
    cellStyle: {
      ...cellStyle,
      minWidth: 150,
    },
    headerStyle,
    sorting: true,
    type: 'numeric',
    field: 'pathDiff',
    filtering: false,
    render: rowData =>
      G.isNullable(rowData.pathDiff) || isNaN(rowData.pathDiff)
        ? '-'
        : DurationFormatter.fromMicroseconds(rowData.pathDiff).toMicroSeconds(3),
  },
  {
    title: 'Delay difference trusted',
    editable: 'never',
    customFilterAndSearch: (terms, row) =>
      terms.length === 0 || terms.includes((row.isLinkPathDiffTrusted ?? false).toString()),
    cellStyle: {
      ...cellStyle,
      width: 32,
      maxWidth: 32,
      textAlign: 'center',
    },
    headerStyle: {
      ...headerStyle,
      textAlign: 'center',
    },
    sorting: true,
    hidden: false,
    field: 'isLinkPathDiffTrusted',
    lookup: { true: 'Yes', false: 'No' },
    render: rowData => <LinkOptionStatus isChecked={rowData.isLinkPathDiffTrusted} />,
  },
  {
    title: 'Default options overridden',
    editable: 'never',
    cellStyle: {
      ...cellStyle,
      width: 32,
      maxWidth: 32,
      textAlign: 'center',
    },
    headerStyle: {
      ...headerStyle,
      textAlign: 'center',
    },
    hidden: true,
    sorting: true,
    field: 'isDefaultLinkOptionsOverriden',
    lookup: { true: 'Yes', false: 'No' },
    render: rowData => <LinkOptionStatus isWarning={rowData.isDefaultLinkOptionsOverriden} />,
  },
  {
    title: 'Auto-calibration enabled',
    editable: 'never',
    cellStyle: {
      ...cellStyle,
      width: 32,
      maxWidth: 32,
      textAlign: 'center',
    },
    headerStyle: {
      ...headerStyle,
      textAlign: 'center',
    },
    sorting: true,
    field: 'isAutoCalibrated',
    hidden: false,
    lookup: { true: 'Yes', false: 'No' },
    render: rowData => <LinkOptionStatus isChecked={rowData.isAutoCalibrated} isWarning={!rowData.isAutoCalibrated} />,
  },
  {
    title: 'Delete profiles on change',
    editable: 'never',
    cellStyle: {
      ...cellStyle,
      width: 32,
      maxWidth: 32,
      textAlign: 'center',
    },
    headerStyle: {
      ...headerStyle,
      textAlign: 'center',
    },
    sorting: true,
    hidden: true,
    field: 'deleteProfilesOnChange',
    lookup: { true: 'Yes', false: 'No' },
    render: rowData => (
      <LinkOptionStatus isChecked={rowData.deleteProfilesOnChange} isWarning={!rowData.deleteProfilesOnChange} />
    ),
  },
  {
    title: 'Delete profiles on auto-calibration',
    editable: 'never',
    cellStyle: {
      ...cellStyle,
      width: 32,
      maxWidth: 32,
      textAlign: 'center',
    },
    headerStyle: {
      ...headerStyle,
      textAlign: 'center',
    },
    sorting: true,
    hidden: true,
    field: 'deleteProfilesOnAutoCalibration',
    lookup: { true: 'Yes', false: 'No' },
    render: rowData => (
      <LinkOptionStatus
        isChecked={rowData.deleteProfilesOnAutoCalibration}
        isWarning={!rowData.deleteProfilesOnAutoCalibration}
      />
    ),
  },
];

const options: TableProps<LinkRow>['options'] = {
  actionsColumnIndex: -1,
  columnsButton: true,
  debounceInterval: 250,
  emptyRowsWhenPaging: false,
  filterCellStyle: {
    padding: '0.25rem 0.25rem',
  },
  filtering: true,
  headerStyle: {
    padding: '0.25rem 0.25rem',
  },
  pageSize: 25,
  pageSizeOptions: [25, 50, 100],
  padding: 'dense',
  search: false,
  thirdSortClick: false,
};

const localization = {
  header: {
    actions: '',
  },
};

export const LinksPage = () => {
  const styles = useStyles();
  const { data: dashboards } = useGrafanaDashboards();
  const { data: syncRegions } = useSyncRegions();
  const navigate = useNavigate();
  const tableRef = useRef<TableProps>(null);
  const { permission: createTTLinkPermission } = useTTLinkCreate();
  const permission = usePermission(createTTLinkPermission);

  const { current: LinksTableState } = useRef(new TableStateHandler());

  useEffect(() => {
    if (tableRef?.current && !LinksTableState.hasTableRefSet) {
      LinksTableState.setTableRef(tableRef);
    }
  }, [LinksTableState, tableRef]);

  const columsWithSyncRegions = useMemo(
    () =>
      pipe(
        columns!,
        A.insertAt(1, {
          title: 'Sync region',
          field: 'syncRegions',
          filtering: true,
          headerStyle,
          cellStyle: {
            ...cellStyle,
            minWidth: 128,
          },
          render: rowData => (
            <Typography variant="body1" className={styles.syncRegionCell}>
              {rowData.syncRegions?.join('\n')}
            </Typography>
          ),
          lookup: getSyncRegionsLookupForTable(syncRegions ?? []),
        } as TableColumn<LinkRow>),
        F.toMutable,
      ),
    [syncRegions, styles.syncRegionCell],
  );
  const handleAddNewRow = useCallback(() => {
    navigate('new-link/');
  }, [navigate]);

  const queryCallback = useLinkTableQueryCallback('links-page');
  const tableStates = useBackendTableState<LinkRow>(columsWithSyncRegions, options, 'links-page');
  const components: TableProps['components'] = useMemo(
    () => ({
      Actions: () => (
        <div className={styles.tableToolbarButtonContainer}>
          <Button
            type="button"
            size="small"
            color="default"
            variant="outlined"
            disabled={!LinksTableState?.hasActiveFilters()}
            startIcon={<CancelIcon />}
            onClick={() => LinksTableState?.clearAllFilters()}
            data-testid="btn-clear-linktable-filters"
          >
            Clear Filters
          </Button>
          <Button
            type="button"
            size="small"
            color="primary"
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleAddNewRow}
            data-testid="btn-create-link"
            {...buttonPropsFromPermission(permission)}
          >
            Create link
          </Button>
          <GrafanaLink
            dashboardUrl={dashboards?.['Zyntai Metrics Overview/time-transfer-region-link']}
            size="small"
            syncRegionsGetter={() => LinksTableState?.getFilters()?.syncRegions ?? []}
          />
        </div>
      ),
    }),
    [LinksTableState, dashboards, handleAddNewRow, styles.tableToolbarButtonContainer, permission],
  );

  return (
    <>
      <Table<LinkRow>
        tableRef={tableRef}
        title="Links"
        data={queryCallback}
        localization={localization}
        components={components}
        {...tableStates}
      />
      <Outlet />
    </>
  );
};
