import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import { merge as deepMerge } from 'ts-deepmerge';
import { useApi } from '@backstage/core-plugin-api';
import { pipe } from '@mobily/ts-belt';
import { booleanScale, metricsApiRef } from '@netinsight/management-app-common-react';
import { clockAccuracyScale, getPtpTransmitterServiceName as getServiceName } from '../../utils/sync/ptpUtils';
import { groupMetricsFromPromise } from '@netinsight/management-app-common-api';

export const usePtpTransmitterStatusMetrics = (nodeId: string, swrConfig: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);

  const fetchMetrics = useCallback(
    async ([nodeIdParam]: [string, string]) => {
      const [
        domainResult,
        clockClassResult,
        clockAccuracyResult,
        clockVarianceResult,
        prio1Result,
        prio2Result,
        timeSourceResult,
        utcOffsetResult,
        timeTraceableResult,
        frequencyTraceableResult,
        totalClientCountResult,
        unicastClientCountResult,
        unicastUtilizationResult,
      ] = await Promise.allSettled([
        metricsApi.instantQuery({
          query: `neti_ptp_default_dataset_domain{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_default_dataset_class{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_default_dataset_accuracy{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_default_dataset_variance{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_default_dataset_prio1{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_default_dataset_prio2{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_time_properties_timeSource{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_time_properties_utcOffset{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_time_properties_timeTraceable{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_time_properties_frequencyTraceable{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_clients_totalClientCount{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_clients_unicastClientCount{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_clients_unicastUtilization{nodeid="${nodeIdParam}"}`,
        }),
      ]);

      const parseIntBase10 = (val: string) => parseInt(val, 10);

      return deepMerge(
        groupMetricsFromPromise(domainResult, getServiceName, val => ({ domain: parseIntBase10(val) })),
        groupMetricsFromPromise(clockClassResult, getServiceName, val => ({
          clockClass: parseIntBase10(val),
        })),
        groupMetricsFromPromise(clockAccuracyResult, getServiceName, val => ({
          clockAccuracy: pipe(val, parseIntBase10, clockAccuracyScale),
        })),
        groupMetricsFromPromise(clockVarianceResult, getServiceName, val => ({ clockVariance: val })),
        groupMetricsFromPromise(prio1Result, getServiceName, val => ({ prio1: val })),
        groupMetricsFromPromise(prio2Result, getServiceName, val => ({ prio2: val })),
        groupMetricsFromPromise(timeSourceResult, getServiceName, val => ({
          timeSource: pipe(val, parseIntBase10),
        })),
        groupMetricsFromPromise(utcOffsetResult, getServiceName, val => ({
          utcOffset: parseIntBase10(val),
        })),
        groupMetricsFromPromise(timeTraceableResult, getServiceName, val => ({
          timeTraceable: pipe(val, parseIntBase10, Boolean, booleanScale),
        })),
        groupMetricsFromPromise(frequencyTraceableResult, getServiceName, val => ({
          frequencyTraceable: pipe(val, parseIntBase10, Boolean, booleanScale),
        })),
        groupMetricsFromPromise(totalClientCountResult, getServiceName, val => ({
          totalClientCount: parseIntBase10(val),
        })),
        groupMetricsFromPromise(unicastClientCountResult, getServiceName, val => ({
          unicastClientCount: parseIntBase10(val),
        })),
        groupMetricsFromPromise(unicastUtilizationResult, getServiceName, val => ({
          unicastUtilization: parseIntBase10(val),
        })),
      );
    },
    [metricsApi],
  );

  return useSwr([nodeId, 'usePtpTransmitterStatusMetrics'], fetchMetrics, swrConfig);
};

export type UsePtpTransmitterStatusMetricsResultData = NonNullable<
  ReturnType<typeof usePtpTransmitterStatusMetrics>['data']
>;
