import { Page, TabbedLayout } from '@backstage/core-components';
import React from 'react';
import { HeaderWithAlarms } from '../../components/HeaderWithAlarms/HeaderWithAlarms';
import { VersionsTab } from './VersionsTab';
import { DiagnosticsPage } from './DiagnosticsPage';
import { NestedTabLayout } from '@netinsight/management-app-common-react';
import { Navigate } from 'react-router';
import {
  GlobalAlertConfigForm,
  GlobalLinkOptionsForm,
  GlobalLoggingConfigForm,
  GlobalSSHKeys,
} from '../GlobalSettings';

export const SystemsPage = () => {
  return (
    <Page themeId="systems">
      <HeaderWithAlarms title="System" />
      <TabbedLayout>
        <TabbedLayout.Route path="/" title="Diagnostics" tabProps={{ id: 'help-diagnostics-tab' }}>
          <DiagnosticsPage />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="global-defaults" title="Global defaults" tabProps={{ id: 'global-defaults-tab' }}>
          <NestedTabLayout>
            <NestedTabLayout.Route index title="Index" element={<Navigate to="link-options" />} />
            <NestedTabLayout.Route path="link-options" title="Link options" element={<GlobalLinkOptionsForm />} />
            <NestedTabLayout.Route path="ssh-keys" title="SSH keys" element={<GlobalSSHKeys />} />
          </NestedTabLayout>
        </TabbedLayout.Route>
        <TabbedLayout.Route path="global-settings" title="Global settings" tabProps={{ id: 'global-settings-tab' }}>
          <NestedTabLayout>
            <NestedTabLayout.Route index title="Index" element={<Navigate to="logging" />} />
            <NestedTabLayout.Route path="logging" title="Logging" element={<GlobalLoggingConfigForm />} />
            <NestedTabLayout.Route path="alerts" title="Alerts" element={<GlobalAlertConfigForm />} />
          </NestedTabLayout>
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/versions" title="Versions">
          <VersionsTab />
        </TabbedLayout.Route>
      </TabbedLayout>
    </Page>
  );
};
