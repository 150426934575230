import React, { FunctionComponent, useCallback } from 'react';
import { Control, UseFieldArrayRemove } from 'react-hook-form';
import { Button, FormControl, makeStyles, Theme } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/CloseOutlined';
import {
  buttonPropsFromPermission,
  PermissionResult,
  TextField,
  useFormStyles,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import { NtpClientConfig, NtpServerConfigSchema } from '@netinsight/node-manager-schema';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2),

    ['&:not(:last-of-type)']: {
      paddingBottom: theme.spacing(4),
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
      marginBottom: theme.spacing(1),
    },
  },
  legend: {
    marginBottom: theme.spacing(2),
  },
}));

export type NtpServerConfigEntryProps = {
  control: Control<NtpClientConfig>;
  index: number;
  onRemove: UseFieldArrayRemove;
  permision: PermissionResult;
};

export const NtpServerConfigEntry: FunctionComponent<NtpServerConfigEntryProps> = ({
  control,
  index,
  onRemove,
  permision,
}) => {
  const styles = useStyles();
  const formStyles = useFormStyles();
  const handleRemove = useCallback(() => {
    onRemove(index);
  }, [onRemove, index]);

  const { field: addressFieldProps } = useTextFieldController({
    control,
    name: `servers.${index}.address`,
    label: 'Address',
    schema: NtpServerConfigSchema.shape.address,
  });
  const buttonProps = buttonPropsFromPermission(permision);

  return (
    <FormControl component="fieldset" className={styles.container} data-testid={`fieldset-${index}`}>
      <div className={formStyles.formRow}>
        <TextField {...addressFieldProps} />
        <Button
          variant="outlined"
          color="default"
          size="small"
          onClick={handleRemove}
          endIcon={<RemoveIcon />}
          data-testid={`btn-remove-ntp-server-${index}`}
          {...buttonProps}
        >
          Remove
        </Button>
      </div>
    </FormControl>
  );
};
