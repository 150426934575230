import React from 'react';
import { Progress } from '@backstage/core-components';
import { makeStyles, Theme } from '@material-ui/core';
import { A, D, pipe } from '@mobily/ts-belt';
import { PtpMetrics, usePtpCountersMetrics } from '../../../../../../hooks/sync';
import {
  BaseCounterKey,
  BaseMessageCounters,
  ErrorCounters,
  MessageCounterSuffixes,
  MessagePerSecSuffix,
} from '../../../../../../constants/ptp';

const [RxSuffix, TxSuffix] = MessageCounterSuffixes;
const DataMissingLabel = '-';
const CounterNumberFormat = [undefined, { maximumFractionDigits: 0 }] as const;
const RateNumberFormat = [undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }] as const;

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    ['border-collapse']: 'collapse',
    [' & td, & th']: {
      ...theme.typography.body2,
      border: '1px solid',
      borderColor: theme.palette.divider,
    },
  },
  header: {
    ['& th']: {
      fontWeight: 'bold',
      padding: theme.spacing(1, 2, 1, 2),
      textAlign: 'center',
    },
  },
  body: {
    ['& th']: {
      fontWeight: 'bold',
      padding: theme.spacing(1),
      textAlign: 'right',
    },
    ['& td']: {
      textAlign: 'right',
      padding: theme.spacing(1),
      fontVariantNumeric: 'tabular-nums',
    },
  },
}));

const RowWithMessageCounters = ({
  data,
  dataKey: key,
  label,
}: {
  data: PtpMetrics;
  dataKey: BaseCounterKey;
  label: string;
}) => {
  return (
    <tr>
      <th scope="row">{label}</th>
      <td>{data[`${key}${TxSuffix}`]?.toLocaleString(...CounterNumberFormat) ?? DataMissingLabel}</td>
      <td>{data[`${key}${RxSuffix}`]?.toLocaleString(...CounterNumberFormat) ?? DataMissingLabel}</td>
      <td>
        {data[`${key}${TxSuffix}${MessagePerSecSuffix}`]?.toLocaleString(...RateNumberFormat) ?? DataMissingLabel}
      </td>
      <td>
        {data[`${key}${RxSuffix}${MessagePerSecSuffix}`]?.toLocaleString(...RateNumberFormat) ?? DataMissingLabel}
      </td>
    </tr>
  );
};

export const PtpTransmitterCounters = ({ nodeId, serviceName }: { nodeId: string; serviceName: string }) => {
  const { data: { [serviceName]: metrics = {} } = {}, isLoading } = usePtpCountersMetrics({ nodeId });
  const styles = useStyles();
  if (isLoading) {
    return <Progress />;
  }

  return (
    <>
      <table className={styles.table}>
        <thead className={styles.header}>
          <tr>
            <th rowSpan={2}>Messages</th>
            <th colSpan={2}>Counter</th>
            <th colSpan={2}>Rate (/sec)</th>
          </tr>
          <tr>
            <th scope="col">Sent</th>
            <th scope="col">Received</th>
            <th scope="col">Sent</th>
            <th scope="col">Received</th>
          </tr>
        </thead>
        <tbody className={styles.body}>
          {pipe(
            BaseMessageCounters,
            D.toPairs,
            A.map(([k, v]) => (
              <RowWithMessageCounters key={k} dataKey={k as BaseCounterKey} label={v} data={metrics} />
            )),
          )}
          <tr>
            <th scope="row">Total</th>
            <td>{metrics.tx?.toLocaleString(...CounterNumberFormat) ?? DataMissingLabel}</td>
            <td>{metrics.rx?.toLocaleString(...CounterNumberFormat) ?? DataMissingLabel}</td>
            <td>{metrics.txPerSec?.toLocaleString(...RateNumberFormat) ?? DataMissingLabel}</td>
            <td>{metrics.rxPerSec?.toLocaleString(...RateNumberFormat) ?? DataMissingLabel}</td>
          </tr>
          <tr>
            <td colSpan={5} style={{ border: 'none' }} />
          </tr>
          <tr>
            <th scope="row">{ErrorCounters.errorRx}</th>
            <td colSpan={4}>{metrics.errorRx?.toLocaleString(...CounterNumberFormat) ?? DataMissingLabel}</td>
          </tr>
          <tr>
            <th scope="row">{ErrorCounters.mgmtErr}</th>
            <td colSpan={4}>{metrics.mgmtErr?.toLocaleString(...CounterNumberFormat) ?? DataMissingLabel}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
