import { A, pipe } from '@mobily/ts-belt';
import { isNullableOrEmpty } from '@netinsight/management-app-common-react';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { getPhysicalInterfaceName } from '../../utils/nodes';

export const usePtpTransmitterSelectedPhysicalInterfaceNames = (index = -1) => {
  const { control } = useFormContext<{ instances: Array<{ interface?: string }> }>();
  const { instances = [] } = useWatch({ control });
  return useMemo(
    () =>
      pipe(
        instances ?? [],
        A.filterWithIndex((instIndex, inst) => !isNullableOrEmpty(inst.interface) && instIndex !== index),
        A.map(inst => getPhysicalInterfaceName(inst.interface!)),
        A.uniq,
      ),
    [instances, index],
  );
};
