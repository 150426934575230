import React, { useCallback, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { AdminStatus, NodeState, VlanInterfaceDefinition } from '@netinsight/node-manager-schema';
import { buttonPropsFromPermission, PermissionResult, useFormStyles } from '@netinsight/management-app-common-react';
import { InterfaceVlanConfigEntry } from './InterfaceVlanConfigEntry';
import { G } from '@mobily/ts-belt';
import { DEFAULT_NAMESPACES } from '../../../../../../constants/nodes';
import { InterfaceUsage, NodeManagerInterfacesFormValue } from '../../../../../../types/nodes';
import { formatInterfaceName } from '../../../../../../utils/nodes';
import { DEFAULT_PRIORITY_CODE_POINT } from '@netinsight/crds';

interface InterfaceVlanConfigProps {
  nodeId: string;
  ifaceIndex: number;
  ifaceName: string;
  usageMap: Record<string, InterfaceUsage>;
  states: Record<string, NodeState.VlanInterface>;
  permission: PermissionResult;
}

const useStyles = makeStyles(theme => ({
  vlanTable: {
    display: 'grid',
    gridTemplateColumns: '2rem 4rem 4rem 3fr 2fr 1fr 6rem',
    gap: theme.spacing(2, 3),
    ['&:empty']: {
      display: 'none',
    },
  },
  vlanTableHeader: {
    fontWeight: 'bold',
  },
}));

export const InterfaceVlanConfig = ({
  nodeId,
  ifaceIndex,
  ifaceName,
  states,
  usageMap,
  permission,
}: InterfaceVlanConfigProps) => {
  const fieldName = `interfaces.${ifaceIndex}.vlanInterfaces` as const;
  const { getValues, control } = useFormContext<NodeManagerInterfacesFormValue>();
  const [expandedRowId, setExpandedRowId] = useState<string>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
    keyName: 'key',
  });
  const handleAddVlan = useCallback(() => {
    const fieldVals = getValues(fieldName);
    const ids = fieldVals?.map(vlan => vlan.id)?.filter(G.isNotNullable) ?? [];
    const newVlanId = Math.max(...ids, 0) + 1;
    const newVlan = {
      id: newVlanId,
      adminStatus: AdminStatus.Up,
      ipAddress: undefined,
      networkNamespace: DEFAULT_NAMESPACES.access, // new vlan get assigned to access namespace by default
      prio: DEFAULT_PRIORITY_CODE_POINT,
    } satisfies VlanInterfaceDefinition;

    append(newVlan);
  }, [append, fieldName, getValues]);

  const formStyles = useFormStyles();
  const styles = useStyles();

  return (
    <Card>
      <CardContent className={formStyles.formContainer}>
        <Typography variant="h6" color="textSecondary" id={`${ifaceName}-vlan`}>
          VLAN Interfaces
        </Typography>
        <div className={styles.vlanTable}>
          {fields.length > 0 ? (
            <>
              <span />
              <Typography
                id={`vlan-table-${ifaceIndex}-label-id`}
                variant="body1"
                component="span"
                color="textSecondary"
                className={styles.vlanTableHeader}
              >
                Id
              </Typography>
              <Typography
                id={`vlan-table-${ifaceIndex}-label-status`}
                variant="body1"
                component="span"
                color="textSecondary"
                className={styles.vlanTableHeader}
              >
                Enabled
              </Typography>
              <Typography
                id={`vlan-table-${ifaceIndex}-label-ipAddress`}
                variant="body1"
                component="span"
                color="textSecondary"
                className={styles.vlanTableHeader}
              >
                IPv4 address
              </Typography>
              <Typography
                id={`vlan-table-${ifaceIndex}-label-namespace`}
                variant="body1"
                component="span"
                color="textSecondary"
                className={styles.vlanTableHeader}
              >
                Network scope
              </Typography>
              <Typography
                id={`vlan-table-${ifaceIndex}-label-priority`}
                variant="body1"
                component="abbr"
                color="textSecondary"
                className={styles.vlanTableHeader}
                title="Priority Code Point"
              >
                PCP
              </Typography>
              <span />
            </>
          ) : null}
          {fields.map((field, index) => (
            <InterfaceVlanConfigEntry
              nodeId={nodeId}
              key={field.key}
              ifaceName={ifaceName}
              ifaceIndex={ifaceIndex}
              index={index}
              onRemove={remove}
              permission={permission}
              states={states}
              usage={usageMap[formatInterfaceName(ifaceName, field.id)]}
              expanded={expandedRowId === field.key}
              onExpandToggle={() => setExpandedRowId(currentKey => (field.key === currentKey ? undefined : field.key))}
            />
          ))}
        </div>
        <div>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleAddVlan}
            data-testid="btn-add-vlan"
            {...buttonPropsFromPermission(permission)}
          >
            Add VLAN
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
