/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import React, { FunctionComponent, useCallback, useContext, useState } from 'react';
import { Link } from '@backstage/core-components';
import { IconButton, makeStyles, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { Alert } from '@material-ui/lab';
import { A, G, pipe } from '@mobily/ts-belt';
import { DurationFormatter, NetiColors, isNullableOrEmpty } from '@netinsight/management-app-common-react';
import { SyncSourceUsage } from '@netinsight/syncd-schema';
import { NodeGraphContext } from './context';
import { SYNC_SOURCE_NAMES } from '@netinsight/management-app-common-api';
import {
  LinkMetrics,
  NodeMetricsViewModel,
  NodeSyncMetricsViewModel,
  SingleLinkMetrics,
} from '../../../types/node-graph';
import { SYNC_SOURCE_LABELS } from '../../../constants/sync';
import { controlStateScale } from '../../../utils/sync';

const useStyles = makeStyles(
  theme => ({
    container: {
      width: '33rem',
      gridRow: '2 / -1',
      gridColumn: '-2 / -1',
      maxWidth: '36rem',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      height: '100%',
      overflowY: 'scroll',
      overflowX: 'auto',
      zIndex: theme.zIndex.mobileStepper - 1,
    },
    alarmLink: {
      textTransform: 'capitalize',
      ['&.critical']: { color: theme.palette.error.main },
      ['&.major']: { color: theme.palette.warning.main },
      ['&.minor']: { color: NetiColors.yellow.main },
      ['&.warning']: { color: theme.palette.info.main },
    },
    table: {
      ['border-collapse']: 'collapse',
      ['&.sub-table']: {
        width: '100%',
        maxWidth: '100%',
        ['& + &']: {
          marginTop: theme.spacing(1),
        },
      },
      ['& td, & th']: {
        ...theme.typography.body2,
        border: '1px solid',
        borderColor: theme.palette.divider,
        padding: theme.spacing(0.5, 0.5),
      },
      ['&> thead th']: {
        fontWeight: 'bold',
        textAlign: 'center',
      },
      ['&> tbody th, &> tbody td']: {},
      ['&> tbody .row-header']: {
        textAlign: 'left',
        width: '8rem',
        maxWidth: '8rem',
        minWidth: '8rem',
        wordWrap: 'break-word',
        whiteSpace: 'wrap',
      },
      ['&> tbody .numeric']: {
        textAlign: 'right',
        fontVariantNumeric: 'tabular-nums',
      },
      ['&> tbody .text']: {
        textAlign: 'center',
      },
      ['&> tbody .icon']: {
        textAlign: 'center',
      },
      ['&> tbody .sub-table-container']: {
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100],
        textAlign: 'center',
      },
    },
  }),
  { name: 'Neti' },
);

const BooleanStatus: FunctionComponent<{ value?: boolean; title?: string }> = ({ value, title }) => {
  const theme = useTheme();
  if (G.isNullable(value) || !value) {
    return <>-</>;
  }
  return <CheckIcon fontSize="small" titleAccess={title} style={{ fill: theme.palette.success.main }} />;
};

const formatAbsTimeUs = (value?: number) =>
  G.isNotNullable(value) ? DurationFormatter.fromSeconds(Math.abs(value)).toMicroSeconds(3) : '-';

const LinkEndpointCells: FunctionComponent<{ data?: SingleLinkMetrics[string] }> = ({ data }) => {
  const profileIdx = data?.selectedProfileIndex;
  const hasProfileIndex = G.isNotNullable(profileIdx) && profileIdx > -1;
  return (
    <>
      <td className="text">{hasProfileIndex ? profileIdx : '-'}</td>
      <td className="numeric">{hasProfileIndex ? formatAbsTimeUs(data?.timeError) : '-'}</td>
      <td className="numeric">{hasProfileIndex ? formatAbsTimeUs(data?.rtt) : '-'}</td>
    </>
  );
};

type NodeGraphDetailPanelProps = {
  nodeMetrics?: NodeMetricsViewModel;
  nodeSyncMetrics?: NodeSyncMetricsViewModel;
  linkMetrics?: LinkMetrics;
};

export const NodeGraphDetailPanel: FunctionComponent<NodeGraphDetailPanelProps> = ({
  nodeMetrics,
  nodeSyncMetrics,
  linkMetrics,
}) => {
  const { state } = useContext(NodeGraphContext);
  const styles = useStyles();
  const [rowExpansion, setRowExpansion] = useState<Record<string, boolean>>({});
  const handleToggleRowExpansion = useCallback(
    (nodeId: string) => {
      setRowExpansion(map => ({ ...map, [nodeId]: !(map[nodeId] ?? false) }));
    },
    [setRowExpansion],
  );

  if (!state.rightBarOpened) {
    return null;
  }
  return (
    <div className={styles.container}>
      {state.selectedNodes.length > 0 ? (
        <table className={styles.table}>
          <thead>
            <tr>
              <th rowSpan={2}>TimeNode</th>
              <th rowSpan={2}>Stable</th>
              <th rowSpan={2}>State</th>
              <th rowSpan={2}>Alarms</th>
              <th colSpan={4}>Time error</th>
            </tr>
            <tr>
              <th>{SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.ppsIn]}</th>
              <th>{SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.gnss]}</th>
              <th>{SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.ptp1]}</th>
              <th>{SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.ptp2]}</th>
            </tr>
          </thead>
          <tbody>
            {pipe(
              state.selectedNodes,
              A.sortBy(n => `${n.parent}/${n.name}`),
              A.map(n => {
                const rowNodeMetrics = nodeMetrics?.[n.id];
                const rowNodeSyncMetrics = nodeSyncMetrics?.[n.id];
                const controlState = rowNodeMetrics?.filteredControlState ?? rowNodeMetrics?.controlState;
                return (
                  <React.Fragment key={n.id}>
                    <tr>
                      <th className="row-header">
                        <IconButton
                          size="small"
                          type="button"
                          aria-label="Toggle node details"
                          title="Toggle node details"
                          onClick={() => handleToggleRowExpansion(n.id)}
                        >
                          {rowExpansion[n.id] === true ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        <Link to={`/nodes/info/${n.id}`} target="_blank">
                          {n.name}
                        </Link>
                      </th>
                      <td className="text">
                        <BooleanStatus value={rowNodeMetrics?.isStable} />
                      </td>
                      <td className="text">{G.isNotNullable(controlState) ? controlStateScale(controlState) : '-'}</td>
                      <td className="text">
                        {G.isNotNullable(rowNodeMetrics?.alarmSeverity) ? (
                          <Link
                            className={classNames(styles.alarmLink, rowNodeMetrics.alarmSeverity)}
                            to={`/nodes/info/${n.id}/alarms`}
                            target="_blank"
                          >
                            {rowNodeMetrics.alarmSeverity}
                          </Link>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td className="numeric">
                        {formatAbsTimeUs(rowNodeMetrics?.timeErrors?.[SYNC_SOURCE_NAMES.ppsIn])}
                      </td>
                      <td className="numeric">
                        {formatAbsTimeUs(rowNodeMetrics?.timeErrors?.[SYNC_SOURCE_NAMES.gnss])}
                      </td>
                      <td className="numeric">
                        {formatAbsTimeUs(rowNodeMetrics?.timeErrors?.[SYNC_SOURCE_NAMES.ptp1])}
                      </td>
                      <td className="numeric">
                        {formatAbsTimeUs(rowNodeMetrics?.timeErrors?.[SYNC_SOURCE_NAMES.ptp2])}
                      </td>
                    </tr>
                    {rowExpansion[n.id] === true ? (
                      <tr>
                        <td colSpan={8} className="sub-table-container">
                          <table className={classNames(styles.table, 'sub-table')}>
                            <thead>
                              <tr>
                                <th>Input</th>
                                <th>Usage</th>
                                <th>Selected</th>
                                <th>Available</th>
                                <th>Priority</th>
                              </tr>
                            </thead>
                            <tbody>
                              {rowNodeSyncMetrics?.inputs.map(
                                (source: {
                                  name: React.Key | null | undefined;
                                  url: string;
                                  label:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                                    | Iterable<React.ReactNode>
                                    | React.ReactPortal
                                    | null
                                    | undefined;
                                  usage: string | undefined;
                                  selected: boolean | undefined;
                                  available: boolean | undefined;
                                  priority: any;
                                }) => (
                                  <tr key={source.name}>
                                    <th className="row-header">
                                      <Link to={source.url} target="_blank">
                                        {source.label}
                                      </Link>
                                    </th>
                                    <td className="icon" title={source.usage}>
                                      {G.isNotNullable(source.usage) ? (
                                        source.usage === SyncSourceUsage.LocalOnly ? (
                                          'Local sync'
                                        ) : (
                                          <BooleanStatus value={source.usage === SyncSourceUsage.Selectable} />
                                        )
                                      ) : null}
                                    </td>
                                    <td className="icon">
                                      <BooleanStatus value={source.selected} />
                                    </td>
                                    <td className="icon">
                                      <BooleanStatus value={source.available} />
                                    </td>
                                    <td className="numeric">{source.priority ?? '-'}</td>
                                  </tr>
                                ),
                              )}
                              {[
                                (rowNodeSyncMetrics?.inputs.length ?? 0) === 0 ? (
                                  <tr>
                                    <td colSpan={5}>There are no sync inputs on this node</td>
                                  </tr>
                                ) : null,
                              ]}
                              <tr>
                                <th className="row-header">
                                  <Link to={`/nodes/info/${n.id}/inputs/ntp`} target="_blank">
                                    NTP
                                  </Link>
                                </th>
                                <td colSpan={2} />
                                <td className="text" title={rowNodeMetrics?.ntpRemote}>
                                  <BooleanStatus value={!isNullableOrEmpty(rowNodeMetrics?.ntpRemote)} />
                                </td>
                                <td />
                              </tr>
                            </tbody>
                          </table>
                          <table className={classNames(styles.table, 'sub-table')}>
                            <thead>
                              <tr>
                                <th>Output</th>
                                <th>Enabled</th>
                              </tr>
                            </thead>
                            <tbody>
                              {rowNodeSyncMetrics?.outputs.map(
                                (output: {
                                  type: React.Key | null | undefined;
                                  url: string;
                                  label:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                                    | Iterable<React.ReactNode>
                                    | React.ReactPortal
                                    | null
                                    | undefined;
                                  subType: any;
                                  count: number;
                                  enabled: boolean | undefined;
                                }) => (
                                  <tr key={output.type}>
                                    <th className="row-header">
                                      <Link to={output.url} target="_blank">
                                        {output.label}
                                        {output.type === 'ptp' && G.isNotNullable(output.subType)
                                          ? ` (${output.subType}${output.count > 1 ? ` × ${output.count}` : ''})`
                                          : ''}
                                      </Link>
                                    </th>
                                    <td className="icon">
                                      <BooleanStatus value={output.enabled} />
                                    </td>
                                  </tr>
                                ),
                              )}
                              {(rowNodeSyncMetrics?.outputs.length ?? 0) === 0 ? (
                                <tr>
                                  <td colSpan={2}>There are no sync outputs on this node</td>
                                </tr>
                              ) : null}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                );
              }),
            )}
          </tbody>
        </table>
      ) : null}
      {state.selectedEdges.length > 0 ? (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Links</th>
              <th>Used</th>
              <th>Stable</th>
              <th>Profile</th>
              <th>Time error (abs)</th>
              <th>RTT</th>
            </tr>
          </thead>
          <tbody>
            {pipe(
              state.selectedEdges,
              A.sortBy(e => e.name),
              A.map(e => (
                <tr key={e.id}>
                  <th className="row-header">
                    <Link to={`/network/links/${e.id}`} target="_blank">
                      {e.name}
                    </Link>
                  </th>
                  <td className="icon">
                    <BooleanStatus value={e.usage >= 2} />
                  </td>
                  <td className="icon" title={e.stability.toString()}>
                    <BooleanStatus value={e.stability >= 2} />
                  </td>
                  <LinkEndpointCells data={linkMetrics?.[e.id]?.[e.source]} />
                </tr>
              )),
            )}
          </tbody>
        </table>
      ) : null}
      {state.selectedNodes.length === 0 && state.selectedEdges.length === 0 ? (
        <Alert variant="outlined" color="info" icon={false}>
          Selected nodes and links are shown here
        </Alert>
      ) : null}
    </div>
  );
};
