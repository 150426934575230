import { G, A, O, pipe } from '@mobily/ts-belt';
import {
  TTLinkEndpoint,
  TT_LINK_DEFAULT_PORT,
  TT_LINK_PORT_MAX,
  TT_LINK_PORT_MIN,
} from '@netinsight/crds-timetransfer';
import { PersistedLinkTarget } from '@netinsight/management-app-common-api';
import { formatInterfaceName, getNodeInterfacePortUsage } from '../nodes';

export const allocateEndpointPort = async (
  endpoint: TTLinkEndpoint,
  getNodeLinks: (nodeId: string) => Promise<PersistedLinkTarget[]>,
): Promise<TTLinkEndpoint> => {
  const { node, iface, vlanId, port } = endpoint;
  if (G.isNotNullable(port)) {
    return endpoint;
  }

  let allocatedPort: number | undefined;
  const linkTargets = pipe(await O.fromPromise(getNodeLinks(node)), O.toNullable);
  if (G.isNullable(linkTargets)) {
    allocatedPort = TT_LINK_DEFAULT_PORT;
  } else {
    const portUsageMap = getNodeInterfacePortUsage(linkTargets);
    const ifacePortUsage = portUsageMap[formatInterfaceName(iface, vlanId)] ?? [];
    const portSet = new Set(ifacePortUsage);

    if (ifacePortUsage.length > 0) {
      const highestPort = Math.min(Math.max(...ifacePortUsage), TT_LINK_PORT_MAX);
      const lowestPort = Math.max(Math.min(...ifacePortUsage), TT_LINK_PORT_MIN);
      const isContinuous = highestPort === lowestPort || highestPort - lowestPort + 1 === ifacePortUsage.length;

      if (!isContinuous) {
        allocatedPort = A.range(lowestPort, highestPort).find(p => !portSet.has(p)) ?? TT_LINK_DEFAULT_PORT;
      } else if (highestPort < TT_LINK_PORT_MAX) {
        allocatedPort = highestPort + 1;
      } else if (lowestPort > TT_LINK_PORT_MIN) {
        allocatedPort = lowestPort - 1;
      } else {
        allocatedPort = TT_LINK_DEFAULT_PORT;
      }
    } else {
      allocatedPort = TT_LINK_DEFAULT_PORT;
    }
  }
  return G.isNumber(allocatedPort) ? { ...endpoint, port: allocatedPort } : endpoint;
};
