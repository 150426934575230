import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';
import z from 'zod';
import { InfoCard, Progress, ResponseErrorPanel } from '@backstage/core-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@material-ui/core';
import { F, G } from '@mobily/ts-belt';
import {
  ConfirmSubmitButton,
  InventoryKinds,
  convertZodSchemaToWebFormSchema,
  useFormStyles,
  useInventoryWatch,
  useNodeManagerConfig,
  useNodeManagerConfigUpdate,
  usePermission,
  useSubmitButtonProps,
  useSubmitConfirmation,
} from '@netinsight/management-app-common-react';
import { AdminStatus, NodeState, PhysicalInterfaceSchema } from '@netinsight/node-manager-schema';
import {
  formatNodeManagerConfig,
  reconcileInterfaceConfigStates,
  getInterfaceStateMap,
  getInterfaceDisplayNameMap,
} from '../../../../../../utils/nodes';
import { InterfaceConfig } from './InterfaceConfig';
import {
  useInterfaceUsage,
  useNodeManagerConfigUpdateConfirmationCallback,
  usePortMetrics,
} from '../../../../../../hooks/nodes';
import { MANAGEMENT_IFACE } from '../../../../../../constants/nodes';
import { NodeManagerConfigWarning, NodeManagerInterfacesFormValue } from '../../../../../../types/nodes';
import { NodeManagerConfigWarnings } from '../common';

const FormSchema = z.object({
  interfaces: z.array(convertZodSchemaToWebFormSchema(PhysicalInterfaceSchema)),
});

export const NodeManagerInterfaces = ({
  nodeId,
  title,
  mode,
}: {
  nodeId: string;
  title?: string;
  mode?: 'management-only' | 'non-management-only' | 'onboarding';
}) => {
  const { state: inventoryState } = useInventoryWatch<NodeState.NodeState>({
    nodeId,
    kind: InventoryKinds.NodeManager,
  });
  const { data: storedConfig, error, isLoading } = useNodeManagerConfig(nodeId);
  const { trigger: uploadConfig, permission: uploadConfigPermission } = useNodeManagerConfigUpdate(nodeId);
  const { isLoading: isLoadingPermission, ...permission } = usePermission(uploadConfigPermission);
  const { data: usageMap = {} } = useInterfaceUsage({ nodeId, noop: mode === 'onboarding' });
  const { data: sfpMetrics } = usePortMetrics(nodeId, { refreshInterval: 10_000 });

  const renderWarnings = useCallback(
    (warnings: NodeManagerConfigWarning[]) => (
      <NodeManagerConfigWarnings warnings={warnings} ifaceDisplayNameMap={getInterfaceDisplayNameMap(inventoryState)} />
    ),
    [inventoryState],
  );
  const confirmationCallback = useNodeManagerConfigUpdateConfirmationCallback({
    nodeId,
    render: renderWarnings,
    noop: mode === 'onboarding',
  });

  const defaultFormValue = useMemo<NodeManagerInterfacesFormValue>(() => {
    const interfaces = storedConfig
      ? reconcileInterfaceConfigStates(storedConfig.interfaces, inventoryState?.transceivers)
      : [];
    return { interfaces };
  }, [storedConfig, inventoryState]);
  const prevDefaultFormValue = usePrevious(defaultFormValue);

  const interfaceStates = useMemo(() => getInterfaceStateMap(inventoryState), [inventoryState]);

  const formMethods = useForm<NodeManagerInterfacesFormValue>({
    mode: 'onChange',
    defaultValues: defaultFormValue,
    resolver: zodResolver(FormSchema),
  });

  const { control, reset, handleSubmit, formState } = formMethods;
  const buttonProps = useSubmitButtonProps({ permission, formState });

  useEffect(() => {
    if (!F.equals(prevDefaultFormValue, defaultFormValue)) {
      reset(defaultFormValue);
    }
  }, [reset, defaultFormValue, prevDefaultFormValue]);
  const { fields } = useFieldArray({
    control,
    name: 'interfaces',
    keyName: 'id',
  });

  const handleReset = useCallback(() => reset(), [reset]);

  const handleSubmitCallback = useCallback(
    async ({ interfaces }: NodeManagerInterfacesFormValue) =>
      await uploadConfig(cfg => formatNodeManagerConfig({ ...cfg, interfaces })),
    [uploadConfig],
  );

  const { onSubmit, onSubmitConfirmed, onCanceled, isCheckingConfirmation, confirmationMessage } =
    useSubmitConfirmation({
      confirmationCallback,
      submitCallback: handleSubmitCallback,
    });

  const formStyles = useFormStyles();

  if (isLoading || isLoadingPermission) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  const interfaceItems = fields.map(({ id, name, adminStatus }, index) => {
    const sfpUid = interfaceStates[name]?.sfpStates?.uid;
    if (
      (mode === 'management-only' && name !== MANAGEMENT_IFACE) ||
      (mode === 'non-management-only' && name === MANAGEMENT_IFACE)
    ) {
      return null;
    }

    return (
      <InterfaceConfig
        key={id}
        index={index}
        name={name}
        nodeId={nodeId}
        permission={permission}
        state={interfaceStates[name]}
        sfpMetrics={typeof sfpUid !== 'undefined' ? sfpMetrics?.[sfpUid] : undefined}
        usageMap={usageMap}
        expanded={
          adminStatus === AdminStatus.Up ||
          G.isNotNullable(interfaceStates[name]?.state?.error) ||
          name === MANAGEMENT_IFACE
        }
      />
    );
  });

  return (
    <InfoCard title={title} titleTypographyProps={{ variant: 'h4' }}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className={formStyles.formContainer}>
          {interfaceItems}
          <div className={formStyles.buttonContainer}>
            <ConfirmSubmitButton
              data-testid="btn-submit"
              confirmationMessage={confirmationMessage}
              onConfirmed={onSubmitConfirmed}
              onCanceled={onCanceled}
              {...buttonProps}
              disabled={buttonProps.disabled || isCheckingConfirmation || G.isNotNullable(confirmationMessage)}
            />
            <Button
              type="button"
              size="large"
              color="default"
              variant="outlined"
              onClick={handleReset}
              {...buttonProps}
              disabled={buttonProps.disabled || isCheckingConfirmation || G.isNotNullable(confirmationMessage)}
            >
              Reset
            </Button>
          </div>
        </form>
      </FormProvider>
    </InfoCard>
  );
};
