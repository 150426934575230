import { useMemo } from 'react';
import { F, G, S } from '@mobily/ts-belt';
import { InventoryKinds, useInventory, useNodeManagerConfig } from '@netinsight/management-app-common-react';
import { AdminStatus, NodeState } from '@netinsight/node-manager-schema';
import { getInterfaceInfos, isElectrical, isManagement } from '../../utils/nodes';
import { InterfaceInfo, InterfaceUsage } from '../../types/nodes';

type InterfaceFilterOptions = {
  namespace?: string;
  statuses?: AdminStatus[];
  includeVlan?: boolean;
  includeManagement?: boolean;
  includeElectrical?: boolean;
  includePtpReceivers?: boolean;
  includePtpTransmitters?: boolean;
};

type InterfaceFilter = (input: InterfaceInfo) => boolean;

const combineFilters = ({
  statuses,
  namespace,
  includeVlan,
  includeElectrical,
  includeManagement,
  includePtpReceivers,
  includePtpTransmitters,
}: InterfaceFilterOptions) => {
  const statusFilter: InterfaceFilter =
    G.isNotNullable(statuses) && statuses.length > 0 ? i => statuses.includes(i.adminStatus) : F.always(true);
  const nsFilter: InterfaceFilter = F.allPass(namespace!, [G.isNotNullable, S.isNotEmpty])
    ? i => i.networkNamespace === namespace
    : F.always(true);
  const vlanFilter: InterfaceFilter = includeVlan ? F.always(true) : i => G.isNullable(i.vlanId);
  const managementFilter: InterfaceFilter = includeManagement ? F.always(true) : i => !isManagement(i);
  const electricalFilter: InterfaceFilter = includeElectrical ? F.always(true) : i => !isElectrical(i.id);
  const ptpTransmitterFilter: InterfaceFilter = includePtpTransmitters
    ? F.always(true)
    : i => G.isNullable(i.usage.ptpTransmitter);
  const ptpReceiverFilter: InterfaceFilter = includePtpReceivers
    ? F.always(true)
    : i => G.isNullable(i.usage.ptpReceiver);
  return F.allPass([
    statusFilter,
    nsFilter,
    vlanFilter,
    managementFilter,
    electricalFilter,
    ptpReceiverFilter,
    ptpTransmitterFilter,
  ]);
};

export const useInterfaceInfos = ({
  nodeId,
  usageMap,
  statuses = [AdminStatus.Up],
  namespace,
  includeVlan = false,
  includeManagement = false,
  includeElectrical = true,
  includePtpReceivers: includePtpReceivers = true,
  includePtpTransmitters: includePtpTransmitters = true,
}: InterfaceFilterOptions & { nodeId: string; usageMap?: Record<string, InterfaceUsage> }) => {
  const {
    data: nodeManagerConfig,
    isLoading: isLoadingNodeManagerConfig,
    error: nodeManagerConfigError,
  } = useNodeManagerConfig(nodeId);
  const {
    data: nodeManagerState,
    isLoading: isLoadingNodeManagerState,
    error: nodeManagerStateError,
  } = useInventory<NodeState.NodeState>({
    nodeId,
    kind: InventoryKinds.NodeManager,
  });
  const allInterfaces = useMemo(
    () =>
      G.isNotNullable(nodeManagerConfig)
        ? getInterfaceInfos(nodeManagerConfig, nodeManagerState?.data, usageMap ?? {})
        : [],
    [nodeManagerConfig, nodeManagerState, usageMap],
  );

  const filteredInterfaces = useMemo(
    () =>
      allInterfaces.filter(
        combineFilters({
          statuses,
          namespace,
          includeVlan,
          includeManagement,
          includeElectrical,
          includePtpReceivers: includePtpReceivers,
          includePtpTransmitters: includePtpTransmitters,
        }),
      ),
    [
      allInterfaces,
      statuses,
      namespace,
      includeVlan,
      includeManagement,
      includeElectrical,
      includePtpTransmitters,
      includePtpReceivers,
    ],
  );
  return {
    data: filteredInterfaces,
    isLoading: isLoadingNodeManagerConfig || isLoadingNodeManagerState,
    error: nodeManagerConfigError ?? nodeManagerStateError,
  };
};
