import { scaleOrdinal } from 'd3-scale';
import React, { FunctionComponent } from 'react';
import { G } from '@mobily/ts-belt';
import { StatusBox, StatusBoxSection } from '@netinsight/management-app-common-react';
import { InterfaceStates, InterfaceUsage } from '../../../../../../types/nodes';
import { PortMetrics } from '../../../../../../hooks/nodes';
import { InterfaceUsageState } from './InterfaceUsageState';
import { NodeError } from '../NodeError';

const duplexModeScale = scaleOrdinal([0, 1, 2], ['Unknown', 'Half', 'Full']).unknown('Unknown');

const unit = (units: TemplateStringsArray, value: any) => {
  return G.isNullable(value) ? undefined : [String(value), ...units].join('');
};

export const roundToPrecision = (value?: number, precision = 3) =>
  G.isNullable(value) || isNaN(value)
    ? undefined
    : Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);

export const InterfaceState: FunctionComponent<{
  nodeId: string;
  name: string;
  index: number;
  state?: InterfaceStates;
  portMetrics?: PortMetrics;
  usage: InterfaceUsage;
}> = ({ name, nodeId, index, state: { state, sfpStates: xcvrState } = {}, portMetrics: sfpMetrics, usage }) => {
  if (G.isNullable(state) && G.isNullable(xcvrState) && G.isNullable(sfpMetrics) && G.isNullable(usage)) {
    return null;
  }
  return (
    <>
      <StatusBox
        showToggle={false}
        statuses={[
          ['Interface name', state?.name ?? xcvrState?.interfaceName],
          ['Operational status', state?.operationalStatus ?? xcvrState?.operationalStatus],
          ['MAC address', state?.macAddress],
          ['IPv4 address', state?.systemIpAddresses?.[0]],
          ['Link speed', state?.speed],
          ['Duplex mode', duplexModeScale(state?.duplexMode ?? 0)],
        ]}
        data-testid={`interface-${index}-statuses`}
      >
        <StatusBoxSection
          key="vendor-info"
          summary="Vendor info"
          statuses={[
            ['Vendor name', xcvrState?.vendorName],
            ['Vendor part number', xcvrState?.vendorPartNr],
            ['Vendor revision', xcvrState?.vendorRevision],
            ['Vendor serial number', xcvrState?.vendorSerialNum],
            ['Date code', xcvrState?.datecode],
          ]}
        />
        <StatusBoxSection
          key="transceiver-status"
          summary="Transceiver status"
          statuses={[
            ['Transceiver temperature', unit`${roundToPrecision(sfpMetrics?.transceiverTemp ?? NaN)} °C`],
            ['Transmitter laser bias', unit`${roundToPrecision(sfpMetrics?.transmitterBias ?? NaN, 2)} mA`],
            ['Transmitter power', unit`${roundToPrecision(sfpMetrics?.transmitterPower ?? NaN, 2)} dBm`],
            ['Receiver power', unit`${roundToPrecision(sfpMetrics?.receiverPower ?? NaN, 2)} dBm`],
          ]}
        />
        <StatusBoxSection
          key="packet-counter"
          summary="Packet counter"
          statuses={[
            ['Rx accepted', sfpMetrics?.receivedPacketCount],
            ['Rx drops', sfpMetrics?.receivedPacketDroppedCount],
            ['Rx errors', sfpMetrics?.receivedPacketErrorCount],
            ['Tx sent', sfpMetrics?.transmittedPacketCount],
            ['Tx drops', sfpMetrics?.transmittedPacketDroppedCount],
            ['Tx errors', sfpMetrics?.transmittedPacketErrorCount],
          ]}
        />
        <InterfaceUsageState usage={usage} nodeId={nodeId} name={name} />
      </StatusBox>
      {state?.error && <NodeError error={state.error} data-testid={`interface-${index}-state-error`} />}
    </>
  );
};
