import { Link } from '@backstage/core-components';
import { G } from '@mobily/ts-belt';
import React, { FunctionComponent } from 'react';
import { SyncSourceName } from '@netinsight/management-app-common-api';
import { SYNC_SOURCE_CONFIG_URLS, SYNC_SOURCE_LABELS } from '../../constants/sync';

export const DuplicatedSyncSourcePriorityWarnings: FunctionComponent<{
  duplicates: [SyncSourceName, number][];
  nodeId: string;
}> = ({ duplicates, nodeId }) => {
  if (G.isNullable(duplicates) || !G.isArray(duplicates) || duplicates.length === 0) {
    return null;
  }

  return (
    <>
      <ul>
        {duplicates.map(([ssn, prio]) => (
          <li key={ssn}>
            <Link to={SYNC_SOURCE_CONFIG_URLS[ssn]?.(nodeId)} target="_blank">
              {SYNC_SOURCE_LABELS[ssn]}
            </Link>{' '}
            has duplicated priority: {prio}
          </li>
        ))}
      </ul>
      <span>Do you want to proceed with this new configuration?</span>
    </>
  );
};
