import classNames from 'classnames';
import React, { FunctionComponent, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { InputTooltip, useTableStyles } from '@netinsight/management-app-common-react';
import { UseGnssMetricsResultData, UseGnssPositionMetricsResultData } from '../../../../../../hooks/sync';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: theme.spacing(3),
    padding: theme.spacing(1, 2),
  },
}));

const MaskedContent = '•••';

export const GnssMetricsTable: FunctionComponent<{
  metrics: UseGnssMetricsResultData;
  position: UseGnssPositionMetricsResultData;
  maskPosition: boolean;
}> = ({ metrics: { systems, satellites }, position, maskPosition }) => {
  const tableStyles = useTableStyles();
  const styles = useStyles();
  const systemRows = useMemo(() => {
    return Object.entries(systems).sort(([k1], [k2]) => k1.localeCompare(k2));
  }, [systems]);

  const satelliteRows = useMemo(() => {
    return Object.entries(satellites).sort(([k1], [k2]) => k1.localeCompare(k2));
  }, [satellites]);

  return (
    <div className={styles.tableContainer}>
      <table className={tableStyles.table}>
        <caption>Position fix</caption>
        <thead>
          <tr>
            <th />
            <th>Value</th>
            <th>
              Deviation (m) <InputTooltip text="Deviation from nominal antenna position" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Latitude</th>
            <td className={maskPosition ? 'masked' : ''}>{maskPosition ? MaskedContent : position.lat?.toFixed(7)}</td>
            <td>{position.northDev?.toFixed(1)}</td>
          </tr>
          <tr>
            <th>Longitude</th>
            <td className={maskPosition ? 'masked' : ''}>{maskPosition ? MaskedContent : position.lon?.toFixed(7)}</td>
            <td>{position.eastDev?.toFixed(1)}</td>
          </tr>
          <tr>
            <th>Altitude (MSL)</th>
            <td className={maskPosition ? 'masked' : ''}>
              {maskPosition ? MaskedContent : position.altMsl?.toFixed(7)}
            </td>
            <td>{position.upDev?.toFixed(1)}</td>
          </tr>
        </tbody>
      </table>
      <table className={tableStyles.table}>
        <caption>GNSS systems</caption>
        <thead>
          <tr>
            <th>Name</th>
            <th>Enabled</th>
            <th>Visible</th>
            <th>Used</th>
          </tr>
        </thead>
        <tbody>
          {systemRows.map(([name, rowData]) => (
            <tr className={classNames({ inactive: rowData.systemEnabled === 'No' })}>
              <th>{name}</th>
              <td>{rowData.systemEnabled}</td>
              <td>{rowData.systemUnusedCount + rowData.systemUsedCount}</td>
              <td>{rowData.systemUsedCount}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <table className={tableStyles.table}>
        <caption>Satellites</caption>
        <thead>
          <tr>
            <th>Id</th>
            <th>Used</th>
            <th>Azimuth</th>
            <th>Elevation</th>
            <th>T-RAIM used</th>
            <th>T-RAIM residual (ns)</th>
            <th>
              S/N (dB) <InputTooltip text="Signal to noise ratio in decibels" />
            </th>
          </tr>
        </thead>
        <tbody>
          {satelliteRows.map(([name, rowData]) => (
            <tr className={classNames({ inactive: rowData.satUsed === 'No' })}>
              <th>{name}</th>
              <td>{rowData.satUsed}</td>
              <td className={maskPosition ? 'masked' : ''}>{maskPosition ? MaskedContent : rowData.satAzimuth}</td>
              <td className={maskPosition ? 'masked' : ''}>{maskPosition ? MaskedContent : rowData.satElevation}</td>
              <td>{rowData.satTraimUsed}</td>
              <td>{rowData.satTraimResidualNs}</td>
              <td>{rowData.satSignalToNoiseRatio === 0 ? '' : rowData.satSignalToNoiseRatio}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
