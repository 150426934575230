import React, { FunctionComponent } from 'react';
import { Control } from 'react-hook-form';
import {
  ClockAccuracySchema,
  ClockVarianceSchema,
  TimeSourceSchema,
  PtpClockQuality as PtpClockQualityType,
} from '@netinsight/crds-timetransfer';
import {
  getOptionsAndDescriptionsFromSchema,
  getSelectOptionFromPair,
  TextField,
  useFormStyles,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import { Typography } from '@material-ui/core';

const removeAutoOption = ([, desc]: [any, string | undefined]) => desc !== 'Auto';

export const PtpClockQuality: FunctionComponent<{
  control: Control<{ ptpClockQuality: PtpClockQualityType }>;
  defaultValues?: PtpClockQualityType;
}> = ({ control, defaultValues }) => {
  const formStyles = useFormStyles();
  const { field: clockAccuracySelectProps } = useTextFieldController({
    control,
    name: `ptpClockQuality.clockAccuracy`,
    label: 'Clock Accuracy',
    schema: ClockAccuracySchema,
    placeholder: defaultValues?.clockAccuracy?.toString() ?? '',
  });

  const { field: clockVarianceInputProps } = useTextFieldController({
    control,
    name: `ptpClockQuality.clockVariance`,
    label: 'Clock Variance',
    placeholder: defaultValues?.clockVariance?.toString() ?? '',
    schema: ClockVarianceSchema,
  });

  const { field: timeSourceSelectProps } = useTextFieldController({
    control,
    name: `ptpClockQuality.timeSource`,
    label: 'Time Source',
    schema: TimeSourceSchema,
    placeholder: defaultValues?.timeSource?.toString() ?? '',
  });
  return (
    <>
      <Typography component="h6" variant="h6">
        Clock Quality
      </Typography>
      <div className={formStyles.formGrid}>
        <TextField fullWidth {...clockAccuracySelectProps}>
          {getOptionsAndDescriptionsFromSchema(ClockAccuracySchema, defaultValues?.clockAccuracy)
            .filter(removeAutoOption)
            .map(getSelectOptionFromPair)}
        </TextField>
        <TextField fullWidth {...clockVarianceInputProps} />
        <TextField fullWidth {...timeSourceSelectProps}>
          {getOptionsAndDescriptionsFromSchema(TimeSourceSchema, defaultValues?.timeSource)
            .filter(removeAutoOption)
            .map(getSelectOptionFromPair)}
        </TextField>
      </div>
    </>
  );
};
