import React, { useMemo, useEffect, useCallback } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';
import { InfoCard, Progress } from '@backstage/core-components';
import { F } from '@mobily/ts-belt';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { NodeState } from '@netinsight/node-manager-schema';
import {
  InventoryKinds,
  useInventoryWatch,
  usePrompt,
  useNodeManagerConfig,
  useFormStyles,
  usePermission,
  useNodeManagerConfigUpdate,
  useSubmitButtonProps,
} from '@netinsight/management-app-common-react';
import { getRouteStateByNamespaces, getNamespaceConfig, getNodeManagerConfig } from '../../../../../../utils/nodes';
import type { NetworkNamespaceConfig } from '../../../../../../types/nodes';
import { NodeManagerNamespace } from './NodeManagerNamespace';
import { useInterfaceInfos } from '../../../../../../hooks/nodes';

const SafeRouting = ({ enabled }: { enabled: boolean }) => {
  usePrompt(enabled);
  return null;
};

export const NodeManagerNetworkNamespaces = ({
  nodeId,
  disableDirtyFormPrompt,
}: {
  nodeId: string;
  disableDirtyFormPrompt?: boolean;
}) => {
  const { state: inventoryState } = useInventoryWatch<NodeState.NodeState>({
    nodeId,
    kind: InventoryKinds.NodeManager,
  });
  const { data: nodeManagerConfig, error, isLoading } = useNodeManagerConfig(nodeId);
  const { trigger: uploadConfig, permission: uploadConfigPermission } = useNodeManagerConfigUpdate(nodeId);
  const { isLoading: isLoadingPermission, ...permission } = usePermission(uploadConfigPermission);

  const routingStatesByNamespace = useMemo(() => getRouteStateByNamespaces(inventoryState), [inventoryState]);
  const { data: interfaceInfos } = useInterfaceInfos({
    nodeId,
    statuses: [],
    includeVlan: true,
    includeManagement: true,
  });
  const defaultFormValues: NetworkNamespaceConfig = useMemo(
    () => getNamespaceConfig(interfaceInfos, nodeManagerConfig),
    [interfaceInfos, nodeManagerConfig],
  );

  const prevDefaultFormValues = usePrevious(defaultFormValues);
  const formMethods = useForm<NetworkNamespaceConfig>({
    mode: 'onChange',
    defaultValues: defaultFormValues,
  });

  const { control, reset, handleSubmit, formState } = formMethods;
  const buttonProps = useSubmitButtonProps({ permission, formState });
  useEffect(() => {
    if (!F.equals(defaultFormValues, prevDefaultFormValues)) {
      reset(defaultFormValues);
    }
  }, [defaultFormValues, prevDefaultFormValues, reset]);

  const { fields } = useFieldArray({
    control,
    name: 'networks',
  });

  const onSubmit = useCallback(
    async (formValues: NetworkNamespaceConfig) => await uploadConfig(cfg => getNodeManagerConfig(cfg, formValues)),
    [uploadConfig],
  );

  const formStyles = useFormStyles();

  if (isLoading || isLoadingPermission) {
    return <Progress />;
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <InfoCard title="Routes" titleTypographyProps={{ variant: 'h4' }}>
      {!disableDirtyFormPrompt ? <SafeRouting enabled={formState.isDirty && !formState.isSubmitted} /> : null}
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className={formStyles.formContainer}>
          {fields.map((field, index) => (
            <NodeManagerNamespace
              name={field.name}
              displayName={field.displayName}
              index={index}
              key={field.id}
              routingState={routingStatesByNamespace[field.name] ?? []}
              interfaceInfos={interfaceInfos}
              permission={permission}
            />
          ))}
          <div className={formStyles.buttonContainer}>
            <Button {...buttonProps} data-testid="btn-submit" />
          </div>
        </form>
      </FormProvider>
    </InfoCard>
  );
};
