import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwrMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { configApi as contract } from '@netinsight/management-app-common-api';
import { apiPermission, errorFromWrappedError, k8sConfigApiRef } from '@netinsight/management-app-common-react';

export const usePtpTransmitterClientsRefresh = (nodeId: string) => {
  const configApi = useApi(k8sConfigApiRef);

  const updateConfig = useCallback(
    async ([nodeIdParam]: any, { arg: index }: { arg: number }) => {
      const result = await configApi.refreshPtpTransmitterClients({ params: { nodeId: nodeIdParam }, body: { index } });

      if (result.status !== StatusCodes.OK) {
        throw errorFromWrappedError(result.status, (result as any).body);
      }
    },
    [configApi],
  );

  const mutation = useSwrMutation([nodeId, 'setPtpTransmitterTimestamp'], updateConfig);

  return { ...mutation, permission: apiPermission(contract.refreshPtpTransmitterClients) };
};
