export const generateNewGraphName = (existingGraphs: Array<{ name: string }> | undefined, prefix = 'New map') => {
  const pattern = new RegExp(`^${prefix}\\s*(\\d+)$`, 'i');
  const existingIndices =
    existingGraphs
      ?.map(({ name }) => pattern.exec(name?.trim())?.[1])
      .filter(Boolean)
      .map(indexStr => parseInt(indexStr!, 10))
      .filter(num => typeof num === 'number' && !isNaN(num)) ?? [];
  const lastNewMapIndex = existingIndices.length === 0 ? 0 : Math.max(...existingIndices);
  return `${prefix} ${lastNewMapIndex + 1}`;
};
