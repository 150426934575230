import React, { FunctionComponent } from 'react';
import { booleanScale, StatusBox, StatusBoxSection } from '@netinsight/management-app-common-react';
import { F, G, A, pipe, D } from '@mobily/ts-belt';
import { useNtpServerStatuses, useNtpTimeStatuses } from '../../../../../../hooks/sync';

const skipIfZeroOrNullish = F.when(F.anyPass([G.isNullable, G.isNot(G.isNumber), F.equals(0)]), F.always(undefined));

const unit = (units: TemplateStringsArray, value: any) =>
  G.isNullable(value) ? undefined : [String(value), ...units].join('');

const SWR_OPTIONS = {
  refreshInterval: 15_000,
};

export const NtpTimeStatusBox: FunctionComponent<{ nodeId: string }> = ({ nodeId }) => {
  const { data: serverStatuses = {}, isLoading: isLoadingServerStatuses } = useNtpServerStatuses(
    { nodeId },
    SWR_OPTIONS,
  );
  const { data: timeStatuses, isLoading: isLoadingTimeStatuses } = useNtpTimeStatuses(
    {
      nodeId,
    },
    SWR_OPTIONS,
  );
  return (
    <StatusBox
      statuses={[
        ['Est. error', unit`${skipIfZeroOrNullish(timeStatuses?.estError)}μs`],
        ['Max error', unit`${skipIfZeroOrNullish(timeStatuses?.maxError)}μs`],
        ['Offset', unit`${skipIfZeroOrNullish(timeStatuses?.offset)}ns`],
        ['Precision', unit`${skipIfZeroOrNullish(timeStatuses?.precision)}ns`],
        ['Frequency', unit`${skipIfZeroOrNullish(timeStatuses?.timeFrequency)?.toFixed(3)} ppm`],
        ['Intervals', skipIfZeroOrNullish(timeStatuses?.intervals)],
      ]}
      showToggle={false}
      isLoading={isLoadingServerStatuses || isLoadingTimeStatuses}
      style={{ marginBottom: '2rem' }}
      data-testid="status-box-ntp-time"
    >
      {pipe(
        serverStatuses,
        D.toPairs,
        A.sortBy(([remote]) => remote),
        A.mapWithIndex((key, [remote, remoteStatuses]) => (
          <StatusBoxSection
            key={`server-${key}`}
            statuses={[
              ['Address', remote],
              ['Selected', booleanScale(remoteStatuses?.selected)],
              ['Delay', `${skipIfZeroOrNullish(remoteStatuses?.delay)?.toFixed(3) ?? 0}ms`],
              ['Jitter', `${skipIfZeroOrNullish(remoteStatuses?.jitter)?.toFixed(3) ?? 0}ms`],
              ['Offset', `${skipIfZeroOrNullish(remoteStatuses?.offset)?.toFixed(3) ?? 0}ms`],
              ['Poll interval', unit`${remoteStatuses.pollInterval}s`],
              ['Reach', remoteStatuses?.reach],
              ['Stratum', remoteStatuses?.stratum],
            ]}
            summary={`Server (IP: ${remote})`}
          />
        )),
      )}
    </StatusBox>
  );
};
