import React, { FunctionComponent, useCallback } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { Badge, Button, IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { G } from '@mobily/ts-belt';
import { DEFAULT_PRIORITY_CODE_POINT, PriorityCodePointSchema } from '@netinsight/crds';
import { AdminStatus, NodeState, VlanInterfaceSchema } from '@netinsight/node-manager-schema';
import {
  buttonPropsFromPermission,
  CheckboxField,
  getSelectOptionsFromSchema,
  PermissionResult,
  StatusBox,
  TextField,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import { MANAGEMENT_IFACE } from '../../../../../../constants/nodes';
import { formatInterfaceName, getDefaultNamespaceOptions, isInterfaceUsed } from '../../../../../../utils/nodes';
import { InterfaceUsage, NodeManagerInterfacesFormValue } from '../../../../../../types/nodes';
import { NodeError } from '../NodeError';
import { InterfaceUsageState } from './InterfaceUsageState';

type InterfaceVlanConfigEntryProps = {
  nodeId: string;
  ifaceIndex: number;
  ifaceName: string;
  index: number;
  onRemove: (index: number) => void;
  permission: PermissionResult;
  states: Record<string, NodeState.VlanInterface>;
  usage?: InterfaceUsage;
  onExpandToggle: VoidFunction;
  expanded: boolean;
};

const CheckboxProps = {
  label: null,
  fromValue: (value?: AdminStatus) => value === AdminStatus.Up,
  toValue: (checked?: boolean) => (checked ? AdminStatus.Up : AdminStatus.Down),
};

const useStyles = makeStyles(
  theme => ({
    vlanCheckbox: {
      justifySelf: 'center',
      alignSelf: 'center',
      margin: 0,
    },
    expandToggle: {
      width: '2rem',
      height: '2rem',
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.infoBackground,
    },
    badge: {
      width: '2rem',
      height: '2rem',
      justifySelf: 'center',
      alignSelf: 'center',
    },
  }),
  { name: 'Neti' },
);

export const InterfaceVlanConfigEntry: FunctionComponent<InterfaceVlanConfigEntryProps> = ({
  nodeId,
  ifaceIndex,
  ifaceName,
  index,
  onRemove,
  permission,
  states,
  usage,
  expanded,
  onExpandToggle,
}) => {
  const styles = useStyles();
  const { getValues, control } = useFormContext<NodeManagerInterfacesFormValue>();
  const handleRemove = useCallback(() => onRemove(index), [onRemove, index]);
  const { field: vlanIdFieldProps } = useTextFieldController({
    control,
    name: `interfaces.${ifaceIndex}.vlanInterfaces.${index}.id`,
    label: null,
    schema: VlanInterfaceSchema.shape.id,
    rules: {
      validate: (id: number) =>
        !(getValues(`interfaces.${ifaceIndex}.vlanInterfaces`) || []).some(
          (vlan, vlanIdx) => vlanIdx !== index && vlan.id === id,
        ) || 'vlan id is not unique',
    },
  });
  const adminStatusFieldProps = useController({
    control,
    name: `interfaces.${ifaceIndex}.vlanInterfaces.${index}.adminStatus`,
  });
  const { field: ipAddressFieldProps } = useTextFieldController({
    control,
    name: `interfaces.${ifaceIndex}.vlanInterfaces.${index}.ipAddress`,
    label: null,
    placeholder: '0.0.0.0/00',
    schema: VlanInterfaceSchema.shape.ipAddress,
  });
  const { field: namespaceFieldProps } = useTextFieldController({
    control,
    name: `interfaces.${ifaceIndex}.vlanInterfaces.${index}.networkNamespace`,
    label: null,
    schema: VlanInterfaceSchema.shape.networkNamespace,
  });
  const { field: prioFieldProps } = useTextFieldController({
    control,
    name: `interfaces.${ifaceIndex}.vlanInterfaces.${index}.prio`,
    label: null,
    schema: VlanInterfaceSchema.shape.prio,
  });

  const vlanId = useWatch({ control, name: `interfaces.${ifaceIndex}.vlanInterfaces.${index}.id` });
  const vlanState = states[formatInterfaceName(ifaceName, vlanId)];
  const isUsed = isInterfaceUsed(usage);
  const hasInterfaceUsageOrState = isUsed || G.isNotNullable(vlanState);
  const badgeColor = isUsed ? 'primary' : 'default';
  return (
    <>
      <Badge
        className={styles.badge}
        variant="dot"
        overlap="circular"
        color={vlanState?.error ? 'secondary' : badgeColor}
      >
        <IconButton
          className={styles.expandToggle}
          size="small"
          aria-label="Toggle details"
          onClick={onExpandToggle}
          disabled={!hasInterfaceUsageOrState}
          color={hasInterfaceUsageOrState ? 'primary' : 'default'}
        >
          {expanded ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
        </IconButton>
      </Badge>

      <TextField
        type="number"
        {...vlanIdFieldProps}
        inputProps={{ ...vlanIdFieldProps.inputProps, 'aria-labelledby': `vlan-table-${ifaceIndex}-label-id` }}
      />
      <CheckboxField fieldProps={adminStatusFieldProps} {...CheckboxProps} className={styles.vlanCheckbox} />
      <TextField
        fullWidth
        {...ipAddressFieldProps}
        inputProps={{
          ...ipAddressFieldProps.inputProps,
          'aria-labelledby': `vlan-table-${ifaceIndex}-label-ipAddress`,
        }}
        description="IP Address should be in CIDR format (i.e. 10.0.0.1/24)"
      />
      <TextField
        fullWidth
        disabled={ifaceName === MANAGEMENT_IFACE}
        {...namespaceFieldProps}
        select
        SelectProps={{ native: true }}
        inputProps={{ 'aria-labelledby': `vlan-table-${ifaceIndex}-label-namespace` }}
      >
        {getDefaultNamespaceOptions()}
      </TextField>
      <TextField
        fullWidth
        select
        SelectProps={{ native: true }}
        {...prioFieldProps}
        inputProps={{ 'aria-labelledby': `vlan-table-${ifaceIndex}-label-priority` }}
        description={PriorityCodePointSchema.description}
      >
        {getSelectOptionsFromSchema(PriorityCodePointSchema, DEFAULT_PRIORITY_CODE_POINT)}
      </TextField>
      <Button
        startIcon={<DeleteIcon />}
        variant="outlined"
        size="small"
        color="default"
        data-testid="btn-remove-vlan"
        style={{ alignSelf: 'baseline' }}
        onClick={handleRemove}
        {...buttonPropsFromPermission(permission)}
      >
        Remove
      </Button>
      {expanded ? (
        <div style={{ gridColumn: '1 / -1' }}>
          <StatusBox
            showToggle={false}
            statuses={[
              ['Operational status', vlanState.operationalStatus],
              ['IPv4 address', vlanState.systemIpAddresses?.[0]],
            ]}
          >
            <InterfaceUsageState name={ifaceName} nodeId={nodeId} usage={usage} />
          </StatusBox>
          {vlanState.error ? <NodeError error={vlanState.error} /> : null}
        </div>
      ) : null}
    </>
  );
};
