import React, { PropsWithChildren } from 'react';
import { InfoCard } from '@backstage/core-components';
import { NtpClientConfig } from './NtpClientConfig';

export const NtpClientConfigCard = ({ nodeId }: PropsWithChildren<{ nodeId: string }>) => {
  return (
    <InfoCard title="NTP client" titleTypographyProps={{ variant: 'h5' }}>
      <NtpClientConfig nodeId={nodeId} />
    </InfoCard>
  );
};
