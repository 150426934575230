import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Link, Progress, ResponseErrorPanel, Table, TableColumn, TableProps } from '@backstage/core-components';
import { useSystemSoftware, useTimeNodes } from '../../../hooks';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { compareSyncRegion, TimeNode } from '@netinsight/management-app-common-api';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import RestoreIcon from '@material-ui/icons/Restore';
import ActivateIcon from '@material-ui/icons/Loop';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  k8sNodeApiRef,
  useSnackbarHelper,
  useTableState,
  SplitButton,
  GrafanaLink,
  useGrafanaDashboards,
  usePermission,
  apiPermission,
  buttonPropsFromPermission,
  StatusBox,
  TableStateHandler,
} from '@netinsight/management-app-common-react';
import { nodeApi as contract } from '@netinsight/management-app-common-api';
import { useApi } from '@backstage/core-plugin-api';
import { DEFAULT_SYNC_REGION, TimeNodeStatusHelper } from '@netinsight/crds';
import { DateTime } from 'luxon';
import CancelIcon from '@material-ui/icons/Cancel';
import { isEqual } from 'lodash';
import { useSyncRegions } from '../../../hooks/sync';
import { getSyncRegionsLookupForTable } from '../../../utils/sync';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  releaseStatusBox: {
    '& dl': {
      gridTemplateColumns: '1fr',
    },
  },
  grafanaLinkInTable: {
    margin: theme.spacing(1, 2.5, 1, 1),
  },
}));

const tableOptions: TableProps['options'] = {
  actionsColumnIndex: -1,
  debounceInterval: 200,
  filtering: true,
  loadingType: 'linear',
  padding: 'dense',
  paging: true,
  pageSize: 20,
  pageSizeOptions: [20, 50, 100],
  search: false,
  thirdSortClick: false,
};

export const TimeNodeUpgradeTable = () => {
  const classes = useStyles();
  const nodeApi = useApi(k8sNodeApiRef);
  const { snackbar } = useSnackbarHelper();
  const { data: versions, error: versionsError, loading: versionsLoading } = useSystemSoftware();
  const { data: nodes = [], error: nodesError, isLoading: nodesLoading, mutate } = useTimeNodes({ interval: 5000 });
  const { data: syncRegions } = useSyncRegions();
  const { data: grafanaDashboards } = useGrafanaDashboards();
  const [rollbackDialog, setRollbackDialog] = React.useState<boolean>(false);
  const [rollbackNode, setRollbackNode] = React.useState<string>('unknown');
  const [selectedNodeName, setSelectedNodeName] = React.useState<string>('unknown');
  const [nextRelease, setNextRelease] = React.useState<string>('unknown');
  const upgradeTableRef = useRef<TableProps>(null);
  const { current: UpgradeTableState } = useRef(new TableStateHandler());

  useEffect(() => {
    if (upgradeTableRef?.current && !UpgradeTableState.hasTableRefSet) {
      UpgradeTableState.setTableRef(upgradeTableRef);
    }
  }, [UpgradeTableState, upgradeTableRef]);

  // Hack to keep the upgrade button states as react state!
  const [upgradeButtonStateMap, setUpgradeButtonStateMap] = React.useState(new Map<string, boolean>());
  const setUpgradeButtonState = (key: string, value: boolean) =>
    setUpgradeButtonStateMap(map => new Map(map.set(key, value)));
  const getUpgradeButtonState = useCallback(
    (key: string) => upgradeButtonStateMap.get(key) ?? false,
    [upgradeButtonStateMap],
  );

  const [activateButtonStateMap, setActivateButtonStateMap] = React.useState(new Map<string, boolean>());
  const setActivateButtonState = (key: string, value: boolean) =>
    setActivateButtonStateMap(map => new Map(map.set(key, value)));
  const getActivateButtonState = useCallback(
    (key: string) => activateButtonStateMap.get(key) ?? true,
    [activateButtonStateMap],
  );

  const handleUpgrade = useCallback(
    async (nodeId: any, immediateActivation: boolean) => {
      const response = await nodeApi.upgradeNode({ params: { nodeId }, body: { immediateActivation } });
      await mutate();
      const method = immediateActivation ? 'Upgrade' : 'Prepare';
      if (response.status === 200) {
        snackbar.info(`${method} requested`);
      } else if (response.status === 403) {
        snackbar.warning(`${method} request denied`);
      } else if (response.status >= 400) {
        snackbar.error(`${method} request failed`);
      }
    },
    [nodeApi, mutate, snackbar],
  );

  const upgradeNodePermission = usePermission(apiPermission(contract.upgradeNode));
  const activateNodePermission = usePermission(apiPermission(contract.activateNode));
  const rollbackNodePermission = usePermission(apiPermission(contract.rollbackNode));
  const cancelLifecycleFailurePermission = usePermission(apiPermission(contract.cancelLifecycleFailure));

  const handleRollback = (nodeId: string, nodeName: string, previousRelease: string) => {
    setRollbackNode(nodeId);
    setSelectedNodeName(nodeName);
    setNextRelease(previousRelease);
    setRollbackDialog(true);
  };

  const handleRollbackCancel = () => setRollbackDialog(false);

  const handleConfirmedRollback = useCallback(
    async (nodeId: string, version: string) => {
      setRollbackDialog(false);
      await nodeApi.rollbackNode({ params: { nodeId } });
      await mutate();
      snackbar.info(`Rollback to version ${version} requested`);
    },
    [nodeApi, mutate, snackbar],
  );

  const handleActivation = useCallback(
    async (nodeId: any) => {
      await nodeApi.activateNode({ params: { nodeId } });
      await mutate();
      snackbar.info('Activation requested');
    },
    [nodeApi, mutate, snackbar],
  );

  const handleAbortedActivation = useCallback(
    async (nodeId: any) => {
      await nodeApi.abortPreparedNode({ params: { nodeId } });
      await mutate();
      snackbar.info('Activation aborted');
    },
    [nodeApi, mutate, snackbar],
  );

  const handleCancelLifecycleFailure = useCallback(
    async (nodeId: any) => {
      await nodeApi.cancelLifecycleFailure({ params: { nodeId } });
      await mutate();
    },
    [nodeApi, mutate],
  );

  const allowUpgrade = useCallback(
    (data: TimeNode) =>
      data.spec.lifecycleState === 'Up' && data.spec.software?.requestedRelease !== versions?.version.release,
    [versions?.version.release],
  );
  const allowDowngrade = useCallback(
    (data: TimeNode) =>
      data.spec.lifecycleState === 'Up' &&
      data.status?.software?.previousRelease &&
      data.status?.software?.installedRelease &&
      data.spec.software?.requestedRelease &&
      data.spec.software?.requestedRelease !== data.status?.software?.previousRelease &&
      data.spec.software?.requestedRelease === versions?.version.release &&
      data.spec.software?.requestedRelease === data.status?.software?.installedRelease,
    [versions?.version.release],
  );
  const allowActivation = (data: TimeNode) => data.spec.lifecycleState === 'WaitForActivation';

  const allowLifecycleFailureCancel = (data: TimeNode) => {
    const lastUpgradeCondition = data.status?.conditions?.find(cond => cond.type === TimeNodeStatusHelper.Upgrade.type);
    const progressing = ['Upgrading', 'Rollback', 'Activating'].includes(data.spec.lifecycleState ?? '');
    return progressing && lastUpgradeCondition?.reason === TimeNodeStatusHelper.Upgrade.reason.Failed;
  };

  const renderActions = useCallback(
    (data: TimeNode) => {
      if (data.spec.nodeType === 'virtual-edge') {
        return <></>;
      }

      if (allowLifecycleFailureCancel(data)) {
        return (
          <Button
            onClick={() => handleCancelLifecycleFailure(data.id)}
            size="small"
            color="secondary"
            variant="outlined"
            data-testid={`btn-abort-upgrade-${data?.spec?.name ?? data.id}`}
            startIcon={<CancelIcon />}
            {...buttonPropsFromPermission(cancelLifecycleFailurePermission)}
          >
            Cancel {data.spec.lifecycleState}
          </Button>
        );
      }

      if (allowDowngrade(data)) {
        return (
          <Button
            onClick={() =>
              handleRollback(data.id, data.spec.name ?? data.id, data.status?.software?.previousRelease ?? 'unknown')
            }
            size="small"
            color="secondary"
            variant="outlined"
            data-testid={`btn-rollback-${data?.spec?.name ?? data.id}`}
            startIcon={<RestoreIcon />}
            {...buttonPropsFromPermission(rollbackNodePermission)}
          >
            Rollback{data.status?.software?.previousRelease ? ` to ${data.status?.software?.previousRelease}` : ''}
          </Button>
        );
      }

      if (allowActivation(data)) {
        return (
          <SplitButton
            data-testid={`btn-activation-${data?.spec?.name ?? data.id}`}
            initialSelectedOption={getActivateButtonState(data.id)}
            options={[
              ['Activate', true],
              ['Abort', false],
            ]}
            size="small"
            buttonTextFormatter={(_value, label) => `${label} ${data.spec?.software?.requestedRelease ?? ''}`}
            buttonProps={{
              startIcon: <ActivateIcon />,
              ...buttonPropsFromPermission(activateNodePermission),
            }}
            disabled={!activateNodePermission.isAllowed}
            color="primary"
            variant="outlined"
            onOptionSelected={value => (value ? handleActivation(data.id) : handleAbortedActivation(data.id))}
            onStateSelected={value => setActivateButtonState(data.id, value)}
          />
        );
      }

      if (allowUpgrade(data)) {
        return (
          <SplitButton
            initialSelectedOption={getUpgradeButtonState(data.id)}
            options={[
              ['Prepare', false],
              ['Upgrade', true],
            ]}
            size="small"
            buttonTextFormatter={(_value, label) => `${label} ${versions?.version.release ?? ''}`}
            buttonProps={{
              startIcon: <SystemUpdateIcon />,
              ...buttonPropsFromPermission(upgradeNodePermission),
            }}
            disabled={!upgradeNodePermission.isAllowed}
            color="primary"
            variant="outlined"
            onOptionSelected={value => handleUpgrade(data.id, value)}
            onStateSelected={value => setUpgradeButtonState(data.id, value)}
          />
        );
      }

      return <></>;
    },
    [
      activateNodePermission,
      allowDowngrade,
      allowUpgrade,
      getActivateButtonState,
      getUpgradeButtonState,
      handleAbortedActivation,
      handleActivation,
      handleUpgrade,
      handleCancelLifecycleFailure,
      rollbackNodePermission,
      upgradeNodePermission,
      cancelLifecycleFailurePermission,
      versions?.version.release,
    ],
  );

  const columns: TableColumn<TimeNode>[] = useMemo(
    () => [
      {
        title: 'Name',
        field: 'spec.name',
        highlight: true,
        sorting: true,
        defaultSort: 'asc',
        customSort: (a, b) => (a.spec.name ?? a.id).localeCompare(b.spec.name ?? b.id),
        render: row => (
          <Link data-testid="row-node-name" to={`/nodes/info/${row.id}`}>
            {row.spec.name ?? row.id}
          </Link>
        ),
      },
      {
        title: 'Sync Region',
        field: 'spec.syncRegion',
        render: data => data.spec.syncRegion ?? DEFAULT_SYNC_REGION,
        customSort: (row1, row2) =>
          compareSyncRegion(row1.spec.syncRegion ?? DEFAULT_SYNC_REGION, row2.spec.syncRegion ?? DEFAULT_SYNC_REGION),
        lookup: getSyncRegionsLookupForTable(syncRegions ?? []),
      },
      {
        title: 'Requested release',
        field: 'spec.software.requestedRelease',
        render: data => (
          <Typography data-testid="row-requested-release">{data.spec.software?.requestedRelease ?? 'N/A'}</Typography>
        ),
      },
      {
        title: 'Installed release',
        field: 'status.software.installedRelease',
        render: data => (
          <Typography data-testid="row-installed-release">
            {data.status?.software?.installedRelease ?? 'Unknown'}
          </Typography>
        ),
      },
      {
        title: 'Message',
        sorting: false,
        render: data => {
          const status = data.status?.conditions?.find(cond => cond.type === TimeNodeStatusHelper.Upgrade.type);
          return (
            <>
              <Typography data-testid="row-message">{status?.message ?? '-'}</Typography>
              <Typography variant="body2" color="textSecondary">
                {status?.lastTransitionTime ? DateTime.fromISO(status?.lastTransitionTime as any).toRelative() : ''}
              </Typography>
            </>
          );
        },
      },
      {
        title: 'Action',
        sorting: false,
        render: renderActions,
      },
    ],
    [syncRegions, renderActions],
  );

  const tableStates = useTableState<TimeNode>(columns, tableOptions as any, 'time-node-upgrade-table');

  const components: TableProps['components'] = useMemo(
    () => ({
      Actions: () => (
        <>
          <Button
            type="button"
            size="small"
            color="default"
            variant="outlined"
            disabled={!UpgradeTableState?.hasActiveFilters()}
            startIcon={<CancelIcon />}
            onClick={() => UpgradeTableState?.clearAllFilters()}
            data-testid="btn-clear-upgradetable-filters"
          >
            Clear Filters
          </Button>
          <GrafanaLink
            className={classes.grafanaLinkInTable}
            dashboardUrl={grafanaDashboards?.['Zyntai TimeNodes/time-node-system-multiple']}
            size="small"
            syncRegionsGetter={() => UpgradeTableState?.getFilters()?.['spec.syncRegion'] ?? []}
          />
        </>
      ),
    }),
    [grafanaDashboards, classes, UpgradeTableState],
  );

  const previousTableData: any = useRef();

  const memoizedTableData = useMemo(() => {
    const dataTableNeeds = nodes.map(node => {
      return {
        id: node?.id,
        spec: {
          name: node?.spec?.name,
          syncRegion: node?.spec?.syncRegion,
          nodeType: node?.spec?.nodeType,
          software: {
            requestedRelease: node?.spec?.software?.requestedRelease,
          },
          lifecycleState: node?.spec?.lifecycleState,
        },
        status: {
          software: {
            previousRelease: node.status?.software?.previousRelease,
            installedRelease: node.status?.software?.installedRelease,
          },
          conditions: (node.status?.conditions ?? []).map(condition => ({
            type: condition?.type,
            message: condition?.message,
            lastTransitionTime: condition?.lastTransitionTime,
            reason: condition?.reason,
          })),
        },
      };
    });

    if (!previousTableData.current || !isEqual(previousTableData.current, dataTableNeeds)) {
      previousTableData.current = dataTableNeeds;
    }
    return previousTableData.current;
  }, [nodes]);

  const isLoading =
    versionsLoading ||
    nodesLoading ||
    upgradeNodePermission.isLoading ||
    activateNodePermission.isLoading ||
    rollbackNodePermission.isLoading;

  return (
    <>
      <div className={classes.container}>
        {versionsError && <ResponseErrorPanel error={versionsError} />}
        {nodesError && <ResponseErrorPanel error={nodesError} />}
        {isLoading && <Progress />}
        {versions && (
          <StatusBox
            className={classes.releaseStatusBox}
            data-testid="timenode-upgrade-status-box"
            statuses={[['Release', versions.version.release]]}
            showToggle={false}
          />
        )}
        <Table<TimeNode>
          title="TimeNodes"
          tableRef={upgradeTableRef}
          data={memoizedTableData}
          components={components}
          {...tableStates}
        />
      </div>
      <Dialog
        open={rollbackDialog}
        onClose={handleRollbackCancel}
        aria-labelledby="rollback-dialog-title"
        aria-describedby="rollback-dialog-description"
      >
        <DialogTitle id="rollback-dialog-title">Confirm version rollback</DialogTitle>
        <DialogContent>
          <DialogContentText id="rollback-dialog-description">
            {`This will attempt to downgrade the node ${selectedNodeName} to version '${nextRelease}'. Rolling back may
            fail! Do you want to roll back?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRollbackCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirmedRollback(rollbackNode, nextRelease)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
