import React, { FunctionComponent, useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { F, G } from '@mobily/ts-belt';
import { TextField, isNullableOrEmpty } from '@netinsight/management-app-common-react';
import { PtpContext } from './PtpContext';
import { usePtpTransmitterSelectedPhysicalInterfaceNames } from '../../../../../../hooks/sync';
import { InterfaceInfo } from '../../../../../../types/nodes';

export const PtpInterfaceSelect: FunctionComponent<{
  index: number;
  description?: string;
  shouldDisable?: (iface: InterfaceInfo) => boolean;
  allowEmpty?: boolean;
}> = ({ index, description, allowEmpty = false, shouldDisable = F.always(false) }) => {
  const { control } = useFormContext<{ instances: Array<{ interface?: string }> }>();
  const { availableInterfaces, interfaceNameMap } = useContext(PtpContext);
  const {
    field: { ref: inputRef, ...interfaceInputControlProps },
    fieldState,
  } = useController({
    name: `instances.${index}.interface`,
    control,
  });
  const currentValue = interfaceInputControlProps.value;
  const hasError = !isNullableOrEmpty(fieldState.error?.message);
  const usedPhysicalInterfaces = usePtpTransmitterSelectedPhysicalInterfaceNames(index) ?? [];

  return (
    <TextField
      label="Interface"
      description={description}
      select
      SelectProps={{ native: true }}
      fullWidth
      inputRef={inputRef}
      error={hasError}
      helperText={hasError ? fieldState.error?.message : undefined}
      {...interfaceInputControlProps}
    >
      {allowEmpty ? <option /> : null}
      {/* The interface might become unavailable after the transmitter/receiver has been configured. */}
      {!isNullableOrEmpty(currentValue) &&
      G.isNullable(availableInterfaces.find(iface => iface.id === currentValue)) ? (
        <option value={currentValue} selected disabled>
          {interfaceNameMap[currentValue] ?? currentValue}
          {' (Unavailable)'}
        </option>
      ) : null}
      {availableInterfaces.map(iface => (
        <option
          key={iface.id}
          value={iface.id}
          disabled={usedPhysicalInterfaces?.includes(iface.name) || shouldDisable(iface)}
        >
          {iface.displayName ?? iface.id}
          {G.isNotNullable(iface.ip) ? ` (${iface.ip})` : ''}
        </option>
      ))}
    </TextField>
  );
};
