import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import useSwrMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { A, G } from '@mobily/ts-belt';
import {
  getScalarResult,
  groupMetricsFromPromise,
  groupMetricsFromPromiseBy2Levels,
  isFulfilledVectorResult,
  PersistedSyncLink,
  ttLinksApi as contract,
} from '@netinsight/management-app-common-api';
import {
  apiPermission,
  errorFromWrappedError,
  k8sTTLinksApiRef,
  useSnackbarHelper,
} from '@netinsight/management-app-common-react';
import { metricsApiRef } from '@netinsight/management-app-common-react';
import { LinkCalibrationEditModel, LinkTimeErrorEditModel, TimeErrorsByTypeAndDuration } from './types';
import { DefaultTimeErrorsDuration } from './constants';
import { NodeLinksByDirectionCacheKey } from '../../../../../hooks/links';

export const useNodeTimeErrorsByTypeAndDuration = (
  nodeId: string,
  durations: string[],
  swrConfig?: SWRConfiguration,
) => {
  const metricsApi = useApi(metricsApiRef);
  const fetcher = useCallback(
    async ([nodeIdParam, durationParams]: [string, string[]]): Promise<TimeErrorsByTypeAndDuration> => {
      const results = await Promise.allSettled([
        ...durationParams.map(duration =>
          metricsApi.instantQuery({
            query: `avg_over_time(neti_hicc_giraffe_pps1_in_err{nodeid="${nodeIdParam}"}[${duration}])`,
          }),
        ),
        ...durationParams.map(duration =>
          metricsApi.instantQuery({
            query: `avg_over_time(neti_hicc_gnss_time_error{nodeid="${nodeIdParam}"}[${duration}])`,
          }),
        ),
        ...durationParams.map(duration =>
          metricsApi.instantQuery({
            query: `avg_over_time(neti_hicc_ptpreceiver_time_error{nodeid="${nodeIdParam}"}[${duration}])`,
          }),
        ),
      ]);
      const [ppsResults, gnssResults, ptpResults] = A.splitEvery(results, durationParams.length);
      const ptpResultArrays = ptpResults.map(r => (isFulfilledVectorResult(r) ? r.value.data.result : []));

      return {
        ppsIn: Object.fromEntries(
          durationParams
            .map(
              (duration, index) =>
                [duration, getScalarResult(val => parseFloat(val), NaN)(ppsResults[index])] as [string, number],
            )
            .map(([k, v]) => [k, G.isNullable(v) || isNaN(v) ? undefined : v]),
        ),
        gnss: Object.fromEntries(
          durationParams
            .map(
              (duration, index) =>
                [duration, getScalarResult(val => parseFloat(val), NaN)(gnssResults[index])] as [string, number],
            )
            .map(([k, v]) => [k, G.isNullable(v) || isNaN(v) ? undefined : v]),
        ),
        ptp: Object.fromEntries(
          durationParams.map((duration, index) => [
            duration,
            Object.fromEntries(
              ptpResultArrays[index].map(arr => [
                arr.metric.name,
                G.isNotNullable(arr.value[1]) ? parseFloat(arr.value[1]) : undefined,
              ]),
            ),
          ]),
        ),
      };
    },
    [metricsApi],
  );

  return useSwr([nodeId, durations, 'node-calibration-time-error-metrics'], fetcher, swrConfig);
};

export const useCalibrationMetrics = (nodeIds: string[], linkIds: string[], swrConfig?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);

  const fetcher = useCallback(
    async ([nodeIdsParam, linkIdsParam]: [string[], string[], string]) => {
      if (
        G.isNullable(nodeIdsParam) ||
        nodeIdsParam.length === 0 ||
        G.isNullable(linkIdsParam) ||
        linkIdsParam.length === 0
      ) {
        return {};
      }
      const linkIdsQuery = linkIdsParam.join('|');
      const nodeIdsQuery = nodeIdsParam.join('|');

      const [
        linkSelectedProfileResult,
        linkRttResult,
        linkTimeErrorResult,
        ppsErrorResult,
        gnssErrorResult,
        ptpRecvErrorResult,
      ] = await Promise.allSettled([
        metricsApi.instantQuery({ query: `neti_hicc_multilink_selected_profile{link_id=~"${linkIdsQuery}"}` }),
        metricsApi.instantQuery({
          query: `avg_over_time(neti_hicc_multilink_filtered_rtt{link_id=~"${linkIdsQuery}"}[${DefaultTimeErrorsDuration}])`,
        }),
        metricsApi.instantQuery({
          query: `avg_over_time(neti_hicc_multilink_link_time_error{link_id=~"${linkIdsQuery}"}[${DefaultTimeErrorsDuration}])`,
        }),
        metricsApi.instantQuery({
          query: `avg_over_time(neti_hicc_giraffe_pps1_in_err{nodeid=~"${nodeIdsQuery}"}[${DefaultTimeErrorsDuration}])`,
        }),
        metricsApi.instantQuery({
          query: `avg_over_time(neti_hicc_gnss_time_error{nodeid=~"${nodeIdsQuery}"}[${DefaultTimeErrorsDuration}])`,
        }),
        metricsApi.instantQuery({
          query: `avg_over_time(neti_hicc_ptpreceiver_time_error{nodeid=~"${nodeIdsQuery}"}[${DefaultTimeErrorsDuration}])`,
        }),
      ]);

      return {
        selectedProfileIndicesByNodeAndLink: groupMetricsFromPromiseBy2Levels(
          linkSelectedProfileResult,
          m => m.link_id,
          m => m.nodeid,
          val => parseInt(val, 10),
        ),
        rttsByNodeAndLink: groupMetricsFromPromiseBy2Levels(
          linkRttResult,
          m => m.link_id,
          m => m.nodeid,
          val => parseFloat(val),
        ),
        timeErrorsByNodeAndLink: groupMetricsFromPromiseBy2Levels(
          linkTimeErrorResult,
          m => m.link_id,
          m => m.nodeid,
          val => parseFloat(val),
        ),
        ppsErrorByNode: groupMetricsFromPromise(
          ppsErrorResult,
          metrics => metrics.nodeid,
          val => parseFloat(val),
        ),
        gnssErrorByNode: groupMetricsFromPromise(
          gnssErrorResult,
          metrics => metrics.nodeid,
          val => parseFloat(val),
        ),
        ptpErrorByNodeAndServiceName: groupMetricsFromPromiseBy2Levels(
          ptpRecvErrorResult,
          metrics => metrics.nodeid,
          metrics => metrics.name,
          val => parseFloat(val),
        ),
      };
    },
    [metricsApi],
  );

  return useSwr([nodeIds, linkIds, 'node-calibration-metrics'], fetcher, swrConfig);
};

export const useLinkTimeErrorsByDuration = (
  linkIds: string[],
  linkErrorDuration: string,
  swrConfig?: SWRConfiguration,
) => {
  const metricsApi = useApi(metricsApiRef);

  const fetcher = useCallback(
    async ([linkIdsParam, durationParam]: [string[], string]) => {
      if (G.isNullable(linkIdsParam) || linkIdsParam.length === 0) {
        return {};
      }
      const linkIdsQuery = linkIdsParam.join('|');

      const [linkTimeErrorResult] = await Promise.allSettled([
        metricsApi.instantQuery({
          query: `avg_over_time(neti_hicc_multilink_link_time_error{link_id=~"${linkIdsQuery}"}[${durationParam}])`,
        }),
      ]);

      return {
        timeErrorsByNodeAndLink: groupMetricsFromPromiseBy2Levels(
          linkTimeErrorResult,
          m => m.link_id,
          m => m.nodeid,
          val => parseFloat(val),
        ),
      };
    },
    [metricsApi],
  );

  return useSwr([linkIds, linkErrorDuration, 'node-calibration-link-spread-time-errors'], fetcher, swrConfig);
};

type SubmitData = {
  submittedData: LinkTimeErrorEditModel;
};

export const useNodeLinksUpdate = (nodeId: string) => {
  const ttLinksApi = useApi(k8sTTLinksApiRef);
  const { snackbar } = useSnackbarHelper();
  const update = useCallback(
    async ([nodeIdParam]: [string, string], { arg: { submittedData } }: { arg: SubmitData }) => {
      const updatedLinks = Object.entries(submittedData).map(
        ([linkId, updatedProfiles]) => [linkId, updatedProfiles] as [string, Record<number, LinkCalibrationEditModel>],
      );
      for (const [linkId, updatedProfiles] of updatedLinks) {
        const existingLinkResponse = await ttLinksApi.getTimeTransferLink({
          params: { id: linkId },
        });
        if (existingLinkResponse.status !== 200) {
          throw errorFromWrappedError(existingLinkResponse.status, existingLinkResponse.body);
        }
        const endpoint = existingLinkResponse.body.config.endpointA.node === nodeIdParam ? 'A' : 'B';
        const sign = endpoint === 'B' ? -1 : +1;
        const updatedLink: PersistedSyncLink = {
          ...existingLinkResponse.body.config,
          profiles:
            existingLinkResponse.body.config.profiles?.map(profile => {
              const newPathDiff = updatedProfiles[profile.index]?.newPathDiff;
              return {
                ...profile,
                delayDifference:
                  G.isNumber(newPathDiff) && !isNaN(newPathDiff) ? newPathDiff * sign : profile.delayDifference,
              };
            }) ?? [],
        };

        const updateResponse = await ttLinksApi.updateTimeTransferLink({
          body: updatedLink,
          params: { id: updatedLink.id },
        });
        if (updateResponse.status !== 200) {
          throw errorFromWrappedError(updateResponse.status, updateResponse.body);
        }
      }
    },
    [ttLinksApi],
  );
  const mutation = useSwrMutation([nodeId, NodeLinksByDirectionCacheKey], update, {
    onSuccess: () => snackbar.notifySuccess('Update'),
    onError: err => snackbar.notifyError('Update', err.response, err.message),
    revalidate: true,
  });
  return { ...mutation, permission: apiPermission(contract.updateTimeTransferLink) };
};
