import { roleHasPermission } from '@netinsight/zyntai-policy';
import { A, G, S } from '@mobily/ts-belt';
import { useRoles } from './useRoles';
import { useMemo } from 'react';

export type PermissionResult = { name: string; isAllowed: boolean; isLoading?: boolean };
export type BasicPermission = { name: string };
type ApiOperation = {
  metadata: { permission: BasicPermission };
};

export const apiPermission = (operation: ApiOperation): BasicPermission => operation.metadata.permission;

export const usePermission = ({ name }: BasicPermission): PermissionResult => {
  const { data: roles, isLoading } = useRoles();

  return useMemo(() => {
    const hasPermission =
      G.isNotNullable(roles) && A.isNotEmpty(roles) && G.isNotNullable(name) && S.isNotEmpty(name)
        ? roles.some(role => roleHasPermission(role, name))
        : false;
    return { name, isAllowed: hasPermission, isLoading };
  }, [name, isLoading, roles]);
};
