import React, { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { PtpReceiverSchema, SyncInputUsage, ptpReceiverDisplayName } from '@netinsight/crds-timetransfer';
import {
  CheckboxField,
  getSelectOptionsFromSchema,
  TextField,
  useFormStyles,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import { PtpReceiverClockQuality } from './PtpReceiverClockQuality';
import type { PtpReceiverConfigFormValues } from './PtpReceiverConfigForm';
import { PtpReceiverConfiguration } from './PtpReceiverConfiguration';
import { PtpReceiverStatusBox } from './PtpReceiverStatusBox';
import { UsePtpReceiverStatusMetricsResultData } from '../../../../../../hooks/sync';

const Schema = PtpReceiverSchema.innerType();

export const PtpReceiverConfigEntry: FunctionComponent<{
  nodeId: string;
  index: number;
  clockId?: string;
  metrics?: UsePtpReceiverStatusMetricsResultData;
}> = ({ index, clockId, metrics }) => {
  const { control } = useFormContext<PtpReceiverConfigFormValues>();
  const styles = useFormStyles();
  const useAsReferenceProps = useController({
    control,
    name: `instances.${index}.useAsReference`,
  });

  const { field: usageFieldProps } = useTextFieldController({
    control,
    name: `instances.${index}.usage`,
    label: 'Usage',
    schema: Schema.shape.usage,
  });

  const { field: priorityInputProps } = useTextFieldController({
    control,
    name: `instances.${index}.priority`,
    schema: Schema.shape.priority,
    label: 'Priority',
    placeholder: '160',
  });

  return (
    <Accordion
      data-testid={`ptp-receiver-${index}-container`}
      variant="elevation"
      style={{ width: '100%' }}
      defaultExpanded
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6" id={`ptp-receiver-${index}`}>
          {ptpReceiverDisplayName(index)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.formContainer}>
        <PtpReceiverStatusBox control={control} index={index} clockId={clockId} metrics={metrics} />
        <Paper className={styles.formSection} component="fieldset">
          <div className={styles.formRow}>
            <TextField {...usageFieldProps}>{getSelectOptionsFromSchema(SyncInputUsage)}</TextField>
            <TextField {...priorityInputProps} />
          </div>
          <CheckboxField
            label="Use as reference"
            description={Schema.shape.useAsReference.description}
            fieldProps={useAsReferenceProps}
          />
        </Paper>
        <Paper className={styles.formSection} component="fieldset">
          <PtpReceiverConfiguration index={index} control={control} />
        </Paper>
        <Paper className={styles.formSection} component="fieldset">
          <PtpReceiverClockQuality index={index} control={control} />
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};
