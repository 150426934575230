import React, { FunctionComponent, PropsWithChildren, useCallback } from 'react';
import { useController, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { Button, IconButton, Typography } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/CloseOutlined';
import CheckIcon from '@material-ui/icons/Check';
import NoteIcon from '@material-ui/icons/Chat';
import WarningIcon from '@material-ui/icons/Warning';
import { Alert } from '@material-ui/lab';
import { F, G } from '@mobily/ts-belt';
import { TTLinkProfileSchema } from '@netinsight/crds-timetransfer';
import {
  buttonPropsFromPermission,
  CheckboxField,
  PermissionResult,
  PopButton,
  TextField,
  useFormStyles,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import { PersistedSyncLink } from '@netinsight/management-app-common-api';
import { useLinkProfileCalculation } from '../../../hooks/time-transfer';
import { LinkDetailProfileCalculateButton } from './LinkDetailProfileCalculateButton';
import { formatProfileSelectionStatus } from '../../../utils/time-transfer';

export type LinkDetailProfileRowProps = {
  data: PersistedSyncLink['profiles'][number];
  linkId: string;
  endpointA: string;
  endpointB: string;
  index: number;
  selectedProfileIndices: Record<string, number>;
  isExpanded: boolean;
  toggleExpansion: VoidFunction;
  onRemove: UseFieldArrayRemove;
  permission: PermissionResult;
};

export const LinkDetailProfileRow: FunctionComponent<PropsWithChildren<LinkDetailProfileRowProps>> = ({
  data,
  endpointA,
  endpointB,
  index,
  selectedProfileIndices,
  onRemove = F.ignore,
  isExpanded,
  toggleExpansion,
  permission,
}) => {
  const formStyles = useFormStyles();
  const { control } = useFormContext<PersistedSyncLink>();
  const handleRemove = useCallback(() => onRemove(index), [index, onRemove]);
  const buttonProps = buttonPropsFromPermission(permission);
  const selectableProps = useController({
    control,
    name: `profiles.${index}.selectable`,
  });

  const { field: delayFromRemoteFieldProps } = useTextFieldController({
    control,
    name: `profiles.${index}.delayFromRemote`,
    numberPrecision: 3,
    schema: TTLinkProfileSchema.shape.delayFromRemote,
  });

  const { field: delayToRemoteFieldProps } = useTextFieldController({
    control,
    name: `profiles.${index}.delayToRemote`,
    numberPrecision: 3,
    schema: TTLinkProfileSchema.shape.delayToRemote,
  });

  const { field: roundtripTimeFieldProps } = useTextFieldController({
    control,
    name: `profiles.${index}.roundtripTime`,
    numberPrecision: 3,
    schema: TTLinkProfileSchema.shape.roundtripTime,
  });

  const { field: delayDiffFieldProps, fieldState: delayDiffFieldState } = useTextFieldController({
    control,
    name: `profiles.${index}.delayDifference`,
    numberPrecision: 3,
    schema: TTLinkProfileSchema.shape.delayDifference,
  });

  const { field: commentFieldProps } = useTextFieldController({
    control,
    name: `profiles.${index}.comment`,
    label: 'Comment',
    schema: TTLinkProfileSchema.shape.comment,
  });

  const selectionStatus = formatProfileSelectionStatus(selectedProfileIndices, endpointA, endpointB, data.index);
  useLinkProfileCalculation({ index });

  return (
    <>
      <tr data-testid={`profile-row-${index}`}>
        <td className="icon">
          {G.isNotNullable(selectionStatus) ? <CheckIcon fontSize="small" titleAccess={selectionStatus} /> : null}
        </td>
        <td className="icon">
          <CheckboxField label="" fieldProps={selectableProps} />
        </td>
        <td className="value numeric" style={{ width: '5rem' }}>
          {data.index}
        </td>
        <td className="input error-container">
          <TextField
            fullWidth
            {...roundtripTimeFieldProps}
            inputProps={{ ...roundtripTimeFieldProps.inputProps, step: 1e-3 }}
            description=""
            unit="μs"
          />
        </td>
        <td className="input error-container">
          <TextField
            fullWidth
            {...delayDiffFieldProps}
            inputProps={{ ...delayDiffFieldProps.inputProps, step: 1e-3 }}
            description=""
            helperText=""
            unit="μs"
          />
          {G.isNotNullable(delayDiffFieldState.error?.message) ? (
            <div className="cell-error" title={delayDiffFieldState.error?.message}>
              !
            </div>
          ) : null}
        </td>
        <td className="input error-container">
          <TextField
            fullWidth
            {...delayToRemoteFieldProps}
            inputProps={{ ...delayToRemoteFieldProps.inputProps, step: 1e-3 }}
            description=""
            unit="μs"
          />
        </td>
        <td className="input error-container">
          <TextField
            fullWidth
            {...delayFromRemoteFieldProps}
            inputProps={{ ...delayFromRemoteFieldProps.inputProps, step: 1e-3 }}
            description=""
            unit="μs"
          />
        </td>
        <td className="text small" style={{ width: '8rem' }}>
          {data.lastModified}
        </td>
        <td className="icon">
          <IconButton onClick={toggleExpansion}>
            <NoteIcon color={isExpanded ? 'primary' : 'action'} fontSize="small" />
          </IconButton>
        </td>
        <td className="action">
          <LinkDetailProfileCalculateButton index={index} {...buttonProps} />
        </td>
        <td className="action">
          <PopButton
            variant="outlined"
            color="default"
            size="small"
            startIcon={<RemoveIcon fontSize="small" />}
            data-testid="btn-remove-profile"
            label="Remove"
            containerProps={{ className: '' }}
            render={onClose => (
              <Alert
                variant="outlined"
                color="error"
                icon={<WarningIcon />}
                classes={{ message: formStyles.formContainer }}
              >
                <Typography variant="body1" component="p">
                  This profile will be removed, do you want to continue?
                </Typography>
                <div className={formStyles.buttonContainer}>
                  <Button
                    type="button"
                    onClick={handleRemove}
                    color="secondary"
                    variant="contained"
                    size="small"
                    data-testid="btn-remove-profile-confirm"
                  >
                    Remove
                  </Button>
                  <Button variant="outlined" color="default" size="small" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </Alert>
            )}
            {...buttonProps}
          />
        </td>
      </tr>
      {isExpanded ? (
        <tr>
          <td colSpan={11}>
            <div className={formStyles.formContainer}>
              <TextField fullWidth {...commentFieldProps} multiline />
              <TextField fullWidth label="Created By" value={data.createdBy} type="text" aria-readonly disabled />
            </div>
          </td>
        </tr>
      ) : null}
    </>
  );
};
