import React, { FunctionComponent } from 'react';
import { StatusBoxSection } from '@netinsight/management-app-common-react';
import { Link } from '@backstage/core-components';
import { getInterfaceUsageLinkProps, isInterfaceUsed } from '../../../../../../utils/nodes';
import { InterfaceUsage } from '../../../../../../types/nodes';

type LinkUsage = Exclude<ReturnType<typeof getInterfaceUsageLinkProps>['links'], undefined>[number];
const compareLink = (l1: LinkUsage, l2: LinkUsage) => l1.children.localeCompare(l2.children);

export const InterfaceUsageState: FunctionComponent<{
  nodeId: string;
  name: string;
  usage: InterfaceUsage | undefined;
}> = ({ name, nodeId, usage }) => {
  const isUsed = isInterfaceUsed(usage);
  if (!isUsed) {
    return null;
  }
  const usageUrls = getInterfaceUsageLinkProps(name, nodeId, usage);
  return (
    <StatusBoxSection
      key="usage"
      summary="Usage"
      open={isUsed}
      statuses={[
        ['PTP Receiver', usageUrls.ptpReceiver ? <Link {...usageUrls.ptpReceiver} /> : null],
        ['PTP Transmitter', usageUrls.ptpTransmitter ? <Link {...usageUrls.ptpTransmitter} /> : null],
        ['SyncE', usageUrls.syncE ? <Link {...usageUrls.syncE} /> : null],
        [
          'Links',
          (usageUrls.links?.length ?? 0) > 0 ? (
            <ul>
              {usageUrls.links.sort(compareLink).map(link => (
                <li key={link.to}>
                  <Link {...link} />
                </li>
              ))}
            </ul>
          ) : null,
          { style: { gridColumn: '1 / -1' } },
        ],
      ]}
    />
  );
};
