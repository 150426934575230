import React, { FunctionComponent, useCallback, useState } from 'react';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';
import { Link } from '@backstage/core-components';
import { Button, Paper, Typography } from '@material-ui/core';
import { LinkOptionSchema, GlobalLinkOptions, TTLinkProfileSchema } from '@netinsight/crds-timetransfer';
import { PersistedSyncLink, PersistedSyncLinkSchema } from '@netinsight/management-app-common-api';
import {
  buttonPropsFromPermission,
  CheckboxField,
  getTooltipForWrappedBooleanSchema,
  InputTooltip,
  PermissionResult,
  useFormStyles,
  useTableStyles,
} from '@netinsight/management-app-common-react';
import { LinkDetailProfileRow } from './LinkDetailProfileRow';
import { LinkProfileCreateButton } from '../common';

export const LinkProfileFormTable: FunctionComponent<{
  data: PersistedSyncLink;
  selectedProfileIndices: Record<string, number>;
  defaultLinkOptions: GlobalLinkOptions;
  permission: PermissionResult;
}> = ({ data, selectedProfileIndices, defaultLinkOptions, permission }) => {
  const { control, setValue } = useFormContext<PersistedSyncLink>();
  const tableStyles = useTableStyles();
  const formStyles = useFormStyles();
  const updateButtonProps = buttonPropsFromPermission(permission);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'profiles',
  });
  const autoCalibrationProps = useController({
    control,
    name: 'autoCalibration',
    defaultValue: true,
  });
  const deleteProfileOnChangeCheckboxProps = useController({
    control,
    name: 'options.deleteProfilesOnChange',
    defaultValue: defaultLinkOptions.deleteProfilesOnChange,
  });
  const autoCalibrationDeleteProfilesCheckboxProps = useController({
    control,
    name: 'options.autoCalibrationDeletesAllProfiles',
    defaultValue: defaultLinkOptions.autoCalibrationDeletesAllProfiles,
  });
  const trustPathDiffCheckboxProps = useController({
    control,
    name: 'trustPathDiff',
  });

  const [rowExpansion, toggleRowExpansion] = useState<Record<string, boolean>>({});
  const handleToggle = useCallback(
    (id: string) => toggleRowExpansion(map => ({ ...map, [id]: !(map[id] ?? false) })),
    [toggleRowExpansion],
  );

  const handleReset = useCallback(() => {
    const setValueOptions = { shouldDirty: true, shouldTouch: true };
    setValue('autoCalibration', defaultLinkOptions.autoCalibration, setValueOptions);
    setValue(
      'options.autoCalibrationDeletesAllProfiles',
      defaultLinkOptions.autoCalibrationDeletesAllProfiles,
      setValueOptions,
    );
    setValue('options.deleteProfilesOnChange', defaultLinkOptions.deleteProfilesOnChange, setValueOptions);
  }, [setValue, defaultLinkOptions]);

  return (
    <>
      <Paper component="fieldset" className={formStyles.formSection}>
        <Typography variant="body2" component="p">
          These settings will override <Link to="/network/link-options">global link profile settings</Link>.
        </Typography>
        <div className={formStyles.formGrid}>
          <CheckboxField
            fieldProps={autoCalibrationProps}
            label="Enable auto-calibration"
            description={
              getTooltipForWrappedBooleanSchema(
                PersistedSyncLinkSchema.shape.autoCalibration,
                defaultLinkOptions.autoCalibration,
              ) ?? ''
            }
          />
          <CheckboxField
            fieldProps={deleteProfileOnChangeCheckboxProps}
            label="Delete profiles on change"
            description={
              getTooltipForWrappedBooleanSchema(
                LinkOptionSchema.shape.deleteProfilesOnChange,
                defaultLinkOptions.deleteProfilesOnChange,
              ) ?? ''
            }
            fromValue={val => val ?? false}
            toValue={checked => checked ?? false}
          />
          <CheckboxField
            fieldProps={autoCalibrationDeleteProfilesCheckboxProps}
            label="Delete profiles on auto-calibration"
            description={
              getTooltipForWrappedBooleanSchema(
                LinkOptionSchema.shape.autoCalibrationDeletesAllProfiles,
                defaultLinkOptions.autoCalibrationDeletesAllProfiles,
              ) ?? ''
            }
            fromValue={val => val ?? false}
            toValue={checked => checked ?? false}
          />
          <div style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
            <Button
              size="small"
              variant="outlined"
              color="default"
              onClick={handleReset}
              data-testid="btn-reset-link-profile-settings"
              {...updateButtonProps}
            >
              Reset to global defaults
            </Button>
          </div>
        </div>
      </Paper>
      <Paper component="fieldset" className={formStyles.formSection}>
        <div className={formStyles.formGrid}>
          <CheckboxField
            label="Trust the delay difference on this link"
            description={PersistedSyncLinkSchema.shape.trustPathDiff.description}
            fieldProps={trustPathDiffCheckboxProps}
            fromValue={val => val ?? false}
            toValue={checked => checked ?? false}
          />
        </div>
      </Paper>
      <table className={tableStyles.table} data-testid="link-profiles-table">
        <thead>
          <tr>
            <th>Selected</th>
            <th>Selectable</th>
            <th>Index</th>
            <th>RTT</th>
            <th>
              Delay difference <InputTooltip text={TTLinkProfileSchema.shape.delayDifference.description} />
            </th>
            <th>Delay from A&nbsp;to&nbsp;B</th>
            <th>Delay from B&nbsp;to&nbsp;A</th>
            <th>Last modified</th>
            <th>Note</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {fields.length === 0 ? (
            <tr>
              <td colSpan={11} style={{ padding: '0.5rem', textAlign: 'center' }}>
                No profiles
              </td>
            </tr>
          ) : null}
          {fields.map((field, index) => (
            <LinkDetailProfileRow
              key={field.id}
              data={field}
              linkId={data.id}
              index={index}
              onRemove={remove}
              endpointA={data.endpointA.node}
              endpointB={data.endpointB.node}
              selectedProfileIndices={selectedProfileIndices}
              isExpanded={rowExpansion[field.id] === true}
              toggleExpansion={() => handleToggle(field.id)}
              permission={permission}
            />
          ))}
        </tbody>
      </table>
      <div>
        <LinkProfileCreateButton append={append as any} {...updateButtonProps} />
      </div>
    </>
  );
};
