import { A, flow, O, pipe } from '@mobily/ts-belt';
import { ClusteredNode, WithPath, Node } from '../../types/node-graph';

type FnGetParentName = (path: readonly string[]) => string;

const groupByParentPath =
  (fnParentName: FnGetParentName) =>
  <TNode extends Node>(nodes: readonly WithPath<TNode>[]): readonly ClusteredNode<TNode>[] => {
    return pipe(
      nodes,
      A.filter(n => n.path.length === 1),
      A.map(n => ({ ...n, parent: undefined, level: 1 })),
      A.concat(
        pipe(
          nodes,
          A.filter(n => n.path.length > 1),
          A.map(n => ({ ...n, parent: fnParentName(A.take(n.path, n.path.length - 1)), level: n.path.length })),
          nodesWithParents =>
            nodesWithParents.length === 0
              ? nodesWithParents
              : A.concat(
                  nodesWithParents,
                  pipe(
                    nodesWithParents,
                    A.map(n => A.take(n.path, n.path.length - 1)),
                    A.uniq,
                    A.map(
                      parentPath =>
                        ({
                          name: fnParentName(parentPath),
                          path: parentPath,
                          level: parentPath.length,
                          parent: pipe(parentPath, A.take(parentPath.length - 1), fnParentName),
                        }) as ClusteredNode<TNode>,
                    ),
                    groupByParentPath(fnParentName),
                  ),
                ),
        ),
      ),
    );
  };

export const createClusters = <T extends Node>(
  nodes: readonly T[],
  fnGetPath: (node: T) => readonly string[],
  fnGetParentName: FnGetParentName,
): readonly ClusteredNode<T>[] =>
  pipe(
    nodes,
    A.map((n: T) => {
      const path = fnGetPath(n);
      return { ...n, path, level: path.length } as WithPath<T>;
    }),
    groupByParentPath(fnGetParentName),
  );

export const clusteringStrategies = {
  turkTelekom: (nodes: Node[]) => {
    const NODE_NAME_PATTERN = /(\d{2})(\d{2,3})-([^-]+-[^-]+-?[^-]*)+-([^-]+)-(\d{1,2})/i;

    const name2Path = flow(
      (name: string) => O.fromNullable(NODE_NAME_PATTERN.exec(name)),
      O.getWithDefault<string[]>([]),
      A.tailOrEmpty,
    );

    const path2Name: FnGetParentName = ([head, ...tail]) => head + tail.join('-');
    return createClusters(nodes, node => name2Path(node.name), path2Name);
  },
  default: (nodes: WithPath<Node>[]) => {
    const path2Name: FnGetParentName = path => path[path.length - 1];
    return createClusters(nodes, n => n.path, path2Name);
  },
};
