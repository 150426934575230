import useSwr, { SWRConfiguration } from 'swr';
import { merge as deepMerge } from 'ts-deepmerge';
import { useApi } from '@backstage/core-plugin-api';
import { ValueOf, metricsApiRef } from '@netinsight/management-app-common-react';
import {
  CONTROL_STATES,
  groupMetricsFromPromise,
  groupMetricsFromPromiseBy2Levels,
  SYNC_SOURCE_NAMES,
} from '@netinsight/management-app-common-api';
import { getLinkId, getNodeId, getSyncSourceName, syncSourceSelectionMetadataMapper } from '../../utils/node-graph';

export const useGraphMetrics = (options?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);

  return useSwr(
    'useGraphMetrics',
    async () => {
      const [
        controlStateResult,
        filteredControlStateResult,
        ppsInErrorResult,
        gnssErrorResult,
        ptpOffsetResult,
        nodeStabilityResult,
        syncSourceSelectionResult,
        syncSourcePriorityResult,
        syncSourceAvailableResult,
        ntpSelectedResult,
        linkSelectedProfileResult,
        linkTimeErrorResult,
        linkRttResult,
      ] = await Promise.allSettled([
        metricsApi.instantQuery({ query: 'neti_hicc_hicc_control_state' }),
        metricsApi.instantQuery({ query: 'neti_hicc_hicc_filtered_control_state' }),
        metricsApi.instantQuery({ query: 'neti_hicc_giraffe_pps1_in_err' }),
        metricsApi.instantQuery({ query: 'neti_hicc_gnss_time_error' }),
        metricsApi.instantQuery({ query: 'neti_hicc_ptpreceiver_time_error' }),
        metricsApi.instantQuery({ query: 'neti_hicc_nodestability_is_stable' }),
        metricsApi.instantQuery({
          query: `last_over_time(neti_hicc_syncsource_is_selected{type!="LINK"}[30s])`,
        }),
        metricsApi.instantQuery({ query: `neti_hicc_syncsource_priority{type!="LINK"}` }),
        metricsApi.instantQuery({ query: `neti_hicc_syncsource_available{type!="LINK"}` }),
        metricsApi.instantQuery({ query: 'last_over_time(neti_ntp_status_selected[30s]) > 0' }),
        metricsApi.instantQuery({ query: `neti_hicc_multilink_selected_profile` }),
        metricsApi.instantQuery({ query: 'neti_hicc_multilink_link_time_error' }),
        metricsApi.instantQuery({ query: 'neti_hicc_multilink_filtered_rtt' }),
      ]);

      const timeErrors = deepMerge(
        groupMetricsFromPromise(ptpOffsetResult, getNodeId, (val, metrics) => ({
          timeErrors: {
            [metrics.name as typeof SYNC_SOURCE_NAMES.ptp1 | typeof SYNC_SOURCE_NAMES.ptp2]: parseFloat(val),
          },
        })),
        groupMetricsFromPromise(ppsInErrorResult, getNodeId, val => ({
          timeErrors: { [SYNC_SOURCE_NAMES.ppsIn]: parseFloat(val) },
        })),
        groupMetricsFromPromise(gnssErrorResult, getNodeId, val => ({
          timeErrors: { [SYNC_SOURCE_NAMES.gnss]: parseFloat(val) },
        })),
      );

      const nodeMetrics = deepMerge(
        groupMetricsFromPromise(filteredControlStateResult, getNodeId, val => ({
          filteredControlState: val as ValueOf<typeof CONTROL_STATES>,
        })),
        groupMetricsFromPromise(controlStateResult, getNodeId, val => ({
          controlState: val as ValueOf<typeof CONTROL_STATES>,
        })),
        groupMetricsFromPromise(nodeStabilityResult, getNodeId, val => ({ isStable: Boolean(parseInt(val, 10)) })),
        groupMetricsFromPromise(ntpSelectedResult, getNodeId, (_, metrics) => ({ ntpRemote: metrics.remote })),
        timeErrors,
      );

      const syncSourceMetrics = deepMerge(
        groupMetricsFromPromiseBy2Levels(
          syncSourceSelectionResult,
          getNodeId,
          getSyncSourceName,
          syncSourceSelectionMetadataMapper,
        ),
        groupMetricsFromPromiseBy2Levels(syncSourcePriorityResult, getNodeId, getSyncSourceName, val => ({
          priority: parseInt(val, 10),
        })),
        groupMetricsFromPromiseBy2Levels(syncSourceAvailableResult, getNodeId, getSyncSourceName, val => ({
          available: Boolean(parseInt(val, 10)),
        })),
      );

      const linkMetrics = deepMerge(
        groupMetricsFromPromiseBy2Levels(linkSelectedProfileResult, getLinkId, getNodeId, val => ({
          selectedProfileIndex: parseInt(val, 10),
        })),
        groupMetricsFromPromiseBy2Levels(linkTimeErrorResult, getLinkId, getNodeId, val => ({
          timeError: parseFloat(val),
        })),
        groupMetricsFromPromiseBy2Levels(linkRttResult, getLinkId, getNodeId, val => ({
          rtt: parseFloat(val),
        })),
      );

      return { nodeMetrics, syncSourceMetrics, linkMetrics };
    },
    options,
  );
};
