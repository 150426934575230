import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import usePrevious from 'react-use/lib/usePrevious';
import { zodResolver } from '@hookform/resolvers/zod';
import { F, G } from '@mobily/ts-belt';
import {
  PtpReceiversConfig,
  PtpReceiversConfigSchema,
  TIMETRANSFER_DEFAULT_CONFIG,
  ptpReceiverDisplayName,
} from '@netinsight/crds-timetransfer';
import {
  ConfirmSubmitButton,
  k8sConfigApiRef,
  useFormStyles,
  getDuplicatedSyncSources,
  usePermission,
  useSnackbarHelper,
  useSubmitButtonProps,
  useSubmitConfirmation,
  useTimeTransferConfigUpdate,
} from '@netinsight/management-app-common-react';
import { FieldErrors, isValidationError } from '@netinsight/management-app-common-api';
import { PtpAvailableInterfacesWarning } from './PtpAvailableInterfacesWarning';
import { PtpReceiverConfigEntry } from './PtpReceiverConfigEntry';
import { useApi } from '@backstage/core-plugin-api';
import { usePtpClockId, usePtpReceiverStatusMetrics } from '../../../../../../hooks/sync';
import { DuplicatedSyncSourcePriorityWarnings } from '../../../../../../components/DuplicatedSyncSourcePriorityWarnings';

type PtpReceiverConfigFormProps = {
  config?: PtpReceiversConfig;
  nodeId: string;
};

export type PtpReceiverConfigFormValues = PtpReceiversConfig;

export const PtpReceiverConfigForm: FunctionComponent<PtpReceiverConfigFormProps> = ({ config, nodeId }) => {
  const styles = useFormStyles();
  const { snackbar } = useSnackbarHelper();
  const { data: clockId } = usePtpClockId(nodeId);
  const { data: ptpReceiverStatus } = usePtpReceiverStatusMetrics(nodeId, { refreshInterval: 10_000 });
  const initialFormValues = useMemo<PtpReceiverConfigFormValues>(
    () => ({ instances: config?.instances ?? TIMETRANSFER_DEFAULT_CONFIG.ptpReceiver.instances }),
    [config],
  );
  const formProps = useForm<PtpReceiverConfigFormValues>({
    defaultValues: initialFormValues,
    mode: 'onChange',
    resolver: zodResolver(PtpReceiversConfigSchema),
    reValidateMode: 'onBlur',
  });
  const { control, handleSubmit, reset, setError, formState } = formProps;
  const prevInitialFormValues = usePrevious(initialFormValues);
  useEffect(() => {
    if (!F.equals(initialFormValues, prevInitialFormValues)) {
      reset(initialFormValues);
    }
  }, [initialFormValues, prevInitialFormValues, reset]);

  const { trigger: updateConfig, permission: updateConfigPermission } = useTimeTransferConfigUpdate(nodeId, true, {
    // we are overriding the default update behavior to be able to show field errors
    onError: (e: any) => {
      const wrapperErrorBody = e.body?.error;
      if (wrapperErrorBody && isValidationError(wrapperErrorBody)) {
        const fieldErrors = wrapperErrorBody.details as FieldErrors;
        Object.entries(fieldErrors).forEach(([fieldName, fieldError]) => {
          setError(fieldName as keyof PtpReceiverConfigFormValues, fieldError);
        });
      } else {
        snackbar.notifyError('Update', e.response, null);
      }
    },
    // disable revalidation on error to be able to show field errors
    revalidate: false,
    throwOnError: false,
  });
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updateConfigPermission);
  const buttonProps = useSubmitButtonProps({ permission, formState });

  const submitConfig = useCallback(
    (updatedConfig: PtpReceiverConfigFormValues) => {
      return updateConfig(existingConfig => ({
        ...existingConfig,
        ptpReceiver: {
          ...existingConfig.ptpReceiver,
          instances: updatedConfig.instances?.map((instance, index) => ({
            ...instance,
            displayName: ptpReceiverDisplayName(index),
          })),
        },
      }));
    },
    [updateConfig],
  );

  const configApi = useApi(k8sConfigApiRef);

  const confirmationCallback = useCallback(
    async (submitValues: PtpReceiverConfigFormValues) => {
      const duplicates = await getDuplicatedSyncSources({
        nodeId,
        sourceKey: 'ptpReceiver',
        submitValues,
        configApi,
      });

      if (!duplicates || !duplicates.length) return null; // Early return for readability
      return <DuplicatedSyncSourcePriorityWarnings duplicates={duplicates} nodeId={nodeId} />;
    },
    [configApi, nodeId],
  );

  const { onSubmit, onSubmitConfirmed, onCanceled, isCheckingConfirmation, confirmationMessage } =
    useSubmitConfirmation({
      confirmationCallback,
      submitCallback: submitConfig,
    });

  const { fields } = useFieldArray({
    control,
    name: 'instances',
  });

  return (
    <FormProvider {...formProps}>
      <PtpAvailableInterfacesWarning mode="receiver" />
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
        {fields.map((field, index) => (
          <PtpReceiverConfigEntry
            key={field.id}
            nodeId={nodeId}
            index={index}
            metrics={ptpReceiverStatus}
            clockId={clockId}
          />
        ))}
        <div className={styles.buttonContainer}>
          <ConfirmSubmitButton
            data-testid="btn-submit"
            confirmationMessage={confirmationMessage}
            onConfirmed={onSubmitConfirmed}
            onCanceled={onCanceled}
            {...buttonProps}
            disabled={buttonProps.disabled || isCheckingConfirmation || G.isNotNullable(confirmationMessage)}
          />
        </div>
      </form>
    </FormProvider>
  );
};
