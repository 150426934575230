import React, { FunctionComponent } from 'react';
import { Link } from '@backstage/core-components';
import { formatInterfaceName } from '../../../../../../utils/nodes';
import { NodeManagerConfigWarningCodes } from '../../../../../../constants/nodes';
import { NodeManagerConfigWarning } from '../../../../../../types/nodes';

export const NodeManagerConfigWarningContent: FunctionComponent<{
  warning: NodeManagerConfigWarning;
  ifaceDisplayNameMap: Record<string, string>;
  otherNodesIfaceDisplayNameMap: Record<string, Record<string, string>>;
  nodeNameMap: Record<string, string>;
}> = ({ warning, nodeNameMap, ifaceDisplayNameMap, otherNodesIfaceDisplayNameMap }) => {
  switch (warning.code) {
    case NodeManagerConfigWarningCodes.SameIp: {
      return (
        <>
          <dt>
            <a href={`#${formatInterfaceName(warning.name, warning.vlanId)}`}>
              {formatInterfaceName(ifaceDisplayNameMap?.[warning.name] ?? warning.name, warning.vlanId)}
            </a>
          </dt>
          <dd>
            The IP address {warning.ipAddress} is similar to the one currently used on{' '}
            <Link
              to={`/nodes/info/${warning.other.nodeId}/networks/interfaces#${formatInterfaceName(warning.other.name, warning.other.vlanId)}`}
              target="_blank"
            >
              {nodeNameMap?.[warning.other.nodeId] ?? warning.other.nodeId}{' '}
              {formatInterfaceName(
                otherNodesIfaceDisplayNameMap?.[warning.other.nodeId]?.[warning.other.name] ?? warning.other.name,
                warning.other.vlanId,
              )}
            </Link>
          </dd>
        </>
      );
    }
    case NodeManagerConfigWarningCodes.DisableOrMoved: {
      return (
        <>
          <dt>
            <a href={`#${formatInterfaceName(warning.name, warning.vlanId)}`}>
              {formatInterfaceName(ifaceDisplayNameMap?.[warning.name] ?? warning.name, warning.vlanId)}
            </a>
          </dt>
          <dd>
            This interface, which is being used by one or more links or services, will be disabled or moved to a new
            network namespace.
          </dd>
        </>
      );
    }
    case NodeManagerConfigWarningCodes.Removed: {
      return (
        <>
          <dt>
            <a href={`#${warning.name}-vlan`}>
              {formatInterfaceName(ifaceDisplayNameMap?.[warning.name] ?? warning.name, warning.vlanId)}
            </a>
          </dt>
          <dd>This VLAN interface, which is being used by one or more links or services, will be removed.</dd>
        </>
      );
    }
    case NodeManagerConfigWarningCodes.ManagementAccess: {
      return (
        <div style={{ gridColumn: '1 / -1' }}>
          There are no other management interfaces enabled for this node, which may make it inaccessible.
        </div>
      );
    }
    default: {
      return `Unknown Warning: ${JSON.stringify(warning)}`;
    }
  }
};
