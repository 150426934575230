import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { isFulfilled } from '@netinsight/management-app-common-api';
import { k8sConfigApiRef, k8sTTLinksApiRef } from '@netinsight/management-app-common-react';
import { getNodeInterfaceUsage } from '../../utils/nodes';

export const InterfaceUsageCacheKey = 'useInterfaceUsage';

export const useInterfaceUsage = (
  { nodeId, noop }: { nodeId: string; noop?: boolean },
  swrConfig?: SWRConfiguration,
) => {
  const configApi = useApi(k8sConfigApiRef);
  const ttLinksApi = useApi(k8sTTLinksApiRef);

  const fetcher = useCallback(
    async ([nodeIdParam]: [string, string]) => {
      if (noop) {
        return {};
      }
      const [nodeManagerConfigResponse, nodeTimeTransferConfigResponse, nodeLinkTargetsResponse] =
        await Promise.allSettled([
          configApi.getNodeManagerConfig({ params: { nodeId: nodeIdParam } }),
          configApi.getNodeTimeTransferConfig({ params: { nodeId: nodeIdParam } }),
          ttLinksApi.getNodeLinkTargets({ params: { nodeId: nodeIdParam } }),
        ]);

      return getNodeInterfaceUsage({
        linkTargets:
          isFulfilled(nodeLinkTargetsResponse) && nodeLinkTargetsResponse.value.status === StatusCodes.OK
            ? nodeLinkTargetsResponse.value.body?.config?.targets
            : undefined,
        nodeManagerConfig:
          isFulfilled(nodeManagerConfigResponse) && nodeManagerConfigResponse.value.status === StatusCodes.OK
            ? nodeManagerConfigResponse.value.body?.config
            : undefined,
        timeTransferConfig:
          isFulfilled(nodeTimeTransferConfigResponse) && nodeTimeTransferConfigResponse.value.status === StatusCodes.OK
            ? nodeTimeTransferConfigResponse.value.body?.config
            : undefined,
      });
    },
    [configApi, ttLinksApi, noop],
  );
  return useSwr([nodeId, InterfaceUsageCacheKey], fetcher, swrConfig);
};
