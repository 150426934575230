import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import { useTimeTransferConfig } from '@netinsight/management-app-common-react';
import { SyncSourceUsage } from '@netinsight/syncd-schema';
import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router';
import { GnssConfigForm } from './GnssConfigForm';
import { GnssStatusBox } from './GnssStatusBox';

export const GnssConfig = ({ nodeId }: PropsWithChildren<{ nodeId: string }>) => {
  const { data: config, isLoading: isConfigLoading, error: configLoadError } = useTimeTransferConfig(nodeId);

  if (isConfigLoading) {
    return <Progress />;
  } else if (configLoadError) {
    return <ResponseErrorPanel error={configLoadError} />;
  } else if (config) {
    return (
      <>
        {((config.gnss?.usage !== undefined && config.gnss.usage !== SyncSourceUsage.NonSelectable) ||
          config.gnss?.useAsReference) && <GnssStatusBox nodeId={nodeId} />}
        <GnssConfigForm nodeId={nodeId} config={config.gnss ?? {}} />
        <Outlet />
      </>
    );
  }
  return null;
};
