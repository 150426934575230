import { Button, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, ResponseErrorPanel, Table, TableColumn, TableProps } from '@backstage/core-components';
import { FormProvider, useForm, SubmitHandler, FieldPath } from 'react-hook-form';
import {
  asInputRef,
  InventoryKinds,
  SyncActiveStatusIcon,
  TextField,
  useAllInventories,
  usePermission,
  useFormStyles,
  useInstantMetrics,
  useNodeNameMap,
  useSnackbarHelper,
  useTableState,
  ValueOf,
  useSubmitButtonProps,
} from '@netinsight/management-app-common-react';
import { TimeTransferSpec } from '@netinsight/crds-timetransfer';
import { usePrevious } from 'react-use';
import { D, F, pipe } from '@mobily/ts-belt';
import {
  compareSyncRegion,
  EXTERNAL_SYNC_SOURCE_CONFIGS,
  groupMetricsVectorBy2Levels,
  isSyncSourceUsed,
  SYNC_SOURCE_NAMES,
} from '@netinsight/management-app-common-api';
import { useSyncRegionByNodeIdLookup, useSyncRegions, useSyncSources } from '../../../../../hooks/sync';
import { SyncdStatus } from '../../../../../types/sync';
import { PortStates } from '../../../../../utils/sync/ptpUtils';
import { SYNC_SOURCE_CONFIG_URLS, SYNC_SOURCE_LABELS } from '../../../../../constants/sync';
import { getSyncRegionsLookupForTable } from '../../../../../utils/sync';

type FormValues = Record<string, TimeTransferSpec>;

type SyncInputRow = {
  id: string;
  nodeId: string;
  nodeName: string;
  syncRegion: string;
  type: ValueOf<typeof SYNC_SOURCE_NAMES>;
  typeLabel: string;
  priority?: number;
  fieldPath: FieldPath<FormValues>;
  configPath: string;
  isActive?: boolean;
};

const tableOptions: TableProps['options'] = {
  debounceInterval: 200,
  emptyRowsWhenPaging: false,
  filtering: true,
  loadingType: 'overlay',
  pageSize: 25,
  pageSizeOptions: [25, 50],
  search: false,
  tableLayout: 'fixed',
  thirdSortClick: false,
};

const localization = {
  body: {
    emptyDataSourceMessage: 'There are no sync inputs configured in the cluster',
  },
} as const;

export const SyncInputsPage = () => {
  const { isLoading, data, error, update, updatePermission } = useSyncSources();
  const { data: nodeNameMap } = useNodeNameMap();
  const { data: syncdStatuses } = useAllInventories<{ [InventoryKinds.SyncStatus]: { data: SyncdStatus } }>({
    nodeIds: Object.keys(nodeNameMap ?? {}),
    kinds: [InventoryKinds.SyncStatus],
  });
  const { data: syncRegions } = useSyncRegions();
  const nodeSyncRegionLookup = useSyncRegionByNodeIdLookup(syncRegions);
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updatePermission);
  const formStyles = useFormStyles();
  const { snackbar } = useSnackbarHelper();
  const initialFormValues = useMemo(() => data?.timeTransferConfig ?? {}, [data]);
  const prevFormValues = usePrevious(initialFormValues);
  const formProps = useForm<FormValues>({
    defaultValues: initialFormValues,
  });
  const { metrics: ptpPortStateResult } = useInstantMetrics({ query: 'neti_ptp_port_dataset_state{type="receiver"}' });

  const ptpStateByNodeAndServiceName = useMemo(
    () =>
      groupMetricsVectorBy2Levels(
        ptpPortStateResult ?? [],
        m => m.nodeid,
        m => m.service_name,
        val => parseInt(val, 10),
      ),
    [ptpPortStateResult],
  );

  const { handleSubmit, register, reset, formState } = formProps;
  const buttonProps = useSubmitButtonProps({ permission, formState });
  useEffect(() => {
    if (!F.equals(prevFormValues, initialFormValues)) {
      reset(initialFormValues);
    }
  }, [prevFormValues, initialFormValues, reset]);

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async submittedData => {
      try {
        const updatedCount = await update(submittedData);
        if (updatedCount > 0) {
          snackbar.success(`Updated ${updatedCount} node${updatedCount > 1 ? 's' : ''}`);
        }
        if (updatedCount === 0) {
          snackbar.info('No nodes to update');
        }
      } catch (e) {
        snackbar.error('Failed to update');
      }
    },
    [snackbar, update],
  );

  const rows: SyncInputRow[] = useMemo(
    () =>
      Object.entries(data?.timeTransferConfig ?? {}).flatMap(([nodeId, nodeConfig]) => {
        return [
          {
            type: SYNC_SOURCE_NAMES.gnss,
            isActive: syncdStatuses?.[nodeId]?.[InventoryKinds.SyncStatus]?.data?.gnss_active,
            fieldPath: `${nodeId}.gnss.priority`,
          },
          {
            type: SYNC_SOURCE_NAMES.ppsIn,
            isActive: syncdStatuses?.[nodeId]?.[InventoryKinds.SyncStatus]?.data?.pps_in_active,
            fieldPath: `${nodeId}.ppsIn.priority`,
          },
          {
            type: SYNC_SOURCE_NAMES.ptp1,
            isActive: ptpStateByNodeAndServiceName?.[nodeId]?.[SYNC_SOURCE_NAMES.ptp1] === PortStates.TimeReceiver,
            fieldPath: `${nodeId}.ptpReceiver.instances.[0].priority`,
          },
          {
            type: SYNC_SOURCE_NAMES.ptp2,
            isActive: ptpStateByNodeAndServiceName?.[nodeId]?.[SYNC_SOURCE_NAMES.ptp2] === PortStates.TimeReceiver,
            fieldPath: `${nodeId}.ptpReceiver.instances.[1].priority`,
          },
        ]
          .filter(({ type }) => isSyncSourceUsed(EXTERNAL_SYNC_SOURCE_CONFIGS[type](nodeConfig)?.usage))
          .map(({ type, isActive, fieldPath }) => ({
            id: `${nodeId}.${type}`,
            nodeId,
            nodeName: nodeNameMap?.[nodeId] ?? nodeId,
            syncRegion: nodeSyncRegionLookup(nodeId),
            type,
            typeLabel: SYNC_SOURCE_LABELS[type],
            priority: EXTERNAL_SYNC_SOURCE_CONFIGS[type](nodeConfig)?.priority,
            configPath: SYNC_SOURCE_CONFIG_URLS[type](nodeId),
            fieldPath,
            isActive,
          }));
      }),
    [data, syncdStatuses, nodeNameMap, ptpStateByNodeAndServiceName, nodeSyncRegionLookup],
  );

  const columns: TableColumn<SyncInputRow>[] = useMemo(
    () => [
      {
        title: 'Node',
        field: 'nodeName',
        width: '40%',
        render: row => (
          <Typography variant="body1" component={Link} to={row.configPath}>
            {row.nodeName}
          </Typography>
        ),
      },
      {
        title: 'Sync region',
        field: 'syncRegion',
        width: '8rem',
        customSort: (row1, row2) => compareSyncRegion(row1.syncRegion, row2.syncRegion),
        lookup: getSyncRegionsLookupForTable(syncRegions ?? []),
      },
      {
        title: 'Type',
        field: 'type',
        width: '8rem',
        render: row => <SyncActiveStatusIcon label={row.typeLabel} isActive={row.isActive} />,
        lookup: pipe(SYNC_SOURCE_LABELS, D.deleteKey(SYNC_SOURCE_NAMES.osc), D.deleteKey(SYNC_SOURCE_NAMES.syncIn)),
      },
      {
        title: 'Priority',
        field: 'priority',
        filtering: false,
        render: row => (
          <TextField
            type="number"
            inputProps={{
              min: 1,
              step: 1,
              max: 65535,
            }}
            {...asInputRef(
              register(row.fieldPath, {
                valueAsNumber: true,
                setValueAs: val => parseInt(val, 10),
              }),
            )}
          />
        ),
      },
    ],
    [register, syncRegions],
  );

  const tableStates = useTableState<SyncInputRow>(columns, tableOptions, 'sync-sources-table');

  return error ? (
    <ResponseErrorPanel error={error} />
  ) : (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)} className={formStyles.formContainer}>
        <Table
          title="Sync sources"
          data={rows}
          isLoading={isLoading || isLoadingPermission}
          localization={localization}
          {...tableStates}
        />
        <div>
          <Button {...buttonProps} data-testid="btn-save-cluster-sync-inputs" />
        </div>
      </form>
    </FormProvider>
  );
};
