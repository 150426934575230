import { useMemo } from 'react';
import { useGlobalSettings } from '@netinsight/management-app-common-react';
import { useClusterNodeNetwork } from './useClusterNodeNetwork';
import { LinkDetailContextValue } from '../../types/time-transfer';
import { A, D, pipe } from '@mobily/ts-belt';
import { useSyncRegionByNodeIdLookup, useSyncRegions } from '../sync';
import { isInterfaceUsable } from '../../utils/time-transfer';

const SWR_CONFIG = {
  refreshInterval: 0,
  revalidateOnFocus: false,
};

export const useLinkDetailContextData = () => {
  const { data: { network = {}, nodes = [] } = {}, isLoading: isLoadingClusterNetwork } =
    useClusterNodeNetwork(SWR_CONFIG);
  const { data: syncRegions = [], isLoading: isLoadingSyncRegions } = useSyncRegions(SWR_CONFIG);
  const syncRegionLookup = useSyncRegionByNodeIdLookup(syncRegions);
  const { data: globalSettings, isLoading: isLoadingGlobalLinkOptions } = useGlobalSettings();
  const nodeNameMap = useMemo(() => D.fromPairs(nodes?.map(({ id, name }) => [id, name]) ?? []), [nodes]);
  const ifaceCountMap = useMemo(
    () =>
      pipe(
        network,
        D.toPairs,
        A.map(
          ([nodeId, { interfaces }]) => [nodeId, interfaces?.filter(isInterfaceUsable).length ?? 0] as [string, number],
        ),
        D.fromPairs,
      ),
    [network],
  );
  const nodeOptions = useMemo(
    () =>
      nodes
        ?.map(node => ({
          ...node,
          ifaceCount: ifaceCountMap[node.id],
          syncRegion: syncRegionLookup(node.id),
        }))
        .sort(
          (nodeA, nodeB) => nodeA.syncRegion.localeCompare(nodeB.syncRegion) || nodeA.name.localeCompare(nodeB.name),
        ),
    [nodes, syncRegionLookup, ifaceCountMap],
  );

  const linkDetailContextValue: LinkDetailContextValue = useMemo(
    () => ({ nodeNameMap, nodeOptions, globalLinkOptions: globalSettings?.linkOptions }),
    [nodeNameMap, nodeOptions, globalSettings],
  );

  return {
    linkDetailContextValue,
    isLoading: isLoadingClusterNetwork || isLoadingSyncRegions || isLoadingGlobalLinkOptions,
  };
};
