import React, { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { merge } from 'ts-deepmerge';
import { useTheme } from '@material-ui/core';
import { F, G } from '@mobily/ts-belt';
import { PersistedSyncLink } from '@netinsight/management-app-common-api';
import { GlobalLinkOptions } from '@netinsight/crds-timetransfer';
import {
  booleanScale,
  DurationFormatter,
  GrafanaLink,
  InventoryKinds,
  StatusBox,
  useAllInventories,
  useGrafanaDashboards,
} from '@netinsight/management-app-common-react';
import { NodeState } from '@netinsight/node-manager-schema';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import { LinkDetailContext } from '../../../constants/time-transfer';
import { LinkDetailEndpointStatus } from './LinkDetailEndpointStatus';
import { formatInterfaceName, getInterfaceDisplayNameMap } from '../../../utils/nodes';
import { linkStateScale } from '../../../utils/time-transfer';

const LinkOptionStatus = ({ isWarning, isChecked }: { isWarning?: boolean; isChecked?: boolean }) => {
  const theme = useTheme();
  if (isWarning) {
    return <WarningIcon fontSize="small" style={{ fill: theme.palette.warning.main }} />;
  }
  if (isChecked) {
    return <CheckIcon fontSize="small" style={{ fill: theme.palette.success.main }} />;
  }
  return null;
};

export type LinkDetailStatusBoxProps = {
  data: PersistedSyncLink;
  showEndpoints?: boolean;
  globalLinkOptions: GlobalLinkOptions;
};

export const LinkDetailStatusBox: FunctionComponent<PropsWithChildren<LinkDetailStatusBoxProps>> = ({
  data,
  showEndpoints,
  globalLinkOptions,
}) => {
  const {
    nodeNameMap = {},
    combinedMetrics: {
      pathDiff = undefined,
      profileIndex = undefined,
      rtt = undefined,
      linkTimeError = undefined,
      linkStable = undefined,
      linkState = undefined,
      syncActive = undefined,
      endpointAStable = undefined,
      endpointBStable = undefined,
    } = {},
  } = useContext(LinkDetailContext);
  const { data: nodeManagerStates } = useAllInventories<{
    [InventoryKinds.NodeManager]: { data: NodeState.NodeState };
  }>(
    {
      nodeIds: [data.endpointA.node, data.endpointB.node],
      kinds: [InventoryKinds.SyncStatus, InventoryKinds.NodeManager],
    },
    { refreshInterval: 5_000 },
  );

  const { data: dashboards } = useGrafanaDashboards();

  const nodeIfaceDisplayNames: Record<string, Record<string, string | undefined>> = Object.fromEntries(
    Object.entries(nodeManagerStates ?? {}).map(([id, kinds]) => [
      id,
      getInterfaceDisplayNameMap(kinds[InventoryKinds.NodeManager]?.data),
    ]),
  );

  const nodeIfaceOperStatuses = Object.fromEntries(
    Object.entries(nodeManagerStates ?? {}).map(([id, kinds]) => [
      id,
      Object.fromEntries([
        ...(kinds[InventoryKinds.NodeManager]?.data?.interfaces?.map(
          iface => [iface.name, iface.operationalStatus] as const,
        ) ?? []),
        ...(kinds[InventoryKinds.NodeManager]?.data?.vlanInterfaces?.map(
          vlanIface => [vlanIface.name, vlanIface.operationalStatus] as const,
        ) ?? []),
      ]),
    ]),
  );

  const isDefaultOptionsOveridden =
    !F.equals(merge(globalLinkOptions, data.options ?? {}), globalLinkOptions) ||
    (G.isNotNullable(data.autoCalibration) && data.autoCalibration !== globalLinkOptions.autoCalibration);
  const isAutoCalibrated =
    data.autoCalibration === true || (G.isNullable(data.autoCalibration) && globalLinkOptions.autoCalibration === true);
  const deleteProfilesOnChange =
    data.options?.deleteProfilesOnChange === true ||
    (G.isNullable(data.options?.deleteProfilesOnChange) && globalLinkOptions.deleteProfilesOnChange === true);
  const deleteProfilesOnAutoCalibration =
    data.options?.autoCalibrationDeletesAllProfiles === true ||
    (G.isNullable(data.options?.autoCalibrationDeletesAllProfiles) &&
      globalLinkOptions.autoCalibrationDeletesAllProfiles === true);
  return (
    <StatusBox
      showToggle={false}
      style={{
        gridTemplateColumns: 'repeat(auto-fill, minmax(20rem, 1fr))',
      }}
      statuses={[
        ['Stable', G.isBoolean(linkStable) ? booleanScale(linkStable) : 'Unknown'],
        ['Sync active', G.isBoolean(syncActive) ? booleanScale(syncActive) : 'Unknown'],
        ['Link state', G.isNotNullable(linkState) ? linkStateScale(linkState) : 'Unknown'],
        [
          'Default options overidden',
          <>
            {booleanScale(isDefaultOptionsOveridden)}
            <LinkOptionStatus isWarning={isDefaultOptionsOveridden} />
          </>,
        ],
        [
          'Auto-calibration enabled',
          <>
            {booleanScale(isAutoCalibrated)}
            <LinkOptionStatus isWarning={!isAutoCalibrated} />
          </>,
        ],
        [
          'Delete profiles on change',
          <>
            {booleanScale(deleteProfilesOnChange)}
            <LinkOptionStatus isWarning={!deleteProfilesOnChange} />
          </>,
        ],
        [
          'Delete profiles on auto-calibration',
          <>
            {booleanScale(deleteProfilesOnAutoCalibration)}
            <LinkOptionStatus isWarning={!deleteProfilesOnAutoCalibration} />
          </>,
        ],
        [
          'Current RTT',
          typeof rtt === 'number' && !isNaN(rtt) ? DurationFormatter.fromSeconds(rtt).toMicroSeconds(3) : 'Unavailable',
        ],
        [
          'Current link time error',
          typeof linkTimeError === 'number' && !isNaN(linkTimeError)
            ? DurationFormatter.fromSeconds(linkTimeError).toMicroSeconds(3)
            : 'Unavailable',
        ],
        [
          'Current delay difference',
          typeof pathDiff === 'number' && !isNaN(pathDiff)
            ? DurationFormatter.fromMicroseconds(pathDiff).toMicroSeconds(3)
            : 'Unavailable',
        ],
        ['Current profile index', profileIndex],
      ]}
    >
      {showEndpoints ? (
        <>
          <LinkDetailEndpointStatus
            label="Endpoint A"
            endpoint={data.endpointA}
            ifaceName={nodeIfaceDisplayNames[data.endpointA.node]?.[data.endpointA.iface]}
            nodeNameMap={nodeNameMap}
            isStable={endpointAStable}
            operStatus={
              nodeIfaceOperStatuses[data.endpointA.node]?.[
                formatInterfaceName(data.endpointA.iface, data.endpointA.vlanId)
              ]
            }
          />
          <LinkDetailEndpointStatus
            label="Endpoint B"
            endpoint={data.endpointB}
            ifaceName={nodeIfaceDisplayNames[data.endpointB.node]?.[data.endpointB.iface]}
            nodeNameMap={nodeNameMap}
            isStable={endpointBStable}
            operStatus={
              nodeIfaceOperStatuses[data.endpointB.node]?.[
                formatInterfaceName(data.endpointB.iface, data.endpointB.vlanId)
              ]
            }
          />
        </>
      ) : null}

      <GrafanaLink
        dashboardUrl={dashboards?.['Zyntai Time-transfer/single-node']}
        linkIds={[data.id]}
        nodeIds={[
          data.endpointA.node.localeCompare(data.endpointB.node) > 0 ? data.endpointB.node : data.endpointA.node,
        ]}
      />
    </StatusBox>
  );
};
