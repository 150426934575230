import React, { FunctionComponent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Progress } from '@backstage/core-components';
import { F } from '@mobily/ts-belt';
import { FieldErrors, isValidationError, PersistedSyncLink } from '@netinsight/management-app-common-api';
import { usePermission, useSnackbarHelper } from '@netinsight/management-app-common-react';
import { useLinkDetailContextData, useTTLinkCreate } from '../../../hooks/time-transfer';
import { LinkDetailContext } from '../../../constants/time-transfer';
import { LinkEndpointsForm } from './LinkEndpointsForm';
import { isFieldErrors } from '../../../utils/time-transfer';

export const LinkEndpointsDrawerContent: FunctionComponent<{
  linkId?: string;
  onClose: VoidFunction;
}> = ({ onClose = F.ignore }) => {
  const navigate = useNavigate();
  const { linkDetailContextValue, isLoading: isLoadingLinkDetailContextData } = useLinkDetailContextData();
  const { trigger: createTTLink, permission: createTTLinkPermission } = useTTLinkCreate();
  const { isLoading: isLoadingPermission, ...canCreatePermission } = usePermission(createTTLinkPermission);
  const { snackbar } = useSnackbarHelper();
  const handleCreate = useCallback(
    async (
      arg: Omit<PersistedSyncLink, 'id'>,
      onServerErrors: (input: FieldErrors) => void,
      skipNavigation?: boolean,
    ) => {
      await createTTLink(arg, {
        onSuccess: ([createdLink]) => {
          snackbar.notifySuccess('Create');
          if (skipNavigation === true) {
            onClose();
            return;
          }
          navigate(`/network/links/${createdLink.id}`);
        },
        onError: err => {
          if (isValidationError(err) && isFieldErrors(err.details)) {
            onServerErrors(err.details);
            return;
          }
          snackbar.notifyError('Create', err.response, err.message);
        },
        throwOnError: false,
      });
    },
    [createTTLink, onClose, navigate, snackbar],
  );

  return (
    <LinkDetailContext.Provider value={linkDetailContextValue}>
      {isLoadingLinkDetailContextData || isLoadingPermission ? <Progress /> : null}
      <LinkEndpointsForm onSubmit={handleCreate} onCancel={onClose} permission={canCreatePermission} />
    </LinkDetailContext.Provider>
  );
};
