import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Box } from '@material-ui/core';
import { TimeTransferSpecSchema, TIMETRANSFER_DEFAULT_CONFIG } from '@netinsight/crds-timetransfer';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { F } from '@mobily/ts-belt';
import {
  TextField,
  useFormStyles,
  usePermission,
  useSubmitButtonProps,
  useTextFieldController,
  useTimeTransferConfigUpdate,
} from '@netinsight/management-app-common-react';
import usePrevious from 'react-use/lib/usePrevious';
import { InfoCard } from '@backstage/core-components';

export const HoldoverConfigSchema = TimeTransferSpecSchema.shape.holdover.unwrap();

export type HoldoverConfig = z.infer<typeof HoldoverConfigSchema>;

export type HoldoverConfigFormProps = {
  config?: HoldoverConfig;
  nodeId: string;
};

export const HoldoverConfigForm: FunctionComponent<HoldoverConfigFormProps> = ({ config, nodeId }) => {
  const styles = useFormStyles();

  const defaultValues: HoldoverConfig = useMemo(
    () => ({ ...TIMETRANSFER_DEFAULT_CONFIG.holdover, ...(config ?? {}) }),
    [config],
  );
  const prevDefaultValues = usePrevious(defaultValues);

  const { control, handleSubmit, reset, formState } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(HoldoverConfigSchema),
  });

  const { trigger: updateConfig, permission: updateConfigPermission } = useTimeTransferConfigUpdate(nodeId);
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updateConfigPermission);
  const buttonProps = useSubmitButtonProps({ permission, formState });

  const handleOnSubmit = useCallback(
    async (updatedConfig: any) => {
      await updateConfig(existingConfig => ({
        ...existingConfig,
        holdover: updatedConfig,
      }));
    },
    [updateConfig],
  );

  useEffect(() => {
    if (!F.equals(prevDefaultValues, defaultValues)) {
      reset(defaultValues, { keepSubmitCount: true, keepIsSubmitted: true, keepValues: true });
    }
  }, [defaultValues, prevDefaultValues, reset]);

  const { field: priorityInputProps } = useTextFieldController({
    control,
    name: 'priority',
    label: 'Priority',
    schema: HoldoverConfigSchema.shape.priority,
  });

  return (
    <InfoCard title="Holdover" titleTypographyProps={{ variant: 'h5' }} cardClassName={styles.formContainer}>
      <form onSubmit={handleSubmit(handleOnSubmit)} className={styles.formContainer}>
        <TextField {...priorityInputProps} />
        <Box>
          <Button {...buttonProps} data-testid="btn-apply-sync-in-config" />
        </Box>
      </form>
    </InfoCard>
  );
};
