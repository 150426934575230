import { createContext } from 'react';
import { LinkDetailContextValue } from '../../types/time-transfer';

export const LinkDetailContext = createContext<LinkDetailContextValue>({});

export const DscpCodePoints: [number, string][] = [
  [0, 'CS0'],
  [8, 'CS1'],
  [16, 'CS2'],
  [24, 'CS3'],
  [32, 'CS4'],
  [40, 'CS5'],
  [48, 'CS6'],
  [56, 'CS7'],
  [10, 'AF11'],
  [12, 'AF12'],
  [14, 'AF13'],
  [18, 'AF21'],
  [20, 'AF22'],
  [22, 'AF23'],
  [26, 'AF31'],
  [28, 'AF32'],
  [30, 'AF33'],
  [34, 'AF41'],
  [36, 'AF42'],
  [38, 'AF43'],
  [46, 'EF'],
  [44, 'VOICE-ADMIT'],
];
