import classNames from 'classnames';
import React, { FunctionComponent, useCallback, useContext, useState } from 'react';
import { Control, useFormState, useWatch } from 'react-hook-form';
import { Button, Drawer, IconButton, makeStyles, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { A, D, G, pipe } from '@mobily/ts-belt';
import {
  LEGACY_PTP_CLOCK_ID,
  PTP_PROFILES_WITH_UNICAST,
  ptpTransmitterDisplayName,
} from '@netinsight/crds-timetransfer';
import { StatusBox } from '@netinsight/management-app-common-react';
import { PtpContext } from './PtpContext';
import { PtpTransmitterCounters } from './PtpTransmitterCounters';
import { PtpTransmitterConfigFormValues } from './PtpTransmitterConfigForm';
import { ptpTransmitterName } from '../../../../../../utils/sync/ptpUtils';
import { PtpTransmitterUnicastClients } from './PtpTransmitterUnicastClients';
import { usePtpClockId, usePtpTransmitterStatusMetrics } from '../../../../../../hooks/sync';

type PtpTransmitterEntryHeaderProps = {
  index: number;
  control: Control<PtpTransmitterConfigFormValues>;
};

const useStyles = makeStyles(theme => ({
  dot: {
    paddingLeft: theme.spacing(3),
    position: 'relative',
    ['&::before']: {
      content: '""',
      display: 'block',
      width: '12px',
      height: '12px',
      borderRadius: '100%',
      position: 'absolute',
      top: '50%',
      left: '4px',
      transform: 'translate(0, -50%)',
    },
  },
  connected: {
    ['&::before']: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
  },
  unconnected: {
    ['&::before']: {
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.grey[500],
    },
  },
  drawer: {
    padding: theme.spacing(8, 3, 3, 3),
    minWidth: '20vw',
    maxWidth: '36rem',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

export const PtpTransmitterEntryHeader: FunctionComponent<PtpTransmitterEntryHeaderProps> = ({ index, control }) => {
  const styles = useStyles();
  const { interfaceNameMap, nodeId } = useContext(PtpContext);
  const { dirtyFields } = useFormState({ control });
  const interfaceName = useWatch({
    control,
    name: `instances.${index}.interface`,
  });
  const profile = useWatch({
    control,
    name: `instances.${index}.profile`,
  });
  const serviceName = ptpTransmitterName(interfaceName);
  const unicastDbKey = `${serviceName}-unicastclients`;
  const { data: ptpClockId } = usePtpClockId(nodeId);
  const { data: ptpMetrics } = usePtpTransmitterStatusMetrics(nodeId, { refreshInterval: 10_000 });
  const ptpStatuses = ptpMetrics?.[serviceName];
  const hasData = pipe(ptpStatuses ?? {}, D.values, A.any(G.isNotNullable));
  const isDirty = dirtyFields?.instances?.[index]?.interface === true;

  const [showCounters, setShowCounters] = useState(false);
  const toggleCounters = useCallback(() => setShowCounters(x => !x), []);
  const closeCounters = useCallback(() => setShowCounters(false), []);

  const [showUnicastClients, setUnicastClients] = useState(false);
  const toggleUnicastClients = useCallback(() => setUnicastClients(x => !x), []);
  const closeUnicastClients = useCallback(() => setUnicastClients(false), []);

  return (
    <>
      <Typography
        variant="h6"
        className={classNames(styles.dot, {
          [styles.connected]: hasData && !isDirty,
          [styles.unconnected]: !hasData || isDirty,
        })}
        title={hasData && !isDirty ? 'Connected to the PTP service' : 'Not connected to the PTP service'}
      >
        {ptpTransmitterDisplayName(interfaceName, interfaceNameMap)}
      </Typography>
      {!isDirty &&
        (ptpStatuses && hasData ? (
          <StatusBox
            data-testid="list-metrics"
            showToggle={false}
            statuses={[
              [`Connected receivers`, ptpStatuses.totalClientCount],
              [`Clock ID`, ptpClockId ?? LEGACY_PTP_CLOCK_ID],
              ['Clock accuracy', ptpStatuses.clockAccuracy],
              ['Clock class', ptpStatuses.clockClass],
              ['Clock variance', ptpStatuses.clockVariance],
              ['Time source', ptpStatuses.timeSource],
              ['Domain', ptpStatuses.domain],
              ['Priority 1', ptpStatuses.prio1],
              ['Priority 2', ptpStatuses.prio2],
              ['UTC offset', ptpStatuses.utcOffset],
              ['Time traceable', ptpStatuses.timeTraceable],
              ['Frequency traceable', ptpStatuses.frequencyTraceable],
              ['Unicast receivers', ptpStatuses.unicastClientCount],
              ['Unicast utilization', ptpStatuses.unicastUtilization],
            ]}
          >
            <>
              <Button variant="outlined" size="small" color="primary" onClick={toggleCounters}>
                Counters
              </Button>
              {PTP_PROFILES_WITH_UNICAST.includes(profile) ? (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={toggleUnicastClients}
                  style={{ marginLeft: '0.5rem' }}
                >
                  Unicast clients
                </Button>
              ) : null}
            </>
          </StatusBox>
        ) : null)}
      <Drawer
        variant="temporary"
        anchor="right"
        open={showCounters}
        onClose={closeCounters}
        classes={{ paper: styles.drawer }}
      >
        <>
          <IconButton className={styles.closeButton} onClick={closeCounters}>
            <CancelIcon />
          </IconButton>
          <PtpTransmitterCounters nodeId={nodeId} serviceName={serviceName} />
        </>
      </Drawer>
      <Drawer
        variant="temporary"
        anchor="right"
        open={showUnicastClients}
        onClose={closeUnicastClients}
        classes={{ paper: styles.drawer }}
      >
        <>
          <IconButton className={styles.closeButton} onClick={closeUnicastClients}>
            <CancelIcon />
          </IconButton>
          <PtpTransmitterUnicastClients nodeId={nodeId} index={index} serviceName={unicastDbKey} />
        </>
      </Drawer>
    </>
  );
};
