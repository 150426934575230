export class CalibrationError extends Error {
  constructor(message: string) {
    super();
    this.name = 'calibration error';
    this.message = message;
  }
}

/**
 * validates the given value is finite, throws otherwise
 * @param value
 * @param scalar_name
 */
const validate_scalar = (value: number, scalar_name: string) => {
  if (!Number.isFinite(value)) {
    throw new CalibrationError(`${scalar_name} contains a non finite value ${value}`);
  }
};

/**
 *
 * @param pathdiff
 * @param rtt
 * @returns
 */
const clamp_to_rtt = (pathdiff: number, rtt: number): [boolean, number] => {
  validate_scalar(pathdiff, 'pathdiff');
  validate_scalar(rtt, 'rtt');
  if (pathdiff > rtt) {
    return [true, rtt];
  }
  if (pathdiff < -rtt) {
    return [true, -rtt];
  }
  return [false, pathdiff];
};

/**
 * validates that x is a numeric array where all entries are finite
 * @param x may be empty but not undefined. all values muste be finite.
 * @param arrayname used for the exception text
 */
export function validate_array(x: readonly number[] | undefined, arrayname: string) {
  if (x === undefined) {
    throw new CalibrationError(`${arrayname} is undefined`);
  }
  x.forEach((value: number, index: number) => {
    if (!Number.isFinite(value)) {
      throw new CalibrationError(`${arrayname} contains a non finite value ${value} at index ${index}`);
    }
  });
}

/**
 * like validate_array, but x must have at least one element
 * @param x
 * @param arrayname
 */
export function validate_array_is_nonempty(x: readonly number[] | undefined, arrayname: string) {
  validate_array(x, arrayname);
  if (x?.length === 0) {
    throw new CalibrationError(`${arrayname} is empty`);
  }
}

/**
 * @param input values to average over (must all be finite)
 * @returns undefined if the input is empty, otherwise the average.
 */
export const average = (input: readonly number[]) => {
  validate_array(input, 'input to average');
  const sum = (sumInput: readonly number[]) => sumInput.reduce((a, b) => a + b, 0);
  return input.length === 0 ? undefined : sum(input) / input.length;
};

/**
 * represents a time node
 */
export class Node {
  constructor(id: string) {
    this.id = id;
  }
  id: string;

  // time_error vs references. we may not know, or have several
  time_error: number[] = [];

  validate() {
    if (this.id.length === 0) {
      throw new CalibrationError('node name must not be empty');
    }
    validate_array(this.time_error, `node ${this.id} time error`);
  }
  get_avg_time_error(): number | undefined {
    return average(this.time_error);
  }
}

export class Link {
  constructor(id: string, name: string, peer_node: Node = new Node('')) {
    this.id = id;
    this.name = name;
    this.peer_node = peer_node;
  }
  id: string;
  name: string;

  // the link error
  link_error = 0;

  // the path diff in the profile, not the actual one
  profile_path_diff = 0;

  // rtt (real or from the profile, the calculation should not care)
  rtt = 0;

  // the peer node
  peer_node: Node = new Node('');

  validate() {
    validate_scalar(this.link_error, 'link error');
    validate_scalar(this.profile_path_diff, 'profile path diff');
    validate_scalar(this.rtt, 'rtt');
    if (!(this.rtt >= 0.0)) {
      throw new CalibrationError('rtt must be positive');
    }
    if (this.profile_path_diff > this.rtt) {
      throw new CalibrationError(
        `Link ${this.name}: path diff (${this.profile_path_diff.toFixed(4)}) must be less than or equal to rtt (${
          this.rtt
        })`,
      );
    }
    if (this.profile_path_diff < -this.rtt) {
      throw new CalibrationError(
        `Link ${this.name}: path diff (${this.profile_path_diff.toFixed(
          4,
        )}) must be greater than or equal to -rtt (${-this.rtt.toFixed(4)})`,
      );
    }
    if (this.peer_node !== undefined) {
      this.peer_node.validate();
    }
  }
}

/**
 * represents a calibration problem
 */
export class Problem {
  myself: Node = new Node('');
  incoming_links: Link[] = [];
  /// outgoing links are from "myself" point of view
  outgoing_links: Link[] = [];

  validate() {
    validate_array_is_nonempty(this.myself.time_error, `node ${this.myself.id} time error`);
    if (this.incoming_links.length === 0) {
      throw new CalibrationError('incomings links must not be empty');
    }
    this.incoming_links.forEach((link: Link) => {
      link.validate();
    });
    this.outgoing_links.forEach((link: Link) => {
      link.validate();
    });

    // check for cycles
    const upstream_node_ids = new Set<string>();
    this.incoming_links.forEach(link => {
      const upstream = link.peer_node.id;
      upstream_node_ids.add(upstream);
    });
    if (upstream_node_ids.has(this.myself.id)) {
      throw new CalibrationError('connected to myself through upstream links!');
    }
    this.outgoing_links.forEach(link => {
      const downstream = link.peer_node.id;
      if (upstream_node_ids.has(downstream)) {
        throw new CalibrationError('sync has cycles - downstream node is also an upstream node!');
      }
      if (downstream === this.myself.id) {
        throw new CalibrationError('connected to myself through downstream link');
      }
    });
  }
}

export class Solution {
  incoming_path_diff: number[] = [];
  outgoing_path_diff: number[] = [];

  validate(problem: Readonly<Problem>) {
    problem.validate();
    validate_array_is_nonempty(this.incoming_path_diff, 'incoming path diff');
    validate_array(this.outgoing_path_diff, 'outgoing path diff');
    if (this.incoming_path_diff.length !== problem.incoming_links.length) {
      throw new CalibrationError('length mismatch between problem and solution incoming links length');
    }
    if (this.outgoing_path_diff.length !== problem.outgoing_links.length) {
      throw new CalibrationError('length mismatch between problem and solution outgoing links length');
    }
  }
}

/**
 * represents the predicted state after calibration has been applied
 */
export class Predicted {
  // predicted time error per reference
  time_error: number[] = [];
  incoming_link_errors: number[] = [];
  // note - there is no point in estimating the downstream link errors, they will
  // be unchanged (assuming downstream does not take sync from other places than us)

  /// downstream node time error will certainly be affected
  downstream_time_errors: { [key: string]: number[] } = {};

  validate(problem: Readonly<Problem>) {
    validate_array_is_nonempty(this.time_error, 'predicted node time error');
    if (problem.myself.time_error.length !== this.time_error.length) {
      throw new CalibrationError('mismatch in size on time_error');
    }
    validate_array_is_nonempty(this.incoming_link_errors, 'predicted incoming link errors');
    if (problem.incoming_links.length !== this.incoming_link_errors.length) {
      throw new CalibrationError('mismatch in size on incoming_link_errors');
    }

    // make sure all the downstream nodes are mentioned in downstream_time_errors
    // (we do not have https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/symmetricDifference available yet)
    const known_downstream_nodes_1 = new Set<string>();
    Object.keys(this.downstream_time_errors).forEach(key => {
      known_downstream_nodes_1.add(key);
    });

    const known_downstream_nodes_2 = new Set<string>();
    problem.outgoing_links.forEach(link => {
      if (!known_downstream_nodes_1.has(link.peer_node.id)) {
        throw new CalibrationError('peer of downstream link is not in downstream_time_errors');
      }
      known_downstream_nodes_2.add(link.peer_node.id);
    });
    known_downstream_nodes_1.forEach(node_id => {
      if (!known_downstream_nodes_2.has(node_id)) {
        throw new CalibrationError('peer in downstream_time_errors is not mentioned in any of the downstream links!');
      }
    });
  }
}

export enum SolveStrategy {
  EliminateTimeError,
  EliminateLinkSpread,
  EliminateTimeErrorWithUnaffectedDownstream,
}

/**
 * solves the calibration problem
 * @param problem must have: valid time error, at least one incoming link
 * @param strategy
 * @returns
 */
export function solve(problem: Readonly<Problem>, strategy: Readonly<SolveStrategy>): Solution {
  problem.validate();

  const solution = new Solution();

  const e = problem.myself.get_avg_time_error();
  if (e === undefined) {
    throw new CalibrationError('this should not happen, but I keep typescript happy');
  }

  const N = problem.incoming_links.length;
  solution.incoming_path_diff.length = N;

  const average_link_error = average(problem.incoming_links.map(link => link.link_error));

  for (let i = 0; i < N; ++i) {
    if (strategy === SolveStrategy.EliminateLinkSpread) {
      solution.incoming_path_diff[i] =
        problem.incoming_links[i].profile_path_diff +
        (problem.incoming_links[i].link_error - (average_link_error ?? 0)) * 2.0;
    } else {
      // positive time error implies we should decrease the pathdiff because:
      // link error= local - remote - pathdiff/2
      // and if pathdiff decreases, link error will temporarily rise, until the feedback loop will lower it by reducing local.
      solution.incoming_path_diff[i] = problem.incoming_links[i].profile_path_diff - e * 2.0;
    }

    // clamp to RTT
    const [wasclamped, clampedvalue] = clamp_to_rtt(solution.incoming_path_diff[i], problem.incoming_links[i].rtt);
    if (wasclamped) {
      solution.incoming_path_diff[i] = clampedvalue;
    }
  }

  const outgoingcount = problem.outgoing_links.length;
  solution.outgoing_path_diff.length = outgoingcount;
  for (let i = 0; i < outgoingcount; ++i) {
    switch (strategy) {
      case SolveStrategy.EliminateTimeError:
      case SolveStrategy.EliminateLinkSpread:
        // leave the outgoing links untouched
        solution.outgoing_path_diff[i] = problem.outgoing_links[i].profile_path_diff;
        break;
      case SolveStrategy.EliminateTimeErrorWithUnaffectedDownstream:
      default:
        // apply the reverse adjustement as on incoming links, by taking advantage of the sign
        // being flipped at the other end of the downstream link
        solution.outgoing_path_diff[i] = problem.outgoing_links[i].profile_path_diff - e * 2.0;
        break;
    }
  }

  // downstream nodes will change their time the same amount we change our
  // time with, plus the changes in downstream pathdiff (with the sign reversed)

  solution.validate(problem);
  return solution;
}

/**
 * given a problem and a solution, predict what the time errors are going to be on all the links and nodes
 * @param problem
 * @param solution
 * @returns
 */
export function predict(problem: Readonly<Problem>, solution: Readonly<Solution>): Predicted {
  problem.validate();

  const predicted = new Predicted();

  // the change in our own time is governed by the change in incoming pathdiff
  const N = problem.incoming_links.length;

  let our_time_change = 0.0;

  for (let i = 0; i < N; ++i) {
    const pathdiff_change = solution.incoming_path_diff[i] - problem.incoming_links[i].profile_path_diff;
    our_time_change += (pathdiff_change * 0.5) / N;
  }

  const refcount = problem.myself.time_error.length;
  predicted.time_error.length = refcount;
  for (let i = 0; i < refcount; ++i) {
    predicted.time_error[i] = problem.myself.time_error[i] + our_time_change;
  }

  // the change in the incoming link errors will be due to path diff change and our own time changing
  for (let i = 0; i < N; ++i) {
    const pathdiff_change = solution.incoming_path_diff[i] - problem.incoming_links[i].profile_path_diff;
    predicted.incoming_link_errors[i] = problem.incoming_links[i].link_error + our_time_change - pathdiff_change * 0.5;
  }

  //  the change in the downstream nodes will be because of downstream link path diff change plus our own time changing

  // because of multilink, we have to average over the change in all links to a given downstream
  // the key is the downstream node id, the value is how much each link wants to change the time error
  const link_change_per_downstream_node: { [key: string]: number[] } = {};
  problem.outgoing_links.forEach(link => {
    link_change_per_downstream_node[link.peer_node.id] = [];
  });

  const outgoingcount = problem.outgoing_links.length;
  for (let i = 0; i < outgoingcount; ++i) {
    const pathdiff_change = solution.outgoing_path_diff[i] - problem.outgoing_links[i].profile_path_diff;
    // flip the sign of pathdiff, the downstream defines pathdiff with opposite sign
    const downstream_change = our_time_change - pathdiff_change * 0.5;
    const peer_id = problem.outgoing_links[i].peer_node.id;
    link_change_per_downstream_node[peer_id].unshift(downstream_change);
  }

  // for each downstream node, calculate the change per link
  problem.outgoing_links.forEach(link => {
    // assume the downstream only gets time from us and the net change is the average
    // of change on all incoming links to it
    const change = average(link_change_per_downstream_node[link.peer_node.id]);
    if (change === undefined) {
      throw new CalibrationError('this should never happen');
    }
    predicted.downstream_time_errors[link.peer_node.id] = link.peer_node.time_error.map(
      (ref_error: number) => ref_error + change,
    );
  });

  predicted.validate(problem);
  return predicted;
}
