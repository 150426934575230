import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DrawerPanel } from '@netinsight/management-app-common-react';
import { LinkEndpointsDrawerContent } from './LinkEndpointsDrawerContent';

const DRAWER_TRANSITION_DURATION = 300;

export const LinkEndpointsDrawer = ({ parentPath }: { parentPath?: string }) => {
  const params = useParams<{ linkId?: string }>();

  const navigate = useNavigate();
  const [isDrawerOpen, setDrawerOpen] = useState(true);
  const handleClose = useCallback(() => {
    setDrawerOpen(false);
    setTimeout(() => {
      if (parentPath) {
        navigate(parentPath);
      } else {
        navigate(-1);
      }
    }, DRAWER_TRANSITION_DURATION);
  }, [navigate, parentPath]);

  return (
    <DrawerPanel
      open={isDrawerOpen}
      onCloseClick={handleClose}
      onClose={handleClose}
      transitionDuration={DRAWER_TRANSITION_DURATION}
    >
      <LinkEndpointsDrawerContent linkId={params.linkId} onClose={handleClose} />
    </DrawerPanel>
  );
};
