import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';
import { InfoCard, ResponseErrorPanel } from '@backstage/core-components';
import { Button, LinearProgress } from '@material-ui/core';
import { F } from '@mobily/ts-belt';
import {
  useFormStyles,
  useNodeManagerConfigUpdate,
  usePermission,
  useSubmitButtonProps,
} from '@netinsight/management-app-common-react';
import { SynceConfigFormValues } from './types';
import { SynceConfigEntry } from './SynceConfigEntry';
import { useInterfaceInfos, useInterfaceUsage } from '../../../../../../hooks/nodes';
import { MANAGEMENT_IFACE } from '../../../../../../constants/nodes';

export const SyncEConfig = ({ nodeId }: PropsWithChildren<{ nodeId: string }>) => {
  const {
    data: interfaceUsage,
    isLoading: isLoadingIfaceUsage,
    error: ifaceUsageError,
  } = useInterfaceUsage({ nodeId });
  const {
    data: interfaceInfos,
    isLoading: isLoadingIfaceInfos,
    error: ifaceInfoError,
  } = useInterfaceInfos({
    nodeId,
    statuses: [],
    includeVlan: false,
    includeManagement: true,
    includeElectrical: true,
    usageMap: interfaceUsage ?? {},
  });
  const { trigger: update, permission: updatePermission } = useNodeManagerConfigUpdate(nodeId);
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updatePermission);
  const formStyles = useFormStyles();
  const initialFormValues = useMemo(
    () => ({ interfaces: interfaceInfos?.filter(iface => iface.id !== MANAGEMENT_IFACE) }),
    [interfaceInfos],
  );
  const prevFormValues = usePrevious(initialFormValues);
  const formProps = useForm<SynceConfigFormValues>({
    defaultValues: initialFormValues,
  });
  const { handleSubmit, control, reset, formState } = formProps;
  const buttonProps = useSubmitButtonProps({ permission, formState });
  useEffect(() => {
    if (!F.equals(prevFormValues, initialFormValues)) {
      reset(initialFormValues);
    }
  }, [prevFormValues, initialFormValues, reset]);

  const onSubmit = useCallback(
    async ({ interfaces }: SynceConfigFormValues) => {
      const synceConfigMap = Object.fromEntries(interfaces.map(iface => [iface.id, iface.usage?.syncE]));
      await update(config => ({
        ...config,
        interfaces: config.interfaces.map(iface => ({
          ...iface,
          useSynce: synceConfigMap[iface.name] ?? iface.useSynce,
          // TODO: This section shall be removed later once there is no VLAN interfaces with Sync-E enabled
          vlanInterfaces: iface.vlanInterfaces?.map(vlanIface => ({
            ...vlanIface,
            useSynce: false, // Make sure that Sync-E is disabled for legacy VLAN interfaces which may have it enabled in a previous release
          })),
        })),
      }));
    },
    [update],
  );
  if (isLoadingIfaceInfos || isLoadingIfaceUsage || isLoadingPermission) {
    return <LinearProgress />;
  }
  const error = ifaceInfoError ?? ifaceUsageError;
  if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <InfoCard title="SyncE" titleTypographyProps={{ component: 'h5' }}>
      <FormProvider {...formProps}>
        <form onSubmit={handleSubmit(onSubmit)} className={formStyles.formContainer}>
          {initialFormValues.interfaces.map((field, index) => (
            <SynceConfigEntry key={field.id} index={index} control={control} field={field} />
          ))}
          <div className={formStyles.buttonContainer}>
            <Button {...buttonProps} data-testid="btn-save-synce-config" />
          </div>
        </form>
      </FormProvider>
    </InfoCard>
  );
};
