import React from 'react';
import { Table, TableColumn } from '@backstage/core-components';
import { Typography, makeStyles } from '@material-ui/core';
import { AlarmDatetime, AlertManagerWebhookAlert } from '@netinsight/management-app-common-react';
import { isUiDebugModeOn } from '../../../utils';

const useStyles = makeStyles(theme => ({
  empty: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface AlertHistoryItem {
  id?: string;
  status: string;
  resolvedAt: string;
  raisedAt: string;
  node: string;
  type: string;
  details: string;
  severity: string;
  service: string;
}

interface AlarmsHistoryTableProps {
  data?: AlertManagerWebhookAlert[];
  isLoading?: boolean;
}

const uiDebugMode = isUiDebugModeOn();

export const AlarmsHistoryTable = (props: AlarmsHistoryTableProps) => {
  const classes = useStyles();

  const columns: TableColumn<AlertHistoryItem>[] = [
    {
      title: 'Id',
      field: 'id',
      sorting: true,
      hidden: uiDebugMode === false,
    },
    {
      title: 'Raised',
      field: 'raisedAt',
      render: row => <AlarmDatetime date={row.raisedAt} />,
      sorting: false,
    },
    {
      title: 'Resolved',
      field: 'resolvedAt',
      render: row => <AlarmDatetime date={row.resolvedAt} />,
      defaultSort: 'desc',
      sorting: true,
    },
    {
      title: 'Node',
      field: 'nodeName',
      highlight: true,
    },
    {
      title: 'Object',
      field: 'object',
    },
    {
      title: 'Type',
      field: 'type',
      sorting: false,
    },
    {
      title: 'Details',
      field: 'details',
      sorting: false,
    },
    {
      title: 'Severity',
      field: 'severity',
      sorting: false,
    },
    {
      title: 'Service',
      field: 'service',
      sorting: false,
    },
  ];

  const alerts: AlertHistoryItem[] = (props.data ?? []).map(alert => ({
    id: `${alert.fingerprint}_${alert.startsAt}`,
    status: alert.status,
    type: alert.annotations.typeName ?? alert.labels.type,
    details: alert.annotations.description ?? alert.annotations.summary,
    severity: alert.labels.severity,
    node: alert.labels.nodeName ?? alert.labels.nodeid,
    object: alert.annotations.object ?? '',
    nodeName: alert.labels.node,
    service: alert.labels.service_name ?? alert.labels.job,
    raisedAt: alert.startsAt,
    resolvedAt: alert.endsAt,
  }));

  return (
    <Table
      options={{
        paging: false,
        search: false,
        toolbar: false,
        // material-table limitation: it supports sticky headers iff maxBodyHeight is set
        headerStyle: { position: 'sticky', top: 0 },
        maxBodyHeight: '60vh',
      }}
      isLoading={props.isLoading}
      data={alerts}
      columns={columns}
      emptyContent={<Typography className={classes.empty}>No entries</Typography>}
    />
  );
};
