import { merge as deepMerge } from 'ts-deepmerge';
import { A, G } from '@mobily/ts-belt';
import { ClusterTimeTransfer, PersistedLinkTarget, SYNC_SOURCE_NAMES } from '@netinsight/management-app-common-api';
import {
  CalculationMethods,
  DefaultEditModel,
  DefaultTimeReferenceErrors,
  DefaultTimeReferenceSelection,
  TimeReferenceSelectionOrder,
} from './constants';
import { useCalibrationMetrics } from './hooks';
import * as singleNodeCalibration from './single_node_calibration';
import type {
  CalculationMethod,
  CalibrationContext,
  CalibrationEditModel,
  CalibrationPredictionViewModel,
  CalibrationSolutionViewModel,
  CalibrationViewModel,
  LinkCalibrationViewModel,
  LinkTimeErrorEditModel,
  LocalTimeReferenceErrors,
  TimeReferenceErrors,
  TimeReferenceSelections,
  TimeReferenceType,
} from './types';
import { fromMicroseconds, toMicroseconds } from '../../../../../utils/time-transfer';

type CalibrationMetrics = ReturnType<typeof useCalibrationMetrics>['data'];

export const roundToPrecision = (value: number, precision: number) =>
  Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);

const getTimeReferenceSelectionsByNodeId = (input: ClusterTimeTransfer): Record<string, TimeReferenceSelections> => {
  return Object.fromEntries(
    Object.entries(input).map(([nodeId, config]) => [
      nodeId,
      {
        [SYNC_SOURCE_NAMES.ppsIn]: config.ppsIn?.useAsReference ?? false,
        [SYNC_SOURCE_NAMES.gnss]: config.gnss?.useAsReference ?? false,
        [SYNC_SOURCE_NAMES.ptp1]: config.ptpReceiver?.instances?.[0]?.useAsReference ?? false,
        [SYNC_SOURCE_NAMES.ptp2]: config.ptpReceiver?.instances?.[1]?.useAsReference ?? false,
      },
    ]),
  );
};

export const getScalarTimeReferenceError = (
  errors: TimeReferenceErrors | undefined,
  selections: TimeReferenceSelections,
): { type?: TimeReferenceType; error?: number } => {
  if (G.isNullable(errors)) {
    return { type: undefined, error: undefined };
  }
  const foundType = TimeReferenceSelectionOrder.find(
    type => selections[type] === true && G.isNotNullable(errors[type]) && !isNaN(errors[type]!),
  );
  return G.isNotNullable(foundType)
    ? { type: foundType, error: errors[foundType]! }
    : { type: undefined, error: undefined };
};

export const getTimeReferenceErrorsArrayFromMap = (
  errors: TimeReferenceErrors,
  selections: TimeReferenceSelections,
): number[] => {
  return TimeReferenceSelectionOrder.reduce(
    (result, type) => (selections[type] === true ? [...result, errors[type] ?? 0] : result),
    [] as number[],
  );
};

const getLocalTimeReferenceErrorsArray = (context: CalibrationContext) => {
  const customTimeErrorArray = context.localTimeReferenceSelections.custom
    ? [fromMicroseconds(context.customTimeReferenceError)]
    : [];
  return getTimeReferenceErrorsArrayFromMap(
    context.localTimeReferenceErrors,
    context.localTimeReferenceSelections,
  ).concat(customTimeErrorArray);
};

const getTimeReferenceErrorsMapFromArray = (
  errors: number[],
  selections: Record<string, boolean>,
  isLocal = false,
): TimeReferenceErrors => {
  if (errors.length === 0) {
    return DefaultTimeReferenceErrors;
  }

  const selectionOrder = TimeReferenceSelectionOrder.concat(isLocal ? (['custom'] as any) : []);
  if (errors.length === 1) {
    const foundType = selectionOrder.find(type => selections[type] === true) ?? SYNC_SOURCE_NAMES.ppsIn;
    return {
      [foundType]: errors[0],
    } as TimeReferenceErrors;
  }

  return Object.fromEntries(
    A.zipWith(
      selectionOrder.filter(type => selections[type] === true),
      errors,
      (type, error) => [type, error],
    ),
  ) as TimeReferenceErrors;
};

const getTimeReferenceErrorsMapFromMetrics = (nodeId: string, metrics: CalibrationMetrics): TimeReferenceErrors => {
  return {
    [SYNC_SOURCE_NAMES.ppsIn]: metrics?.ppsErrorByNode?.[nodeId],
    [SYNC_SOURCE_NAMES.gnss]: metrics?.gnssErrorByNode?.[nodeId],
    [SYNC_SOURCE_NAMES.ptp1]: metrics?.ptpErrorByNodeAndServiceName?.[nodeId]?.[SYNC_SOURCE_NAMES.ptp1],
    [SYNC_SOURCE_NAMES.ptp2]: metrics?.ptpErrorByNodeAndServiceName?.[nodeId]?.[SYNC_SOURCE_NAMES.ptp2],
  };
};

export const getLinkCalibrationViewmodel = ({
  nodeId,
  linkTarget,
  metrics,
  nodeNameMap,
  timeReferenceSelectionsByNodeId,
}: {
  nodeId: string;
  linkTarget: PersistedLinkTarget;
  metrics: CalibrationMetrics;
  nodeNameMap: Record<string, string>;
  timeReferenceSelectionsByNodeId: Record<string, TimeReferenceSelections>;
}): LinkCalibrationViewModel => {
  const endpoint = linkTarget.index === 1 ? 'A' : 'B';
  const sign = endpoint === 'B' ? -1 : 1;

  const profileIndex = metrics?.selectedProfileIndicesByNodeAndLink?.[linkTarget.linkId!]?.[linkTarget.node];
  const currentProfile = linkTarget.profiles?.find(p => p.index === profileIndex);

  let currentProfileRTT: number | undefined;
  let currentProfilePathDiff: number | undefined;

  if (currentProfile) {
    currentProfilePathDiff = currentProfile.delayDifference * sign;
    currentProfileRTT = currentProfile.roundtripTime;
  } else {
    currentProfilePathDiff = undefined;
    currentProfileRTT = undefined;
  }
  const peerTimeReferenceSelection: TimeReferenceSelections =
    timeReferenceSelectionsByNodeId?.[linkTarget.node] ?? DefaultTimeReferenceSelection;
  const peerTimeReferenceErrors = getTimeReferenceErrorsMapFromMetrics(linkTarget.node, metrics);
  const { type: peerTimeErrorType, error: peerTimeError } = getScalarTimeReferenceError(
    peerTimeReferenceErrors,
    peerTimeReferenceSelection,
  );
  return {
    linkId: linkTarget.linkId!,
    name: linkTarget.name!,
    isAutoCalibrated: linkTarget.autoCalibration,
    profileIndex,
    currentPathDiff: currentProfilePathDiff,
    currentRtt: currentProfileRTT,
    linkTimeError: metrics?.timeErrorsByNodeAndLink?.[linkTarget.linkId!]?.[nodeId],
    rtt: metrics?.rttsByNodeAndLink?.[linkTarget.linkId!]?.[linkTarget.node],
    peerNodeId: linkTarget.node,
    peerNodeName: nodeNameMap?.[linkTarget.node] ?? linkTarget.node,
    peerTimeReferenceSelection,
    peerTimeReferenceErrors,
    peerTimeError,
    peerTimeErrorType,
  };
};

export const getNodeCalibrationViewModel = ({
  nodeId,
  inLinkTargets,
  outLinkTargets,
  unusedLinkTargets,
  nodeNameMap,
  metrics,
  clusterTimeTransferConfig,
}: {
  nodeId: string;
  inLinkTargets: PersistedLinkTarget[];
  outLinkTargets: PersistedLinkTarget[];
  unusedLinkTargets: PersistedLinkTarget[];
  nodeNameMap: Record<string, string>;
  metrics: CalibrationMetrics;
  clusterTimeTransferConfig: ClusterTimeTransfer;
}): CalibrationViewModel => {
  const timeReferenceSelectionsByNodeId = getTimeReferenceSelectionsByNodeId(clusterTimeTransferConfig);
  const inLinks: LinkCalibrationViewModel[] =
    inLinkTargets
      .map(linkTarget =>
        getLinkCalibrationViewmodel({
          nodeId,
          linkTarget,
          metrics,
          nodeNameMap,
          timeReferenceSelectionsByNodeId,
        }),
      )
      .filter(il => (il.profileIndex ?? 0) > 0)
      .sort((l1, l2) => l1.name.localeCompare(l2.name)) ?? [];
  const outLinks: LinkCalibrationViewModel[] =
    outLinkTargets
      .map(linkTarget =>
        getLinkCalibrationViewmodel({
          nodeId,
          linkTarget,
          metrics,
          nodeNameMap,
          timeReferenceSelectionsByNodeId,
        }),
      )
      .filter(ol => (ol.profileIndex ?? 0) > 0)
      .sort((l1, l2) => l1.name.localeCompare(l2.name)) ?? [];
  const unusedLinks: LinkCalibrationViewModel[] = unusedLinkTargets.map(linkTarget =>
    getLinkCalibrationViewmodel({
      nodeId,
      linkTarget,
      metrics,
      nodeNameMap,
      timeReferenceSelectionsByNodeId,
    }),
  );
  return {
    nodeId,
    localTimeReferenceErrors: getTimeReferenceErrorsMapFromMetrics(nodeId, metrics),
    defaultLocalTimeReferenceSelections: {
      ...(timeReferenceSelectionsByNodeId?.[nodeId] ?? DefaultTimeReferenceSelection),
    },
    inLinks,
    outLinks,
    unusedLinks,
    isHeadEndNode: inLinks.length === 0,
  };
};

export const getCalibrationEditModel = (viewModel: CalibrationViewModel): CalibrationEditModel => {
  return {
    ...DefaultEditModel,
    localTimeReferenceSelections: {
      ...(viewModel.defaultLocalTimeReferenceSelections ?? DefaultEditModel.localTimeReferenceSelections),
      custom: false,
    },
    linkTimeErrorsByLinkIdAndProfileIndex: Object.fromEntries(
      viewModel.inLinks
        ?.concat(viewModel.outLinks ?? [])
        .map(l => [l.linkId, { [l.profileIndex ?? 0]: { newPathDiff: l.currentPathDiff ?? 0 } }]),
    ),
  };
};

const getPathDiffsMapFromArray = (links: LinkCalibrationViewModel[], pathDiffs: number[]): LinkTimeErrorEditModel => {
  return Object.fromEntries(
    A.zipWith(links, pathDiffs, (link, pathDiff) => [
      link.linkId,
      { [link.profileIndex ?? 0]: { newPathDiff: roundToPrecision(toMicroseconds(pathDiff), 3) } },
    ]),
  );
};

const getPathDiffsArrayFromMap = (
  links: LinkCalibrationViewModel[],
  linkTimeErrorsByLinkIdAndProfileIndex: LinkTimeErrorEditModel,
): number[] => {
  return links.map(link =>
    fromMicroseconds(linkTimeErrorsByLinkIdAndProfileIndex?.[link.linkId]?.[link.profileIndex ?? 0].newPathDiff ?? 0),
  );
};

const getProblemLink = (link: LinkCalibrationViewModel) => {
  const peerNode = new singleNodeCalibration.Node(link.peerNodeId);
  peerNode.time_error = getTimeReferenceErrorsArrayFromMap(
    link.peerTimeReferenceErrors,
    link.peerTimeReferenceSelection,
  );
  const pLink = new singleNodeCalibration.Link(link.linkId, link.name, peerNode);
  pLink.rtt = fromMicroseconds(link.currentRtt ?? 0);
  pLink.link_error = link.linkTimeError ?? 0;
  pLink.profile_path_diff = fromMicroseconds(link.currentPathDiff ?? 0);
  return pLink;
};

const getCalibrationStrategy = (calculationMethod: CalculationMethod) => {
  switch (calculationMethod) {
    case CalculationMethods.eliminateLinkSpread:
      return singleNodeCalibration.SolveStrategy.EliminateLinkSpread;
    case CalculationMethods.eliminateTimeError:
      return singleNodeCalibration.SolveStrategy.EliminateTimeError;
    case CalculationMethods.adjustTimeOnly:
    default:
      return singleNodeCalibration.SolveStrategy.EliminateTimeErrorWithUnaffectedDownstream;
  }
};

const getProblemFromContext = (context: CalibrationContext) => {
  const problem = new singleNodeCalibration.Problem();
  problem.myself = new singleNodeCalibration.Node(context.nodeId);
  problem.myself.time_error = getLocalTimeReferenceErrorsArray(context);
  problem.incoming_links = context.inLinks.map(il => getProblemLink(il));
  problem.outgoing_links = context.outLinks.map(ol => getProblemLink(ol));
  problem.validate();
  return problem;
};

const getSolutionFromProblem = (problem: singleNodeCalibration.Problem, context: CalibrationContext) => {
  return singleNodeCalibration.solve(problem, getCalibrationStrategy(context.calculationMethod));
};

const getSolutionFromContext = (context: CalibrationContext): singleNodeCalibration.Solution => {
  const problem = getProblemFromContext(context);
  const solution = new singleNodeCalibration.Solution();
  solution.incoming_path_diff = getPathDiffsArrayFromMap(
    context.inLinks,
    context.linkTimeErrorsByLinkIdAndProfileIndex,
  );

  solution.outgoing_path_diff = getPathDiffsArrayFromMap(
    context.outLinks,
    context.linkTimeErrorsByLinkIdAndProfileIndex,
  );
  solution.validate(problem);
  return solution;
};

const getPredictionViewModelFromCalculation = (
  prediction: singleNodeCalibration.Predicted,
  context: CalibrationContext,
): CalibrationPredictionViewModel => {
  const peerTimeReferenceSelectionByNodeId = Object.fromEntries(
    context.outLinks.map(ol => [ol.peerNodeId, ol.peerTimeReferenceSelection]),
  );
  return {
    localTimeReferenceErrors: getTimeReferenceErrorsMapFromArray(
      prediction.time_error,
      context.localTimeReferenceSelections,
      true,
    ) as LocalTimeReferenceErrors,
    inLinkTimeErrorsByLinkId: Object.fromEntries(
      A.zipWith(context.inLinks, prediction.incoming_link_errors, (inLink, timeError) => [inLink.linkId, timeError]),
    ),
    outlinkPeerTimeReferenceErrorsByNodeId: Object.fromEntries(
      Object.entries(prediction.downstream_time_errors).map(([nodeId, timeErrors]) => [
        nodeId,
        getTimeReferenceErrorsMapFromArray(
          timeErrors,
          peerTimeReferenceSelectionByNodeId[nodeId] ?? DefaultTimeReferenceSelection,
        ),
      ]),
    ),
  };
};

export const calculatePathDiffs = (context: CalibrationContext): CalibrationSolutionViewModel => {
  const problem = getProblemFromContext(context);
  const solution = getSolutionFromProblem(problem, context);
  const linkTimeErrorsEditModel = deepMerge(
    context.linkTimeErrorsByLinkIdAndProfileIndex,
    getPathDiffsMapFromArray(context.inLinks, solution.incoming_path_diff),
    getPathDiffsMapFromArray(context.outLinks, solution.outgoing_path_diff),
  );
  return { linkTimeErrorsEditModel };
};

export const predictTimeErrors = (context: CalibrationContext): CalibrationPredictionViewModel => {
  const problem = getProblemFromContext(context);
  const solution = getSolutionFromContext(context);
  const prediction = singleNodeCalibration.predict(problem, solution);
  return getPredictionViewModelFromCalculation(prediction, context);
};
