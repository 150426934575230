import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo } from 'react';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { usePrevious } from 'react-use';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@material-ui/core';
import { F } from '@mobily/ts-belt';
import {
  PtpTransmitterConfig,
  PtpTransmitterConfigSchema,
  ptpTransmitterDisplayName,
} from '@netinsight/crds-timetransfer';
import {
  TextField,
  useFormStyles,
  usePermission,
  useSnackbarHelper,
  useSubmitButtonProps,
  useTextFieldController,
  useTimeTransferConfigUpdate,
} from '@netinsight/management-app-common-react';
import {
  FieldErrors,
  getDefaultLegacyUnicastMaxConnectionsValue,
  isValidationError,
} from '@netinsight/management-app-common-api';
import { PtpAvailableInterfacesWarning } from './PtpAvailableInterfacesWarning';
import { PtpContext } from './PtpContext';
import { PtpTransmitterConfigEntry } from './PtpTransmitterConfigEntry';
import { PtpTransmitterInstanceAddButton } from './PtpTransmitterInstanceAddButton';

type PtpTransmitterConfigFormProps = {
  config?: PtpTransmitterConfig;
  nodeId: string;
};

export type PtpTransmitterConfigFormValues = PtpTransmitterConfig;

const defaultInitialFormValues: PtpTransmitterConfigFormValues = {
  delayNs: 0,
  instances: [],
};

// legacy configuration does not have unicastMaxConnections configured so make sure to add it
function getPTPConfig(config: PtpTransmitterConfig | undefined): PtpTransmitterConfig {
  if (!config?.instances) return defaultInitialFormValues;

  return {
    ...config,
    instances: (config.instances ?? []).map(instance => {
      const unicastMaxConnections =
        instance.unicastMaxConnections ?? getDefaultLegacyUnicastMaxConnectionsValue(instance.profile);
      return {
        ...instance,
        unicastMaxConnections,
      };
    }),
  };
}

export const PtpTransmitterConfigForm: FunctionComponent<PtpTransmitterConfigFormProps> = ({ nodeId, config }) => {
  const styles = useFormStyles();
  const { snackbar } = useSnackbarHelper();
  const initialFormValues = useMemo(() => getPTPConfig(config), [config]);
  const prevInitialFormValues = usePrevious(initialFormValues);

  const formProps = useForm<PtpTransmitterConfigFormValues>({
    defaultValues: initialFormValues,
    mode: 'onChange',
    resolver: zodResolver(PtpTransmitterConfigSchema),
  });
  const { control, handleSubmit, reset, formState, setError } = formProps;

  const { trigger: updateConfig, permission: updateConfigPermission } = useTimeTransferConfigUpdate(nodeId, true, {
    // we are overriding the default update behavior to be able to show field errors
    onError: (e: any) => {
      const wrapperErrorBody = e.body?.error;
      if (wrapperErrorBody && isValidationError(wrapperErrorBody)) {
        const fieldErrors = wrapperErrorBody.details as FieldErrors;
        Object.entries(fieldErrors).forEach(([fieldName, fieldError]) => {
          setError(fieldName as keyof PtpTransmitterConfigFormValues, fieldError);
        });
      } else {
        snackbar.notifyError('Update', e.response, null);
      }
    },
    // disable revalidation on error to be able to show field errors
    revalidate: false,
    throwOnError: false,
  });
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updateConfigPermission);
  const buttonProps = useSubmitButtonProps({ permission, formState });

  const { interfaceNameMap } = useContext(PtpContext);
  const submitConfig = useCallback(
    (updatedConfig: PtpTransmitterConfigFormValues) => {
      return updateConfig(existingConfig => ({
        ...existingConfig,
        ptpGm: {
          ...updatedConfig,
          instances: updatedConfig.instances?.map(instance => ({
            ...instance,
            displayName: ptpTransmitterDisplayName(instance.interface, interfaceNameMap),
          })),
        },
      }));
    },
    [updateConfig, interfaceNameMap],
  );

  const handleReset = useCallback(() => {
    reset();
  }, [reset]);

  const { field: delayNsFieldProps } = useTextFieldController({
    control,
    name: 'delayNs',
    label: 'Delay compensation',
    placeholder: '0',
    schema: PtpTransmitterConfigSchema.shape.delayNs.removeDefault(),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'instances',
  });

  useEffect(() => {
    if (!F.equals(prevInitialFormValues, initialFormValues)) {
      reset(initialFormValues, { keepSubmitCount: true, keepIsSubmitted: true, keepValues: true });
    }
  }, [prevInitialFormValues, initialFormValues, reset]);

  return (
    <FormProvider {...formProps}>
      <PtpAvailableInterfacesWarning mode="transmitter" />
      <form onSubmit={handleSubmit(submitConfig)} className={styles.formContainer}>
        {fields.map((field, index) => (
          <PtpTransmitterConfigEntry
            field={field}
            index={index}
            key={field.id}
            onRemoveClick={remove}
            permission={permission}
          />
        ))}
        <div>
          <PtpTransmitterInstanceAddButton onAddInstance={append} permission={permission} />
        </div>
        <TextField {...delayNsFieldProps} unit="ns" />
        <div className={styles.buttonContainer}>
          <Button {...buttonProps} data-testid="btn-apply-ptp-config" />
          <Button
            type="button"
            size="large"
            variant="outlined"
            color="default"
            data-testid="btn-reset-ptp-config"
            onClick={handleReset}
            {...buttonProps}
            disabled={buttonProps.disabled || !formState.isDirty}
          >
            Reset
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
