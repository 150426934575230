import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import {
  InventoryKinds,
  StatusBox,
  useInventoryWatch,
  useTimeTransferConfig,
} from '@netinsight/management-app-common-react';
import React, { PropsWithChildren } from 'react';
import { SyncInConfigForm } from './SyncInConfigForm';
import { SyncdStatus } from '../../../../../../types/sync';

export const SyncInConfig = ({ nodeId }: PropsWithChildren<{ nodeId: string }>) => {
  const { state: syncdStatuses, loading: isLoadingSyncdStatuses } = useInventoryWatch<SyncdStatus>({
    nodeId,
    kind: InventoryKinds.SyncStatus,
  });
  const { data: config, isLoading: isConfigLoading, error: configLoadError } = useTimeTransferConfig(nodeId);

  if (isConfigLoading) {
    return <Progress />;
  }
  if (configLoadError) {
    return <ResponseErrorPanel error={configLoadError} />;
  }
  if (config) {
    return (
      <>
        {config.syncIn?.selectable === true ? (
          <StatusBox
            isLoading={isLoadingSyncdStatuses}
            showToggle={false}
            style={{ marginBottom: '1.5rem' }}
            statuses={[['Status', syncdStatuses?.sync_in_active ? 'Active' : 'Inactive']]}
          />
        ) : null}
        <SyncInConfigForm nodeId={nodeId} config={config.syncIn ?? {}} />
      </>
    );
  }
  return null;
};
