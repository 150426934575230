import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from '@backstage/core-components';
import { Button, makeStyles } from '@material-ui/core';
import { InventoryKinds, StatusBox, useAllInventories } from '@netinsight/management-app-common-react';
import { G } from '@mobily/ts-belt';
import { useGnssStatusMetrics } from '../../../../../../hooks/sync';
import { SyncdStatus } from '../../../../../../types/sync';

const useStyles = makeStyles(theme => ({
  statusBox: {
    marginBottom: theme.spacing(3),
  },
}));

const SwrConfig = { refreshInterval: 10_000 };

export const GnssStatusBox: FunctionComponent<{ nodeId: string }> = ({ nodeId }) => {
  const { data: gnssStatuses, isLoading: isLoadingGnssStatuses } = useGnssStatusMetrics(nodeId, SwrConfig);
  const { data: syncInventory, isLoading: isLoadingSyncInventory } = useAllInventories<{
    [InventoryKinds.SyncStatus]?: { data: SyncdStatus };
  }>(
    {
      nodeIds: [nodeId],
      kinds: [InventoryKinds.SyncStatus],
    },
    SwrConfig,
  );
  const syncStatuses = syncInventory?.[nodeId]?.[InventoryKinds.SyncStatus]?.data;

  const styles = useStyles();

  return (
    <StatusBox
      className={styles.statusBox}
      isLoading={isLoadingSyncInventory || isLoadingGnssStatuses}
      showToggle={false}
      statuses={[
        ['Status', syncStatuses?.gnss_active ? 'Active' : 'Inactive'],
        ['Fix Status', gnssStatuses?.fixStatus],
        ['Fix Mode', gnssStatuses?.fixMode],
        ['Antenna Status', gnssStatuses?.antennaStatus],
        ['Visible satellites', gnssStatuses?.visibleSats],
        ['Used satellites', gnssStatuses?.usedSats],
        ['T-RAIM enabled', gnssStatuses?.traimEnabled],
        ...(gnssStatuses?.traimEnabled === 'Yes'
          ? ([
              [
                'T-RAIM threshold',
                G.isNotNullable(gnssStatuses?.traimThreshold)
                  ? `${(gnssStatuses.traimThreshold * 1e9).toFixed(0)}ns`
                  : '',
              ],
              ['T-RAIM solution', gnssStatuses?.traimSolution],
              ['T-RAIM avg time error', gnssStatuses?.traimAvgTimeErr],
              ['T-RAIM used satellites', gnssStatuses?.traimUsedSats],
              ['T-RAIM removed satellites', gnssStatuses?.traimRemovedSats],
            ] as [string, ReactNode][])
          : []),
      ]}
      data-testid="gnss-status-box"
    >
      <Button variant="outlined" color="default">
        <Link to="metrics">Details</Link>
      </Button>
    </StatusBox>
  );
};
