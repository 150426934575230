import React, { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { UseFieldArrayAppend } from 'react-hook-form';
import AddIcon from '@material-ui/icons/Add';
import { G } from '@mobily/ts-belt';
import { PTP_TRANSMITTER_PROFILES, PtpProfile, PtpProfileLabels, PtpProfiles } from '@netinsight/crds-timetransfer';
import {
  buttonPropsFromPermission,
  isNullableOrEmpty,
  PermissionResult,
  SplitButton,
  SplitButtonProps,
  useSnackbarHelper,
} from '@netinsight/management-app-common-react';
import { getDefaultInstanceValues } from './constants';
import { PtpContext } from './PtpContext';
import { PtpTransmitterConfigFormValues } from './PtpTransmitterConfigForm';
import {
  usePtpTransmitterRemainingInstancesCount,
  usePtpTransmitterSelectedPhysicalInterfaceNames,
} from '../../../../../../hooks/sync';

const ProfileOptions = PTP_TRANSMITTER_PROFILES.map(
  profile => [PtpProfileLabels[profile] ?? profile, profile] as [string, any],
);
const buttonTextFormatter: SplitButtonProps['buttonTextFormatter'] = (_, label) =>
  label ? `Add new ${label} instance` : `Add`;

export const PtpTransmitterInstanceAddButton: FunctionComponent<{
  onAddInstance: UseFieldArrayAppend<PtpTransmitterConfigFormValues, 'instances'>;
  permission: PermissionResult;
}> = ({ onAddInstance, permission }) => {
  const buttonProps = buttonPropsFromPermission(permission);
  const { maxConnectionsTotal } = useContext(PtpContext);
  const { availableInterfaces } = useContext(PtpContext);
  const { snackbar } = useSnackbarHelper();
  const usedPhysicalInterfaces = usePtpTransmitterSelectedPhysicalInterfaceNames();

  const freeInterfaces = useMemo(
    () => availableInterfaces.filter(ai => !usedPhysicalInterfaces?.includes(ai.name)),
    [availableInterfaces, usedPhysicalInterfaces],
  );
  const remainingInstanceCount = usePtpTransmitterRemainingInstancesCount();
  const canAddInstance = useMemo(
    () => freeInterfaces.length > 0 && remainingInstanceCount > 0,
    [freeInterfaces, remainingInstanceCount],
  );
  const getFirstUnusedInterfaceForSelectedProfile = useCallback(
    (profile: PtpProfile) =>
      freeInterfaces.find(
        profile === PtpProfiles.G8275_1 ? iface => G.isNullable(iface.vlanId) : iface => !isNullableOrEmpty(iface.ip),
      ),
    [freeInterfaces],
  );
  const handleOptionSelected = useCallback(
    (selectedOption: PtpProfile) => {
      const firstUnusedInterface = getFirstUnusedInterfaceForSelectedProfile(selectedOption);
      if (G.isNullable(firstUnusedInterface)) {
        snackbar.error(`No available interfaces for ${PtpProfileLabels[selectedOption]}`);
        return;
      }
      onAddInstance({
        ...getDefaultInstanceValues(selectedOption, maxConnectionsTotal),
        interface: firstUnusedInterface.id,
      });
    },
    [onAddInstance, getFirstUnusedInterfaceForSelectedProfile, snackbar, maxConnectionsTotal],
  );

  return (
    <SplitButton
      color="primary"
      initialSelectedOption={PtpProfiles.G8275_1}
      disabled={buttonProps.disabled || !canAddInstance}
      title={buttonProps.title}
      options={ProfileOptions}
      buttonTextFormatter={buttonTextFormatter}
      buttonProps={{
        startIcon: <AddIcon />,
        size: 'small',
        ...buttonProps,
      }}
      data-testid="btn-add-ptp-config-entry"
      onOptionSelected={handleOptionSelected}
    />
  );
};
