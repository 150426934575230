import React, { useMemo } from 'react';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import { useTimeTransferConfig } from '@netinsight/management-app-common-react';
import { PtpContextProvider, PtpContextValue } from './PtpContext';
import { PtpReceiverConfigForm } from './PtpReceiverConfigForm';
import { PtpTransmitterConfigForm } from './PtpTransmitterConfigForm';
import { useInterfaceInfos, useInterfaceUsage } from '../../../../../../hooks/nodes';

export const PtpConfigPage = ({ nodeId, mode }: { nodeId: string; mode: 'receiver' | 'transmitter' }) => {
  const {
    data: usageMap,
    isLoading: isLoadingInterfaceUsage,
    error: interfaceUsageError,
  } = useInterfaceUsage({ nodeId });
  const {
    data: availableInterfaces,
    isLoading: isLoadingInterfaceInfos,
    error: interfaceInfosError,
  } = useInterfaceInfos({
    nodeId,
    usageMap,
    includeVlan: mode !== 'receiver',
    includePtpTransmitters: mode === 'transmitter',
    includePtpReceivers: mode === 'receiver',
  });
  const { data: allInterfaces, isLoading: isLoadingAllInterfaces } = useInterfaceInfos({
    nodeId,
    statuses: [],
    includeVlan: true,
    includeManagement: true,
  });
  const {
    data: timeTransferConfig,
    maxConnectionsTotal,
    isLoading: isConfigLoading,
    error: timeTransferError,
  } = useTimeTransferConfig(nodeId);
  const isLoading = isConfigLoading || isLoadingInterfaceInfos || isLoadingInterfaceUsage || isLoadingAllInterfaces;
  const error = timeTransferError ?? interfaceInfosError ?? interfaceUsageError;

  const contextValue: PtpContextValue = useMemo(
    () => ({
      availableInterfaces,
      nodeId,
      maxConnectionsTotal,
      interfaceNameMap: Object.fromEntries(allInterfaces.map(iface => [iface.id, iface.displayName])),
    }),
    [availableInterfaces, allInterfaces, nodeId, maxConnectionsTotal],
  );

  if (isLoading) {
    return <Progress style={{ width: '100%' }} />;
  }
  if (error) {
    return <ResponseErrorPanel error={error} />;
  }
  if (timeTransferConfig) {
    return (
      <PtpContextProvider value={contextValue}>
        {mode === 'receiver' ? <PtpReceiverConfigForm nodeId={nodeId} config={timeTransferConfig.ptpReceiver} /> : null}
        {mode === 'transmitter' ? <PtpTransmitterConfigForm nodeId={nodeId} config={timeTransferConfig.ptpGm} /> : null}
      </PtpContextProvider>
    );
  }
  return null;
};
