import React, { useCallback, useMemo, useState } from 'react';
import {
  useSnackbarHelper,
  errorFromWrappedError,
  k8sOnboardingApiRef,
  useTableState,
  usePermission,
  apiPermission,
} from '@netinsight/management-app-common-react';
import { onboardingApi as contract } from '@netinsight/management-app-common-api';
import { Progress, ResponseErrorPanel, Link, Table, TableColumn, TableProps } from '@backstage/core-components';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useApi } from '@backstage/core-plugin-api';
import { DateTime } from 'luxon';
import { ResolvedOnboardingRequest, useOnboardingRequests } from '../../../hooks';
import { DEFAULT_SYNC_REGION } from '@netinsight/crds';
import { compareSyncRegion } from '@netinsight/management-app-common-api';
import { useSyncRegionByNodeIdLookup, useSyncRegions } from '../../../hooks/sync';
import { getSyncRegionsLookupForTable } from '../../../utils/sync';

interface OnboardingRow {
  id: string;
  nodeId?: string;
  nodeName?: string;
  syncRegion?: string;
  hostname: string;
  message?: string;
  lastTransitionTime: DateTime | null;
}

const toRow = (
  model: ResolvedOnboardingRequest,
  syncRegionLookup: (nodeId: string) => string | undefined,
): OnboardingRow => {
  const condition = model.status.conditions?.find((c: any) => c.type === 'Accepted');
  return {
    id: model.id,
    nodeId: model.status.nodeId ?? '',
    nodeName: model.status.nodeName ?? '',
    syncRegion: syncRegionLookup(model.status.nodeId ?? ''),
    hostname: model.spec.hostname,
    message: condition?.message ?? '',
    lastTransitionTime:
      condition && condition.lastTransitionTime ? DateTime.fromISO(`${condition.lastTransitionTime}`) : null,
  };
};

const tableOptions: TableProps['options'] = {
  actionsColumnIndex: -1,
  debounceInterval: 250,
  filtering: true,
  loadingType: 'linear',
  padding: 'dense',
  pageSize: 20,
  pageSizeOptions: [20, 50, 100],
  paging: true,
  search: false,
  thirdSortClick: false,
  columnsButton: true,
};

export const OnboardingList = () => {
  const onboardingApi = useApi(k8sOnboardingApiRef);
  const { data, error } = useOnboardingRequests();
  const { data: syncRegions } = useSyncRegions();
  const syncRegionLookup = useSyncRegionByNodeIdLookup(syncRegions);
  const rows = useMemo(() => data?.map(item => toRow(item, syncRegionLookup)) ?? [], [data, syncRegionLookup]);
  const { snackbar } = useSnackbarHelper();
  const [open, setOpen] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');

  const columns: TableColumn<OnboardingRow>[] = useMemo(
    () => [
      {
        title: 'Name',
        field: 'nodeName',
        filtering: true,
        highlight: true,
        sorting: true,
        defaultSort: 'asc',
        render: (rowData: any) => {
          return (
            <Link data-testid="row-node-name" to={`/nodes/info/${rowData.nodeId}`}>
              {rowData.nodeName}
            </Link>
          );
        },
      },
      {
        title: 'Sync Region',
        field: 'syncRegion',
        filtering: true,
        lookup: getSyncRegionsLookupForTable(syncRegions ?? []),
        customSort: (row1, row2) =>
          compareSyncRegion(row1.syncRegion ?? DEFAULT_SYNC_REGION, row2.syncRegion ?? DEFAULT_SYNC_REGION),
      },
      { title: 'Id', field: 'nodeId', filtering: true },
      { title: 'Hostname', field: 'hostname', hidden: true },
      { title: 'Message', field: 'message', filtering: true },
      {
        title: 'Last Changed',
        field: 'lastTransitionTime',
        filtering: false,
        render: (rowData: any) => rowData.lastTransitionTime?.toRelative(),
        customSort: (a: OnboardingRow, b: OnboardingRow) =>
          a.lastTransitionTime && b.lastTransitionTime ? a.lastTransitionTime.diff(b.lastTransitionTime).valueOf() : 0,
      },
    ],
    [syncRegions],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    setToken('');
  }, []);

  const handleCopy = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigator.clipboard.writeText(token);
    snackbar.info('Copied to clipboard');
  }, [snackbar, token]);

  const generateToken = useCallback(() => {
    setOpen(true);
    onboardingApi
      .createOnboardingToken()
      .then(result => {
        if (result.status !== 200) {
          throw errorFromWrappedError(result.status, result.body);
        }
        setToken(result.body.access_token);
      })
      .catch(err => {
        snackbar.error(err.message);
        setOpen(false);
      });
  }, [onboardingApi, snackbar]);

  const { isAllowed } = usePermission(apiPermission(contract.createOnboardingToken));
  const actions: TableProps<OnboardingRow>['actions'] = useMemo(
    () =>
      isAllowed
        ? [
            {
              icon: () => <VpnKeyIcon />,
              tooltip: 'Generate New Token',
              isFreeAction: true,
              onClick: () => generateToken(),
            },
          ]
        : [],
    [isAllowed, generateToken],
  );

  const tableStates = useTableState<OnboardingRow>(columns, tableOptions, 'onboarding-table');

  return (
    <div>
      {error && <ResponseErrorPanel error={error} />}
      <Table<OnboardingRow> title="Onboarding Requests" data={rows} actions={actions} {...tableStates} />
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>New onboarding token</DialogTitle>
        <DialogContent>
          {!token && <Progress />}
          {token && (
            <DialogContentText data-testid="token" style={{ overflowWrap: 'break-word' }}>
              {token}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <IconButton title="Copy to clipboard" disabled={!token} size="small" onClick={handleCopy}>
            <FileCopyIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
