import React, { FunctionComponent } from 'react';
import { Control } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import {
  PtpReceiverSchema,
  PtpProfileLabels,
  PtpProfiles,
  DEFAULT_PTP_RECEIVER_UNCERTAINTY_SECONDS,
  DEFAULT_PTP_RECEIVER_TOLERANCE_SECONDS,
} from '@netinsight/crds-timetransfer';
import { TextField, useFormStyles, useTextFieldController } from '@netinsight/management-app-common-react';
import { PtpInterfaceSelect } from './PtpInterfaceSelect';
import type { PtpReceiverConfigFormValues } from './PtpReceiverConfigForm';

const Schema = PtpReceiverSchema.innerType();

export const PtpReceiverConfiguration: FunctionComponent<{
  control: Control<PtpReceiverConfigFormValues>;
  index: number;
}> = ({ index, control }) => {
  const formStyles = useFormStyles();

  const { field: domainInputProps } = useTextFieldController({
    control,
    name: `instances.${index}.domain`,
    schema: Schema.shape.domain,
    label: 'Domain',
  });

  const { field: delayInputProps } = useTextFieldController({
    control,
    name: `instances.${index}.delayNs`,
    schema: Schema.shape.delayNs,
    label: 'Delay compensation',
    placeholder: '0',
  });

  const { field: uncertaintyInputProps } = useTextFieldController({
    control,
    name: `instances.${index}.uncertaintyNs`,
    schema: Schema.shape.uncertaintyNs,
    label: 'PTP servo uncertainty',
    placeholder: (DEFAULT_PTP_RECEIVER_UNCERTAINTY_SECONDS * 1e9).toString(),
  });

  const { field: toleranceInputProps } = useTextFieldController({
    control,
    name: `instances.${index}.maxOffsetFromTransmitterNs`,
    schema: Schema.shape.maxOffsetFromTransmitterNs,
    label: 'PTP servo threshold',
    placeholder: (DEFAULT_PTP_RECEIVER_TOLERANCE_SECONDS * 1e9).toString(),
  });

  return (
    <>
      <Typography component="h6" variant="h6">
        PTP Configuration
      </Typography>
      <div className={formStyles.formGrid}>
        <TextField
          label="Profile"
          disabled
          fullWidth
          value={PtpProfileLabels[PtpProfiles.G8275_1]}
          description={Schema.shape.profile.description}
        />
        <PtpInterfaceSelect index={index} allowEmpty description={Schema.shape.interface.description} />
        <TextField fullWidth {...domainInputProps} />
        <TextField
          fullWidth
          unit="ns"
          {...delayInputProps}
          inputProps={{ ...delayInputProps.inputProps, type: 'text' }}
        />
        <TextField
          fullWidth
          unit="ns"
          {...toleranceInputProps}
          inputProps={{ ...toleranceInputProps.inputProps, type: 'text' }}
        />
        <TextField
          fullWidth
          unit="ns"
          {...uncertaintyInputProps}
          inputProps={{ ...uncertaintyInputProps.inputProps, type: 'text' }}
        />
      </div>
    </>
  );
};
