import React from 'react';
import { Page, TabbedLayout } from '@backstage/core-components';
import { UserSettingsFeatureFlags } from '@backstage/plugin-user-settings';
import { GeneralSettingsTab } from './GeneralSettingsTab';
import { HeaderWithAlarms } from '../../components/HeaderWithAlarms/HeaderWithAlarms';

export const SettingsPage = () => {
  return (
    <Page themeId="home">
      <HeaderWithAlarms title="Settings" />
      <TabbedLayout>
        <TabbedLayout.Route path="/" title="General">
          <GeneralSettingsTab />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="feature-flags" title="Feature Flags">
          <UserSettingsFeatureFlags />
        </TabbedLayout.Route>
      </TabbedLayout>
    </Page>
  );
};
