import {
  EXTERNAL_SYNC_SOURCES,
  getSyncSourcePriorityConfigMap,
  getSyncSourceUsageConfigMap,
  SYNC_SOURCE_NAMES,
} from '@netinsight/management-app-common-api';
import { useNodeLinksByDirection } from '../../hooks/links';
import { useTimeNodeOverviewMetrics } from '../../hooks/metrics';
import { ValueOf } from '@netinsight/management-app-common-react';
import { SYNC_SOURCE_CONFIG_URLS, SYNC_SOURCE_LABELS } from '../../constants/sync';

export const sortByPriority = (
  { priority: prio1 = NaN }: { priority?: number },
  { priority: prio2 = NaN }: { priority?: number },
) => prio1 - prio2 || Number(isNaN(prio1)) - Number(isNaN(prio2));

export const getSyncSourceViewModels = ({
  nodeId,
  usageConfig,
  priorityConfig,
  syncSourceAvailabilities,
  syncSourcePriorities,
  syncSourceSelections,
}: {
  nodeId: string;
  usageConfig: ReturnType<typeof getSyncSourceUsageConfigMap>;
  priorityConfig: ReturnType<typeof getSyncSourcePriorityConfigMap>;
} & Partial<Required<ReturnType<typeof useNodeLinksByDirection>['data']>> &
  Partial<Required<ReturnType<typeof useTimeNodeOverviewMetrics>['data']>>) => {
  const getSyncSourceViewModelEntry = (name: ValueOf<typeof SYNC_SOURCE_NAMES>) => ({
    name,
    label: SYNC_SOURCE_LABELS[name],
    url: SYNC_SOURCE_CONFIG_URLS[name](nodeId),
    usage: usageConfig?.[name],
    available: syncSourceAvailabilities?.[name],
    priority: syncSourcePriorities?.[name] ?? priorityConfig?.[name],
    selected: syncSourceSelections?.[name],
  });

  return {
    externals: EXTERNAL_SYNC_SOURCES.map(getSyncSourceViewModelEntry).sort(sortByPriority),
    internals: [SYNC_SOURCE_NAMES.osc].map(getSyncSourceViewModelEntry).sort(sortByPriority),
    frequencies: [SYNC_SOURCE_NAMES.syncIn].map(getSyncSourceViewModelEntry).sort(sortByPriority),
  };
};
