import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import { G, S } from '@mobily/ts-belt';
import {
  useNodeManagerConfig,
  useNodeManagerConfigUpdate,
  usePermission,
} from '@netinsight/management-app-common-react';
import React, { FunctionComponent, useCallback } from 'react';
import { NtpClientConfigForm, NtpClientConfigFormProps } from './NtpClientConfigForm';
import { NtpTimeStatusBox } from './NtpTimeStatusBox';

export const NtpClientConfig: FunctionComponent<{ nodeId: string }> = ({ nodeId }) => {
  const { data: config, isLoading, error } = useNodeManagerConfig(nodeId, { revalidateOnFocus: false });
  const { trigger: uploadConfig, permission: updatePermission } = useNodeManagerConfigUpdate(nodeId);
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updatePermission);
  const handleFormSubmit = useCallback<NtpClientConfigFormProps['onSubmit']>(
    async updatedConfig => {
      await uploadConfig(cfg => ({
        ...cfg,
        system: {
          ...cfg?.system,
          ntp: updatedConfig,
        },
      }));
    },
    [uploadConfig],
  );

  const ntpConfig = config?.system?.ntp ?? { servers: [] };
  if (isLoading || isLoadingPermission) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  } else if (config) {
    return (
      <>
        {ntpConfig.servers &&
        ntpConfig.servers.length > 0 &&
        ntpConfig.servers.some(
          ({ address }) => G.isNotNullable(address) && G.isString(address) && S.isNotEmpty(address),
        ) ? (
          <NtpTimeStatusBox nodeId={nodeId} />
        ) : null}
        <NtpClientConfigForm config={ntpConfig} onSubmit={handleFormSubmit} permission={permission} />
      </>
    );
  }
  return null;
};
