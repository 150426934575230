import React, { FunctionComponent, useCallback, useEffect } from 'react';
import usePrevious from 'react-use/lib/usePrevious';
import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { F, A } from '@mobily/ts-belt';
import { Button, Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { PermissionResult, useFormStyles, useSubmitButtonProps } from '@netinsight/management-app-common-react';
import { DnsConfigEntry } from './DnsConfigEntry';
import { DnsDefinition, DnsSchema } from '@netinsight/node-manager-schema';

export type DnsConfigFormProps = {
  config: DnsDefinition;
  onSubmit: (data: DnsDefinition) => Promise<void>;
  permission: PermissionResult;
};

export const DnsConfigForm: FunctionComponent<DnsConfigFormProps> = ({ config, onSubmit, permission }) => {
  const styles = useFormStyles();
  const { control, handleSubmit, reset, setValue, getValues, formState } = useForm({
    resolver: zodResolver(DnsSchema),
    mode: 'onChange',
    defaultValues: config,
  });
  const buttonProps = useSubmitButtonProps({ permission, formState });

  const prevDefaultValues = usePrevious(config);
  useEffect(() => {
    if (!F.equals(prevDefaultValues, config)) {
      reset(config, { keepSubmitCount: true, keepIsSubmitted: true, keepValues: true });
    }
  }, [prevDefaultValues, reset, config]);

  const handleAppendNewServer = useCallback(() => {
    const { nameservers } = getValues();
    setValue('nameservers', [...nameservers, '']);
  }, [getValues, setValue]);

  const handleRemove = useCallback(
    (index: number) => {
      const { nameservers } = getValues();
      setValue('nameservers', A.removeAt(nameservers, index) as string[]);
    },
    [getValues, setValue],
  );

  const nameserverValues = useWatch({ control, name: 'nameservers' });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
      {nameserverValues.map((_, index) => (
        <DnsConfigEntry
          key={index}
          label={`Name server ${index + 1}`}
          control={control}
          index={index}
          onRemove={handleRemove}
          permission={permission}
        />
      ))}
      <Button
        variant="outlined"
        size="small"
        color="primary"
        type="button"
        data-testid="btn-add-dns-nameserver"
        startIcon={<AddIcon />}
        style={{ alignSelf: 'flex-start' }}
        onClick={handleAppendNewServer}
        disabled={buttonProps.disabled}
      >
        Add name server
      </Button>
      <Box>
        <Button {...buttonProps} data-testid="btn-apply-dns-config" />
      </Box>
    </form>
  );
};
