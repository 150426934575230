import { merge as deepMerge } from 'ts-deepmerge';
import { A, D, F, N, pipe } from '@mobily/ts-belt';
import { PtpProfile, PtpProfileLabels } from '@netinsight/crds-timetransfer';
import {
  ALARM_SEVERITY_ORDER,
  ClusterTimeTransfer,
  EXTERNAL_SYNC_SOURCES,
  getSyncSourcePriorityConfigMap,
  getSyncSourceUsageConfigMap,
  SYNC_SOURCE_NAMES,
} from '@netinsight/management-app-common-api';
import { Alert, ValueOf } from '@netinsight/management-app-common-react';
import { PpsOutputSource } from '@netinsight/syncd-schema';
import { SYNC_OUTPUT_CONFIG_URLS, SYNC_OUTPUT_LABELS } from '../../constants/node-graph';
import { NodeMetrics, SyncSourceMetrics } from '../../types/node-graph';
import { SYNC_SOURCE_CONFIG_URLS, SYNC_SOURCE_LABELS } from '../../constants/sync';
import { sortByPriority } from '../sync';

type NodeAlarms = ReturnType<typeof getAlarmsViewModel>;

const getAlarmsViewModel = (alerts?: Alert[]) => {
  return pipe(
    alerts ?? [],
    A.groupBy(alert => alert.labels.nodeid),
    D.toPairs,
    A.map(([nodeId, nodeAlerts]) => {
      const alarmCountBySeverity = pipe(
        nodeAlerts!,
        A.groupBy(nodeAlert => nodeAlert.labels.severity),
        D.toPairs,
        A.map(([severity, severityAlerts]) => [severity, severityAlerts?.length ?? 0] as const),
        D.fromPairs,
        alarmsCounts =>
          pipe(
            ALARM_SEVERITY_ORDER,
            A.reverse,
            A.map(severity => [severity, alarmsCounts[severity] ?? 0] as [string, number]),
            A.filter(([, count]) => count > 0),
          ),
      );
      return [
        nodeId,
        {
          alarms: alarmCountBySeverity,
          alarmsTotal: pipe(
            alarmCountBySeverity,
            A.map(([, count]) => count),
            A.reduce(0, N.add),
          ),
          alarmSeverity: alarmCountBySeverity[0]?.[0],
        },
      ] as const;
    }),
    D.fromPairs,
  );
};

const getNodeSyncSourceViewModel = ({
  nodeId,
  usageConfig,
  priorityConfig,
  metrics,
}: {
  nodeId: string;
  usageConfig: ReturnType<typeof getSyncSourceUsageConfigMap>;
  priorityConfig: ReturnType<typeof getSyncSourcePriorityConfigMap>;
  metrics?: SyncSourceMetrics[string];
}) => {
  const getSyncSourceViewModelEntry = (name: ValueOf<typeof SYNC_SOURCE_NAMES>) => ({
    name,
    label: SYNC_SOURCE_LABELS[name],
    url: SYNC_SOURCE_CONFIG_URLS[name](nodeId),
    usage: usageConfig?.[name],
    available: metrics?.[name]?.available,
    priority: metrics?.[name]?.priority ?? priorityConfig?.[name],
    selected: metrics?.[name]?.selected,
  });

  return EXTERNAL_SYNC_SOURCES.map(getSyncSourceViewModelEntry)
    .sort(sortByPriority)
    .concat([SYNC_SOURCE_NAMES.syncIn].map(getSyncSourceViewModelEntry))
    .concat([SYNC_SOURCE_NAMES.osc].map(getSyncSourceViewModelEntry));
};

export const getNodeMetricsViewModel = (alerts?: Alert[], metrics?: NodeMetrics): NodeAlarms & NodeMetrics =>
  deepMerge(getAlarmsViewModel(alerts), metrics ?? {});

export const getNodeSyncMetricsViewModel = (configs?: ClusterTimeTransfer, syncSourceMetrics?: SyncSourceMetrics) => {
  return pipe(
    syncSourceMetrics ?? {},
    D.toPairs,
    A.map(([nodeId, metrics]) => {
      return [
        nodeId,
        deepMerge(
          {
            inputs: getNodeSyncSourceViewModel({
              nodeId,
              usageConfig: getSyncSourceUsageConfigMap(configs?.[nodeId]),
              priorityConfig: getSyncSourcePriorityConfigMap(configs?.[nodeId]),
              metrics,
            }),
          },
          {
            outputs: [
              {
                type: 'freqOut' as const,
                label: SYNC_OUTPUT_LABELS.freqOut,
                url: SYNC_OUTPUT_CONFIG_URLS.freqOut(nodeId),
                enabled: configs?.[nodeId]?.freqOut?.enabled === true,
              },
              {
                type: 'ppsOut' as const,
                label: SYNC_OUTPUT_LABELS.ppsOut,
                url: SYNC_OUTPUT_CONFIG_URLS.ppsOut(nodeId),
                enabled: configs?.[nodeId]?.ppsOut?.source === PpsOutputSource.TenMhzOsc,
              },
              ...pipe(
                configs?.[nodeId]?.ptpGm?.instances ?? [],
                F.ifElse(
                  instances => instances.length > 0,
                  instances =>
                    pipe(
                      instances,
                      A.groupBy(x => x.profile),
                      Object.entries,
                      A.map(([profile, profileInstances]) => ({
                        type: 'ptp' as const,
                        label: SYNC_OUTPUT_LABELS.ptp,
                        url: SYNC_OUTPUT_CONFIG_URLS.ptp(nodeId),
                        enabled: true,
                        subType: PtpProfileLabels[profile as PtpProfile] as string | undefined,
                        count: (profileInstances?.length ?? 0) as number,
                      })),
                    ),
                  F.always([
                    {
                      type: 'ptp' as const,
                      label: SYNC_OUTPUT_LABELS.ptp,
                      url: SYNC_OUTPUT_CONFIG_URLS.ptp(nodeId),
                      enabled: false,
                      subType: undefined,
                      count: 0,
                    },
                  ]),
                ),
              ),
            ],
            references: (
              [
                [SYNC_SOURCE_NAMES.gnss, configs?.[nodeId]?.gnss?.useAsReference === true],
                [SYNC_SOURCE_NAMES.ppsIn, configs?.[nodeId]?.ppsIn?.useAsReference === true],
                [SYNC_SOURCE_NAMES.ptp1, configs?.[nodeId]?.ptpReceiver?.instances?.[0]?.useAsReference === true],
                [SYNC_SOURCE_NAMES.ptp2, configs?.[nodeId]?.ptpReceiver?.instances?.[1]?.useAsReference === true],
              ] as const
            ).map(([syncSourceName, isReferenced]) => ({
              type: syncSourceName,
              label: SYNC_SOURCE_LABELS[syncSourceName],
              url: SYNC_SOURCE_CONFIG_URLS[syncSourceName](nodeId),
              enabled: isReferenced,
            })),
          },
        ),
      ] as const;
    }),
    D.fromPairs,
  );
};
