import React, { FunctionComponent, useCallback } from 'react';
import { F } from '@mobily/ts-belt';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { usePermission, useSnackbarHelper, useSubmitButtonProps } from '@netinsight/management-app-common-react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useFormStyles,
  TextField,
  useTextFieldController,
  getSelectOptionsFromSchema,
} from '@netinsight/management-app-common-react';
import { AlarmSubscriptionSpecSchema } from '@netinsight/crds';
import { z } from 'zod';
import classNames from 'classnames';
import { useAlarmSubscriptionCreate } from '../../../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3),
  },
}));

type AlarmSubscriptionFormData = z.infer<typeof AlarmSubscriptionSpecSchema>;

const DefaultFormValues: AlarmSubscriptionFormData = {
  name: '',
  purpose: '',
  target: '',
  format: 'prometheus',
  username: '',
  password: '',
};

export const AlarmSubscriptionDrawerContent: FunctionComponent<{
  onClose: VoidFunction;
}> = ({ onClose = F.ignore }) => {
  const { trigger: createSubscription, permission: createSubscriptionPermission } = useAlarmSubscriptionCreate();
  const { isLoading, ...permission } = usePermission(createSubscriptionPermission);
  const { snackbar } = useSnackbarHelper();

  const formProps = useForm({
    defaultValues: DefaultFormValues,
    mode: 'onChange',
    resolver: zodResolver(AlarmSubscriptionSpecSchema),
  });
  const { control, handleSubmit, formState, watch } = formProps;
  const buttonProps = useSubmitButtonProps({ permission, formState });

  const handleCreateOrUpdate = useCallback(
    async (submitData: AlarmSubscriptionFormData) => {
      await createSubscription(submitData, {
        onSuccess: () => snackbar.notifySuccess('Created subscription'),
        onError: () => snackbar.error('Subscription creation failed'),
      });
      onClose();
    },
    [createSubscription, onClose, snackbar],
  );

  const styles = useStyles();
  const formStyles = useFormStyles();

  const { field: nameInputProps } = useTextFieldController({
    control,
    name: 'name',
    label: 'Name',
    placeholder: 'Auto',
    description: 'Name for the subscription',
    schema: AlarmSubscriptionSpecSchema.shape.name,
  });

  const { field: purposeInputProps } = useTextFieldController({
    control,
    name: 'purpose',
    label: 'Purpose',
    placeholder: 'Auto',
    description: 'Freetext purpose for this subscription',
    schema: AlarmSubscriptionSpecSchema.shape.purpose,
  });

  const { field: targetInputProps } = useTextFieldController({
    control,
    name: 'target',
    label: 'Target',
    placeholder: 'Auto',
    description: 'Webhook to call',
    schema: AlarmSubscriptionSpecSchema.shape.target,
  });

  const { field: authenticationFlowSelectProps } = useTextFieldController({
    control,
    name: 'authenticationFlow',
    schema: AlarmSubscriptionSpecSchema.shape.authenticationFlow,
    label: 'Authentication method',
    defaultValue: 'none',
  });

  const { field: usernameInputProps } = useTextFieldController({
    control,
    name: 'username',
    label: 'Username',
    placeholder: 'Optional',
    description: 'Basic authentication username',
    schema: AlarmSubscriptionSpecSchema.shape.username,
  });

  const { field: passwordInputProps } = useTextFieldController({
    control,
    name: 'password',
    label: 'Password',
    placeholder: 'Optional',
    description: 'Basic authentication password',
    schema: AlarmSubscriptionSpecSchema.shape.password,
  });

  const { field: tokenUrlInputProps } = useTextFieldController({
    control,
    name: 'tokenUrl',
    label: 'Token URL',
    placeholder: 'Required',
    description: 'RFC6749 compliant token URL',
    schema: AlarmSubscriptionSpecSchema.shape.tokenUrl,
  });

  const { field: scopeInputProps } = useTextFieldController({
    control,
    name: 'scope',
    label: 'Scope',
    placeholder: 'Optional',
    description: 'Scope',
    schema: AlarmSubscriptionSpecSchema.shape.scope,
  });

  const { field: clientIdInputProps } = useTextFieldController({
    control,
    name: 'username',
    label: 'Client Id',
    placeholder: 'Required',
    description: 'Client Identity',
    schema: AlarmSubscriptionSpecSchema.shape.username,
  });

  const { field: clientSecretInputProps } = useTextFieldController({
    control,
    name: 'password',
    label: 'Client Secret',
    placeholder: 'Required',
    description: 'Client Secret',
    schema: AlarmSubscriptionSpecSchema.shape.password,
  });

  const selectedAuthenticationFlow = watch('authenticationFlow');

  return (
    <FormProvider {...formProps}>
      <form
        onSubmit={handleSubmit(handleCreateOrUpdate as SubmitHandler<AlarmSubscriptionFormData>)}
        className={classNames(styles.container, formStyles.formContainer)}
        data-testid="alarm-subscription-add-form"
      >
        <TextField fullWidth {...nameInputProps} />
        <TextField fullWidth {...purposeInputProps} />
        <TextField fullWidth {...targetInputProps} />
        <TextField fullWidth {...authenticationFlowSelectProps}>
          {getSelectOptionsFromSchema(AlarmSubscriptionSpecSchema.shape.authenticationFlow)}
        </TextField>
        {selectedAuthenticationFlow === 'basic' && (
          <>
            <TextField fullWidth {...usernameInputProps} />
            <TextField
              fullWidth
              {...passwordInputProps}
              inputProps={{
                ...passwordInputProps.inputProps,
                type: 'password',
              }}
            />
          </>
        )}
        {selectedAuthenticationFlow === 'oauth2' && (
          <>
            <TextField fullWidth {...tokenUrlInputProps} />
            <TextField fullWidth {...clientIdInputProps} />
            <TextField
              fullWidth
              {...clientSecretInputProps}
              inputProps={{
                ...clientSecretInputProps.inputProps,
                type: 'password',
              }}
            />
            <TextField fullWidth {...scopeInputProps} />
          </>
        )}
        <div className={formStyles.buttonContainer}>
          <Button {...buttonProps} data-testid="btn-save-subscription">
            Create
          </Button>
          <Button
            variant="outlined"
            color="default"
            size="large"
            type="button"
            data-testid="btn-cancel-subscription"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
