import React, { useEffect } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  CheckboxField,
  getOptionsAndDescriptionsFromNativeEnumSchema,
  getSelectOptionFromPair,
  PermissionResult,
  TextField,
  useFormStyles,
  useSnackbarHelper,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import { AdminStatus, PhysicalInterfaceSchema } from '@netinsight/node-manager-schema';
import { DEFAULT_NAMESPACES, MANAGEMENT_IFACE } from '../../../../../../constants/nodes';
import { InterfaceStates, InterfaceUsage, NodeManagerInterfacesFormValue } from '../../../../../../types/nodes';
import { InterfaceVlanConfig } from './InterfaceVlanConfig';
import { InterfaceState } from './InterfaceState';
import { getDefaultNamespaceOptions } from '../../../../../../utils/nodes';
import { PortMetrics } from '../../../../../../hooks/nodes';
import { usePrevious } from 'react-use';
import { G } from '@mobily/ts-belt';

interface InterfaceConfigProps {
  index: number;
  nodeId: string;
  name: string;
  permission: PermissionResult;
  state?: InterfaceStates;
  sfpMetrics?: PortMetrics;
  usageMap: Record<string, InterfaceUsage>;
  expanded?: boolean;
}

export const InterfaceConfig = ({
  index,
  nodeId,
  permission,
  state,
  sfpMetrics,
  usageMap,
  name,
  expanded,
}: InterfaceConfigProps) => {
  const { control, setValue, getValues } = useFormContext<NodeManagerInterfacesFormValue>();
  const enableCheckboxFieldProps = useController({
    control,
    name: `interfaces.${index}.adminStatus`,
  });

  const { field: linkSpeedFieldProps } = useTextFieldController({
    control,
    name: `interfaces.${index}.linkSpeed`,
    label: 'Link speed',
    schema: PhysicalInterfaceSchema.shape.linkSpeed,
  });

  const { field: ipAddressFieldProps } = useTextFieldController({
    control,
    name: `interfaces.${index}.ipAddress`,
    label: 'IPv4 Address',
    placeholder: '0.0.0.0/00',
    schema: PhysicalInterfaceSchema.shape.ipAddress,
  });

  const { field: namespaceFieldProps } = useTextFieldController({
    control,
    name: `interfaces.${index}.networkNamespace`,
    label: 'Network scope',
    defaultValue: DEFAULT_NAMESPACES.default,
    schema: PhysicalInterfaceSchema.shape.networkNamespace,
  });

  const useDhcpCheckboxProps = useController({
    name: `interfaces.${index}.useDhcp`,
    control,
  });

  const isUsingDHCP = useWatch({
    control,
    name: `interfaces.${index}.useDhcp`,
  });

  const adminStatusValue = useWatch({ control, name: `interfaces.${index}.adminStatus` });
  const prevAdminStatusValue = usePrevious(adminStatusValue);
  const { snackbar } = useSnackbarHelper();
  useEffect(() => {
    if (adminStatusValue === AdminStatus.Up && prevAdminStatusValue === AdminStatus.Down) {
      const currIface = getValues()?.interfaces?.[index];
      // do not touch existing iface, but if one becomes enabled, set its namespace to access
      const currNs = currIface?.networkNamespace;
      if (currIface?.name !== MANAGEMENT_IFACE && (G.isNullable(currNs) || currNs === DEFAULT_NAMESPACES.default)) {
        setValue(`interfaces.${index}.networkNamespace`, DEFAULT_NAMESPACES.access);
        snackbar.info(`Interface ${currIface?.name} was moved to ${DEFAULT_NAMESPACES.access}`);
      }
    }
  }, [adminStatusValue, prevAdminStatusValue, setValue, index, getValues, snackbar]);

  const formStyles = useFormStyles();

  return (
    <Accordion
      data-testid={`interface-${index}-container`}
      defaultExpanded={expanded}
      variant="elevation"
      style={{ width: '100%' }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h5" id={name}>
          {state?.sfpStates?.displayName ?? name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={formStyles.formContainer}>
        <InterfaceState
          name={name}
          nodeId={nodeId}
          index={index}
          state={state}
          portMetrics={sfpMetrics}
          usage={usageMap[name]}
        />
        <div className={formStyles.formRow}>
          <CheckboxField
            label="Enabled"
            fieldProps={enableCheckboxFieldProps}
            fromValue={value => value === AdminStatus.Up}
            toValue={checked => (checked ? AdminStatus.Up : AdminStatus.Down)}
          />
          <TextField select SelectProps={{ native: true }} {...linkSpeedFieldProps}>
            {getOptionsAndDescriptionsFromNativeEnumSchema(PhysicalInterfaceSchema.shape.linkSpeed.removeDefault())
              .filter(([val]) => state?.state?.supportedSpeeds?.includes(val as any) ?? true)
              .map(([val]) => getSelectOptionFromPair([val, val] as [any, string]))}
          </TextField>
          {isUsingDHCP !== true ? (
            <TextField
              id={`interface-${index}-ip-address`}
              {...ipAddressFieldProps}
              description="IP Address should be in CIDR format (i.e. 10.0.0.1/24)"
            />
          ) : null}
          <TextField
            select
            SelectProps={{ native: true }}
            disabled={name === MANAGEMENT_IFACE}
            {...namespaceFieldProps}
          >
            {getDefaultNamespaceOptions()}
          </TextField>
          {name === MANAGEMENT_IFACE && (
            <CheckboxField label="DHCP Client" fieldProps={useDhcpCheckboxProps} size="small" />
          )}
        </div>
        <InterfaceVlanConfig
          nodeId={nodeId}
          ifaceIndex={index}
          ifaceName={name}
          states={state?.state?.vlanStates ?? {}}
          usageMap={usageMap}
          permission={permission}
        />
      </AccordionDetails>
    </Accordion>
  );
};
