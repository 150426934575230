import React, { FunctionComponent, useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TextField, isNullableOrEmpty } from '@netinsight/management-app-common-react';
import { PersistedSyncLink } from '@netinsight/management-app-common-api';
import { LinkDetailContext } from '../../../constants/time-transfer';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';

export const LinkEndpointNodeSelector: FunctionComponent<{
  prefix: 'endpointA' | 'endpointB';
}> = ({ prefix }) => {
  const { control } = useFormContext<PersistedSyncLink>();
  const { nodeOptions = [] } = useContext(LinkDetailContext);

  const {
    field: { value, name, onChange: innerOnChange },
    fieldState: { error },
  } = useController({
    control,
    name: `${prefix}.node`,
  });

  return (
    <Autocomplete
      disableClearable
      fullWidth
      options={nodeOptions}
      value={nodeOptions.find(({ id }) => id === value)}
      onChange={(_, selectedOption) => innerOnChange(selectedOption.id)}
      groupBy={opt => opt.syncRegion}
      getOptionLabel={opt => `${opt.syncRegion}/${opt.name}`}
      getOptionDisabled={opt => opt.ifaceCount === 0}
      getOptionSelected={opt => opt.id === value}
      renderOption={opt => opt.name}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          fullWidth
          name={name}
          label="Node"
          placeholder="Select node"
          helperText={error?.message}
          error={!isNullableOrEmpty(error?.message)}
          variant="standard"
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
          }}
          style={{ width: '100%' }}
        />
      )}
    />
  );
};
