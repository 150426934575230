import { createContext } from 'react';
import { InterfaceInfo } from '../../../../../../types/nodes';

export type PtpContextValue = {
  nodeId: string;
  availableInterfaces: InterfaceInfo[];
  interfaceNameMap: Record<string, string>;
  maxConnectionsTotal: number;
};

const initialValues: PtpContextValue = {
  nodeId: '',
  availableInterfaces: [],
  maxConnectionsTotal: 0,
  interfaceNameMap: {},
};

export const PtpContext = createContext(initialValues);

export const PtpContextProvider = PtpContext.Provider;
