import { PersistedLinkTarget } from '@netinsight/management-app-common-api';
import { useTimeNodeOverviewMetrics } from '../../hooks/metrics';
import { useNodeLinksByDirection } from '../../hooks/links';

export const getLinkViewModels = ({
  nodeId,
  nodeNameMap,
  inLinks,
  outLinks,
  unusedLinks,
  syncSourceAvailabilities,
  syncSourcePriorities,
  linkDirectionMap,
}: {
  nodeId: string;
  nodeNameMap?: Record<string, string>;
} & Partial<Required<ReturnType<typeof useNodeLinksByDirection>['data']>> &
  Partial<Required<ReturnType<typeof useTimeNodeOverviewMetrics>['data']>>) => {
  const getLinkTargetViewModel = (lt: PersistedLinkTarget) => {
    const linkSelectionMeta = linkDirectionMap?.[lt.linkId]?.[nodeId];
    const peerNodeId = linkSelectionMeta?.peerNodeId;
    const clockNodeId = linkSelectionMeta?.clockNodeId;
    return {
      ...lt,
      peerNodeId: peerNodeId,
      peerNodeName: nodeNameMap?.[peerNodeId ?? ''] ?? peerNodeId,
      clockNodeId: clockNodeId,
      clockNodeName: nodeNameMap?.[clockNodeId ?? ''] ?? clockNodeId,
      selected: linkSelectionMeta?.selected ?? false,
      available: syncSourceAvailabilities?.[lt.linkId] ?? false,
      priority: syncSourcePriorities?.[lt.linkId],
    };
  };

  const sortByName = (
    { name: l1Name, linkId: l1Id }: ReturnType<typeof getLinkTargetViewModel>,
    { name: l2Name, linkId: l2Id }: ReturnType<typeof getLinkTargetViewModel>,
  ) => (l1Name ?? l1Id).localeCompare(l2Name ?? l2Id);
  return {
    inLinks: inLinks?.map(getLinkTargetViewModel).sort(sortByName) ?? [],
    outLinks: outLinks?.map(getLinkTargetViewModel).sort(sortByName) ?? [],
    unusedLinks: unusedLinks?.map(getLinkTargetViewModel).sort(sortByName) ?? [],
  };
};
