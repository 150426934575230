/* eslint-disable no-nested-ternary */
import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import { merge } from 'ts-deepmerge';
import { useApi } from '@backstage/core-plugin-api';
import { metricsApiRef, ValueOf } from '@netinsight/management-app-common-react';
import {
  CONTROL_STATES,
  getScalarResult,
  groupMetricsFromPromise,
  SYNC_SOURCE_NAMES,
} from '@netinsight/management-app-common-api';

export const useTimeNodeOverviewMetrics = (nodeId: string, swrConfig?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);
  const fetcher = useCallback(
    async ([nodeIdParam]: any) => {
      const [
        syncSourceSelectionResult,
        syncSourcePriorityResult,
        syncSourceAvailableResult,
        filteredControlStateResult,
        controlStateResult,
        ppsErrorResult,
        gnssErrorResult,
        ptpRecvErrorResult,
        nodeIsStableResult,
        nodeTimeInStableResult,
        nodeTimeInUnstableResult,
        nodeTimeSinceUnstableResult,
        hiccUptimeResult,
      ] = await Promise.allSettled([
        metricsApi.instantQuery({
          query: `last_over_time(neti_hicc_syncsource_is_selected{type!="LINK",nodeid="${nodeIdParam}"}[30s])`,
        }),
        metricsApi.instantQuery({ query: `neti_hicc_syncsource_priority{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_hicc_syncsource_available{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_hicc_hicc_filtered_control_state{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_hicc_hicc_control_state{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_hicc_giraffe_pps1_in_err{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_hicc_gnss_time_error{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_hicc_ptpreceiver_time_error{nodeid=~"${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_hicc_nodestability_is_stable{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_hicc_nodestability_time_in_stable{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_hicc_nodestability_time_in_unstable{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_hicc_nodestability_time_since_unstable{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_hicc_metadata_hicc_uptime{nodeid="${nodeIdParam}"}` }),
      ]);

      const syncSourceSelectionMap = groupMetricsFromPromise(
        syncSourceSelectionResult,
        metric => metric.name,
        value => Boolean(parseInt(value, 10)),
      );
      const syncSourcePriorityMap = groupMetricsFromPromise(
        syncSourcePriorityResult,
        metric => metric.name,
        value => parseInt(value, 10),
      );

      const syncSourceAvailabilityMap = groupMetricsFromPromise(
        syncSourceAvailableResult,
        metric => metric.name,
        value => Boolean(parseInt(value, 10)),
      );

      return {
        syncSourceSelections: syncSourceSelectionMap,
        syncSourcePriorities: syncSourcePriorityMap,
        syncSourceAvailabilities: syncSourceAvailabilityMap,
        syncSourceTimeOffsets: merge(
          getScalarResult(val => ({ [SYNC_SOURCE_NAMES.ppsIn]: parseFloat(val) }))(ppsErrorResult)!,
          getScalarResult(val => ({ [SYNC_SOURCE_NAMES.gnss]: parseFloat(val) }))(gnssErrorResult)!,
          groupMetricsFromPromise(
            ptpRecvErrorResult,
            metrics => metrics.name,
            val => parseFloat(val),
          ) as Record<typeof SYNC_SOURCE_NAMES.ptp1 | typeof SYNC_SOURCE_NAMES.ptp2, number>,
        ),
        statuses: merge(
          getScalarResult(val => ({ filteredControlState: val as ValueOf<typeof CONTROL_STATES> }))(
            filteredControlStateResult,
          )!,
          getScalarResult(val => ({ controlState: val as ValueOf<typeof CONTROL_STATES> }))(controlStateResult)!,
          getScalarResult(val => ({ nodeIsStable: Boolean(parseInt(val, 10)) }))(nodeIsStableResult)!,
          getScalarResult(val => ({ nodeTimeInStable: parseFloat(val), hasNodeTimeInStable: parseFloat(val) > -1 }))(
            nodeTimeInStableResult,
          )!,
          getScalarResult(val => ({ nodeTimeInUnstable: parseFloat(val) }))(nodeTimeInUnstableResult)!,
          getScalarResult(val => ({ nodeTimeSinceUnstable: parseFloat(val) }))(nodeTimeSinceUnstableResult)!,
          getScalarResult(val => ({ hiccUptime: parseFloat(val) }))(hiccUptimeResult)!,
        ),
      };
    },
    [metricsApi],
  );
  return useSwr([nodeId, 'TimeNodeOverviewMetrics'], fetcher, swrConfig);
};
