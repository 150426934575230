import React, { FunctionComponent, useContext, useMemo } from 'react';
import { Link } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import { PtpContext } from './PtpContext';
import {
  usePtpTransmitterRemainingInstancesCount,
  usePtpTransmitterSelectedPhysicalInterfaceNames,
} from '../../../../../../hooks/sync';

export const PtpAvailableInterfacesWarning: FunctionComponent<{ mode: 'receiver' | 'transmitter' }> = ({ mode }) => {
  const { nodeId, availableInterfaces } = useContext(PtpContext);
  const usedPhysicalInterfaces = usePtpTransmitterSelectedPhysicalInterfaceNames();
  const freeInterfaces = useMemo(
    () => availableInterfaces.filter(ai => !usedPhysicalInterfaces?.includes(ai.name)),
    [availableInterfaces, usedPhysicalInterfaces],
  );
  const remainingInstanceCount = usePtpTransmitterRemainingInstancesCount();

  const shouldShowWarning =
    mode === 'transmitter'
      ? freeInterfaces.length === 0 && remainingInstanceCount > 0
      : availableInterfaces.length === 0;

  return shouldShowWarning ? (
    <Alert severity="warning" data-testid="text-no-available-interfaces">
      No network interfaces available at the moment. They can be set up under{' '}
      <Link to={`/nodes/info/${nodeId}/networks/interfaces`}>Network configuration</Link>.
    </Alert>
  ) : null;
};
