export const BaseMessageCounters = {
  announceMsg: 'Announce',
  syncMsg: 'Sync',
  followUpMsg: 'Follow-up',
  dlyReqMsg: 'Delay request',
  dlyRespMsg: 'Delay response',
  pDlyReqMsg: 'PDelay request',
  pDlyRespMsg: 'PDelay response',
  pDlyFollowUp: 'PDelay follow-up',
  signalling: 'Signalling',
  mgmt: 'Management',
} as const;
export const ErrorCounters = {
  errorRx: 'Error',
  mgmtErr: 'Management Error',
} as const;
export const OtherCounters = {
  annReceptTout: 'Announce recept timeout',
} as const;

export const MessageCounterSuffixes = ['Rx', 'Tx'] as const;
export const MessagePerSecSuffix = 'PerSec';

export type BaseCounterKey = keyof typeof BaseMessageCounters;

type MessageCounterKey = `${BaseCounterKey}${(typeof MessageCounterSuffixes)[number]}${
  | typeof MessagePerSecSuffix
  | ''}`;

export type TotalCounterKey = `${Lowercase<(typeof MessageCounterSuffixes)[number]>}${typeof MessagePerSecSuffix | ''}`;

export type CounterKey = MessageCounterKey | TotalCounterKey | keyof typeof ErrorCounters | keyof typeof OtherCounters;

export const CounterKeys = Object.keys(BaseMessageCounters)
  .flatMap(key => MessageCounterSuffixes.map(suffix => `${key}${suffix}`))
  .flatMap(key => [key, `${key}${MessagePerSecSuffix}`])
  .concat(MessageCounterSuffixes.flatMap(key => [key.toLocaleLowerCase(), `${key.toLocaleLowerCase()}PerSec`]))
  .concat(Object.keys(ErrorCounters))
  .concat(Object.keys(OtherCounters)) as CounterKey[];

export const CounterDescriptions: Partial<Record<CounterKey, string>> = {
  errorRx:
    'Invalid Rx packet counter may indicates one of the following issues: wrong PTP version, wrong domain number, message from self, message from other BC port, multicast message in unicast mode or message extraction error (size error or inconsistent format',
};
