import React, { useCallback, FunctionComponent, useContext } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  FormControlLabel,
  IconButton,
  Select,
  Switch,
  Typography,
  makeStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import LayoutIcon from '@material-ui/icons/AccountTree';
import SideBarIcon from '@material-ui/icons/ChromeReaderMode';
import HighlightIcon from '@material-ui/icons/Pageview';
import TogglesIcon from '@material-ui/icons/Tune';
import TraceIcon from '@material-ui/icons/Explore';
import CloseIcon from '@material-ui/icons/Close';
import { G } from '@mobily/ts-belt';
import { NodeGraphHighlightSchema, NodeGraphToggleKeySchema } from '@netinsight/management-app-common-api';
import {
  MenuButton,
  PopButton,
  getOptionsAndDescriptionsFromSchema,
  isNullableOrEmpty,
  useSnackbarHelper,
} from '@netinsight/management-app-common-react';
import { NodeGraphContext } from './context';
import { findPaths } from './helpers';
import { useSyncRegionsSelectOptions } from '../../TimeNetworkPage/SyncRegionSelector';
import { useSyncRegions } from '../../../hooks/sync';
import { NODE_GRAPH_LAYOUTS } from '../../../constants/node-graph';

const useStyles = makeStyles(
  theme => ({
    container: {
      gridRow: '1 / 2',
      gridColumn: '1 / -1',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      padding: theme.spacing(0, 2, 0, 2),
      alignItems: 'center',
      zIndex: theme.zIndex.mobileStepper,
      boxShadow: theme.shadows[2],
      ['& button:last-of-type']: {
        marginLeft: 'auto',
      },
    },
    regionSelect: {
      maxHeight: '2.25rem',
    },
    graphTitle: {
      display: 'inline-block',
      textTransform: 'capitalize',
      paddingRight: theme.spacing(1),
    },
    mainMenuItem: {
      minWidth: '4rem',
    },
    mainMenuIcon: {
      width: '2.5rem',
      minWidth: 0,
    },
    dialogActions: {
      justifyContent: 'flex-start',
      padding: theme.spacing(1, 3),
    },
  }),
  { name: 'Neti' },
);

const ToggleOptions = getOptionsAndDescriptionsFromSchema(NodeGraphToggleKeySchema.optional());

const HighlightOptions = getOptionsAndDescriptionsFromSchema(NodeGraphHighlightSchema.optional());

export const NodeGraphControls: FunctionComponent<{ graphId: string }> = ({ graphId }) => {
  const { state, dispatch, cyRef } = useContext(NodeGraphContext);
  const styles = useStyles();
  const { snackbar } = useSnackbarHelper();

  // SYNC REGIONS
  const { data: availableSyncRegions } = useSyncRegions();
  const { onChange: onSelectedSyncRegionsChanged, options: syncRegionOptions } = useSyncRegionsSelectOptions({
    syncRegions: availableSyncRegions,
    values: state.syncRegions,
    onValuesChange: newSyncRegions => dispatch({ type: 'sync-regions', syncRegions: newSyncRegions }),
  });
  const renderSelectedRegion = useCallback(
    (selectedValues: any = []) =>
      selectedValues.length === 0 || selectedValues.length === availableSyncRegions?.length
        ? 'All regions'
        : `${selectedValues.length} region${selectedValues.length > 1 ? 's' : ''}`,
    [availableSyncRegions],
  );

  // AUTO ARRANGE
  const handleAutoLayout = useCallback(() => {
    const cy = cyRef.current;
    if (!cy) {
      return;
    }
    const selectedItems = cy.filter(':selected');

    if (selectedItems.size() > 0) {
      selectedItems.layout(NODE_GRAPH_LAYOUTS.default).run();
    } else {
      cy.layout(NODE_GRAPH_LAYOUTS.default).run();
    }
  }, [cyRef]);

  // TRACE
  const handleTrace = useCallback(() => {
    const cy = cyRef.current;
    if (!isNullableOrEmpty(state.tracingTargetNodeId)) {
      dispatch({ type: 'trace', nodeId: undefined });
      cy?.elements().selectify();
      return;
    }

    const targetNodeId = state.selectedNodes?.[0]?.id;
    if (!cy || state.selectedNodes.length !== 1 || G.isNullable(targetNodeId)) {
      return;
    }

    const target = cy.getElementById(targetNodeId);
    if (G.isNullable(target) || !target.isNode() || target.isParent()) {
      return;
    }

    const clockNodeIds = [
      ...new Set(
        target
          .incomers()
          .map(edge => edge.data('clockNodeId') as string)
          .filter(cnid => !isNullableOrEmpty(cnid)),
      ).values(),
    ];

    const clockNodes = clockNodeIds
      .map(cnid => cy.getElementById(cnid))
      .filter(clockNode => G.isNotNullable(clockNode) && clockNode.isNode());

    if (clockNodes.length > 0) {
      for (const clockNode of clockNodes) {
        const pathElems = findPaths(targetNodeId, clockNode.id(), cy);

        if (pathElems.length > 0) {
          dispatch({ type: 'trace', nodeId: targetNodeId });
          void Promise.resolve().then(() => {
            pathElems.forEach(elem => {
              elem.select();
            });
            clockNode.flashClass('trace-active', 5000);
            cy.elements().unselectify();
          });
        } else {
          snackbar.warning('Fail to trace to clock node');
        }
      }
    } else {
      snackbar.warning('Fail to trace to clock node');
    }
  }, [dispatch, cyRef, state.selectedNodes, state.tracingTargetNodeId, snackbar]);

  const isTracing = !isNullableOrEmpty(state.tracingTargetNodeId);

  return (
    <div className={styles.container}>
      <IconButton
        aria-label="Toggle menu"
        onClick={() => dispatch({ type: 'menu' })}
        size="medium"
        color={state.leftBarOpened ? 'primary' : 'default'}
      >
        <MenuIcon fontSize="small" />
      </IconButton>
      <Typography variant="h6" component="h6" className={styles.graphTitle}>
        {state.name ?? graphId}
      </Typography>
      <Select
        multiple
        labelId="region-selector-label"
        id="region-selector"
        displayEmpty
        placeholder="All"
        color="primary"
        variant="outlined"
        onChange={onSelectedSyncRegionsChanged}
        className={styles.regionSelect}
        renderValue={renderSelectedRegion}
        value={state.syncRegions}
      >
        {syncRegionOptions}
      </Select>
      <ButtonGroup>
        <Button
          startIcon={<LayoutIcon />}
          onClick={handleAutoLayout}
          variant="outlined"
          color="default"
          disabled={isTracing}
        >
          Arrange
        </Button>
        <PopButton label="Settings" startIcon={<TogglesIcon />}>
          {ToggleOptions.map(([key, label]) => (
            <FormControlLabel
              key={key}
              control={
                <Switch
                  size="small"
                  checked={state.toggles?.[key] === true}
                  onChange={(_evt, checked) => dispatch({ type: 'toggle', toggles: { [key]: checked } })}
                />
              }
              label={label}
            />
          ))}
        </PopButton>
      </ButtonGroup>
      <ButtonGroup size="medium" variant="outlined" color="default">
        <Button
          startIcon={isTracing ? <CloseIcon /> : <TraceIcon />}
          variant="outlined"
          color={isTracing ? 'primary' : 'default'}
          disabled={(state.selectedNodes.length !== 1 || G.isNullable(state.selectedNodes[0].parent)) && !isTracing}
          title={isTracing ? 'Stop tracing' : 'Trace to clock node'}
          onClick={handleTrace}
        >
          Trace
        </Button>
        <MenuButton
          options={HighlightOptions}
          buttonTextFormatter={(value, label) => (value && label && value !== 'none' ? label : 'Highlight')}
          startIcon={<HighlightIcon />}
          color={state.highlight && state.highlight !== 'none' ? 'primary' : 'default'}
          selectedOption={state.highlight}
          onOptionSelected={newHighlight => dispatch({ type: 'highlight', highlight: newHighlight })}
        />
      </ButtonGroup>
      <IconButton
        aria-label="Side-bar"
        title="Toggle selection details"
        color={state.rightBarOpened ? 'primary' : 'default'}
        onClick={() => dispatch({ type: 'sidebar' })}
      >
        <Badge
          badgeContent={state.selectedEdges.length + state.selectedNodes.length}
          color="primary"
          max={99}
          overlap="rectangular"
        >
          <SideBarIcon fontSize="small" />
        </Badge>
      </IconButton>
    </div>
  );
};
