import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { NodeGraphState } from '@netinsight/management-app-common-api';
import { inventoryApiRef } from '@netinsight/management-app-common-react';
import { GRAPH_STATE_CACHE_KEY } from '../../constants/node-graph';

export function useGraphState(id: string, options?: SWRConfiguration<NodeGraphState>) {
  const inventoryApi = useApi(inventoryApiRef);
  const fetcher = useCallback(
    async ([, idParam]: [string, string]) => inventoryApi.getNodeGraphState(idParam),
    [inventoryApi],
  );
  return useSwr<NodeGraphState>([GRAPH_STATE_CACHE_KEY, id], fetcher, options);
}
