import React, { useCallback, useMemo } from 'react';
import { useInterval } from 'react-use';
import { z } from 'zod';
import { useInventory, usePermission, useSnackbarHelper } from '@netinsight/management-app-common-react';
import { Alert } from '@material-ui/lab';
import RefreshIcon from '@material-ui/icons/Refresh';
import { UnicastClientsSchema } from '@netinsight/ptpctld-schema';
import { G } from '@mobily/ts-belt';
import { TableProps, Table } from '@backstage/core-components';
import { formatPtpInterval } from '../../../../../../utils/sync/ptpUtils';
import { usePtpTransmitterClientsRefresh } from '../../../../../../hooks/sync';

type UnicastClientsState = z.infer<typeof UnicastClientsSchema>;
type UnicastClient = UnicastClientsState['clients'][number];
const UnicastStatus = ['OK', 'Connection error', 'Not unicast'];

const cellStyle = {
  padding: '4px 4px',
};

const columns: TableProps<UnicastClient>['columns'] = [
  {
    field: 'address',
    title: 'Address',
    type: 'string',
    sorting: true,
    filtering: true,
    headerStyle: cellStyle,
    cellStyle,
  },
  {
    field: 'sync_interval',
    title: 'Sync interval',
    type: 'numeric',
    sorting: true,
    filtering: false,
    headerStyle: cellStyle,
    cellStyle,
    render: row =>
      G.isNumber(row.sync_interval) ? `${row.sync_interval} (${formatPtpInterval(row.sync_interval)})` : '-',
  },
  {
    field: 'time_since_sync',
    title: 'Time since sync',
    type: 'numeric',
    sorting: true,
    filtering: false,
    headerStyle: cellStyle,
    cellStyle,
    render: row => (G.isNumber(row.time_since_sync) ? `${row.time_since_sync}s` : '-'),
  },
];

const options: TableProps['options'] = {
  padding: 'dense' as const,
  filtering: true,
  search: false,
  pageSize: 10,
  pageSizeOptions: [10, 25, 50],
  headerStyle: cellStyle,
  filterCellStyle: cellStyle,
};

export const PtpTransmitterUnicastClients = ({
  nodeId,
  serviceName,
  index,
}: {
  nodeId: string;
  serviceName: string;
  index: number;
}) => {
  const { snackbar } = useSnackbarHelper();
  const { trigger: updateConfig, permission } = usePtpTransmitterClientsRefresh(nodeId);
  const { isLoading: isLoadingPermission, ...canUpdatePermission } = usePermission(permission);

  const handleReloadClients = useCallback(async () => {
    await updateConfig(index, {
      onError: () => {
        snackbar.notifyError('Loading unicast clients', null, null);
      },
    });
  }, [updateConfig, index, snackbar]);

  const { data: { data: state } = {}, mutate } = useInventory<UnicastClientsState>(
    { nodeId, kind: serviceName },
    { refreshInterval: 0 },
  );

  useInterval(async () => {
    if (isLoadingPermission || !canUpdatePermission.isAllowed) {
      return;
    }
    await handleReloadClients();
    await mutate();
  }, 10_000);

  const actions: TableProps['actions'] = useMemo(
    () =>
      canUpdatePermission.isAllowed
        ? [
            {
              icon: () => <RefreshIcon data-testid="btn-refresh-unicast-clients" />,
              isFreeAction: true,
              onClick: async () => await mutate(),
              tooltip: 'Reload clients',
            },
          ]
        : [],
    [mutate, canUpdatePermission.isAllowed],
  );

  return (
    <>
      {G.isNotNullable(state?.status) && state.status !== 0 ? (
        <Alert variant="outlined" color="warning">
          {UnicastStatus[state.status]}
        </Alert>
      ) : null}
      <Table<UnicastClient>
        actions={actions}
        title="Unicast clients"
        columns={columns}
        options={options}
        data={state?.clients ?? []}
      />
    </>
  );
};
