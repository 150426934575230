import React, { ChangeEvent, Dispatch, FunctionComponent, SetStateAction, useCallback, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  LinearProgress,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/ArrowForward';
import RemoveIcon from '@material-ui/icons/CloseOutlined';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  ConfirmButton,
  SplitButton,
  buttonPropsFromPermission,
  isNullableOrEmpty,
  usePermission,
  useFormStyles,
} from '@netinsight/management-app-common-react';
import { DEFAULT_SYNC_REGION } from '@netinsight/crds';
import { useNodeSyncRegionUpdate, useSyncRegionDelete } from '../../../hooks/sync';

const useStyles = makeStyles(theme => ({
  nodeMultiSelection: {
    appearance: 'none',
    backgroundColor: theme.palette.background.default,
    border: 'none',
    padding: theme.spacing(1),
    margin: 0,
    width: '100%',
    maxHeight: '50vh',
    ['& > option']: {
      appearance: 'none',
      display: 'block',
      padding: theme.spacing(2, 2),
      color: theme.palette.text.primary,
      whiteSpace: 'normal',
      ...theme.typography.body1,
      ['&:checked']: {
        fontWeight: 'bold',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));

export const SyncRegionConfigEntry: FunctionComponent<{
  id: string;
  name: string;
  index: number;
  nodeIdsBySyncRegion: Record<string, string[]>;
  setNodeIdsBySyncRegion: Dispatch<SetStateAction<Record<string, string[]>>>;
  nodeNameMap: Record<string, string>;
  syncRegions: { name: string }[];
}> = ({ name, id, index, nodeIdsBySyncRegion, setNodeIdsBySyncRegion, nodeNameMap, syncRegions }) => {
  const {
    trigger: deleteSyncRegion,
    isMutating: isDeleting,
    permission: deleteSyncRegionPermission,
  } = useSyncRegionDelete();
  const {
    trigger: updateNodeSyncRegion,
    isMutating: isUpdating,
    permission: updateNodeSyncRegionPermission,
  } = useNodeSyncRegionUpdate();
  const { isLoading: isLoadingRemovePermission, ...canRemovePermission } = usePermission(deleteSyncRegionPermission);
  const { isLoading: isLoadingAssignPermission, ...canAssignPermission } =
    usePermission(updateNodeSyncRegionPermission);
  const assignButtonProps = buttonPropsFromPermission(canAssignPermission);
  const handleRemoveRegion = useCallback(async () => {
    await deleteSyncRegion({ id });
  }, [deleteSyncRegion, id]);
  const formStyles = useFormStyles();
  const nodeIds = nodeIdsBySyncRegion[name] ?? [];
  const styles = useStyles();
  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
  const handleNodeSelection = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedNodes([...(e.target?.selectedOptions ?? [])].map(o => o.value));
  }, []);

  const handleMove = useCallback(
    async (newRegion?: string) => {
      if (isNullableOrEmpty(newRegion)) {
        return;
      }
      const myNodes = nodeIdsBySyncRegion[name] ?? [];
      const theirNodes = nodeIdsBySyncRegion[newRegion] ?? [];
      await updateNodeSyncRegion({ syncRegionName: newRegion, nodeIds: selectedNodes });
      setNodeIdsBySyncRegion(val => ({
        ...val,
        [name]: myNodes.filter(n => !selectedNodes.includes(n)),
        [newRegion]: [...new Set([...theirNodes, ...selectedNodes]).values()],
      }));

      setSelectedNodes([]);
    },
    [selectedNodes, nodeIdsBySyncRegion, setNodeIdsBySyncRegion, updateNodeSyncRegion, name],
  );
  return (
    <Accordion
      data-testid={`sync-region-${index}-container`}
      defaultExpanded
      variant="elevation"
      style={{ width: '100%' }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div>
          <Typography variant="h6" id={name}>
            {name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {nodeIds?.length ?? 0} nodes
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={formStyles.formContainer}>
        {isDeleting || isUpdating ? <LinearProgress /> : null}
        <select multiple className={styles.nodeMultiSelection} onChange={handleNodeSelection}>
          {nodeIds?.map(nodeId => (
            <option key={nodeId} value={nodeId}>
              {nodeNameMap[nodeId] ?? nodeId}
            </option>
          ))}
        </select>
        <div className={formStyles.formRow}>
          <SplitButton
            disabled={assignButtonProps.disabled || selectedNodes.length === 0 || syncRegions.length === 0}
            options={syncRegions?.map(sr => [sr.name, sr.name]) ?? []}
            buttonTextFormatter={(value, label) => (value && label ? `Assign to: ${label}` : `Assign`)}
            buttonProps={{
              startIcon: <AddIcon />,
              size: 'small',
              ...assignButtonProps,
            }}
            onOptionSelected={handleMove}
          />
        </div>
        {nodeIds?.length === 0 && name !== DEFAULT_SYNC_REGION ? (
          <div className={formStyles.buttonContainer}>
            <ConfirmButton
              confirmation="This sync region will be removed, do you want to continue?"
              variant="outlined"
              color="default"
              size="small"
              onClick={handleRemoveRegion}
              startIcon={<RemoveIcon />}
              data-testid={`btn-remove-sync-region-${index}`}
              {...buttonPropsFromPermission(canRemovePermission)}
            >
              Remove
            </ConfirmButton>
          </div>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};
