import {
  PtpTransmitterInstanceConfig,
  PtpProfile,
  PtpProfiles,
  G82751ProfilePtpTransmitterInstanceSchema,
  G82752ProfilePtpTransmitterInstanceSchema,
  G82651ProfilePtpTransmitterInstanceSchema,
  Default1588ProfilePtpTransmitterInstanceSchema,
  SMPTEProfilePtpTransmitterInstanceSchema,
} from '@netinsight/crds-timetransfer';
import { getDefaultUnicastMaxConnectionsValue } from '@netinsight/management-app-common-api';

export const getDefaultInstanceValues = (profile: PtpProfile, maxTotalConnections: number) => {
  const values: Record<string, PtpTransmitterInstanceConfig> = {
    [PtpProfiles.G8275_1]: {
      profile: PtpProfiles.G8275_1,
      interface: '',
      domain: 24,
      priority1: 128,
      priority2: 128,
      networkMode: 'multicast',
      unicastMaxConnections: getDefaultUnicastMaxConnectionsValue(PtpProfiles.G8275_1, maxTotalConnections),
    },
    [PtpProfiles.G8275_2]: {
      profile: PtpProfiles.G8275_2,
      interface: '',
      domain: 44,
      priority1: 128,
      priority2: 128,
      networkMode: 'unicast',
      unicastMaxConnections: getDefaultUnicastMaxConnectionsValue(PtpProfiles.G8275_2, maxTotalConnections),
    },
    [PtpProfiles.G8265_1]: {
      profile: PtpProfiles.G8265_1,
      interface: '',
      domain: 4,
      priority1: 128,
      priority2: 128,
      networkMode: 'unicast',
      unicastMaxConnections: getDefaultUnicastMaxConnectionsValue(PtpProfiles.G8265_1, maxTotalConnections),
    },
    [PtpProfiles.Default1588]: {
      profile: PtpProfiles.Default1588,
      interface: '',
      domain: 0,
      priority1: 128,
      priority2: 128,
      networkMode: 'unicast',
      unicastMaxConnections: getDefaultUnicastMaxConnectionsValue(PtpProfiles.Default1588, maxTotalConnections),
    },
    [PtpProfiles.SMPTE]: {
      profile: PtpProfiles.SMPTE,
      interface: '',
      domain: 127,
      priority1: 128,
      priority2: 128,
      networkMode: 'both',
      unicastMaxConnections: getDefaultUnicastMaxConnectionsValue(PtpProfiles.SMPTE, maxTotalConnections),
    },
  };

  return values[profile];
};

export const ProfileSchema = {
  [PtpProfiles.Default1588]: Default1588ProfilePtpTransmitterInstanceSchema,
  [PtpProfiles.G8265_1]: G82651ProfilePtpTransmitterInstanceSchema,
  [PtpProfiles.G8275_1]: G82751ProfilePtpTransmitterInstanceSchema,
  [PtpProfiles.G8275_2]: G82752ProfilePtpTransmitterInstanceSchema,
  [PtpProfiles.SMPTE]: SMPTEProfilePtpTransmitterInstanceSchema,
} as const;
