/* eslint-disable no-nested-ternary */
import React, { FunctionComponent, useCallback } from 'react';
import { Control } from 'react-hook-form';
import { Link } from '@backstage/core-components';
import { Button, Paper, Typography, useTheme } from '@material-ui/core';
import { G } from '@mobily/ts-belt';
import {
  buttonPropsFromPermission,
  DurationFormatter,
  InputTooltip,
  PermissionResult,
  TextField,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import { CalibrationEditModel, TimeErrorsByTypeAndDuration } from './types';
import { useStyles } from './styles';
import { PredefinedTimeErrorsDurations } from './constants';
import { SYNC_SOURCE_NAMES } from '@netinsight/management-app-common-api';
import { SYNC_SOURCE_CONFIG_URLS, SYNC_SOURCE_LABELS } from '../../../../../constants/sync';

const LocalTimeErrorCell: FunctionComponent<{
  value?: number;
  duration: string;
  onValueSelected: (value: number, duration: string) => void;
  permission: PermissionResult;
}> = ({ value, duration, onValueSelected, permission }) => {
  const hasValue = G.isNotNullable(value) && !isNaN(value);
  const handleClick = useCallback(() => {
    if (hasValue) {
      onValueSelected(value, duration);
    }
  }, [value, duration, onValueSelected, hasValue]);
  const buttonProps = buttonPropsFromPermission(permission);
  return hasValue ? (
    <td className="button-container">
      <span>{DurationFormatter.fromSeconds(value).toMicroSeconds(3)}</span>
      <Button
        size="small"
        variant="outlined"
        color="default"
        onClick={handleClick}
        data-testid={`btn-use-${duration}`}
        {...buttonProps}
      >
        Use
      </Button>
    </td>
  ) : (
    <td style={{ textAlign: 'right' }}>-</td>
  );
};

const LocalTimeErrorsTable: FunctionComponent<{
  nodeId: string;
  data: TimeErrorsByTypeAndDuration;
  selectedDuration: string;
  onValueSelected: (value: number, duration: string) => void;
  permission: PermissionResult;
}> = ({ nodeId, data: { gnss, ppsIn, ptp }, selectedDuration, onValueSelected, permission }) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <table
      className={styles.table}
      id="node-calibration-local-time-errors-selector-table"
      data-testid="local-input-time-errors-table"
    >
      <thead className={styles.header}>
        <tr>
          <th style={{ textAlign: 'left' }}>Reference</th>
          {Object.keys(PredefinedTimeErrorsDurations).map(duration => (
            <th
              key={duration}
              style={{
                textAlign: 'right',
                backgroundColor:
                  selectedDuration === duration
                    ? theme.palette.type === 'dark'
                      ? theme.palette.background.default
                      : theme.palette.infoBackground
                    : 'transparent',
              }}
            >
              Time error {duration} (μs){' '}
              <InputTooltip
                text={`Current node time error compared to the reference, averaged over ${
                  PredefinedTimeErrorsDurations[duration as keyof typeof PredefinedTimeErrorsDurations] ?? duration
                }.`}
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={styles.body}>
        <tr>
          <th>
            <Typography
              variant="body1"
              component={Link}
              target="_blank"
              to={SYNC_SOURCE_CONFIG_URLS[SYNC_SOURCE_NAMES.ppsIn](nodeId)}
            >
              {SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.ppsIn]}
            </Typography>
          </th>
          {Object.keys(PredefinedTimeErrorsDurations).map(duration => (
            <LocalTimeErrorCell
              key={duration}
              duration={duration}
              value={ppsIn[duration]}
              onValueSelected={onValueSelected}
              permission={permission}
            />
          ))}
        </tr>
        <tr>
          <th>
            <Typography
              variant="body1"
              component={Link}
              target="_blank"
              to={SYNC_SOURCE_CONFIG_URLS[SYNC_SOURCE_NAMES.gnss](nodeId)}
            >
              {SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.gnss]}
            </Typography>
          </th>
          {Object.keys(PredefinedTimeErrorsDurations).map(duration => (
            <LocalTimeErrorCell
              key={duration}
              duration={duration}
              value={gnss[duration]}
              onValueSelected={onValueSelected}
              permission={permission}
            />
          ))}
        </tr>
        <tr>
          <th>
            <Typography
              variant="body1"
              component={Link}
              target="_blank"
              to={SYNC_SOURCE_CONFIG_URLS[SYNC_SOURCE_NAMES.ptp1](nodeId)}
            >
              {SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.ptp1]}
            </Typography>
          </th>
          {Object.keys(PredefinedTimeErrorsDurations).map(duration => (
            <LocalTimeErrorCell
              key={duration}
              duration={duration}
              value={ptp[duration][SYNC_SOURCE_NAMES.ptp1]}
              onValueSelected={onValueSelected}
              permission={permission}
            />
          ))}
        </tr>
        <tr>
          <th>
            <Typography
              variant="body1"
              component={Link}
              target="_blank"
              to={SYNC_SOURCE_CONFIG_URLS[SYNC_SOURCE_NAMES.ptp2](nodeId)}
            >
              {SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.ptp2]}
            </Typography>
          </th>
          {Object.keys(PredefinedTimeErrorsDurations).map(duration => (
            <LocalTimeErrorCell
              key={duration}
              duration={duration}
              value={ptp[duration][SYNC_SOURCE_NAMES.ptp2]}
              onValueSelected={onValueSelected}
              permission={permission}
            />
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export const LocalTimeErrorSelector: FunctionComponent<{
  nodeId: string;
  control: Control<CalibrationEditModel>;
  data: TimeErrorsByTypeAndDuration;
  selectedDuration: string;
  onValueSelected: (value: number, duration: string) => void;
  permission: PermissionResult;
}> = ({ data, nodeId, selectedDuration, onValueSelected, permission, control }) => {
  const styles = useStyles();
  const { field: textFieldProps } = useTextFieldController({
    control,
    name: 'customTimeReferenceError',
    label: 'Time error to be used for calculation',
    valueAsNumber: true,
    numberPrecision: 3,
    defaultValue: 0 as any,
  });

  return (
    <Paper className={styles.container} component="fieldset" id="node-calibration-local-time-errors-selector">
      <Typography component="h6" variant="h6">
        Local inputs
      </Typography>
      <LocalTimeErrorsTable
        data={data}
        selectedDuration={selectedDuration}
        onValueSelected={onValueSelected}
        nodeId={nodeId}
        permission={permission}
      />
      <TextField
        {...textFieldProps}
        id="node-calibration-custom-time-error-textfield"
        inputProps={{ ...textFieldProps.inputProps, type: 'number', step: 1e-3 }}
        style={{ width: '20rem' }}
      />
    </Paper>
  );
};
