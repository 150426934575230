import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Box } from '@material-ui/core';
import { TIMETRANSFER_DEFAULT_CONFIG, TimeTransferSpecSchema } from '@netinsight/crds-timetransfer';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import { F } from '@mobily/ts-belt';
import {
  CheckboxField,
  getSelectOptionsFromSchema,
  TextField,
  useFormStyles,
  usePermission,
  useSubmitButtonProps,
  useTextFieldController,
  useTimeTransferConfigUpdate,
} from '@netinsight/management-app-common-react';
import usePrevious from 'react-use/lib/usePrevious';
import { InfoCard } from '@backstage/core-components';

export const SyncInConfigSchema = TimeTransferSpecSchema.shape.syncIn.unwrap();

export type SyncInConfig = z.infer<typeof SyncInConfigSchema>;

export type SyncInConfigFormProps = {
  config: SyncInConfig;
  nodeId: string;
};

const DEFAULT_CONFIG: Required<SyncInConfig> = {
  selectable: false,
  driftRate: '1e-7',
  priority: TIMETRANSFER_DEFAULT_CONFIG.syncIn!.priority!,
  networkHoldoverCapable: false,
};

export const SyncInConfigForm: FunctionComponent<SyncInConfigFormProps> = ({ config, nodeId }) => {
  const styles = useFormStyles();

  const defaultValues: Required<SyncInConfig> = useMemo(() => {
    return {
      selectable: config.selectable ?? DEFAULT_CONFIG.selectable,
      priority: config.priority ?? DEFAULT_CONFIG.priority,
      driftRate: config.driftRate ?? DEFAULT_CONFIG.driftRate,
      networkHoldoverCapable: config.networkHoldoverCapable ?? DEFAULT_CONFIG.networkHoldoverCapable,
    };
  }, [config]);

  const prevDefaultValues = usePrevious(defaultValues);

  const formProps = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(SyncInConfigSchema),
  });
  const { control, handleSubmit, reset, formState } = formProps;

  const { trigger: updateConfig, permission: updateConfigPermission } = useTimeTransferConfigUpdate(nodeId);
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updateConfigPermission);
  const buttonProps = useSubmitButtonProps({ permission, formState });

  const handleOnSubmit = useCallback(
    async (updatedConfig: any) => {
      await updateConfig(existingConfig => ({
        ...existingConfig,
        syncIn: updatedConfig,
      }));
    },
    [updateConfig],
  );

  useEffect(() => {
    if (!F.equals(prevDefaultValues, defaultValues)) {
      reset(defaultValues, { keepSubmitCount: true, keepIsSubmitted: true, keepValues: true });
    }
  }, [defaultValues, prevDefaultValues, reset]);

  const selectableProps = useController({
    name: 'selectable',
    control,
  });
  const { field: priorityInputProps } = useTextFieldController({
    control,
    name: 'priority',
    label: 'Priority',
    schema: SyncInConfigSchema.shape.priority,
  });
  const { field: driftRateSelectProps } = useTextFieldController({
    control,
    name: 'driftRate',
    label: 'Drift Rate',
    schema: SyncInConfigSchema.shape.driftRate,
  });
  const networkHoldoverCapableCheckboxProps = useController({
    name: 'networkHoldoverCapable',
    control,
  });

  return (
    <InfoCard title="10M/Sync" titleTypographyProps={{ variant: 'h5' }} cardClassName={styles.formContainer}>
      <FormProvider {...formProps}>
        <form onSubmit={handleSubmit(handleOnSubmit)} className={styles.formContainer}>
          <div className={styles.formRow}>
            <CheckboxField
              label="Enabled"
              description={SyncInConfigSchema.shape.selectable.description ?? ''}
              fieldProps={selectableProps}
            />
            <TextField {...priorityInputProps} />
          </div>
          <TextField {...driftRateSelectProps}>
            {getSelectOptionsFromSchema(SyncInConfigSchema.shape.driftRate)}
          </TextField>
          <CheckboxField
            label="Network holdover capable"
            description={SyncInConfigSchema.shape.networkHoldoverCapable.description}
            fieldProps={networkHoldoverCapableCheckboxProps}
          />
          <Box>
            <Button {...buttonProps} data-testid="btn-apply-sync-in-config" />
          </Box>
        </form>
      </FormProvider>
    </InfoCard>
  );
};
