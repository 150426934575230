import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { metricsApiRef } from '@netinsight/management-app-common-react';
import { groupMetrics, groupMetricsBy2Levels } from '@netinsight/management-app-common-api';
import {
  getLinkId,
  getNodeId,
  getSyncSourceName,
  linkSelectionMetadataMapper,
  linkStabilityMapper,
} from '../../utils/node-graph';

export const useGraphLinkMetrics = (config?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);

  const getConfig = useCallback(async () => {
    const [syncSourceIsSelectedMetricsResult, linkStableResult] = await Promise.all([
      metricsApi.instantQuery({
        query: 'last_over_time(neti_hicc_syncsource_is_selected{type="LINK"}[30s]) > 0',
      }),
      metricsApi.instantQuery({ query: 'neti_hicc_link_stable' }),
    ]);

    const linkDirectionMap = groupMetrics(
      syncSourceIsSelectedMetricsResult,
      getSyncSourceName,
      linkSelectionMetadataMapper,
    );
    const linkStablilityMap = groupMetricsBy2Levels(linkStableResult, getLinkId, getNodeId, linkStabilityMapper);

    return { linkDirectionMap, linkStablilityMap };
  }, [metricsApi]);

  return useSwr('useGraphLinkMetrics', getConfig, config);
};
