import { A, D, F, G, pipe } from '@mobily/ts-belt';
import {
  EXTERNAL_SYNC_SOURCES,
  getSyncSourcePriorityConfigMap,
  SyncSourceName,
} from '@netinsight/management-app-common-api';

const getDuplicatedPriorities = (
  updatedPriorities: Record<SyncSourceName, number | undefined>,
  existingPriorities: Record<SyncSourceName, number | undefined>,
): [SyncSourceName, number][] => {
  const updatedSsns = pipe(updatedPriorities, D.filter(G.isNumber), D.keys);
  const updatedPrios = pipe(updatedPriorities, D.filter(G.isNumber), map => D.values(map as any) as number[], A.uniq);
  const duplicates = pipe(
    existingPriorities,
    D.filterWithKey(
      (existingSsn, existingPrio) =>
        EXTERNAL_SYNC_SOURCES.includes(existingSsn as any) &&
        !updatedSsns.includes(existingSsn) &&
        updatedPrios.includes(existingPrio as any),
    ),
    Object.entries,
    A.map(([ssn, prio]) => [ssn, prio] as [SyncSourceName, number]),
    F.toMutable,
  );

  return duplicates;
};

export const getDuplicatedSyncSources = async ({
  nodeId,
  sourceKey,
  submitValues,
  configApi,
}: {
  nodeId: string;
  sourceKey: string;
  submitValues: Record<string, any>;
  configApi: any;
}) => {
  const newPriorities = getSyncSourcePriorityConfigMap({ [sourceKey]: submitValues });
  const existingConfigResult = await configApi.getNodeTimeTransferConfig({ params: { nodeId } });
  if (existingConfigResult.status !== 200) {
    return null;
  }
  const existingPriorities = getSyncSourcePriorityConfigMap(existingConfigResult.body.config);
  return getDuplicatedPriorities(newPriorities, existingPriorities);
};
