import React, { FunctionComponent, useCallback } from 'react';
import { CircularProgress } from '@material-ui/core';
import { TextField } from '@netinsight/management-app-common-react';
import { DefaultTimeErrorsDuration } from './constants';

export const LinkSpreadDurationSelector: FunctionComponent<{
  isLoading: boolean;
  duration: string;
  onDurationChanged: (duration: string) => void;
}> = ({ isLoading, duration, onDurationChanged }) => {
  const handleLinkSpreadDurationChanged = useCallback(
    (evt: any) => {
      const fieldVal = evt.target?.value;
      const fieldValNum = parseFloat(fieldVal);
      const durationVal = !isNaN(fieldValNum) ? `${fieldValNum}m` : DefaultTimeErrorsDuration;
      onDurationChanged(durationVal);
    },
    [onDurationChanged],
  );

  return (
    <TextField
      label="Estimate link time errors by averaging over (minutes)"
      description="Averaging period, in minutes, for link time errors"
      inputProps={{ type: 'number', min: 1, max: 60, steps: 1 }}
      style={{ width: '32rem', maxWidth: '32rem' }}
      value={parseInt(duration, 10)}
      onChange={handleLinkSpreadDurationChanged}
      InputProps={{
        endAdornment: isLoading ? <CircularProgress color="inherit" size="1em" /> : null,
      }}
    />
  );
};
