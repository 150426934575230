import StatusCodes from 'http-status-codes';
import { mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { NodeGraphState } from '@netinsight/management-app-common-api';
import { inventoryApiRef, useSnackbarHelper } from '@netinsight/management-app-common-react';
import { GRAPH_STATE_CACHE_KEY, GRAPH_STATE_LIST_CACHE_KEY } from '../../constants/node-graph';

export const useGraphStateCreate = () => {
  const inventoryApi = useApi(inventoryApiRef);
  const { snackbar } = useSnackbarHelper();

  const create = async (_: any, { arg: { state: newState } }: { arg: { state: NodeGraphState } }) => {
    const result = await inventoryApi.postNodeGraphState(newState);
    return [result.id, newState] as const;
  };

  return useSWRMutation(GRAPH_STATE_CACHE_KEY, create, {
    throwOnError: false, // handling error in onError
    onSuccess: async ([id, newState]) => {
      await mutate([GRAPH_STATE_CACHE_KEY, id], newState);
      await mutate(GRAPH_STATE_LIST_CACHE_KEY);
      snackbar.success('Creating map successful');
    },
    onError: error => {
      const { status, statusText } = error.body ?? {};
      const errorReasonDetails =
        status === StatusCodes.REQUEST_TOO_LONG ? `: ${statusText || 'Payload Too Large'}` : ''; // handle if statusText is empty string
      snackbar.error(`Fail to save map: ${errorReasonDetails}`);
    },
  });
};
