import React, { FunctionComponent, useCallback, useEffect } from 'react';
import usePrevious from 'react-use/lib/usePrevious';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { F } from '@mobily/ts-belt';
import { Button, Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { PermissionResult, useFormStyles, useSubmitButtonProps } from '@netinsight/management-app-common-react';
import { NtpServerConfigEntry } from './NtpServerConfigEntry';
import { MAX_NTP_SERVERS, NtpClientConfig, NtpClientConfigSchema } from '@netinsight/node-manager-schema';

export type NtpClientConfigFormProps = {
  config: NtpClientConfig;
  onSubmit: (data: NtpClientConfig) => Promise<void>;
  permission: PermissionResult;
};

export const NtpClientConfigForm: FunctionComponent<NtpClientConfigFormProps> = ({ config, onSubmit, permission }) => {
  const styles = useFormStyles();

  const { control, handleSubmit, reset, formState } = useForm({
    defaultValues: config,
    mode: 'onBlur',
    resolver: zodResolver(NtpClientConfigSchema),
  });

  const { fields: serverFields, append, remove } = useFieldArray({ control, name: 'servers' });

  const prevDefaultValues = usePrevious(config);
  useEffect(() => {
    if (!F.equals(prevDefaultValues, config)) {
      reset(config, { keepSubmitCount: true, keepIsSubmitted: true, keepValues: true });
    }
  }, [prevDefaultValues, reset, config]);

  const handleAddNewServer = useCallback(() => {
    append({
      address: '',
    });
  }, [append]);

  const buttonProps = useSubmitButtonProps({ permission, formState });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
        <Typography component="h6" variant="h6">
          Servers
        </Typography>
        {serverFields.map((field, index) => (
          <NtpServerConfigEntry
            key={field.id}
            control={control}
            index={index}
            onRemove={remove}
            permision={permission}
          />
        ))}
        <Button
          variant="outlined"
          size="small"
          color="primary"
          type="button"
          data-testid="btn-add-ntp-server"
          startIcon={<AddIcon />}
          style={{ alignSelf: 'flex-start' }}
          onClick={handleAddNewServer}
          disabled={buttonProps.disabled || serverFields.length >= MAX_NTP_SERVERS}
        >
          Add server
        </Button>
        <Box>
          <Button data-testid="btn-apply-ntp-config" {...buttonProps} />
        </Box>
      </form>
    </>
  );
};
