import React, { FunctionComponent } from 'react';
import { Link } from '@backstage/core-components';
import { Paper, Typography } from '@material-ui/core';
import { DurationFormatter, InputTooltip } from '@netinsight/management-app-common-react';
import { LocalTimeReferenceErrors, TimeReferenceType } from './types';
import { useStyles } from './styles';
import { TimeReferenceDisplayNames } from './constants';
import { SYNC_SOURCE_CONFIG_URLS } from '../../../../../constants/sync';

export const LocalTimeErrorPrediction: FunctionComponent<{
  nodeId: string;
  prediction: LocalTimeReferenceErrors;
}> = ({ nodeId, prediction }) => {
  const styles = useStyles();
  return (
    <Paper className={styles.container} component="fieldset">
      <Typography component="h6" variant="h6">
        Predicted node time error after calibration
      </Typography>
      <table
        className={styles.table}
        style={{ width: 'auto', minWidth: '32rem' }}
        id="node-calibration-local-time-error-prediction-table"
        data-testid="node-calibration-local-time-error-prediction-table"
      >
        <thead className={styles.header}>
          <tr>
            <th style={{ textAlign: 'left' }}>Reference</th>
            <th style={{ textAlign: 'right' }}>
              Predicted time error{' '}
              <InputTooltip text="Predicted node time error, based on the current node time error and the new path diff values in the incoming links table. The current node time error is taken from one of the four Time error columns above. The column selected is the last one where the USE button is pressed or otherwise the first column." />{' '}
            </th>
          </tr>
        </thead>
        <tbody className={styles.body}>
          {(Object.entries(prediction) as Array<[TimeReferenceType, number | undefined]>)
            .filter(([type]) => (type as any) !== 'custom')
            .map(([type, predictionValue]) => (
              <tr key={type}>
                <th>
                  <Typography
                    variant="body1"
                    component={Link}
                    target="_blank"
                    to={SYNC_SOURCE_CONFIG_URLS[type](nodeId)}
                  >
                    {TimeReferenceDisplayNames[type]}
                  </Typography>
                </th>
                <td className="numeric value prediction">
                  {DurationFormatter.fromSeconds(predictionValue ?? NaN).toMicroSeconds(3) ?? '-'}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Paper>
  );
};
