import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, makeStyles } from '@material-ui/core';
import { ResponseErrorPanel, Progress, InfoCard } from '@backstage/core-components';
import {
  useTextFieldController,
  useFormStyles,
  TextField,
  getSelectOptionFromPair,
  usePermission,
  useSubmitButtonProps,
} from '@netinsight/management-app-common-react';
import { TimeNodeSpecSchema } from '@netinsight/crds';
import { useTimeNodeConfig } from '../../../../../hooks';
import { F } from '@mobily/ts-belt';
import { usePrevious } from 'react-use';
import { useTimeNodeConfigUpdate } from '../../../../../hooks/useTimeNodeConfigUpdate';
import { useSyncRegions } from '../../../../../hooks/sync';

const useStyles = makeStyles(() => ({
  nodeNameFieldContainer: {
    width: 'calc(max(30%, 30rem))',
  },
}));

interface TimeNodeConfigProps {
  nodeId: string;
}

const TimeNodeFormValuesSchema = z.object({
  name: TimeNodeSpecSchema.shape.name,
  syncRegion: TimeNodeSpecSchema.shape.syncRegion,
  thresholds: TimeNodeSpecSchema.shape.thresholds,
});

type TimeNodeFormValues = z.infer<typeof TimeNodeFormValuesSchema>;

export const TimeNodeConfig = ({ nodeId }: PropsWithChildren<TimeNodeConfigProps>) => {
  const { data: syncRegions } = useSyncRegions();
  const { data: timeNodeConfig, error: fetchError, isLoading: isLoadingNodeManagerConfig } = useTimeNodeConfig(nodeId);
  const { trigger: updateConfig, permission: updateConfigPermission } = useTimeNodeConfigUpdate(nodeId);
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updateConfigPermission);

  const formStyles = useFormStyles();
  const styles = useStyles();
  const regionOptions = useMemo(
    () => syncRegions?.map(({ name }) => [name, name] as [string, string]).map(getSelectOptionFromPair) ?? [],
    [syncRegions],
  );

  const defaultValues = useMemo<TimeNodeFormValues>(
    () => ({
      name: timeNodeConfig?.name,
      syncRegion: timeNodeConfig?.syncRegion,
      thresholds: timeNodeConfig?.thresholds ?? {},
    }),
    [timeNodeConfig],
  );

  const {
    control,
    reset: resetForm,
    handleSubmit,
    formState,
  } = useForm<TimeNodeFormValues>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(TimeNodeFormValuesSchema),
  });
  const buttonProps = useSubmitButtonProps({ permission, formState });

  const prevDefaultValues = usePrevious(defaultValues);
  useEffect(() => {
    if (!F.equals(defaultValues, prevDefaultValues)) {
      resetForm(defaultValues);
    }
  }, [resetForm, defaultValues, prevDefaultValues]);

  const { field: nameProps } = useTextFieldController({
    control,
    name: 'name',
    schema: TimeNodeSpecSchema.shape.name,
    label: 'Node name',
  });

  const { field: syncRegionProps } = useTextFieldController({
    control,
    name: 'syncRegion',
    schema: TimeNodeSpecSchema.shape.syncRegion,
    label: 'Sync region',
  });

  const { field: thresholdProps } = useTextFieldController({
    control,
    name: 'thresholds.timeErrorNS',
    schema: TimeNodeSpecSchema.shape.thresholds.unwrap().shape.timeErrorNS,
    isOptional: true,
    label: 'Node Time Error Alert Threshold (ns)',
  });

  const onSubmit = async (newConfig: TimeNodeFormValues) =>
    await updateConfig(cfg => ({
      ...cfg,
      ...newConfig,
    }));
  if (fetchError) {
    return <ResponseErrorPanel error={fetchError} />;
  }

  if (isLoadingNodeManagerConfig || isLoadingPermission) {
    return <Progress />;
  }

  return (
    <InfoCard title="General Settings">
      <form onSubmit={handleSubmit(onSubmit)} className={formStyles.formContainer} noValidate>
        <Box className={formStyles.formRow}>
          <TextField {...nameProps} id="name" rootClassName={styles.nodeNameFieldContainer} />
          <TextField {...syncRegionProps} id="syncRegion" select SelectProps={{ native: true }}>
            {regionOptions}
          </TextField>
        </Box>
        <Box className={formStyles.formRow}>
          <div className={formStyles.formGrid}>
            <TextField {...thresholdProps} />
          </div>
        </Box>
        <div className={formStyles.buttonContainer}>
          <Button {...buttonProps} size="medium" data-testid="btn-apply-general-settings" />
          <Button
            color="primary"
            size="medium"
            variant="outlined"
            disabled={buttonProps.disabled || !formState.isDirty}
            onClick={() => resetForm(defaultValues)}
          >
            Reset
          </Button>
        </div>
      </form>
    </InfoCard>
  );
};
