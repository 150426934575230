import React, { useLayoutEffect, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { ResponseErrorPanel } from '@backstage/core-components';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { G } from '@mobily/ts-belt';
import { useTimeNetworkConfigs, useAlerts } from '@netinsight/management-app-common-react';
import { NodeGraph, NodeGraphControls, NodeGraphDetailPanel, NodeGraphListMenu } from '../NodeGraph';
import { useGraphData, useGraphLinkMetrics, useGraphMetrics, useGraphState } from '../../../hooks/node-graph';
import { getNodeMetricsViewModel, getNodeSyncMetricsViewModel } from '../../../utils/node-graph';
import { useSyncRegionByNodeIdLookup, useSyncRegions } from '../../../hooks/sync';
import { LAST_GRAPH_ID_STORAGE_KEY } from '../../../constants/node-graph';

const useStyles = makeStyles(theme => ({
  tabBody: {
    padding: 0,
    position: 'relative',
  },
  backdrop: {
    zIndex: theme.zIndex.modal,
    color: theme.palette.textContrast,
    position: 'absolute',
  },
}));

const METRICS_SWR_CONFIG = { refreshInterval: 15_000 };

export const NodeGraphPage = ({ graphId }: { graphId: string }) => {
  const styles = useStyles();
  const {
    data: { nodes, links } = {},
    error: fetchError,
    isLoading: isLoadingGraphData,
  } = useGraphData({ refreshInterval: 0 });
  const { data: configs } = useTimeNetworkConfigs();
  const { data: { linkDirectionMap, linkStablilityMap } = {} } = useGraphLinkMetrics(METRICS_SWR_CONFIG);
  const { data: alerts } = useAlerts(undefined, METRICS_SWR_CONFIG);
  const { data: metrics } = useGraphMetrics(METRICS_SWR_CONFIG);
  const { data: nodeGraphState, isLoading: isLoadingGraphState } = useGraphState(graphId, {
    refreshInterval: 0,
    revalidateOnFocus: false,
    errorRetryCount: 0,
    errorRetryInterval: 0,
  });

  const nodeMetricsViewModel = useMemo(() => getNodeMetricsViewModel(alerts, metrics?.nodeMetrics), [alerts, metrics]);
  const nodeSyncSourceMetricsViewModel = useMemo(
    () => getNodeSyncMetricsViewModel(configs, metrics?.syncSourceMetrics),
    [configs, metrics],
  );

  const { data: allSyncRegions } = useSyncRegions();
  const syncRegionLookup = useSyncRegionByNodeIdLookup(allSyncRegions);

  const [, setLastGraphId] = useLocalStorage<string>(LAST_GRAPH_ID_STORAGE_KEY);
  useLayoutEffect(() => {
    setLastGraphId(graphId);
    // this is a hack to get a nice canvas for the graph because backstage doesn't expose any ways to change the padding on the tab body
    const tabBody = document.querySelector('article');
    if (tabBody) {
      tabBody.classList.add(styles.tabBody);
    }
  }, [styles, graphId, setLastGraphId]);

  if (fetchError) {
    return <ResponseErrorPanel error={fetchError} />;
  }

  const isLoading = isLoadingGraphData || isLoadingGraphState;

  if (G.isNullable(nodes) || G.isNullable(links)) {
    return null;
  }
  return (
    <>
      <NodeGraph
        graphId={graphId}
        nodes={nodes}
        links={links}
        linkDirectionMap={linkDirectionMap ?? {}}
        linkStabilityMap={linkStablilityMap ?? {}}
        initialState={nodeGraphState ?? {}}
        syncRegionLookup={syncRegionLookup}
        nodeMetrics={nodeMetricsViewModel}
        nodeSyncMetrics={nodeSyncSourceMetricsViewModel}
      >
        <NodeGraphControls graphId={graphId} />
        <NodeGraphListMenu graphId={graphId} />
        <NodeGraphDetailPanel
          nodeMetrics={nodeMetricsViewModel}
          nodeSyncMetrics={nodeSyncSourceMetricsViewModel}
          linkMetrics={metrics?.linkMetrics}
        />
      </NodeGraph>
      <Backdrop open={isLoading} className={styles.backdrop}>
        <CircularProgress size="5rem" />
      </Backdrop>
    </>
  );
};
