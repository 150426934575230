import React, { FunctionComponent, useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, ButtonProps, Typography, makeStyles } from '@material-ui/core';
import FlashIcon from '@material-ui/icons/FlashOn';
import WarningIcon from '@material-ui/icons/Warning';
import { Alert } from '@material-ui/lab';
import { DurationFormatter, PopButton, useFormStyles } from '@netinsight/management-app-common-react';
import { PersistedSyncLink } from '@netinsight/management-app-common-api';
import { LinkDetailContext } from '../../../constants/time-transfer';
import { G } from '@mobily/ts-belt';
import { calculateProfileFromMetrics, getCanCalculate } from '../../../utils/time-transfer';

const useStyles = makeStyles(() => ({
  popContainer: { width: '40rem' },
  popText: { textAlign: 'left', ['& summary']: { cursor: 'pointer' } },
}));

const CalculateButtonProps: ButtonProps & { ['data-testid']: string } = {
  variant: 'outlined',
  color: 'default',
  size: 'small',
  ['data-testid']: 'btn-calculate-profile-path-diff',
  startIcon: <FlashIcon fontSize="small" />,
};

export const LinkDetailProfileCalculateButton: FunctionComponent<
  {
    index: number;
  } & Partial<ButtonProps>
> = ({ index, ...buttonProps }) => {
  const formStyles = useFormStyles();
  const styles = useStyles();
  const { combinedMetrics = {} } = useContext(LinkDetailContext);
  const { setValue } = useFormContext<PersistedSyncLink>();
  const canCalculate = getCanCalculate(combinedMetrics);
  const handleCalculate = useCallback(
    (assumeSymmetry: boolean) => {
      const calcResult = calculateProfileFromMetrics(combinedMetrics, assumeSymmetry);
      if (G.isNullable(calcResult)) {
        return;
      }
      const { delayDifference, roundtripTime, delayFromRemote, delayToRemote } = calcResult;
      setValue(`profiles.${index}.delayDifference`, delayDifference, {
        shouldDirty: true,
      });
      setValue(`profiles.${index}.roundtripTime`, roundtripTime, {
        shouldDirty: true,
      });
      setValue(`profiles.${index}.delayToRemote`, delayToRemote, {
        shouldDirty: true,
      });
      setValue(`profiles.${index}.delayFromRemote`, delayFromRemote, {
        shouldDirty: true,
      });
    },
    [combinedMetrics, index, setValue],
  );

  const renderPopupContent = useCallback(
    (onClose: VoidFunction) => (
      <Alert variant="outlined" color="warning" icon={<WarningIcon />} classes={{ message: formStyles.formContainer }}>
        <div className={styles.popText}>
          {typeof combinedMetrics.linkTimeError === 'undefined' || isNaN(combinedMetrics.linkTimeError) ? (
            <Typography variant="body1" component="p">
              Both nodes are unstable and/or there is not enough information to suggest a value for asymmetry. Do you
              want to assume the link is symmetric?
            </Typography>
          ) : (
            <Typography variant="body1" component="p">
              One or both of the nodes is unstable. The calculated asymmetry{' '}
              <strong>
                {DurationFormatter.fromMicroseconds(
                  calculateProfileFromMetrics(combinedMetrics, false)?.delayDifference ?? NaN,
                )?.toMicroSeconds(3) ?? ''}{' '}
              </strong>
              is likely inaccurate. Do you want to use it anyway or assume the link is symmetric?
            </Typography>
          )}
          <details>
            <Typography variant="overline" component="summary">
              More details
            </Typography>
            <Typography variant="body2">
              If the unstable node has access to another time source like GNSS, PTP receiver, or PPS in, you can
              temporarily set the node in local sync to get it stable. Once it is stable, the link asymmetry can be
              re-calculated, either using this page again or by other means such as node calibration or group
              calibration.
            </Typography>
          </details>
        </div>
        <div className={formStyles.buttonContainer}>
          {typeof combinedMetrics.linkTimeError !== 'undefined' && !isNaN(combinedMetrics.linkTimeError) ? (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                handleCalculate(false);
                onClose();
              }}
            >
              Use suggested asymmetry
            </Button>
          ) : null}
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => {
              handleCalculate(true);
              onClose();
            }}
          >
            Assume link is symmetric
          </Button>
          <Button variant="outlined" color="default" size="small" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </Alert>
    ),
    [handleCalculate, combinedMetrics, formStyles, styles],
  );

  return combinedMetrics?.endpointAStable === true && combinedMetrics?.endpointBStable === true ? (
    <Button
      {...CalculateButtonProps}
      {...buttonProps}
      disabled={buttonProps.disabled || !canCalculate}
      onClick={() => {
        handleCalculate(false);
      }}
    >
      Calculate
    </Button>
  ) : (
    <PopButton
      {...CalculateButtonProps}
      disabled={!canCalculate}
      label="Calculate"
      containerProps={{
        className: styles.popContainer,
      }}
      render={renderPopupContent}
    />
  );
};
