import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { A, G, flow, pipe } from '@mobily/ts-belt';
import { Typography, makeStyles } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { TextField, booleanScale, isNullableOrEmpty } from '@netinsight/management-app-common-react';
import { PersistedSyncLink } from '@netinsight/management-app-common-api';
import { AdminStatus, OperStatus } from '@netinsight/node-manager-schema';
import { PtpProfileLabels } from '@netinsight/crds-timetransfer';
import { DEFAULT_NAMESPACE_LABELS } from '../../../constants/nodes';
import { InterfaceInfo } from '../../../types/nodes';
import { isManagement } from '../../../utils/nodes';

const useStyles = makeStyles(
  theme => ({
    input: { width: '100%' },
    listBoxItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(0.25),
    },
  }),
  { name: 'Neti' },
);

const capitalize = (input: string) => (input.length > 1 ? input.charAt(0).toUpperCase() + input.slice(1) : input);
const groupBy = flow(
  (opt: InterfaceInfo) => DEFAULT_NAMESPACE_LABELS[opt.networkNamespace] ?? opt.networkNamespace,
  capitalize,
);
const getOptionDisabled = (opt: InterfaceInfo) =>
  opt.adminStatus !== AdminStatus.Up || isNullableOrEmpty(opt.ip) || isManagement(opt);
const getOptionLabel = (opt: InterfaceInfo) => `${opt.ip ?? '?'} (${opt.displayName})`;
const formatInterfaceStatus = (opt: InterfaceInfo) =>
  pipe(
    [
      ['speed', G.isNullable(opt.vlanId) ? opt.speed : undefined],
      ['status', opt.operationalStatus !== OperStatus.Up ? opt.operationalStatus : undefined],
      ['links', opt.usage.links?.length],
      ['PTP receiver', G.isString(opt.usage?.ptpReceiver) ? PtpProfileLabels[opt.usage.ptpReceiver] : undefined],
      [
        'PTP transmitter',
        G.isString(opt.usage?.ptpTransmitter) ? PtpProfileLabels[opt.usage.ptpTransmitter] : undefined,
      ],
      ['SyncE', opt.usage?.syncE === true ? booleanScale(opt.usage.syncE) : undefined],
    ] as [string, any][],
    A.filter(([, val]) => G.isNotNullable(val)),
    A.mapWithIndex((idx, [k, v]) => [idx === 0 ? capitalize(k) : k, v].join(': ')),
    kvs => kvs.join(', '),
  );

export const LinkEndpointInterfaceSelector: FunctionComponent<{
  prefix: 'endpointA' | 'endpointB';
  availableInterfaces: InterfaceInfo[];
}> = ({ prefix, availableInterfaces }) => {
  const styles = useStyles();
  const { control, setValue } = useFormContext<PersistedSyncLink>();
  const { errors } = useFormState({ control });
  const errorMessage = errors[prefix]?.iface?.message ?? errors[prefix]?.vlanId?.message;
  const { iface: currentIfaceName, vlanId: currentVlanId } = useWatch({ control, name: prefix });

  const handleChange = useCallback(
    (_: React.ChangeEvent<object>, selected?: InterfaceInfo) => {
      if (G.isNullable(selected)) {
        return;
      }
      setValue(`${prefix}.iface`, selected.name);
      setValue(`${prefix}.vlanId`, selected.vlanId);
    },
    [setValue, prefix],
  );
  const selectedOption = useMemo(
    () => availableInterfaces.find(({ name, vlanId }) => name === currentIfaceName && vlanId === currentVlanId),
    [availableInterfaces, currentIfaceName, currentVlanId],
  );
  const getOptionSelected = useCallback(
    (opt: InterfaceInfo) => opt.name === currentIfaceName && opt.vlanId === currentVlanId,
    [currentIfaceName, currentVlanId],
  );

  return (
    <Autocomplete<InterfaceInfo, false, true, false>
      disableClearable
      fullWidth
      options={availableInterfaces}
      value={selectedOption}
      onChange={handleChange}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      getOptionSelected={getOptionSelected}
      renderOption={opt => (
        <div className={styles.listBoxItem} data-name={opt.name} data-vlanid={opt.vlanId}>
          <Typography variant="body1" color="textPrimary" component="span">
            {getOptionLabel(opt)}
          </Typography>
          {!getOptionDisabled(opt) ? (
            <Typography variant="subtitle2" color="textSecondary" component="span">
              {formatInterfaceStatus(opt)}
            </Typography>
          ) : null}
        </div>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          fullWidth
          label="IP address"
          name={`${prefix}.ip`}
          placeholder="Select interface"
          helperText={errorMessage}
          error={!isNullableOrEmpty(errorMessage)}
          variant="standard"
          className={styles.input}
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
          }}
        />
      )}
    />
  );
};
