import React from 'react';
import { InfoCard, Progress, ResponseErrorPanel } from '@backstage/core-components';
import { TimeNodeLogLevelForm } from './TimeNodeLogLevelForm';
import { useServiceLogging } from '../../../../../hooks';
import { useGrafanaDashboards, usePermission } from '@netinsight/management-app-common-react';

export const TimeNodeLoggingTab = ({ nodeId }: { nodeId: string }) => {
  const { config, isLoading, error, update, permission } = useServiceLogging(nodeId);
  const { isLoading: isLoadingPermission, ...updatePermission } = usePermission(permission);
  const { data: dashboards = {}, isLoading: isLoadingGrafanaDashboards } = useGrafanaDashboards();

  if (isLoading || isLoadingGrafanaDashboards || isLoadingPermission) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <InfoCard title="Logging">
      <TimeNodeLogLevelForm
        nodeId={nodeId}
        dashboards={dashboards}
        initialValues={config}
        onSubmit={update}
        permission={updatePermission}
      />
    </InfoCard>
  );
};
