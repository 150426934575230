/* eslint-disable no-nested-ternary */
import React, { FunctionComponent, useMemo } from 'react';
import { Outlet } from 'react-router';
import { Link } from '@backstage/core-components';
import { makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { G } from '@mobily/ts-belt';
import {
  DurationFormatter,
  StatusBox,
  booleanScale,
  useNodeNameMap,
  useTimeTransferConfig,
} from '@netinsight/management-app-common-react';
import { SyncSourceUsage } from '@netinsight/syncd-schema';
import {
  getSyncSourcePriorityConfigMap,
  getSyncSourceUsageConfigMap,
  SYNC_SOURCE_NAMES,
} from '@netinsight/management-app-common-api';
import { useTimeNodeOverviewMetrics } from '../../../../hooks/metrics';
import { controlStateScale, getSyncMode, getSyncSourceViewModels } from '../../../../utils/sync';
import { getLinkViewModels } from '../../../../utils/links';
import { useNodeLinksByDirection } from '../../../../hooks/links';

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      border: 'none',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '100%',
      gap: theme.spacing(3),
      overflow: 'hidden',
    },
    linkNameColumn: {
      width: 'auto',
      maxWidth: '20rem',
    },
    linkTypeHeader: {
      ['&:only-child']: {
        textAlign: 'left',
        textIndent: theme.spacing(1),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.fontSize * 1.125,
      },
    },
    table: {
      width: '100%',
      ['border-collapse']: 'collapse',
      [' & td, & th']: {
        ...theme.typography.body2,
        border: '1px solid',
        borderColor: theme.palette.divider,
      },
    },
    header: {
      ['& th']: {
        fontWeight: 'bold',
        padding: theme.spacing(1, 1),
        ['&:first-child']: {
          textAlign: 'left',
          padding: theme.spacing(1, 2, 1, 4),
        },
        ['&.numeric']: {
          textAlign: 'right',
        },
      },
    },
    body: {
      ['& tr[aria-selected="true"]']: {
        backgroundColor: theme.palette.action.selected,
        ['& > th:first-child a']: {
          position: 'relative',
          height: '1.125rem',
          display: 'inline-block',
          lineHeight: '1.125rem',
          ['&::before']: {
            content: '""',
            display: 'block',
            width: '0.5rem',
            height: '0.5rem',
            position: 'absolute',
            left: '-1.25rem',
            top: '50%',
            transform: 'translate(0, -50%)',
            borderRadius: '50%',
            backgroundColor: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.primary.main,
            borderWidth: '0',
            boxShadow: `
              0 0 0.25rem 0.25rem ${
                theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.primary.light
              }
            `,
          },
        },
      },
      ['& th']: {
        fontWeight: 'bold',
        padding: theme.spacing(1, 1),
        textAlign: 'left',
      },
      ['& th:first-child']: {
        padding: theme.spacing(1, 2, 1, 4),
      },
      ['& td']: {
        textAlign: 'left',
        padding: theme.spacing(1, 1),
        fontVariantNumeric: 'tabular-nums',
      },
      ['& td.value']: {
        minWidth: '8rem',
        width: '8rem',
      },
      ['& td.numeric']: {
        textAlign: 'right',
      },
      ['& td.icon']: {
        textAlign: 'center',
      },
    },
    cellIcon: {
      width: '1.25rem',
      height: '1.25rem',
    },
    warningIcon: {
      fill: theme.palette.warning.main,
    },
    statusWarningIcon: {
      width: '1.25rem',
      marginLeft: '0.125rem',
    },
    okIcon: {
      fill: theme.palette.success.main,
    },
    errorIcon: {
      fill: theme.palette.error.main,
    },
  }),
  { name: 'Neti' },
);

const LinkStatusValue: FunctionComponent<{
  selected?: boolean;
}> = ({ selected: value }) => {
  const theme = useTheme();
  return value === true ? <CheckIcon style={{ fill: theme.palette.success.main }} /> : null;
};

const SyncSourceUsageValue: FunctionComponent<{
  value?: SyncSourceUsage | string;
}> = ({ value }) => {
  switch (value) {
    case SyncSourceUsage.LocalOnly:
      return <span>Local sync</span>;
    case SyncSourceUsage.Selectable:
      return <LinkStatusValue selected />;
    default:
      return null;
  }
};

const LinkSubTable: FunctionComponent<{ data: any; showSelection?: boolean }> = ({ data, showSelection = false }) => {
  const styles = useStyles();

  return (
    <tr key={data.linkId} {...(showSelection ? { ['aria-selected']: data.selected } : {})}>
      <th className={styles.linkNameColumn}>
        <Link to={`/network/links/${data.linkId}`} target="_blank">
          {data.name ?? data.linkId}
        </Link>
      </th>
      <td>
        <Typography component={Link} to={`/nodes/info/${data.clockNodeId}`} target="_blank" variant="body2">
          {data.clockNodeName}
        </Typography>
      </td>
      <td style={{ width: 'auto' }}>
        <Typography
          component={Link}
          to={`/nodes/info/${data.peerNodeId}`}
          target="_blank"
          variant="body2"
          title={data.selected ? 'Selected' : ''}
        >
          {data.peerNodeName}
        </Typography>
      </td>
      <td className="icon value">
        <LinkStatusValue selected={data.selectable !== false} />
      </td>
      <td className="icon value">
        <LinkStatusValue selected={data.available} />
      </td>
      <td className="numeric value">{data.priority}</td>
    </tr>
  );
};

export const SyncStatusOverview = ({ nodeId }: { nodeId: string }) => {
  const styles = useStyles();
  const { data: nodeNameMap } = useNodeNameMap();
  const { data: nodeLinksByDirection } = useNodeLinksByDirection(nodeId, { refreshInterval: 10_000 });
  const { data: metrics } = useTimeNodeOverviewMetrics(nodeId, { refreshInterval: 10_000 });
  const { data: timeTransferConfig } = useTimeTransferConfig(nodeId);
  const { inLinks, outLinks, unusedLinks } = useMemo(
    () =>
      getLinkViewModels({
        nodeId,
        nodeNameMap,
        ...(metrics ?? {}),
        ...(nodeLinksByDirection ?? {}),
      }),
    [nodeId, metrics, nodeLinksByDirection, nodeNameMap],
  );

  const usageConfig = useMemo(() => getSyncSourceUsageConfigMap(timeTransferConfig), [timeTransferConfig]);
  const priorityConfig = useMemo(() => getSyncSourcePriorityConfigMap(timeTransferConfig), [timeTransferConfig]);

  const syncSources = useMemo(
    () =>
      getSyncSourceViewModels({
        nodeId,
        ...(metrics ?? {}),
        ...(nodeLinksByDirection ?? {}),
        usageConfig,
        priorityConfig,
      }),
    [nodeId, metrics, nodeLinksByDirection, usageConfig, priorityConfig],
  );

  const mode = useMemo(
    () =>
      getSyncMode({
        incomingLinkMetrics: Object.values(nodeLinksByDirection?.linkDirectionMap ?? {}).map(lt => lt[nodeId]),
        usageConfig,
        ...(metrics ?? {}),
        ...(nodeLinksByDirection ?? {}),
      }),
    [nodeId, metrics, usageConfig, nodeLinksByDirection],
  );

  const controlState = metrics?.statuses?.filteredControlState ?? metrics?.statuses?.controlState;

  return (
    <>
      <StatusBox
        statuses={[
          ['Control state', G.isNotNullable(controlState) ? controlStateScale(controlState) : null],
          ['Mode', mode],
          [
            'Node stable',
            G.isNotNullable(metrics?.statuses?.nodeIsStable) ? booleanScale(metrics.statuses.nodeIsStable) : null,
          ],
          [
            'Stable time',
            metrics?.statuses?.hasNodeTimeInStable === true
              ? DurationFormatter.fromSeconds(metrics.statuses.nodeTimeInStable).toString()
              : null,
          ],
          [
            'Unstable time',
            metrics?.statuses?.hasNodeTimeInStable === false && metrics.statuses.nodeTimeInUnstable > -1
              ? DurationFormatter.fromSeconds(metrics.statuses.nodeTimeInUnstable).toString()
              : null,
          ],
          [
            'GNSS time offset',
            timeTransferConfig?.gnss?.useAsReference === true && metrics?.syncSourceTimeOffsets.gnss
              ? DurationFormatter.fromSeconds(metrics.syncSourceTimeOffsets.gnss).toMicroSeconds(3)
              : null,
          ],
          [
            'PPS time offset',
            timeTransferConfig?.ppsIn?.useAsReference === true && metrics?.syncSourceTimeOffsets.pps_in
              ? DurationFormatter.fromSeconds(metrics.syncSourceTimeOffsets.pps_in).toMicroSeconds(3)
              : null,
          ],
          [
            'PTP time receiver 1 offset',
            timeTransferConfig?.ptpReceiver?.instances?.[0]?.useAsReference === true &&
            G.isNotNullable(metrics?.syncSourceTimeOffsets[SYNC_SOURCE_NAMES.ptp1])
              ? DurationFormatter.fromSeconds(metrics.syncSourceTimeOffsets[SYNC_SOURCE_NAMES.ptp1]).toMicroSeconds(3)
              : null,
          ],
          [
            'PTP time receiver 2 offset',
            timeTransferConfig?.ptpReceiver?.instances?.[1]?.useAsReference === true &&
            G.isNotNullable(metrics?.syncSourceTimeOffsets[SYNC_SOURCE_NAMES.ptp2])
              ? DurationFormatter.fromSeconds(metrics.syncSourceTimeOffsets[SYNC_SOURCE_NAMES.ptp2]).toMicroSeconds(3)
              : null,
          ],
        ]}
        showToggle={false}
      />
      <div className={styles.container}>
        <Typography component="h6" variant="h6">
          Links
        </Typography>
        <table className={styles.table}>
          <thead className={styles.header}>
            <tr>
              <th>Name</th>
              <th align="left">Source node</th>
              <th align="left">Peer node</th>
              <th>Selectable</th>
              <th>Available</th>
              <th className="numeric">Priority</th>
            </tr>
          </thead>
          <tbody className={styles.body}>
            <tr>
              <th colSpan={6} className={styles.linkTypeHeader}>
                Incoming links ({inLinks.length})
              </th>
            </tr>
            {inLinks.map(ls => (
              <LinkSubTable key={ls.linkId} data={ls} showSelection />
            ))}
            <tr>
              <th colSpan={6} className={styles.linkTypeHeader}>
                Outgoing links ({outLinks.length})
              </th>
            </tr>
            {outLinks.map(ls => (
              <LinkSubTable key={ls.linkId} data={ls} />
            ))}
            <tr>
              <th colSpan={6} className={styles.linkTypeHeader}>
                Unused links ({unusedLinks.length})
              </th>
            </tr>
            {unusedLinks.map(ls => (
              <LinkSubTable key={ls.linkId} data={ls} />
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.container}>
        <Typography component="h6" variant="h6">
          Local sources
        </Typography>
        <table className={styles.table}>
          <thead className={styles.header}>
            <tr>
              <th align="left">Name</th>
              <th>Usage</th>
              <th>Available</th>
              <th className="numeric">Priority</th>
            </tr>
          </thead>
          <tbody className={styles.body}>
            {syncSources.externals.map(source => (
              <tr key={source.name} aria-selected={source.selected}>
                <th style={{ width: 'auto' }}>
                  <Link to={source.url}>{source.label}</Link>
                </th>
                <td className="icon value">
                  <SyncSourceUsageValue value={source.usage} />
                </td>
                <td className="icon value">
                  <LinkStatusValue selected={source.available} />
                </td>
                <td className="numeric value">{source.priority ?? '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.container}>
        <Typography component="h6" variant="h6">
          Frequency sources
        </Typography>
        <table className={styles.table}>
          <thead className={styles.header}>
            <tr>
              <th align="left">Name</th>
              <th>Available</th>
              <th className="numeric">Priority</th>
            </tr>
          </thead>
          <tbody className={styles.body}>
            {syncSources.frequencies.map(source => (
              <tr key={source.name} aria-selected={source.selected}>
                <th style={{ width: 'auto' }}>
                  <Link to={source.url}>{source.label}</Link>
                </th>
                <td className="icon value">
                  <LinkStatusValue selected={source.available} />
                </td>
                <td className="numeric value">{source.priority ?? '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.container}>
        <Typography component="h6" variant="h6">
          Holdover
        </Typography>
        <table className={styles.table}>
          <thead className={styles.header}>
            <tr>
              <th align="left">Name</th>
              <th>Available</th>
              <th className="numeric">Priority</th>
            </tr>
          </thead>
          <tbody className={styles.body}>
            {syncSources.internals.map(source => (
              <tr key={source.name} aria-selected={source.selected}>
                <th style={{ width: 'auto' }}>
                  <Link to={source.url}>{source.label}</Link>
                </th>
                <td className="icon value">
                  <LinkStatusValue selected={source.available} />
                </td>
                <td className="numeric value">{source.priority ?? '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Outlet />
    </>
  );
};
