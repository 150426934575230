import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import { A, D, F, G, pipe } from '@mobily/ts-belt';
import { useNodeNameMap } from '@netinsight/management-app-common-react';
import { formatInterfaceName } from '../../../../../../utils/nodes';
import { useInterfaceDisplayNameMap } from '../../../../../../hooks/nodes';
import { NodeManagerConfigWarningCodes } from '../../../../../../constants/nodes';
import { NodeManagerConfigWarning } from '../../../../../../types/nodes';
import { NodeManagerConfigWarningContent } from './NodeManagerConfigWarningContent';

const useStyles = makeStyles(
  theme => ({
    nodeManagerConfigWarnings: {
      ['& > dl']: {
        marginBottom: theme.spacing(2),
        display: 'grid',
        gridTemplateColumns: '6rem 1fr',
      },
      ['& > dl > dt:not(:first-of-type)']: {
        visibility: 'hidden',
      },
      ['& dt > a[href]']: {
        fontWeight: 'bold',
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
  }),
  { name: 'Neti' },
);

export const NodeManagerConfigWarnings: FunctionComponent<{
  warnings: NodeManagerConfigWarning[];
  ifaceDisplayNameMap: Record<string, string>;
}> = ({ warnings, ifaceDisplayNameMap }) => {
  const sortedWarningGroups = pipe(
    warnings,
    A.groupBy(w =>
      w.code === NodeManagerConfigWarningCodes.DisableOrMoved || w.code === NodeManagerConfigWarningCodes.SameIp
        ? formatInterfaceName(w.name, w.vlanId)
        : '',
    ),
    F.coerce<Record<string, NodeManagerConfigWarning[]>>,
    D.toPairs,
    F.toMutable,
  );
  const nodeIds = pipe(
    sortedWarningGroups,
    A.map(([, ws]) =>
      pipe(
        ws ?? [],
        A.map(
          w => (w.code === NodeManagerConfigWarningCodes.SameIp ? w.other.nodeId : undefined) as string | undefined,
        ),
      ),
    ),
    A.flat,
    A.filter(G.isString),
    A.uniq,
  );
  const { data: nodeNameMap = {} } = useNodeNameMap();
  const { data: otherNodesIfaceDisplayNameMap = {} } = useInterfaceDisplayNameMap(...nodeIds);
  const styles = useStyles();

  return (
    <div className={styles.nodeManagerConfigWarnings}>
      {sortedWarningGroups.map(([groupId, warnings2]) => (
        <dl key={groupId}>
          {warnings2.map((warning, index) => (
            <NodeManagerConfigWarningContent
              key={index}
              warning={warning}
              ifaceDisplayNameMap={ifaceDisplayNameMap}
              otherNodesIfaceDisplayNameMap={otherNodesIfaceDisplayNameMap}
              nodeNameMap={nodeNameMap}
            />
          ))}
        </dl>
      ))}
      <span>Do you want to proceed with this change?</span>
    </div>
  );
};
