/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';
import { z } from 'zod';
import { InfoCard } from '@backstage/core-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, LinearProgress } from '@material-ui/core';
import { F } from '@mobily/ts-belt';
import {
  getSelectOptionsFromSchema,
  NetiHeadingTypographyProps,
  TextField,
  usePermission,
  useFormStyles,
  useGlobalSettings,
  useTextFieldController,
  useSubmitButtonProps,
} from '@netinsight/management-app-common-react';
import { GlobalSettings, GlobalSettingsSchema, LogRedactLevelSchema } from '@netinsight/management-app-common-api';
import { useGlobalSettingsUpdate } from '../../../hooks/settings';

type GlobalLoggingConfigFormValues = Pick<GlobalSettings, 'logging'>;

export const GlobalLoggingConfigForm = () => {
  const formStyles = useFormStyles();
  const { data = {}, isLoading } = useGlobalSettings();
  const initialValues = useMemo(() => ({ logging: data.logging }), [data]);
  const { trigger: update, permission: updatePermission } = useGlobalSettingsUpdate();
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updatePermission);

  const formProps = useForm<GlobalLoggingConfigFormValues>({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: zodResolver(
      z.object({
        logging: GlobalSettingsSchema.shape.logging,
      }),
    ),
  });
  const handleSubmitCallback = useCallback(
    async (submittedData: GlobalLoggingConfigFormValues) => {
      await update({ ...(data ?? {}), ...submittedData });
    },
    [update, data],
  );
  const { handleSubmit, reset, control, formState } = formProps;
  const buttonProps = useSubmitButtonProps({ permission, formState });
  const prevInitialValues = usePrevious(initialValues);
  useEffect(() => {
    if (!F.equals(prevInitialValues, initialValues)) {
      reset(initialValues);
    }
  }, [initialValues, prevInitialValues, reset]);

  const { field: logRedactLevelFieldProps } = useTextFieldController({
    control,
    name: 'logging.logRedactLevel',
    schema: LogRedactLevelSchema,
    label: 'Log redact level',
  });

  if (isLoading || isLoadingPermission) {
    return <LinearProgress />;
  }

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(handleSubmitCallback)} className={formStyles.formContainer}>
        <InfoCard
          title="Global logging settings"
          titleTypographyProps={NetiHeadingTypographyProps.h2}
          cardClassName={formStyles.formContainer}
        >
          <TextField {...logRedactLevelFieldProps}>
            {getSelectOptionsFromSchema(LogRedactLevelSchema.optional())}
          </TextField>
        </InfoCard>
        <div className={formStyles.buttonContainer}>
          <Button {...buttonProps} data-testid="btn-save-global-logging-settings" />
        </div>
      </form>
    </FormProvider>
  );
};
