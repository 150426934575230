import React, { PropsWithChildren } from 'react';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import { useTimeTransferConfig } from '@netinsight/management-app-common-react';
import { HoldoverConfigForm } from './HoldoverConfigForm';

export const HoldoverConfig = ({ nodeId }: PropsWithChildren<{ nodeId: string }>) => {
  const { data: config, isLoading: isConfigLoading, error: configLoadError } = useTimeTransferConfig(nodeId);

  if (isConfigLoading) {
    return <Progress />;
  }
  if (configLoadError) {
    return <ResponseErrorPanel error={configLoadError} />;
  }
  if (config) {
    return <HoldoverConfigForm nodeId={nodeId} config={config.holdover} />;
  }
  return null;
};
