import { Dispatch, createContext } from 'react';
import { F } from '@mobily/ts-belt';
import { getInitialNodeGraphState, LocalNodeGraphState, NodeGraphAction } from './reducer';

export type NodeGraphContextValue = {
  state: LocalNodeGraphState;
  dispatch: Dispatch<NodeGraphAction>;
  cyRef: React.MutableRefObject<cytoscape.Core | undefined>;
};

export const NodeGraphContext = createContext<NodeGraphContextValue>({
  state: getInitialNodeGraphState(),
  dispatch: F.ignore,
  cyRef: { current: undefined },
});
