import { G } from '@mobily/ts-belt';
import { SyncSourceUsage } from '@netinsight/syncd-schema';
import {
  CONTROL_STATES,
  EXTERNAL_SYNC_SOURCES,
  getSyncSourceUsageConfigMap,
  SYNC_SOURCE_NAMES,
} from '@netinsight/management-app-common-api';
import { LinkDirectionMeta } from '../../types/links';
import { useNodeLinksByDirection } from '../../hooks/links';
import { useTimeNodeOverviewMetrics } from '../../hooks/metrics';
import { ValueOf } from '@netinsight/management-app-common-react';

export const getSyncMode = ({
  usageConfig,
  statuses,
  incomingLinkMetrics,
  syncSourceSelections,
}: {
  incomingLinkMetrics?: LinkDirectionMeta[];
  usageConfig: ReturnType<typeof getSyncSourceUsageConfigMap>;
} & Partial<Required<ReturnType<typeof useNodeLinksByDirection>['data']>> &
  Partial<Required<ReturnType<typeof useTimeNodeOverviewMetrics>['data']>>) => {
  // MODE:  time transfer (syncing on links)
  // local sync (syncing on a local sync source)
  // network source (syncing on a local source GNSS/PPS, but not in local sync)
  // extended holdover (in HOLDOVER, but has an enabled frequency source)
  // holdover
  // free running (in holdover, has never been stable)

  const selectedLink = incomingLinkMetrics?.find(lt => lt?.selected);
  if (G.isNotNullable(selectedLink)) {
    return 'Time transfer';
  }
  const selectedSyncSourceType = Object.entries(syncSourceSelections ?? {})?.find(([, selected]) => selected)?.[0];
  if (G.isNotNullable(selectedSyncSourceType) && EXTERNAL_SYNC_SOURCES.indexOf(selectedSyncSourceType as any)) {
    return usageConfig[selectedSyncSourceType as ValueOf<typeof SYNC_SOURCE_NAMES>] === SyncSourceUsage.LocalOnly
      ? 'Local sync'
      : 'Network source';
  }
  if ((statuses?.filteredControlState ?? statuses?.controlState) === CONTROL_STATES.Holdover) {
    if (statuses?.hasNodeTimeInStable === false && statuses?.nodeTimeSinceUnstable > statuses.hiccUptime) {
      return 'Free running';
    }
    return usageConfig?.sync_in === SyncSourceUsage.Selectable ? 'Extended holdover' : 'Holdover';
  }

  return null;
};
