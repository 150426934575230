import React, { useCallback, useEffect, useMemo } from 'react';
import { Control, FormProvider, useController, useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';
import { merge } from 'ts-deepmerge';
import { z } from 'zod';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import { Box, Paper } from '@material-ui/core';
import { F, G } from '@mobily/ts-belt';
import {
  CheckboxField,
  getSelectOptionsFromSchema,
  InventoryKinds,
  StatusBox,
  TextField,
  useFormStyles,
  useInventoryWatch,
  useTimeTransferConfig,
  useTextFieldController,
  useTimeTransferConfigUpdate,
  usePermission,
  useSubmitButtonProps,
  k8sConfigApiRef,
  getDuplicatedSyncSources,
  useSubmitConfirmation,
  ConfirmSubmitButton,
} from '@netinsight/management-app-common-react';
import {
  TIMETRANSFER_DEFAULT_CONFIG,
  PPSInConfigSchema,
  SyncInputUsage,
  DEFAULT_PPS_PTP_CLOCK_QUALITY,
  PtpClockQuality as PtpClockQualityType,
} from '@netinsight/crds-timetransfer';
import { SyncSourceUsage } from '@netinsight/syncd-schema';
import { PtpClockQuality } from '../../components/PtpClockQuality';
import { useApi } from '@backstage/core-plugin-api';
import { SyncdStatus } from '../../../../../../types/sync';
import { DuplicatedSyncSourcePriorityWarnings } from '../../../../../../components/DuplicatedSyncSourcePriorityWarnings';

type PPSInFormValues = z.infer<typeof PPSInConfigSchema>;

const DefaultPPSInFormValues: PPSInFormValues = {
  usage: SyncSourceUsage.NonSelectable,
  priority: TIMETRANSFER_DEFAULT_CONFIG.ppsIn!.priority!,
  delayCompensation: 0,
  useAsReference: false,
  ptpClockQuality: DEFAULT_PPS_PTP_CLOCK_QUALITY,
};

export const PPSIn = ({ nodeId }: { nodeId: string }) => {
  const { state: syncdStatuses, loading: isLoadingSyncdStatuses } = useInventoryWatch<SyncdStatus>({
    nodeId,
    kind: InventoryKinds.SyncStatus,
  });
  const { data: config, isLoading, error } = useTimeTransferConfig(nodeId);
  const { trigger: updateConfig, permission: updateConfigPermission } = useTimeTransferConfigUpdate(nodeId);
  const styles = useFormStyles();
  const initialFormValues = useMemo(() => {
    const result = merge(DefaultPPSInFormValues, config?.ppsIn ?? {});
    // remove existing auto values
    if (result.ptpClockQuality?.clockAccuracy === -1) {
      result.ptpClockQuality.clockAccuracy = DEFAULT_PPS_PTP_CLOCK_QUALITY.clockAccuracy;
    }
    if (result.ptpClockQuality?.timeSource === -1) {
      result.ptpClockQuality.timeSource = DEFAULT_PPS_PTP_CLOCK_QUALITY.timeSource;
    }
    return result;
  }, [config]);
  const prevFormValues = usePrevious(initialFormValues);

  const formProps = useForm<PPSInFormValues>({
    mode: 'onChange',
    defaultValues: initialFormValues,
  });
  const { control, reset, handleSubmit, formState } = formProps;
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updateConfigPermission);
  const buttonProps = useSubmitButtonProps({ permission, formState });

  useEffect(() => {
    if (!F.equals(prevFormValues, initialFormValues)) {
      reset(initialFormValues);
    }
  }, [initialFormValues, prevFormValues, reset]);

  const useAsReferenceProps = useController({
    control,
    name: 'useAsReference',
  });
  const { field: usageFieldProps } = useTextFieldController({
    control,
    name: 'usage',
    label: 'Usage',
  });
  const { field: ppsInPriorityInputProps } = useTextFieldController({
    control,
    name: 'priority',
    schema: PPSInConfigSchema.shape.priority,
    label: 'Priority',
    placeholder: `${TIMETRANSFER_DEFAULT_CONFIG.ppsIn.priority}`,
  });

  const { field: ppsInDelayInputProps } = useTextFieldController({
    control,
    name: 'delayCompensation',
    schema: PPSInConfigSchema.shape.delayCompensation,
    label: 'Delay compensation (ns)',
  });

  const handleResult = useCallback(
    async (newConfig: PPSInFormValues) => {
      return updateConfig(existingConfig => ({
        ...existingConfig,
        ppsIn: newConfig,
      }));
    },
    [updateConfig],
  );

  const configApi = useApi(k8sConfigApiRef);

  const confirmationCallback = useCallback(
    async (submitValues: PPSInFormValues) => {
      const duplicates = await getDuplicatedSyncSources({
        nodeId,
        sourceKey: 'ppsIn',
        submitValues,
        configApi,
      });

      if (!duplicates || !duplicates.length) return null; // Early return for readability
      return <DuplicatedSyncSourcePriorityWarnings duplicates={duplicates} nodeId={nodeId} />;
    },
    [configApi, nodeId],
  );

  const { onSubmit, onSubmitConfirmed, onCanceled, isCheckingConfirmation, confirmationMessage } =
    useSubmitConfirmation({
      confirmationCallback,
      submitCallback: handleResult,
    });

  if (isLoading || isLoadingPermission) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
        {config?.ppsIn?.usage !== SyncSourceUsage.NonSelectable || config?.ppsIn?.useAsReference === true ? (
          <StatusBox
            isLoading={isLoadingSyncdStatuses}
            statuses={[['Status', syncdStatuses?.pps_in_active ? 'Active' : 'Inactive']]}
            showToggle={false}
          />
        ) : null}
        <div className={styles.formRow}>
          <TextField {...usageFieldProps} select SelectProps={{ native: true }}>
            {getSelectOptionsFromSchema(SyncInputUsage)}
          </TextField>
          <TextField {...ppsInPriorityInputProps} id="ppsInPriority" InputLabelProps={{ shrink: true }} />
        </div>
        <CheckboxField
          label="Use as reference"
          description={PPSInConfigSchema.shape.useAsReference.description}
          fieldProps={useAsReferenceProps}
        />
        <TextField {...ppsInDelayInputProps} />

        <Paper className={styles.formSection}>
          <PtpClockQuality
            control={control as Control<{ ptpClockQuality: PtpClockQualityType }>}
            defaultValues={DEFAULT_PPS_PTP_CLOCK_QUALITY}
          />
        </Paper>
        <Box>
          <ConfirmSubmitButton
            data-testid="btn-submit"
            confirmationMessage={confirmationMessage}
            onConfirmed={onSubmitConfirmed}
            onCanceled={onCanceled}
            {...buttonProps}
            disabled={buttonProps.disabled || isCheckingConfirmation || G.isNotNullable(confirmationMessage)}
          />
        </Box>
      </form>
    </FormProvider>
  );
};
