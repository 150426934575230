import React, { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, makeStyles, Paper, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import type { NetworkNamespaceConfig } from '../../../../../../types/nodes';
import { NodeManagerRoutesEntry } from './NodeManagerRouteEntry';
import { buttonPropsFromPermission, PermissionResult } from '@netinsight/management-app-common-react';

interface NodeManagerRoutesConfigProps {
  index: number;
  name: string;
  permission: PermissionResult;
}

const useStyles = makeStyles(theme => ({
  routeTableContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: theme.spacing(3),
  },
  routeTable: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr) 6rem',
    gap: theme.spacing(2, 3),
    ['&:empty']: {
      display: 'none',
    },
  },
  routeTableHeader: {
    fontWeight: 'bold',
  },
}));

export const NodeManagerRoutesConfig = ({ index, name, permission }: NodeManagerRoutesConfigProps) => {
  const { control } = useFormContext<NetworkNamespaceConfig>();
  const { fields, append, remove } = useFieldArray({ control, name: `networks.${index}.routes` });
  const handleAddRoute = useCallback(() => {
    append({ network: '', gateway: '' });
  }, [append]);
  const styles = useStyles();
  return (
    <Paper className={styles.routeTableContainer}>
      <Typography variant="h6" color="textSecondary" id={`${name || 'default'}-route-table`}>
        Routing Configuration
      </Typography>
      <div className={styles.routeTable} data-testid={`namespace-${index}-route-table-config`}>
        {fields.length > 0 ? (
          <>
            <Typography
              id={`route-table-${index}-label-network`}
              variant="body1"
              component="span"
              color="textSecondary"
              className={styles.routeTableHeader}
            >
              Network
            </Typography>
            <Typography
              id={`route-table-${index}-label-gateway`}
              variant="body1"
              component="span"
              color="textSecondary"
              className={styles.routeTableHeader}
            >
              Gateway
            </Typography>
            <span />
          </>
        ) : null}
        {fields.map((field, fieldIndex) => (
          <NodeManagerRoutesEntry
            key={field.id}
            namespaceIndex={index}
            index={fieldIndex}
            onRemove={remove}
            permission={permission}
          />
        ))}
      </div>
      <div>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<AddIcon />}
          onClick={handleAddRoute}
          data-testid="btn-add-route"
          {...buttonPropsFromPermission(permission)}
        >
          Add Route
        </Button>
      </div>
    </Paper>
  );
};
