import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { merge } from 'ts-deepmerge';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Paper } from '@material-ui/core';
import {
  GNSSConfigSchema,
  SyncInputUsage,
  PtpClockQuality as PtpClockQualityType,
  DEFAULT_GNSS_PTP_CLOCK_QUALITY,
  DEFAULT_GNSS_CONTROLLER_CONFIG,
  GnssConfig,
  GNSSControllerConfigSchema,
  GNSSPositionSchema,
  validateGnssControllerConfig,
  validateGnssPosition,
  TIMETRANSFER_DEFAULT_CONFIG,
} from '@netinsight/crds-timetransfer';
import { Control, FormProvider, useController, useForm } from 'react-hook-form';
import { D, F, G } from '@mobily/ts-belt';
import {
  CheckboxField,
  ConfirmSubmitButton,
  getDuplicatedSyncSources,
  getSelectOptionsFromSchema,
  k8sConfigApiRef,
  TextField,
  useFormStyles,
  usePermission,
  useSubmitButtonProps,
  useSubmitConfirmation,
  useTextFieldController,
  useTimeTransferConfigUpdate,
  validateRequireAllFieldsOrNone,
} from '@netinsight/management-app-common-react';
import usePrevious from 'react-use/lib/usePrevious';
import { PtpClockQuality } from '../../components/PtpClockQuality';
import { GnssControllerConfigForm } from './GnssControllerConfigForm';
import { useApi } from '@backstage/core-plugin-api';
import { DuplicatedSyncSourcePriorityWarnings } from '../../../../../../components/DuplicatedSyncSourcePriorityWarnings';

export type GnssConfigFormProps = {
  config: GnssConfig;
  nodeId: string;
};

const FormSchema = z.object({
  ...GNSSConfigSchema.shape,
  controller: GNSSControllerConfigSchema.extend({
    position: z
      .object({
        lat: GNSSPositionSchema.shape.lat.optional(),
        lon: GNSSPositionSchema.shape.lon.optional(),
        altMSL: GNSSPositionSchema.shape.altMSL.optional(),
      })
      .optional()
      .superRefine(validateRequireAllFieldsOrNone(GNSSPositionSchema.shape))
      .superRefine(validateGnssPosition),
  })
    .optional()
    .superRefine(validateGnssControllerConfig),
});

const DEFAULT_GNSS_CONFIG = {
  priority: TIMETRANSFER_DEFAULT_CONFIG.gnss!.priority!,
  ptpClockQuality: DEFAULT_GNSS_PTP_CLOCK_QUALITY,
  controller: DEFAULT_GNSS_CONTROLLER_CONFIG,
};

export const GnssConfigForm: FunctionComponent<GnssConfigFormProps> = ({ config, nodeId }) => {
  const styles = useFormStyles();

  const defaultValues: Required<GnssConfig> = useMemo(() => {
    const result = merge(DEFAULT_GNSS_CONFIG, (config ?? {}) as Required<GnssConfig>);
    // Remove existing auto values
    if (result.ptpClockQuality.clockAccuracy === -1) {
      result.ptpClockQuality.clockAccuracy = DEFAULT_GNSS_PTP_CLOCK_QUALITY.clockAccuracy;
    }
    if (result.ptpClockQuality.timeSource === -1) {
      result.ptpClockQuality.timeSource = DEFAULT_GNSS_PTP_CLOCK_QUALITY.timeSource;
    }
    return result;
  }, [config]);

  const prevDefaultValues = usePrevious(defaultValues);

  const formProps = useForm<GnssConfig>({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(FormSchema),
  });
  const { control, handleSubmit, reset, formState } = formProps;
  useEffect(() => {
    if (!F.equals(prevDefaultValues, defaultValues)) {
      reset(defaultValues, { keepSubmitCount: true, keepIsSubmitted: true, keepValues: true });
    }
  }, [defaultValues, prevDefaultValues, reset]);

  const { trigger: updateConfig, permission: updateConfigPermission } = useTimeTransferConfigUpdate(nodeId);
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updateConfigPermission);
  const buttonProps = useSubmitButtonProps({ permission, formState });
  const handleFormSubmit = useCallback(
    async (updatedConfig: GnssConfig) => {
      // GAS-5715 - Set masking angle to (default) 15 degrees until we support setting it again.
      if (updatedConfig.controller) {
        updatedConfig.controller.maskingAngle = 15;
      }
      const updatedPosition = updatedConfig?.controller?.position;
      if (
        G.isNotNullable(updatedPosition) &&
        (D.isEmpty(updatedPosition) ||
          (updatedPosition.lat === 0 && updatedPosition.lon === 0) ||
          (G.isNullable(updatedPosition.lat) &&
            G.isNullable(updatedPosition.lon) &&
            G.isNullable(updatedPosition.altMSL)))
      ) {
        updatedConfig.controller!.position = undefined;
      }

      await updateConfig(existingConfig => ({
        ...existingConfig,
        gnss: updatedConfig,
      }));
    },
    [updateConfig],
  );

  const configApi = useApi(k8sConfigApiRef);

  const confirmationCallback = useCallback(
    async (submitValues: GnssConfig) => {
      const duplicates = await getDuplicatedSyncSources({
        nodeId,
        sourceKey: 'gnss',
        submitValues,
        configApi,
      });
      if (!duplicates || !duplicates.length) return null; // Early return for readability
      return <DuplicatedSyncSourcePriorityWarnings duplicates={duplicates} nodeId={nodeId} />;
    },
    [configApi, nodeId],
  );

  const { onSubmit, onSubmitConfirmed, onCanceled, isCheckingConfirmation, confirmationMessage } =
    useSubmitConfirmation({
      confirmationCallback,
      submitCallback: handleFormSubmit,
    });

  const handleReset = useCallback(() => {
    reset(DEFAULT_GNSS_CONFIG);
  }, [reset]);

  const { field: usageFieldProps } = useTextFieldController({
    control,
    name: 'usage',
    label: 'Usage',
  });
  const useAsReferenceProps = useController({
    name: 'useAsReference',
    control,
  });
  const { field: priorityInputProps } = useTextFieldController({
    control,
    name: 'priority',
    label: 'Priority',
    schema: FormSchema.shape.priority,
  });

  const { field: dedlayInputProps } = useTextFieldController({
    control,
    name: 'delayNs',
    label: 'Delay compensation (ns)',
    schema: FormSchema.shape.delayNs,
  });

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
        <div className={styles.formRow}>
          <TextField {...usageFieldProps} select SelectProps={{ native: true }}>
            {getSelectOptionsFromSchema(SyncInputUsage)}
          </TextField>
          <TextField {...priorityInputProps} />
        </div>
        <CheckboxField
          label="Use as reference"
          description={GNSSConfigSchema.shape.useAsReference.description ?? ''}
          fieldProps={useAsReferenceProps}
        />
        <TextField {...dedlayInputProps} />
        <Paper className={styles.formSection} component="fieldset">
          <PtpClockQuality
            control={control as Control<{ ptpClockQuality: PtpClockQualityType }>}
            defaultValues={DEFAULT_GNSS_PTP_CLOCK_QUALITY}
          />
        </Paper>
        <GnssControllerConfigForm nodeId={nodeId} permission={permission} />
        <div className={styles.buttonContainer}>
          <ConfirmSubmitButton
            data-testid="btn-apply-gnss-config"
            confirmationMessage={confirmationMessage}
            onConfirmed={onSubmitConfirmed}
            onCanceled={onCanceled}
            {...buttonProps}
            disabled={buttonProps.disabled || isCheckingConfirmation || G.isNotNullable(confirmationMessage)}
          />
          <Button
            type="button"
            onClick={handleReset}
            size="large"
            variant="outlined"
            color="default"
            data-testid="btn-reset-gnss-config"
            disabled={buttonProps.disabled}
          >
            Reset
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
