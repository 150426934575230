import { useCallback } from 'react';
import useSWR, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { metricsApiRef } from '@netinsight/management-app-common-react';
import {
  groupMetricsFromPromise,
  groupMetricsFromPromiseBy2Levels,
  LinkState,
} from '@netinsight/management-app-common-api';

export const useSingleLinkMetrics = (linkId: string, nodeIds: string[], swrConfig?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);
  const fetcher = useCallback(
    async ([linkIdParam, nodeIdsParam]: [string, string[], string]) => {
      const nodeIdsQuery = nodeIdsParam.join('|');
      const [rttResult, linkTimeErrorResult, linkStableResult, linkStateResult, nodeStableResult] =
        await Promise.allSettled([
          metricsApi.instantQuery({
            query: `avg_over_time(neti_hicc_multilink_filtered_rtt{link_id="${linkIdParam}"}[30s])`,
          }),
          metricsApi.instantQuery({
            query: `avg_over_time(neti_hicc_multilink_link_time_error{link_id="${linkIdParam}"}[30s])`,
          }),
          metricsApi.instantQuery({ query: `last_over_time(neti_hicc_link_stable{link_id="${linkIdParam}"}[60s])` }),
          metricsApi.instantQuery({
            query: `last_over_time(neti_link_state_state{tcp="client", link_id="${linkIdParam}"}[60s])`,
          }),

          metricsApi.instantQuery({
            query: `last_over_time(neti_hicc_nodestability_is_stable{nodeid=~"${nodeIdsQuery}"}[60s])`,
          }),
        ]);
      const rtts = groupMetricsFromPromiseBy2Levels(
        rttResult,
        m => m.link_id,
        m => m.nodeid,
        val => parseFloat(val),
      );
      const linkTimeErrors = groupMetricsFromPromiseBy2Levels(
        linkTimeErrorResult,
        m => m.link_id,
        m => m.nodeid,
        val => parseFloat(val),
      );
      const linkStable = groupMetricsFromPromiseBy2Levels(
        linkStableResult,
        m => m.link_id,
        m => m.nodeid,
        val => Boolean(parseInt(val, 10)),
      );
      const linkState = groupMetricsFromPromise(
        linkStateResult,
        m => m.link_id,
        val => parseInt(val, 10) as LinkState,
      );
      const nodeStable = groupMetricsFromPromise(
        nodeStableResult,
        m => m.nodeid,
        val => Boolean(parseInt(val, 10)),
      );

      return {
        rtts,
        linkTimeErrors,
        linkStable,
        linkState,
        nodeStable,
      };
    },
    [metricsApi],
  );
  return useSWR([linkId, nodeIds, 'useSingleLinkMetrics'], fetcher, swrConfig);
};
