import React, { FunctionComponent } from 'react';
import { Control, useController } from 'react-hook-form';
import { CheckboxField } from '@netinsight/management-app-common-react';
import { SynceConfigFormValues } from './types';
import { Typography } from '@material-ui/core';
import { InterfaceInfo } from '../../../../../../types/nodes';

export const SynceConfigEntry: FunctionComponent<{
  control: Control<SynceConfigFormValues>;
  index: number;
  field: InterfaceInfo;
}> = ({ control, index, field }) => {
  const checkboxFieldProps = useController({
    control,
    name: `interfaces.${index}.usage.syncE`,
  });
  return (
    <div>
      <Typography variant="h6" component="h6">
        {field.displayName}
      </Typography>
      <CheckboxField
        fieldProps={checkboxFieldProps}
        fromValue={val => val ?? false}
        toValue={checked => checked ?? false}
        label="Enabled"
        description="Toggles SyncE for this interface. When enabled, ESMC is started."
      />
    </div>
  );
};
