import useSwr, { SWRConfiguration } from 'swr';
import { merge as deepMerge } from 'ts-deepmerge';
import { useApi } from '@backstage/core-plugin-api';
import { pipe } from '@mobily/ts-belt';
import { groupMetricsFromPromise } from '@netinsight/management-app-common-api';
import { booleanScale, metricsApiRef } from '@netinsight/management-app-common-react';
import { parseIntBase10 } from '../../utils/gnss';

const CacheKey = 'useGnssMetrics';

export const useGnssMetrics = (nodeId: string, swrConfig?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);
  return useSwr(
    [nodeId, CacheKey],
    async ([nodeIdParam]) => {
      const [
        systemEnabledResult,
        systemUsedCountResult,
        systemUnusedCountResult,
        satUsedResult,
        satSignalToNoiseRatioResult,
        satAzimuthResult,
        satElevationResult,
        satPrnResult,
        satTraimUsedResult,
        satTraimResidualNsResult,
      ] = await Promise.allSettled([
        metricsApi.instantQuery({ query: `neti_gnss_system_enabled{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_system_usedcount{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_system_notusedcount{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_satellite_used{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_satellite_SNR{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_satellite_azimuth{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_satellite_elevation{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_satellite_PRN{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_satellite_traim_used{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_satellite_traimres_ns{nodeid="${nodeIdParam}"}` }),
      ]);

      const systems = deepMerge(
        groupMetricsFromPromise(
          systemEnabledResult,
          metrics => metrics.system,
          val => ({ systemEnabled: pipe(val, parseIntBase10, Boolean, booleanScale) }),
        ),
        groupMetricsFromPromise(
          systemUsedCountResult,
          metrics => metrics.system,
          val => ({ systemUsedCount: parseIntBase10(val) }),
        ),
        groupMetricsFromPromise(
          systemUnusedCountResult,
          metrics => metrics.system,
          val => ({ systemUnusedCount: parseIntBase10(val) }),
        ),
      );
      const satellites = deepMerge(
        groupMetricsFromPromise(
          satUsedResult,
          metrics => metrics.identifier,
          val => ({ satUsed: pipe(val, parseIntBase10, Boolean, booleanScale) }),
        ),
        groupMetricsFromPromise(
          satSignalToNoiseRatioResult,
          metrics => metrics.identifier,
          val => ({ satSignalToNoiseRatio: parseFloat(val) }),
        ),
        groupMetricsFromPromise(
          satAzimuthResult,
          metrics => metrics.identifier,
          val => ({ satAzimuth: parseInt(val, 10) }),
        ),
        groupMetricsFromPromise(
          satElevationResult,
          metrics => metrics.identifier,
          val => ({ satElevation: parseInt(val, 10) }),
        ),
        groupMetricsFromPromise(
          satPrnResult,
          metrics => metrics.identifier,
          val => ({ satPrn: parseInt(val, 10) }),
        ),
        groupMetricsFromPromise(
          satTraimUsedResult,
          metrics => metrics.identifier,
          val => ({ satTraimUsed: pipe(val, parseIntBase10, Boolean, booleanScale) }),
        ),
        groupMetricsFromPromise(
          satTraimResidualNsResult,
          metrics => metrics.identifier,
          val => ({ satTraimResidualNs: parseFloat(val) }),
        ),
      );

      return {
        systems,
        satellites,
      };
    },
    swrConfig,
  );
};

export type UseGnssMetricsResultData = NonNullable<ReturnType<typeof useGnssMetrics>['data']>;
