/* eslint-disable no-nested-ternary */
import { D, F, G } from '@mobily/ts-belt';
import { CONTROL_STATES, NodeGraphHighlight, SYNC_SOURCE_NAMES } from '@netinsight/management-app-common-api';
import { DurationFormatter, isNullableOrEmpty } from '@netinsight/management-app-common-react';
import { scaleOrdinal } from 'd3-scale';
import {
  NodeMetricsViewModel,
  NodeSyncMetricsViewModel,
  SingleNodeMetrics,
  SingleNodeSyncMetrics,
} from '../../types/node-graph';
import { controlStateScale } from '../sync';
import { SYNC_SOURCE_LABELS } from '../../constants/sync';

const resetTimeNodeDom = (elem: HTMLElement) => {
  elem.className = 'tn';
  elem.innerHTML = '';
};

export const getTimeNodeDom = (nodeId: string) => {
  const result = document.createElement('div');
  result.classList.add('tn');
  result.dataset.nodeId = nodeId;
  return result;
};

type HighlightContext = {
  mode: NodeGraphHighlight;
  nodeMetrics?: SingleNodeMetrics;
  nodeSyncMetrics?: SingleNodeSyncMetrics;
};

const createHighlighter =
  <T>(
    selector: (context: HighlightContext) => T | undefined,
    renderer: (elem: HTMLElement, data: T, context: HighlightContext) => void,
  ) =>
  (elem: HTMLElement, context: HighlightContext) => {
    const data = selector(context);
    if (G.isNullable(data)) {
      return false;
    }
    renderer(elem, data, context);
    return true;
  };

type Highlighter = ReturnType<typeof createHighlighter>;

const chipHtml = ({
  classSuffix,
  icon,
  text,
  value,
  outlined,
}: {
  classSuffix?: string;
  icon?: string;
  text?: string;
  value?: string;
  outlined?: boolean;
}) => {
  const iconSegment = !isNullableOrEmpty(icon)
    ? `<div class="tn-chip-icon"><div class="tn-icon tn-icon-${icon}"></div></div>`
    : '';
  const textSegment = !isNullableOrEmpty(text) ? `<div class="tn-chip-text">${text}</div>` : '';
  const valueSegment = !isNullableOrEmpty(value) ? `<div class="tn-chip-value">${value}</div>` : '';

  return `<div class="tn-chip ${outlined ? 'tn-outlined' : ''} ${
    !isNullableOrEmpty(classSuffix) ? `tn-chip-${classSuffix}` : ''
  }">${iconSegment}${textSegment}${valueSegment}</div>`;
};

const syncInputHtml = ({
  type,
  priority,
  available,
  selected,
}: {
  type: string;
  priority?: number;
  available: boolean;
  selected: boolean;
}) => {
  return `<div class="tn-input tn-outlined">
  <div class="tn-input-type">${type}</div>
  <div class="tn-input-priority">${priority}</div>
  <div class="tn-input-status-icon" data-value="${available}"><div class="tn-icon tn-icon-${
    available ? 'check-circle' : 'circle'
  }"></div></div>
  <div class="tn-input-status-text" data-value="${available}">Available</div>
  <div class="tn-input-status-icon" data-value="${selected}"><div class="tn-icon tn-icon-${
    selected ? 'check-circle' : 'circle'
  }"></div></div>
  <div class="tn-input-status-text" data-value="${selected}">Selected</div>
</div>`;
};

const listHtml = (items: string[]) =>
  `<ul class="tn-list">${items
    .filter(item => !isNullableOrEmpty(item))
    .map(item => `<li>${item}</li>`)
    .join('')}</ul>`;

const controlStateClassScale = scaleOrdinal(
  [CONTROL_STATES.Holdover, CONTROL_STATES.AbsTimeSymmetric, CONTROL_STATES.RelTime, CONTROL_STATES.AbsTime],
  ['exclamation-circle', 'clock', 'clock', 'check-circle'],
).unknown('Unknown');

const highlighters: Record<Exclude<NodeGraphHighlight, 'none'>, Highlighter> = {
  alarms: createHighlighter(
    ({ nodeMetrics: { alarmSeverity } = {} }) => (G.isNotNullable(alarmSeverity) ? alarmSeverity : undefined),
    (elem, alarmSeverity, { mode }) => {
      elem.innerHTML = chipHtml({
        classSuffix: `${mode}-${alarmSeverity}`,
        text: alarmSeverity,
      });
    },
  ),
  'control-states': createHighlighter(
    ({ nodeMetrics: { filteredControlState, controlState } = {} }) => filteredControlState ?? controlState,
    (elem, controlState, { mode }) => {
      elem.innerHTML = chipHtml({
        icon: controlStateClassScale(controlState),
        text: controlStateScale(controlState),
        classSuffix: `${mode}-${controlState}`,
      });
    },
  ),
  'node-stability': createHighlighter(
    ({ nodeMetrics: { isStable } = {} }) => (G.isNotNullable(isStable) ? isStable : undefined),
    (elem, isStable, { mode }) => {
      const stableText = isStable ? 'stable' : 'unstable';
      elem.innerHTML = chipHtml({
        icon: isStable ? 'check-circle' : 'exclamation-circle',
        text: stableText,
        classSuffix: `${mode}-${stableText}`,
      });
    },
  ),
  'ntp-selections': createHighlighter(
    ({ nodeMetrics: { ntpRemote } = {} }) => (!isNullableOrEmpty(ntpRemote) ? ntpRemote : undefined),
    (elem, ntpRemote) => {
      elem.innerHTML = chipHtml({ text: ntpRemote, outlined: true });
    },
  ),
  references: createHighlighter(
    ({ nodeSyncMetrics: { references } = {} }) => {
      const enabledReferences = references?.filter(ref => ref.enabled);
      return G.isNotNullable(enabledReferences) && enabledReferences.length > 0 ? enabledReferences : undefined;
    },
    (elem, references) => {
      elem.innerHTML = listHtml(
        references.map(ref =>
          chipHtml({
            text: ref.label,
            outlined: true,
          }),
        ),
      );
    },
  ),
  'sync-inputs': createHighlighter(
    ({ nodeSyncMetrics: { inputs } = {} }) => {
      const selectedInputs = inputs?.filter(input => (input.selected || input.available) && input.name !== 'osc');
      return G.isNotNullable(selectedInputs) && selectedInputs?.length > 0 ? selectedInputs : undefined;
    },
    (elem, inputs) => {
      elem.innerHTML = listHtml(
        inputs.map(({ label, priority, available = false, selected = false }) =>
          syncInputHtml({
            type: label,
            priority,
            available,
            selected,
          }),
        ),
      );
    },
  ),
  'sync-outputs': createHighlighter(
    ({ nodeSyncMetrics: { outputs } = {} }) => {
      const enabledOutputs = outputs?.filter(output => output.enabled);
      return G.isNotNullable(enabledOutputs) && enabledOutputs.length > 0 ? enabledOutputs : undefined;
    },
    (elem, outputs) => {
      elem.innerHTML = listHtml(
        outputs.map(output =>
          chipHtml({
            text: `${output.label}${
              output.type === 'ptp' ? ` (${output.subType}${output.count > 1 ? ` × ${output.count}` : ''})` : ''
            }`,
            outlined: true,
          }),
        ),
      );
    },
  ),
  'time-offsets': createHighlighter(
    ({
      nodeMetrics: {
        timeErrors: {
          [SYNC_SOURCE_NAMES.gnss]: gnssError = NaN,
          [SYNC_SOURCE_NAMES.ppsIn]: ppsInError = NaN,
          [SYNC_SOURCE_NAMES.ptp1]: ptp1Error = NaN,
          [SYNC_SOURCE_NAMES.ptp2]: ptp2Error = NaN,
        } = {},
      } = {},
    }) => {
      const timeErrors = (
        [
          [ppsInError, SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.ppsIn]],
          [gnssError, SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.gnss]],
          [ptp1Error, SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.ptp1]],
          [ptp2Error, SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.ptp2]],
        ] as [number, string][]
      ).filter(([timeError]) => G.isNotNullable(timeError) && G.isNumber(timeError) && !isNaN(timeError));
      return timeErrors.length > 0 ? timeErrors : undefined;
    },
    (elem, timeErrors) => {
      elem.innerHTML = listHtml(
        timeErrors.map(([timeError, label]) =>
          chipHtml({
            text: label,
            value: DurationFormatter.fromSeconds(Math.abs(timeError)).toMicroSeconds(3) ?? '-',
            outlined: true,
          }),
        ),
      );
    },
  ),
};

export const updateHighlightedNodes = F.throttle(
  (
    cy: cytoscape.Core,
    cyDom: any,
    {
      mode,
      nodeMetrics,
      nodeSyncMetrics,
    }: {
      mode?: NodeGraphHighlight;
      nodeMetrics?: NodeMetricsViewModel;
      nodeSyncMetrics?: NodeSyncMetricsViewModel;
    },
  ) => {
    const cyDomElemMap = cyDom._node_dom as Record<string, HTMLElement> | undefined;
    if (G.isNullable(cyDomElemMap) || D.isEmpty(cyDomElemMap)) {
      return;
    }
    Object.entries(cyDomElemMap).forEach(([nodeId, elem]) => {
      if (G.isNullable(elem)) {
        return;
      }
      const graphNode = cy.$id(nodeId);
      if (G.isNullable(graphNode)) {
        resetTimeNodeDom(elem);
        return;
      }

      if (mode === 'none' || G.isNullable(mode)) {
        graphNode.removeClass('nobg');
        resetTimeNodeDom(elem);
        return;
      }

      const singleNodeMetrics = nodeMetrics?.[nodeId];
      const singleNodeSyncMetrics = nodeSyncMetrics?.[nodeId];
      if (G.isNullable(singleNodeMetrics) && G.isNullable(singleNodeSyncMetrics)) {
        graphNode.removeClass('nobg');
        resetTimeNodeDom(elem);
        return;
      }

      const context: HighlightContext = {
        mode,
        nodeMetrics: singleNodeMetrics,
        nodeSyncMetrics: singleNodeSyncMetrics,
      };
      const highlighter = highlighters[mode];
      if (G.isNotNullable(highlighter) && highlighter(elem, context)) {
        graphNode.addClass('nobg');
      } else {
        graphNode.removeClass('nobg');
        resetTimeNodeDom(elem);
      }
    });
  },
  100,
);
