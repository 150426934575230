import { scaleOrdinal } from 'd3-scale';
import React, { FunctionComponent, useEffect } from 'react';
import { usePrevious } from 'react-use';
import { SubmitHandler, useController, useFieldArray, useForm } from 'react-hook-form';
import { Button, Paper, Typography } from '@material-ui/core';
import { A, F } from '@mobily/ts-belt';
import {
  CheckboxField,
  getOptionsAndDescriptionsFromSchema,
  getSelectOptionFromPair,
  getSelectOptionsFromSchema,
  GrafanaLink,
  InputTooltip,
  PermissionResult,
  SliderField,
  TextField,
  useFormStyles,
  useSliderController,
  useSubmitButtonProps,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import { DEFAULT_GNSS_CONTROLLER_CONFIG, GNSSControllerConfigSchema } from '@netinsight/crds-timetransfer';
import { LogLevelSchema } from '@netinsight/crds-common';
import { ServiceLoggingConfiguration } from '../../../../../hooks';
import { LogLevelWarning } from './LogLevelWarning';
import { PtpLoggingFormSection } from './PtpLoggingFormSection';

const schema = LogLevelSchema.optional();

export type TimeNodeLogLevelFormProps = {
  nodeId: string;
  dashboards: Record<string, string>;
  initialValues: ServiceLoggingConfiguration;
  onSubmit: SubmitHandler<ServiceLoggingConfiguration>;
  permission: PermissionResult;
};

const TelemetryIntervalSliderOptions = [
  ['0s', 0],
  ['1s', 1],
  ['2s', 2],
  ['3s', 3],
  ['4s', 4],
  ['5s', 5],
  ['10s', 10],
  ['15s', 15],
  ['20s', 20],
  ['25s', 25],
  ['30s', 30],
  ['45s', 45],
  ['1m', 60],
  ['2m', 2 * 60],
  ['3m', 3 * 60],
  ['4m', 4 * 60],
  ['5m', 5 * 60],
  ['10m', 10 * 60],
  ['15m', 15 * 60],
  ['20m', 20 * 60],
  ['25m', 25 * 60],
  ['30m', 30 * 60],
  ['45m', 45 * 60],
  ['1h', 60 * 60],
  ['2h', 2 * 60 * 60],
  ['3h', 3 * 60 * 60],
  ['4h', 4 * 60 * 60],
  ['5h', 5 * 60 * 60],
  ['6h', 6 * 60 * 60],
  ['12h', 12 * 60 * 60],
  ['24h', 24 * 60 * 60],
  ['Off', -1],
] as const;

const getDefaultValueIndex = (defaultValue?: number) =>
  TelemetryIntervalSliderOptions.findIndex(([, val]) => val === (defaultValue ?? 0));

const SliderFieldProps = {
  min: 0,
  max: TelemetryIntervalSliderOptions.length - 1,
  step: 1,
  toValueScale: (val: number | number[]) =>
    scaleOrdinal(...A.unzip(TelemetryIntervalSliderOptions.map(([, value], index) => [index, value])))(
      Array.isArray(val) ? val[0] : val,
    ),
  fromValueScale: (val: number | number[]) =>
    scaleOrdinal(...A.unzip(TelemetryIntervalSliderOptions.map(([, value], index) => [value, index])))(
      Array.isArray(val) ? val[0] : val,
    ),
  labelScale: (val: number) =>
    scaleOrdinal(...A.unzip(TelemetryIntervalSliderOptions.map(([label], index) => [index, label])))(val),
  marks: TelemetryIntervalSliderOptions.map(([label], index) => ({ value: index, label: label })).filter(
    ({ value }) => value === TelemetryIntervalSliderOptions.length - 1 || value % 5 === 0,
  ),
};

export const TimeNodeLogLevelForm: FunctionComponent<TimeNodeLogLevelFormProps> = ({
  nodeId,
  dashboards,
  initialValues,
  onSubmit,
  permission,
}) => {
  const formStyles = useFormStyles();
  const { control, reset, handleSubmit, formState } = useForm<ServiceLoggingConfiguration>({
    mode: 'onChange',
    defaultValues: initialValues,
  });
  const buttonProps = useSubmitButtonProps({ permission, formState });
  const prevInitialValues = usePrevious(initialValues);
  useEffect(() => {
    if (!F.equals(prevInitialValues, initialValues)) {
      reset(initialValues, { keepSubmitCount: true, keepIsSubmitted: true, keepValues: true });
    }
  }, [reset, prevInitialValues, initialValues]);

  const { field: syncdSelectProps } = useTextFieldController({
    control,
    name: 'syncd',
    label: 'Syncd',
    schema,
    defaultValue: 'info',
  });
  const { field: hiccSelectProps } = useTextFieldController({
    control,
    name: 'hicc',
    label: 'HICC',
    schema,
    defaultValue: 'info',
  });
  const { field: nodeManagerSelectProps } = useTextFieldController({
    control,
    name: 'nodeManager',
    label: 'Node manager',
    schema,
    defaultValue: 'info',
  });

  const { field: gnssctldSelectProps } = useTextFieldController({
    control,
    name: 'gnssctld',
    label: 'GNSS controller',
    schema,
    defaultValue: 'info',
  });

  const tsProcTelemetryProps = useController({
    control,
    name: 'tsProcTelemetry',
  });

  const gnssFixTelemetryRateProps = useSliderController({
    control,
    name: 'gnssctldFixTelemetryRate',
    label: 'Fix',
    defaultValue: getDefaultValueIndex(DEFAULT_GNSS_CONTROLLER_CONFIG.telemetryRate?.fix),
    ...SliderFieldProps,
  });

  const gnssTraimTelemetryRateProps = useSliderController({
    control,
    name: 'gnssctldTraimTelemetryRate',
    label: 'T-RAIM',
    defaultValue: getDefaultValueIndex(DEFAULT_GNSS_CONTROLLER_CONFIG.telemetryRate?.traim),
    ...SliderFieldProps,
  });

  const gnssPerSatelliteTelemetryRateProps = useSliderController({
    control,
    name: 'gnssctldPerSatelliteTelemetryRate',
    label: 'Per satellite',
    defaultValue: getDefaultValueIndex(DEFAULT_GNSS_CONTROLLER_CONFIG.telemetryRate?.perSatellite),
    ...SliderFieldProps,
  });

  const gnssStatusTelemetryRateProps = useSliderController({
    control,
    name: 'gnssctldStatusTelemetryRate',
    label: 'Status',
    defaultValue: getDefaultValueIndex(DEFAULT_GNSS_CONTROLLER_CONFIG.telemetryRate?.status),
    ...SliderFieldProps,
  });

  const gnssSystemTelemetryRateProps = useSliderController({
    control,
    name: 'gnssctldSystemTelemetryRate',
    label: 'System',
    defaultValue: getDefaultValueIndex(DEFAULT_GNSS_CONTROLLER_CONFIG.telemetryRate?.system),
    ...SliderFieldProps,
  });

  const { fields: ptpTransmitterFields } = useFieldArray({
    control,
    name: 'ptpTransmitters',
  });

  const { fields: ptpReceiverFields } = useFieldArray({
    control,
    name: 'ptpReceivers',
  });

  const dashboardUrl = dashboards?.['Zyntai TimeNodes/container-logs'];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={formStyles.formContainer}>
      <Paper className={formStyles.formSection} component="fieldset">
        <Typography component="h6" variant="h6">
          Time transfer
        </Typography>
        <div className={formStyles.formGrid}>
          <TextField
            {...syncdSelectProps}
            fullWidth
            InputProps={{ startAdornment: <LogLevelWarning name={syncdSelectProps.name!} control={control} /> }}
          >
            {getSelectOptionsFromSchema(schema)}
          </TextField>
          <CheckboxField fieldProps={tsProcTelemetryProps} label="TS Proc Telemetry" />
          <div className={formStyles.formRow}>
            <GrafanaLink
              nodeIds={[nodeId]}
              containers={['syncd']}
              dashboardUrl={dashboardUrl}
              size="small"
              label="Logs"
            />
          </div>
        </div>
      </Paper>
      <Paper className={formStyles.formSection} component="fieldset">
        <Typography component="h6" variant="h6">
          HICC
        </Typography>
        <div className={formStyles.formGrid}>
          <TextField
            {...hiccSelectProps}
            fullWidth
            InputProps={{ startAdornment: <LogLevelWarning name={hiccSelectProps.name!} control={control} /> }}
          >
            {getOptionsAndDescriptionsFromSchema(schema)
              .filter(([val]) => val !== 'off')
              .map(getSelectOptionFromPair)}
          </TextField>
          <div className={formStyles.formRow}>
            <GrafanaLink
              nodeIds={[nodeId]}
              containers={['hicc']}
              dashboardUrl={dashboardUrl}
              size="small"
              label="Logs"
            />
          </div>
        </div>
      </Paper>
      <Paper className={formStyles.formSection} component="fieldset">
        <Typography component="h6" variant="h6">
          Node manager
        </Typography>
        <div className={formStyles.formGrid}>
          <TextField
            {...nodeManagerSelectProps}
            fullWidth
            InputProps={{ startAdornment: <LogLevelWarning name={nodeManagerSelectProps.name!} control={control} /> }}
          >
            {getSelectOptionsFromSchema(schema)}
          </TextField>
          <div className={formStyles.formRow}>
            <GrafanaLink
              nodeIds={[nodeId]}
              containers={['node-manager']}
              dashboardUrl={dashboardUrl}
              size="small"
              label="Logs"
            />
          </div>
        </div>
      </Paper>
      <Paper className={formStyles.formSection} component="fieldset">
        <Typography component="h6" variant="h6">
          GNSS
        </Typography>
        <div className={formStyles.formGrid}>
          <TextField
            {...gnssctldSelectProps}
            fullWidth
            InputProps={{ startAdornment: <LogLevelWarning name={gnssctldSelectProps.name!} control={control} /> }}
          >
            {getSelectOptionsFromSchema(schema)}
          </TextField>
        </div>
        <div className={formStyles.formRow}>
          <Typography variant="h6">
            Telemetry rate <InputTooltip text={GNSSControllerConfigSchema.shape.telemetryRate.description} />
          </Typography>
        </div>
        <SliderField {...gnssFixTelemetryRateProps} />
        <SliderField {...gnssPerSatelliteTelemetryRateProps} />
        <SliderField {...gnssStatusTelemetryRateProps} />
        <SliderField {...gnssSystemTelemetryRateProps} />
        <SliderField {...gnssTraimTelemetryRateProps} />
        <div className={formStyles.buttonContainer}>
          <GrafanaLink
            nodeIds={[nodeId]}
            containers={['gpsd', 'gnssctld']}
            dashboardUrl={dashboardUrl}
            size="small"
            label="Logs"
          />
        </div>
      </Paper>
      <Paper className={formStyles.formSection} component="fieldset">
        <Typography component="h6" variant="h6">
          PTP
        </Typography>
        {ptpTransmitterFields.map((item, index) => (
          <PtpLoggingFormSection
            control={control}
            key={item.id}
            prefix="ptpTransmitters"
            index={index}
            field={item}
            dashboardUrl={dashboardUrl}
            nodeId={nodeId}
          />
        ))}
        {ptpReceiverFields.map((item, index) => (
          <PtpLoggingFormSection
            control={control}
            key={item.id}
            prefix="ptpReceivers"
            index={index}
            field={item}
            dashboardUrl={dashboardUrl}
            nodeId={nodeId}
          />
        ))}
      </Paper>
      <div className={formStyles.buttonContainer}>
        <Button {...buttonProps} data-testid="btn-submit" />
      </div>
    </form>
  );
};
