export const getNodeId = (metrics: any) => metrics.nodeid;
export const getSyncSourceName = (metrics: any) => metrics.name;
export const getLinkId = (metrics: any) => metrics.link_id;
export const syncSourceSelectionMetadataMapper = (value: any, { type }: any) => ({
  type: type,
  selected: Boolean(parseInt(value, 10)),
});
export const linkSelectionMetadataMapper = (_: any, { peer_node_id, nodeid, clock_node_id }: any) => ({
  peerNodeId: peer_node_id,
  nodeId: nodeid,
  clockNodeId: clock_node_id,
});
export const linkStabilityMapper = (value: any) => ({
  isStable: Boolean(parseInt(value, 10)),
});
