import classNames from 'classnames';
import React, { FunctionComponent, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button, Typography, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import {
  DEFAULT_GNSS_CONTROLLER_CONFIG,
  GnssConfig,
  SupportedGnssCombinations,
  getGnssNamesFromFlag,
} from '@netinsight/crds-timetransfer';
import { useTableStyles } from '@netinsight/management-app-common-react';
import { F } from '@mobily/ts-belt';

const useStyles = makeStyles(
  theme => {
    const defaultBg = theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[200];
    const currentBg = theme.palette.type === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light;
    return {
      container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: theme.spacing(3),
        padding: theme.spacing(1, 2),
      },
      table: {
        ['& tr.current, & tr.current.default']: {
          backgroundColor: currentBg,
          ['& td, & td button']: {
            color: theme.palette.getContrastText(currentBg),
          },
          ['& td button']: {
            borderColor: theme.palette.getContrastText(currentBg),
          },
        },
        ['& tr.default:not(.current)']: {
          backgroundColor: defaultBg,
          ['& td button']: {
            borderColor: theme.palette.getContrastText(defaultBg),
            color: theme.palette.getContrastText(defaultBg),
          },
        },
      },
    };
  },
  { name: 'Neti' },
);

export type SystemNames = ReturnType<typeof getGnssNamesFromFlag>;

const isDefaultCombination = (flags: SystemNames) =>
  F.equals(
    [
      flags.includes('Beidou'),
      flags.includes('Galileo'),
      flags.includes('GLONASS'),
      flags.includes('GPS'),
      flags.includes('QZSS'),
    ],
    [
      DEFAULT_GNSS_CONTROLLER_CONFIG.useBeidou,
      DEFAULT_GNSS_CONTROLLER_CONFIG.useGalileo,
      DEFAULT_GNSS_CONTROLLER_CONFIG.useGLONASS,
      DEFAULT_GNSS_CONTROLLER_CONFIG.useGPS,
      DEFAULT_GNSS_CONTROLLER_CONFIG.useQZSS,
    ],
  );

export const GnssSupportedSystemsPanel: FunctionComponent<{
  onSystemSelected: (names: SystemNames) => void;
}> = ({ onSystemSelected }) => {
  const tableStyles = useTableStyles();
  const styles = useStyles();
  const columnNames: SystemNames = ['Beidou', 'Galileo', 'GLONASS', 'GPS', 'QZSS'];
  const { control } = useFormContext<GnssConfig>();
  const currentConfigValue = useWatch({ control, name: 'controller' });
  const isCurrentCombination = useCallback(
    (flags: SystemNames) =>
      F.equals(
        [
          flags.includes('Beidou'),
          flags.includes('Galileo'),
          flags.includes('GLONASS'),
          flags.includes('GPS'),
          flags.includes('QZSS'),
        ],
        [
          currentConfigValue?.useBeidou,
          currentConfigValue?.useGalileo,
          currentConfigValue?.useGLONASS,
          currentConfigValue?.useGPS,
          currentConfigValue?.useQZSS,
        ],
      ),
    [currentConfigValue],
  );
  return (
    <div className={styles.container}>
      <Typography variant="h6">Supported GNSS combinations</Typography>
      <table className={classNames(tableStyles.table, styles.table)}>
        <thead>
          <tr>
            {columnNames.map(cn => (
              <th key={cn}>{cn}</th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {SupportedGnssCombinations.map(flag => getGnssNamesFromFlag(flag)).map(fcns => (
            <React.Fragment key={fcns.join('|')}>
              <tr
                data-combination={fcns.join('|')}
                className={classNames({ default: isDefaultCombination(fcns), current: isCurrentCombination(fcns) })}
              >
                {columnNames.map(cns => (
                  <td className="icon" key={cns}>
                    {fcns.includes(cns) ? <CheckIcon /> : ''}
                  </td>
                ))}
                <td className="icon">
                  <Button variant="outlined" color="default" size="small" onClick={() => onSystemSelected(fcns)}>
                    Use
                  </Button>
                </td>
              </tr>
              {isDefaultCombination(fcns) ? (
                <tr
                  className={classNames({ default: isDefaultCombination(fcns), current: isCurrentCombination(fcns) })}
                >
                  <td className="icon" colSpan={columnNames.length + 1}>
                    (default)
                  </td>
                </tr>
              ) : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};
