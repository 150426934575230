import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { usePrevious } from 'react-use';
import { useSWRConfig } from 'swr';
import { InfoCard, Link } from '@backstage/core-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, CircularProgress, LinearProgress, makeStyles } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/CloseOutlined';
import { F, G } from '@mobily/ts-belt';
import { DynamicLinkNoiseParametersSchema, TSRate, TsRateDivisorMapping } from '@netinsight/crds-timetransfer';
import {
  buttonPropsFromPermission,
  ConfirmButton,
  NetiHeadingTypographyProps,
  usePermission,
  useFormStyles,
  useSnackbarHelper,
  validateRequireAllFieldsOrNone,
  PermissionResult,
  useSubmitButtonProps,
} from '@netinsight/management-app-common-react';
import { isValidationError, PersistedSyncLink, PersistedSyncLinkSchema } from '@netinsight/management-app-common-api';
import { LinkDetailContext } from '../../../constants/time-transfer';
import {
  useDefaultLinkOptions,
  useLinkDetailContextData,
  useLinkSelectedProfileIndices,
  useSingleLinkMetrics,
  useTTLinkDelete,
  useTTLinkUpdate,
} from '../../../hooks/time-transfer';
import {
  LinkEndpointsFormContent,
  LinkName,
  LinkDetailStatusBox,
  LinkBandwidthFormContent,
  LinkOptionsFormContent,
} from '../common';
import { LinkProfileFormTable } from './LinkProfileFormTable';
import { RefinementCtx, z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { merge } from 'ts-deepmerge';
import { LinkDetailContextValue } from '../../../types/time-transfer';
import { getCombinedLinkDetailMetrics } from '../../../utils/metrics';
import { isFieldErrors } from '../../../utils/time-transfer';

const useStyles = makeStyles(theme => ({
  endpointCardsContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'auto auto',
      gap: theme.spacing(3),
      ['& > fieldset']: {
        gridRow: '2 / 3',
      },
      ['& > [role="alert"]']: {
        gridRow: '1 / 2',
        gridColumn: '1 / -1',
      },
    },
    ['& fieldset']: {
      background: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      padding: theme.spacing(4, 3, 3),
      position: 'relative',
      ['& legend']: {
        transform: `translate(0, calc(100% + ${theme.spacing(2)}px))`,
      },
    },
  },
}));

const validateProfileRtt = (value: Pick<PersistedSyncLink, 'profiles'>, ctx: RefinementCtx) => {
  if (G.isNullable(value) || G.isNullable(value.profiles) || value.profiles.length === 0) {
    return;
  }
  value.profiles.forEach((prof, index) => {
    if (G.isNullable(prof.delayDifference) || prof.delayDifference === 0 || G.isNullable(prof.roundtripTime)) {
      return;
    }

    if (Math.abs(prof.delayDifference) > prof.roundtripTime) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'The absolute path diff value must not be greater than round-trip time',
        path: ['profiles', index, 'delayDifference'],
      });
    }
  });
};

const defaultDivisorValue = 4;

type LinkDetailFormMode = 'general' | 'endpoints' | 'profiles' | 'link-options';

const LinkProfileRemoveAllButton = ({
  onClick,
  isRemoving,
  permission,
}: {
  onClick: VoidFunction;
  isRemoving: boolean;
  permission: PermissionResult;
}) => {
  const { control, formState } = useFormContext<PersistedSyncLink>();
  const buttonProps = buttonPropsFromPermission(permission);
  const currentProfiles = useWatch({ control, name: 'profiles' });
  return (
    <ConfirmButton
      variant="contained"
      color="secondary"
      onClick={onClick}
      startIcon={isRemoving ? <CircularProgress size="1rem" /> : <RemoveIcon fontSize="small" />}
      data-testid="btn-remove-all-profiles"
      confirmation={`${currentProfiles.length} profile${currentProfiles.length === 1 ? '' : 's'} will be removed, do you want to continue?`}
      {...buttonProps}
      disabled={buttonProps.disabled || currentProfiles.length === 0 || formState.isSubmitting}
    >
      Remove all profiles
    </ConfirmButton>
  );
};

const SWR_CONFIG = { refreshInterval: 5_000 };

export const LinkDetailForm = ({ data, mode = 'general' }: { data: PersistedSyncLink; mode: LinkDetailFormMode }) => {
  const navigate = useNavigate();
  const linkId = data.id;
  const formStyles = useFormStyles();
  const styles = useStyles();
  const { mutate: globalMutate } = useSWRConfig();
  const { linkDetailContextValue, isLoading: isLoadingLinkDetailContextData } = useLinkDetailContextData();
  const defaultLinkOptions = useDefaultLinkOptions(data, linkDetailContextValue.globalLinkOptions ?? {});
  const { data: selectedProfileIndices, isLoading: isLoadingSelectedProfileIndices } = useLinkSelectedProfileIndices(
    linkId,
    SWR_CONFIG,
  );
  const { data: metrics, isLoading: isLoadingLinkMetrics } = useSingleLinkMetrics(
    data.id,
    [data.endpointA.node, data.endpointB.node],
    SWR_CONFIG,
  );

  const contextValue: LinkDetailContextValue = useMemo(
    () => ({
      ...linkDetailContextValue,
      combinedMetrics: getCombinedLinkDetailMetrics({
        data,
        selectedProfileIndices,
        metrics,
      }),
    }),
    [data, selectedProfileIndices, metrics, linkDetailContextValue],
  );

  const { trigger: deleteTTLink, permission: deleteTTLinkPermission } = useTTLinkDelete();
  const { trigger: updateTTLink, permission: updateTTLinkPermission } = useTTLinkUpdate();
  const { isLoading: isLoadingDeletePermission, ...deletePermissionResult } = usePermission(deleteTTLinkPermission);
  const { isLoading: isLoadingUpdatePermission, ...updatePermissionResult } = usePermission(updateTTLinkPermission);

  const { snackbar } = useSnackbarHelper();
  const schema = useMemo(() => {
    switch (mode) {
      case 'general': {
        return z.object({
          name: PersistedSyncLinkSchema.shape.name,
          packetSize: PersistedSyncLinkSchema.shape.packetSize,
          timestampRate: PersistedSyncLinkSchema.shape.timestampRate,
          timestampRateDivisor: PersistedSyncLinkSchema.shape.timestampRateDivisor,
        });
      }
      case 'endpoints': {
        return z.object({
          endpointA: PersistedSyncLinkSchema.shape.endpointA,
          endpointB: PersistedSyncLinkSchema.shape.endpointB,
          tcpServer: PersistedSyncLinkSchema.shape.tcpServer,
        });
      }
      case 'profiles': {
        return z
          .object({
            autoCalibration: PersistedSyncLinkSchema.shape.autoCalibration,
            profiles: PersistedSyncLinkSchema.shape.profiles,
            options: PersistedSyncLinkSchema.shape.options,
            trustPathDiff: PersistedSyncLinkSchema.shape.trustPathDiff,
          })
          .superRefine(validateProfileRtt);
      }
      case 'link-options': {
        return z.object({
          options: PersistedSyncLinkSchema.shape.options
            .unwrap()
            .omit({
              dynamicLinkNoise: true,
            })
            .extend({
              dynamicLinkNoise: DynamicLinkNoiseParametersSchema.optional().superRefine(
                validateRequireAllFieldsOrNone(DynamicLinkNoiseParametersSchema.shape),
              ),
            })
            .optional(),
        });
      }
      default:
        return PersistedSyncLinkSchema;
    }
  }, [mode]);

  const initialFormValues = useMemo(() => {
    const tsRate = {
      timestampRateDivisor:
        data?.timestampRateDivisor ??
        (data?.timestampRate ? TsRateDivisorMapping[data?.timestampRate as TSRate] : defaultDivisorValue),
    };
    return merge(data, tsRate);
  }, [data]);

  const formProps = useForm<PersistedSyncLink>({
    defaultValues: initialFormValues,
    mode: 'onChange',
    resolver: zodResolver(schema),
  });
  const { handleSubmit, reset, setError, setValue, formState } = formProps;
  const updateButtonProps = useSubmitButtonProps({ permission: updatePermissionResult, formState });

  const prevInitialValues = usePrevious(initialFormValues);
  useEffect(() => {
    if (!F.equals(prevInitialValues, initialFormValues)) {
      reset(initialFormValues);
    }
  }, [initialFormValues, prevInitialValues, reset]);
  const [isResetting, setResetting] = useState(false);
  const [isRemovingProfiles, setRemovingProfiles] = useState(false);

  const handleUpdate = useCallback(
    async (arg: PersistedSyncLink) => {
      await updateTTLink(
        { ...data, ...arg },
        {
          onSuccess: async () => {
            snackbar.notifySuccess('Update');
            await globalMutate([linkId, 'getTimeTransferLink']);
          },
          onError: err => {
            if (isValidationError(err) && isFieldErrors(err.details)) {
              Object.entries(err.details).forEach(([name, error]) => setError(name as keyof PersistedSyncLink, error));
              return;
            }
            snackbar.notifyError('Update', err.response, err.message);
          },
          throwOnError: false,
        },
      );
    },
    [updateTTLink, data, linkId, snackbar, globalMutate, setError],
  );
  const handleDelete = useCallback(async () => {
    if (!data) {
      return;
    }
    await deleteTTLink(data, {
      onSuccess: () => {
        snackbar.notifySuccess('Remove');
        navigate(`/network/`);
      },
      onError: err => snackbar.notifyError('Remove', err.response, err.message),
    });
  }, [data, deleteTTLink, navigate, snackbar]);

  const handleResetLinkOptions = useCallback(() => {
    const fieldOpts = { shouldDirty: true, shouldTouch: true };
    setValue('options.dynamicLinkChangeThreshold', defaultLinkOptions.dynamicLinkChangeThreshold, fieldOpts);
    setValue(
      'options.dynamicLinkChangeThresholdParams',
      defaultLinkOptions.dynamicLinkChangeThresholdParams ?? {},
      fieldOpts,
    );
    setValue('options.linkChangeThreshold', defaultLinkOptions.linkChangeThreshold, fieldOpts);
    setValue('options.linkNoise', defaultLinkOptions.linkNoise, fieldOpts);
    setValue('options.dynamicLinkNoise', defaultLinkOptions.dynamicLinkNoise, fieldOpts);
    setValue('options.useDynamicLinkNoise', defaultLinkOptions.useDynamicLinkNoise, fieldOpts);
    setValue('options.profileMaxRttMismatch', defaultLinkOptions.profileMaxRttMismatch, fieldOpts);
    setResetting(true);
    setTimeout(async () => {
      await handleSubmit(handleUpdate)();
      setResetting(false);
    }, 100);
  }, [setValue, setResetting, handleSubmit, handleUpdate, defaultLinkOptions]);

  const handleRemoveAllProfiles = useCallback(() => {
    setValue('profiles', [], { shouldDirty: true, shouldTouch: true });
    setRemovingProfiles(true);
    setTimeout(async () => {
      await handleSubmit(handleUpdate)();
      setRemovingProfiles(false);
    }, 100);
  }, [setValue, setRemovingProfiles, handleSubmit, handleUpdate]);

  const isLoading =
    isLoadingLinkDetailContextData ||
    isLoadingLinkMetrics ||
    isLoadingSelectedProfileIndices ||
    isLoadingDeletePermission ||
    isLoadingUpdatePermission;

  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <LinkDetailContext.Provider value={contextValue}>
      <FormProvider {...formProps}>
        <form
          onSubmit={handleSubmit(handleUpdate)}
          className={classNames([formStyles.formContainer, { [styles.endpointCardsContainer]: mode === 'endpoints' }])}
          data-testid="link-detail-form"
        >
          {mode === 'general' ? (
            <>
              <LinkDetailStatusBox data={data} showEndpoints globalLinkOptions={defaultLinkOptions} />
              <InfoCard title="General" cardClassName={formStyles.formContainer}>
                <LinkName permission={updatePermissionResult} />
                <LinkBandwidthFormContent />
              </InfoCard>
            </>
          ) : null}
          {mode === 'endpoints' ? (
            <>
              <LinkDetailStatusBox data={data} globalLinkOptions={defaultLinkOptions} />
              <LinkEndpointsFormContent />
            </>
          ) : null}
          {mode === 'profiles' ? (
            <>
              <LinkDetailStatusBox data={data} globalLinkOptions={defaultLinkOptions} showEndpoints />
              <InfoCard
                title="Profiles"
                titleTypographyProps={NetiHeadingTypographyProps.h2}
                cardClassName={formStyles.formContainer}
              >
                <LinkProfileFormTable
                  data={data}
                  defaultLinkOptions={defaultLinkOptions}
                  selectedProfileIndices={selectedProfileIndices}
                  permission={updatePermissionResult}
                />
              </InfoCard>
            </>
          ) : null}
          {mode === 'link-options' ? (
            <>
              <LinkDetailStatusBox data={data} globalLinkOptions={defaultLinkOptions} showEndpoints />
              <InfoCard
                title="Options"
                titleTypographyProps={NetiHeadingTypographyProps.h2}
                cardClassName={formStyles.formContainer}
              >
                <LinkOptionsFormContent
                  defaultOptions={defaultLinkOptions}
                  subtitle={
                    <>
                      These settings will override{' '}
                      <Link to="/system/global-defaults/link-options">global link options</Link>.
                    </>
                  }
                  headingLevel={3}
                />
              </InfoCard>
            </>
          ) : null}
          <div className={formStyles.buttonContainer}>
            <Button
              data-testid="btn-save-link"
              {...updateButtonProps}
              startIcon={!isResetting && !isRemovingProfiles ? updateButtonProps.startIcon : null}
            />
            {mode === 'link-options' ? (
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="button"
                data-testid="btn-reset-link-options"
                onClick={handleResetLinkOptions}
                startIcon={isResetting ? updateButtonProps.startIcon : null}
                disabled={updateButtonProps.disabled}
                title={updateButtonProps.title}
              >
                Reset to global defaults
              </Button>
            ) : null}
            {mode === 'profiles' ? (
              <LinkProfileRemoveAllButton
                onClick={handleRemoveAllProfiles}
                isRemoving={isRemovingProfiles}
                permission={updatePermissionResult}
              />
            ) : null}
          </div>
          {mode === 'general' ? (
            <div className={formStyles.buttonContainer}>
              <ConfirmButton
                confirmation="This link will be removed, do you want to continue?"
                variant="contained"
                color="secondary"
                size="large"
                type="button"
                data-testid="btn-remove-link"
                onClick={handleDelete}
                startIcon={<RemoveIcon />}
                {...buttonPropsFromPermission(deletePermissionResult)}
              >
                Remove
              </ConfirmButton>
            </div>
          ) : null}
        </form>
      </FormProvider>
    </LinkDetailContext.Provider>
  );
};
