import { Page, TabbedLayout } from '@backstage/core-components';
import React from 'react';
import { HeaderWithAlarms } from '../../components/HeaderWithAlarms/HeaderWithAlarms';
import { GroupCalibrationPage } from './GroupCalibrationPage';
import { NestedTabLayout } from '@netinsight/management-app-common-react';
import { Navigate, Route, Routes } from 'react-router';
import { CalibrationGroups } from './CalibrationTab/GroupsTab/CalibrationGroups';
import { NodeGraphPageRedirector } from '../NodeGraph';
import { SyncInputsPage } from '../TimeNodes/TimeNodeDetail/tabs/InputsTab/SyncInputsPage';
import { SyncRegionStatus } from './SyncRegionStatus';
import { SyncRegionConfig } from './SyncRegionConfig';
import { LinksPage } from './LinksPage';

const tabStyle = {
  height: '4.25rem',
};

export const TimeNetworkPage = () => {
  return (
    <Page themeId="home">
      <HeaderWithAlarms title="Zyntai Time Network" />
      <TabbedLayout>
        <TabbedLayout.Route path="/" title="Links" tabProps={{ id: 'time-nodes-links-tab', style: tabStyle }}>
          <LinksPage />
        </TabbedLayout.Route>
        <TabbedLayout.Route
          path="/sources"
          title="Sync Sources"
          tabProps={{ id: 'time-nodes-sync-sources', style: tabStyle }}
        >
          <SyncInputsPage />
        </TabbedLayout.Route>
        <TabbedLayout.Route
          path="/sync-regions"
          title="Sync regions"
          tabProps={{ id: 'sync-regions-tab', style: tabStyle }}
        >
          <NestedTabLayout>
            <NestedTabLayout.Route index title="Index" element={<Navigate to="status" />} />
            <NestedTabLayout.Route path="status" title="Status" element={<SyncRegionStatus />} />
            <NestedTabLayout.Route path="config" title="Config" element={<SyncRegionConfig />} />
          </NestedTabLayout>
        </TabbedLayout.Route>
        <TabbedLayout.Route
          path="/graph/"
          title="Topology"
          tabProps={{ id: 'time-nodes-topology-tab', style: tabStyle }}
        >
          <Routes>
            <Route path=":graphId?" element={<NodeGraphPageRedirector />} />
          </Routes>
        </TabbedLayout.Route>
        <TabbedLayout.Route
          path="/calibration"
          title="Calibration"
          tabProps={{ id: 'time-nodes-calibration-tab', style: tabStyle }}
        >
          <NestedTabLayout>
            <NestedTabLayout.Route index title="Index" element={<Navigate to="status" />} />
            <NestedTabLayout.Route path="status" title="Status" element={<GroupCalibrationPage />} />
            <NestedTabLayout.Route path="presets" title="Groups" element={<CalibrationGroups />} />
          </NestedTabLayout>
        </TabbedLayout.Route>
      </TabbedLayout>
    </Page>
  );
};
