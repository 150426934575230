import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import { merge as deepMerge } from 'ts-deepmerge';
import { useApi } from '@backstage/core-plugin-api';
import { metricsApiRef } from '@netinsight/management-app-common-react';
import { groupMetricsFromPromise } from '@netinsight/management-app-common-api';

export const usePtpReceiverStatusMetrics = (nodeId: string, swrConfig: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);

  const fetchMetrics = useCallback(
    async ([nodeIdParam]: [string, string]) => {
      const [
        timePropsTimeSourceResult,
        timePropsTimeTraceableResult,
        timePropsFrequencyTraceableResult,
        portStateResult,
        currOffsetFromTransmitterResult,
        currMeanPathDelayResult,
        currStepsRemovedResult,
        parentGmClockClassResult,
        parentGmAccuracyResult,
        parentGmVarianceResult,
        parentGmPrio1Result,
        parentGmPrio2Result,
      ] = await Promise.allSettled([
        metricsApi.instantQuery({
          query: `neti_ptp_time_properties_timeSource{nodeid=~"${nodeIdParam}", type="receiver"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_time_properties_timeTraceable{nodeid=~"${nodeIdParam}", type="receiver"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_time_properties_frequencyTraceable{nodeid=~"${nodeIdParam}", type="receiver"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_port_dataset_state{nodeid=~"${nodeIdParam}", type="receiver"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_current_dataset_offsetFromTransmitter{nodeid=~"${nodeIdParam}", type="receiver"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_current_dataset_meanPathDelay{nodeid=~"${nodeIdParam}", type="receiver"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_current_dataset_stepsRemoved{nodeid=~"${nodeIdParam}", type="receiver"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_parent_dataset_gmClockClass{nodeid=~"${nodeIdParam}", type="receiver"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_parent_dataset_gmAccuracy{nodeid=~"${nodeIdParam}", type="receiver"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_parent_dataset_gmVariance{nodeid=~"${nodeIdParam}", type="receiver"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_parent_dataset_gmPrio1{nodeid=~"${nodeIdParam}", type="receiver"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_ptp_parent_dataset_gmPrio2{nodeid=~"${nodeIdParam}", type="receiver"}`,
        }),
      ]);

      const parseIntBase10 = (val: string) => parseInt(val, 10);
      const getServiceName = (metrics: any) => metrics.service_name;
      return deepMerge(
        groupMetricsFromPromise(timePropsTimeSourceResult, getServiceName, val => ({
          timePropsTimeSource: parseIntBase10(val),
        })),
        groupMetricsFromPromise(timePropsTimeTraceableResult, getServiceName, val => ({
          timePropsTimeTraceable: Boolean(parseIntBase10(val)),
        })),
        groupMetricsFromPromise(timePropsFrequencyTraceableResult, getServiceName, val => ({
          timePropsFrequencyTraceable: Boolean(parseIntBase10(val)),
        })),
        groupMetricsFromPromise(portStateResult, getServiceName, val => ({ portState: parseIntBase10(val) })),
        groupMetricsFromPromise(currOffsetFromTransmitterResult, getServiceName, val => ({
          currOffsetFromTransmitter: parseFloat(val),
        })),
        groupMetricsFromPromise(currMeanPathDelayResult, getServiceName, val => ({
          currMeanPathDelay: parseFloat(val),
        })),
        groupMetricsFromPromise(currStepsRemovedResult, getServiceName, val => ({
          currStepsRemoved: parseIntBase10(val),
        })),
        groupMetricsFromPromise(parentGmClockClassResult, getServiceName, (val, meta) => ({
          parentGmClockClass: parseIntBase10(val),
          transmitterClockId: meta.transmitterClockId,
          gmClockId: meta.gmClockId,
        })),
        groupMetricsFromPromise(parentGmAccuracyResult, getServiceName, val => ({
          parentGmAccuracy: parseIntBase10(val),
        })),
        groupMetricsFromPromise(parentGmVarianceResult, getServiceName, val => ({
          parentGmVariance: parseIntBase10(val),
        })),
        groupMetricsFromPromise(parentGmPrio1Result, getServiceName, val => ({ parentGmPrio1: parseIntBase10(val) })),
        groupMetricsFromPromise(parentGmPrio2Result, getServiceName, val => ({ parentGmPrio2: parseIntBase10(val) })),
      );
    },
    [metricsApi],
  );

  return useSwr([nodeId, 'usePtpReceiverStatusMetrics'], fetchMetrics, swrConfig);
};

export type UsePtpReceiverStatusMetricsResultData = NonNullable<ReturnType<typeof usePtpReceiverStatusMetrics>['data']>;
