import React, { useMemo } from 'react';
import { Content, InfoCard, Page, Progress, TabbedLayout } from '@backstage/core-components';
import { Navigate, Route, useLocation, useNavigate, useParams } from 'react-router';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { DEFAULT_SYNC_REGION } from '@netinsight/crds';
import {
  FeatureFlags,
  GrafanaLink,
  NestedTabLayout,
  NodeOnlineStatusIcon,
  useAlerts,
  useGrafanaDashboards,
  useInfoCardStyles,
  useTimeNode,
} from '@netinsight/management-app-common-react';
import { TimeNodeConfigurationTab } from './tabs/TimeNodeConfigurationTab/TimeNodeConfigurationTab';
import { TimeNodeWorkloadTab } from './tabs/TimeNodeWorkloadTab/TimeNodeWorkloadTab';
import { TimeNodeInventoryTab } from './tabs/TimeNodeInventoryTab/TimeNodeInventoryTab';
import { HeaderWithAlarms } from '../../../components/HeaderWithAlarms/HeaderWithAlarms';
import { TimeNodeLoggingTab } from './tabs/TimeNodeLoggingTab/TimeNodeLoggingTab';
import { TimeNodeHiccTab } from './tabs/TimeNodeHiccTab/TimeNodeHiccTab';
import { TimeNodePPSOutTab } from './tabs/TimeNodePPSOutTab/TimeNodePPSOutTab';
import { TimeNodeFrequencyOutTab } from './tabs/TimeNodeFrequencyOutTab/TimeNodeFrequencyOutTab';
import { useNodeStatus } from '../../../hooks';
import { AlarmsTableCard } from './tabs/AlarmsTableCard';
import { TimeNodeInventoryDBStateTab } from './tabs/TimeNodeInventoryDBStateTab/TimeNodeInventoryDBStateTab';
import { SyncStatusOverview } from './tabs/SyncStatusOverview';
import { PPSInConfigCard } from './tabs/InputsTab/PPSIn';
import { SyncInConfig } from './tabs/InputsTab/SyncIn';
import { GnssConfig, GnssMetricsDrawer } from './tabs/InputsTab/GNSS';
import { NtpClientConfigCard } from './tabs/InputsTab/NTP';
import { HoldoverConfig } from './tabs/InputsTab/Holdover';
import { PtpConfigPage } from './tabs/InputsTab/PTP/PtpConfigPage';
import { NodeManagerInterfaces } from './tabs/NetworksTab/NodeManagerInterfaces';
import { NodeManagerNetworkNamespaces } from './tabs/NetworksTab/NodeManagerNetworkNamespaces';
import { SyncEConfig } from './tabs/NetworksTab/SynceConfig';
import { DnsConfig } from './tabs/NetworksTab/DnsConfig';
import { NodeCalibration } from './tabs/CalibrationTab';

const useStyles = makeStyles(theme => ({
  alarmsCount: {
    position: 'absolute',
    right: '1rem',
    top: '50%',
    transform: 'translate(0, -50%)',
    zIndex: theme.zIndex.tooltip,
    backgroundColor: theme.palette.error.main,
    width: '1.5rem',
    height: '1.5rem',
    color: theme.palette.error.contrastText,
    lineHeight: '1.5rem',
    textAlign: 'center',
    borderRadius: '100%',
  },
}));

export const TimeNodeDetailPage = () => {
  const { node: nodeId = '' } = useParams();
  const styles = useStyles();
  const infoCardStyles = useInfoCardStyles();
  const { data: timeNode, error, isLoading } = useTimeNode(nodeId, { refreshInterval: 15_000 });
  const { data: nodeStatus, isLoading: statusLoading } = useNodeStatus(nodeId, { refreshInterval: 15_000 });
  const { data: dashboards } = useGrafanaDashboards();
  const { data: alerts } = useAlerts({ labels: { nodeid: nodeId } });
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const showAdvancedSettings = featureFlagsApi.isActive(FeatureFlags.ShowAdvancedSettings);
  const debugModeEnabled = featureFlagsApi.isActive(FeatureFlags.EnableDebugMode);
  const alarmsCount = useMemo(() => alerts?.length ?? 0, [alerts]);
  const navigate = useNavigate();
  const location = useLocation();

  if (error) {
    // errorFromWrappedError function wraps statusCode in body.response.statusCode
    if (error instanceof ResponseError && error.body?.response?.statusCode === 404) {
      return (
        <Content>
          <Alert severity="error">TimeNode not found!</Alert>
          <Button
            style={{ marginTop: 8 }}
            variant="contained"
            color="primary"
            onClick={() => {
              // the location.key = "default" when the history stack is empty
              const historyExists = location.key !== 'default';
              return historyExists ? navigate(-1) : navigate('/nodes', { replace: true });
            }}
          >
            Go Back
          </Button>
        </Content>
      );
    }

    return (
      <Content>
        <Alert severity="error">{error.toString()}</Alert>
      </Content>
    );
  }

  if (isLoading || statusLoading) {
    return <Progress />;
  }

  return (
    <Page themeId="service">
      <HeaderWithAlarms
        subtitle={
          <NodeOnlineStatusIcon
            heartbeatStatus={nodeStatus?.conditions?.find(c => c.type === 'TimeNodeHeartbeat')?.status}
            lifecycleState={timeNode?.spec.lifecycleState}
            offlineOnly
          />
        }
        type={timeNode?.spec.syncRegion ?? DEFAULT_SYNC_REGION}
        typeLink={`/nodes?syncRegion=${timeNode?.spec.syncRegion ?? DEFAULT_SYNC_REGION}`}
        title={timeNode?.spec.name ?? nodeId}
      />
      <TabbedLayout>
        <TabbedLayout.Route path="/" title="Sync status" tabProps={{ id: 'time-node-overview-tab' }}>
          <InfoCard
            title="Sync status"
            titleTypographyProps={{ variant: 'h5', component: 'h5' }}
            className={infoCardStyles.container}
            cardClassName={infoCardStyles.contentContainer}
          >
            <GrafanaLink
              className={infoCardStyles.topRightAction}
              dashboardUrl={dashboards?.['Zyntai Time-transfer/single-node']}
              nodeIds={[nodeId]}
              syncRegions={[timeNode?.spec.syncRegion ?? 'All']}
            />
            <SyncStatusOverview nodeId={nodeId} />
          </InfoCard>
        </TabbedLayout.Route>
        <TabbedLayout.Route
          path="/alarms"
          title="Alarms"
          tabProps={{
            id: 'time-node-alarm-tab',
            style: {
              paddingRight: alarmsCount > 0 ? '3.5rem' : undefined,
            },
            icon:
              alarmsCount > 0 ? (
                <span className={styles.alarmsCount}>{alarmsCount > 9 ? '9+' : alarmsCount}</span>
              ) : undefined,
          }}
        >
          <AlarmsTableCard nodeId={nodeId} />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/config" title="General" tabProps={{ id: 'time-node-configuration-tab' }}>
          <TimeNodeConfigurationTab nodeId={nodeId} />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/inputs" title="Inputs" tabProps={{ id: 'time-node-sync-inputs-tab' }}>
          <NestedTabLayout>
            <NestedTabLayout.Route index title="Index" element={<Navigate to="pps" />} />
            <NestedTabLayout.Route path="pps" title="PPS" element={<PPSInConfigCard nodeId={nodeId} />} />
            <NestedTabLayout.Route path="sync-input" title="10M/Sync" element={<SyncInConfig nodeId={nodeId} />} />
            <NestedTabLayout.Route
              path="gnss"
              title="GNSS"
              element={
                <InfoCard
                  title="GNSS"
                  titleTypographyProps={{ variant: 'h5', component: 'h5' }}
                  className={infoCardStyles.container}
                  cardClassName={infoCardStyles.contentContainer}
                >
                  <GrafanaLink
                    className={infoCardStyles.topRightAction}
                    dashboardUrl={dashboards?.['Zyntai Time-transfer/gnss-single']}
                    nodeIds={[nodeId]}
                  />
                  <GnssConfig nodeId={nodeId} />
                </InfoCard>
              }
            >
              <Route path="metrics" element={<GnssMetricsDrawer nodeId={nodeId} />} />
            </NestedTabLayout.Route>
            <NestedTabLayout.Route
              path="ptp"
              title="PTP"
              element={
                <InfoCard
                  title="PTP Time Receivers"
                  titleTypographyProps={{ variant: 'h5', component: 'h5' }}
                  className={infoCardStyles.container}
                  cardClassName={infoCardStyles.contentContainer}
                >
                  <GrafanaLink
                    className={infoCardStyles.topRightAction}
                    dashboardUrl={dashboards?.['Zyntai Time-transfer/ptp-client-single-node']}
                    nodeIds={[nodeId]}
                  />
                  <PtpConfigPage nodeId={nodeId} mode="receiver" />
                </InfoCard>
              }
            />
            <NestedTabLayout.Route path="ntp" title="NTP" element={<NtpClientConfigCard nodeId={nodeId} />} />
            <NestedTabLayout.Route path="holdover" title="Holdover" element={<HoldoverConfig nodeId={nodeId} />} />
          </NestedTabLayout>
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/outputs" title="Outputs" tabProps={{ id: 'time-node-sync-outputs-tab' }}>
          <NestedTabLayout>
            <NestedTabLayout.Route index title="Index" element={<Navigate to="ptp" />} />
            <NestedTabLayout.Route
              path="ptp"
              title="PTP"
              element={
                <InfoCard
                  title="PTP Time Transmitters"
                  titleTypographyProps={{ variant: 'h5', component: 'h5' }}
                  className={infoCardStyles.container}
                  cardClassName={infoCardStyles.contentContainer}
                >
                  <GrafanaLink
                    className={infoCardStyles.topRightAction}
                    dashboardUrl={dashboards?.['Zyntai Time-transfer/ptp-gm-single-node']}
                    nodeIds={[nodeId]}
                  />
                  <PtpConfigPage nodeId={nodeId} mode="transmitter" />
                </InfoCard>
              }
            />
            <NestedTabLayout.Route path="pps-out" title="PPS" element={<TimeNodePPSOutTab nodeId={nodeId} />} />
            <NestedTabLayout.Route
              path="sync-out"
              title="10M/Sync"
              element={<TimeNodeFrequencyOutTab nodeId={nodeId} />}
            />
            <NestedTabLayout.Route path="synce" title="SyncE" element={<SyncEConfig nodeId={nodeId} />} />
          </NestedTabLayout>
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/calibration" title="Calibration" tabProps={{ id: 'time-node-sync-calibration-tab' }}>
          <NestedTabLayout>
            <NestedTabLayout.Route index title="Index" element={<Navigate to="node-time" />} />
            <NestedTabLayout.Route
              path="node-time"
              title="Node time"
              element={<NodeCalibration nodeId={nodeId} mode="node-time" />}
            />
            <NestedTabLayout.Route
              path="link-spread"
              title="Link spread"
              element={<NodeCalibration nodeId={nodeId} mode="link-spread" />}
            />
          </NestedTabLayout>
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/networks" title="Networks" tabProps={{ id: 'time-node-network-tab' }}>
          <NestedTabLayout>
            <NestedTabLayout.Route index title="Index" element={<Navigate to="interfaces" />} />
            <NestedTabLayout.Route
              path="interfaces"
              title="Network Ports"
              element={<NodeManagerInterfaces nodeId={nodeId} title="Network Ports" mode="non-management-only" />}
            />
            <NestedTabLayout.Route
              path="mgmt-interfaces"
              title="Management Ports"
              element={<NodeManagerInterfaces nodeId={nodeId} title="Management Ports" mode="management-only" />}
            />
            <NestedTabLayout.Route
              path="namespaces"
              title="Routes"
              element={<NodeManagerNetworkNamespaces nodeId={nodeId} />}
            />
            <NestedTabLayout.Route path="dns" title="Dns" element={<DnsConfig nodeId={nodeId} />} />
          </NestedTabLayout>
        </TabbedLayout.Route>
        {showAdvancedSettings ? (
          <TabbedLayout.Route path="/advanced" title="Advanced" tabProps={{ id: 'time-node-advanced-tab' }}>
            <NestedTabLayout>
              <NestedTabLayout.Route index title="Index" element={<Navigate to="time-transfer" />} />
              <NestedTabLayout.Route
                path="time-transfer"
                title="Time Transfer"
                element={<TimeNodeHiccTab nodeId={nodeId} />}
              />
              <NestedTabLayout.Route path="logging" title="Logging" element={<TimeNodeLoggingTab nodeId={nodeId} />} />
            </NestedTabLayout>
          </TabbedLayout.Route>
        ) : null}
        <TabbedLayout.Route path="/about" title="About" tabProps={{ id: 'time-node-about-tab' }}>
          <NestedTabLayout>
            <NestedTabLayout.Route index title="Index" element={<Navigate to="inventory" />} />
            <NestedTabLayout.Route
              path="inventory"
              title="Inventory"
              element={<TimeNodeInventoryTab nodeId={nodeId} />}
            />
            <NestedTabLayout.Route path="workload" title="Workload" element={<TimeNodeWorkloadTab nodeId={nodeId} />} />
            {debugModeEnabled ? (
              <NestedTabLayout.Route
                path="inventorydb-state"
                title="Inventory DB State"
                element={<TimeNodeInventoryDBStateTab nodeId={nodeId} />}
              />
            ) : null}
          </NestedTabLayout>
        </TabbedLayout.Route>
      </TabbedLayout>
    </Page>
  );
};
