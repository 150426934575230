import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwrMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { apiPermission, errorFromWrappedError, k8sTTLinksApiRef } from '@netinsight/management-app-common-react';
import { PersistedSyncLink, ValidationError, ttLinksApi as contract } from '@netinsight/management-app-common-api';
import { TTLinksKey } from './useTTLinks';
import { allocateEndpointPort, isFieldErrors } from '../../utils/time-transfer';

export const useTTLinkCreate = () => {
  const ttLinksApi = useApi(k8sTTLinksApiRef);

  const getNodeLinks = useCallback(
    async (nodeId: string) => {
      const response = await ttLinksApi.getNodeLinkTargets({ params: { nodeId } });
      return response.status === StatusCodes.OK ? (response.body?.config?.targets ?? []) : [];
    },
    [ttLinksApi],
  );

  const create = useCallback(
    async (_: string, { arg: linkData }: { arg: Omit<PersistedSyncLink, 'id'> }) => {
      linkData.endpointA = await allocateEndpointPort(linkData.endpointA, getNodeLinks);
      linkData.endpointB = await allocateEndpointPort(linkData.endpointB, getNodeLinks);
      const createLinkResponse = await ttLinksApi.createTimeTransferLink({
        body: linkData,
      });
      if (createLinkResponse.status === StatusCodes.OK) {
        return [createLinkResponse.body.config];
      }
      if (
        createLinkResponse.status === StatusCodes.BAD_REQUEST &&
        isFieldErrors(createLinkResponse.body?.error?.details)
      ) {
        throw new ValidationError(createLinkResponse.body.error.details);
      }
      throw errorFromWrappedError(createLinkResponse.status, createLinkResponse.body);
    },
    [ttLinksApi, getNodeLinks],
  );
  const mutation = useSwrMutation(TTLinksKey, create, {
    populateCache: (createdLinks, links) => [...createdLinks, ...(links ?? [])],
    revalidate: false,
  });

  return { ...mutation, permission: apiPermission(contract.createTimeTransferLink) };
};
