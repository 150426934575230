import React from 'react';
import { useLocalStorage } from 'react-use';
import { Navigate, useParams } from 'react-router-dom';
import { isNullableOrEmpty } from '@netinsight/management-app-common-react';
import { NodeGraphPage } from './NodeGraphPage';
import { DEFAULT_GRAPH_ID, LAST_GRAPH_ID_STORAGE_KEY } from '../../../constants/node-graph';

export const NodeGraphPageRedirector = () => {
  const [lastGraphId] = useLocalStorage<string>(LAST_GRAPH_ID_STORAGE_KEY);
  const { graphId } = useParams<{ graphId?: string }>();

  if (isNullableOrEmpty(graphId)) {
    return <Navigate to={`/network/graph/${isNullableOrEmpty(lastGraphId) ? DEFAULT_GRAPH_ID : lastGraphId}`} />;
  }
  return <NodeGraphPage graphId={graphId} />;
};
