/* eslint-disable no-nested-ternary */
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useController } from 'react-hook-form';
import { useInterval, usePrevious } from 'react-use';
import { F, G } from '@mobily/ts-belt';
import { Button } from '@material-ui/core';
import {
  CheckboxField,
  InputTooltip,
  PermissionResult,
  useFormStyles,
  useSnackbarHelper,
  useSubmitButtonProps,
} from '@netinsight/management-app-common-react';
import {
  CalculationMethods,
  DefaultLinkSpreadDuration,
  DefaultPrediction,
  DefaultTimeErrorsDuration,
  PredefinedTimeErrorsDurations,
} from './constants';
import { calculatePathDiffs, getCalibrationEditModel, predictTimeErrors, roundToPrecision } from './helper';
import {
  CalibrationViewModel,
  CalibrationEditModel,
  CalibrationPredictionViewModel,
  CalibrationMode,
  CalibrationPersistedState,
} from './types';
import { LinkCalibrationTable } from './LinkCalibrationTable';
import { LocalTimeErrorSelector } from './LocalTimeErrorSelector';
import { LocalTimeErrorPrediction } from './LocalTimeErrorPrediction';
import { useLinkTimeErrorsByDuration, useNodeTimeErrorsByTypeAndDuration } from './hooks';
import { LinkSpreadDurationSelector } from './LinkSpreadDurationSelector';
import { SYNC_SOURCE_NAMES } from '@netinsight/management-app-common-api';
import { toMicroseconds } from '../../../../../utils/time-transfer';

export const NodeCalibrationForm: FunctionComponent<{
  mode: CalibrationMode;
  viewModel: CalibrationViewModel;
  persistedState: CalibrationPersistedState;
  onPersistedStateChanged: (newState: Partial<CalibrationPersistedState>) => void;
  onSubmit: (data: CalibrationEditModel) => any;
  permission: PermissionResult;
}> = ({ mode, viewModel, persistedState, onPersistedStateChanged, onSubmit, permission }) => {
  const { snackbar } = useSnackbarHelper();
  const [selectedTimeErrorDuration, setSelectedTimeErrorDuration] = useState<string>(
    persistedState?.timeErrorDuration ?? DefaultTimeErrorsDuration,
  );
  const [selectedLinkTimeErrorDuration, setSelectedLinkTimeErrorDuration] = useState<string>(
    persistedState?.linkSpreadDuration ?? DefaultLinkSpreadDuration,
  );
  const inLinkIds = useMemo(() => viewModel.inLinks.map(x => x.linkId), [viewModel.inLinks]);
  const { data: linkTimeErrors, isLoading: isLoadingLinkTimeErrors } = useLinkTimeErrorsByDuration(
    mode === 'link-spread' ? inLinkIds : [],
    selectedLinkTimeErrorDuration,
    { refreshInterval: 10_000 },
  );
  const { data: timeErrorsByTypeAndDuration } = useNodeTimeErrorsByTypeAndDuration(
    viewModel.nodeId,
    mode === 'link-spread' ? [selectedTimeErrorDuration] : Object.keys(PredefinedTimeErrorsDurations),
    { refreshInterval: 10_000 },
  );
  const [predictions, setPrediction] = useState<CalibrationPredictionViewModel>(DefaultPrediction);

  const inLinks = useMemo(() => {
    return mode === 'link-spread'
      ? viewModel.inLinks.map(il => ({
          ...il,
          linkTimeError: linkTimeErrors?.timeErrorsByNodeAndLink?.[il.linkId!]?.[viewModel.nodeId] ?? il.linkTimeError,
        }))
      : viewModel.inLinks;
  }, [mode, viewModel.nodeId, viewModel.inLinks, linkTimeErrors]);
  const initialFormValues = useMemo<CalibrationEditModel>(
    () => getCalibrationEditModel(viewModel),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify({
        ...viewModel.defaultLocalTimeReferenceSelections,
        inLinks: viewModel.inLinks.map(l => l.linkId).sort(),
        outLinks: viewModel.outLinks.map(l => l.linkId).sort(),
      }),
    ],
  );
  const prevDefaultValues = usePrevious(initialFormValues);

  const formProps = useForm<CalibrationEditModel>({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });
  const { control, handleSubmit, getValues, formState, setValue, reset } = formProps;
  const buttonProps = useSubmitButtonProps({ permission, formState });
  useEffect(() => {
    if (!F.equals(prevDefaultValues, initialFormValues)) {
      reset(initialFormValues);
    }
  }, [initialFormValues, prevDefaultValues, reset]);

  const calculationMethodCheckboxProps = useController({
    control,
    name: 'calculationMethod',
  });

  const handleTimeErrorSelected = useCallback(
    (newTimeErrorValue: number, newDuration: string) => {
      setValue('customTimeReferenceError', roundToPrecision(toMicroseconds(newTimeErrorValue), 3));
      setSelectedTimeErrorDuration(newDuration);
    },
    [setValue],
  );

  const handleLinkTimeErrorDurationChanged = useCallback(
    (newLinkTimeErrorDuration: string) => {
      setSelectedLinkTimeErrorDuration(newLinkTimeErrorDuration);
      onPersistedStateChanged({ linkSpreadDuration: newLinkTimeErrorDuration });
    },
    [onPersistedStateChanged, setSelectedLinkTimeErrorDuration],
  );

  const handleCalculateClick = useCallback(() => {
    const currentFormValues = getValues();

    try {
      const ppsInValue =
        timeErrorsByTypeAndDuration?.ppsIn?.[selectedTimeErrorDuration] ??
        viewModel.localTimeReferenceErrors[SYNC_SOURCE_NAMES.ppsIn];
      const gnssValue =
        timeErrorsByTypeAndDuration?.gnss?.[selectedTimeErrorDuration] ??
        viewModel.localTimeReferenceErrors[SYNC_SOURCE_NAMES.gnss];
      const ptp1Value =
        timeErrorsByTypeAndDuration?.ptp?.[selectedTimeErrorDuration]?.[SYNC_SOURCE_NAMES.ptp1] ??
        viewModel.localTimeReferenceErrors[SYNC_SOURCE_NAMES.ptp1];
      const ptp2Value =
        timeErrorsByTypeAndDuration?.ptp?.[selectedTimeErrorDuration]?.[SYNC_SOURCE_NAMES.ptp2] ??
        viewModel.localTimeReferenceErrors[SYNC_SOURCE_NAMES.ptp2];
      const { linkTimeErrorsEditModel } = calculatePathDiffs({
        ...{
          ...currentFormValues,
          calculationMethod: mode === 'link-spread' ? 'eliminateLinkSpread' : currentFormValues.calculationMethod,
          linkTimeErrorsByLinkIdAndProfileIndex: initialFormValues.linkTimeErrorsByLinkIdAndProfileIndex,
          localTimeReferenceSelections: {
            // only use custom when calibrating node-time
            [SYNC_SOURCE_NAMES.ppsIn]: mode !== 'node-time' && (G.isNotNullable(ppsInValue) || G.isNullable(gnssValue)),
            [SYNC_SOURCE_NAMES.gnss]: mode !== 'node-time' && G.isNotNullable(gnssValue),
            [SYNC_SOURCE_NAMES.ptp1]: mode !== 'node-time' && G.isNotNullable(ptp1Value),
            [SYNC_SOURCE_NAMES.ptp2]: mode !== 'node-time' && G.isNotNullable(ptp2Value),
            custom: mode === 'node-time',
          },
        },
        ...{
          ...viewModel,
          inLinks: mode === 'link-spread' ? inLinks : viewModel.inLinks,
          localTimeReferenceErrors: {
            ...viewModel.localTimeReferenceErrors,
            [SYNC_SOURCE_NAMES.ppsIn]: ppsInValue ?? 0,
            [SYNC_SOURCE_NAMES.gnss]: gnssValue ?? 0,
            [SYNC_SOURCE_NAMES.ptp1]: ptp1Value ?? 0,
            [SYNC_SOURCE_NAMES.ptp2]: ptp2Value ?? 0,
          },
        },
      });
      setValue('linkTimeErrorsByLinkIdAndProfileIndex', linkTimeErrorsEditModel, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    } catch (error: any) {
      snackbar.error(error.message);
    }
  }, [
    initialFormValues.linkTimeErrorsByLinkIdAndProfileIndex,
    getValues,
    viewModel,
    setValue,
    snackbar,
    timeErrorsByTypeAndDuration,
    mode,
    selectedTimeErrorDuration,
    inLinks,
  ]);

  useInterval(() => {
    const currentFormValues = getValues();
    const ppsInValue =
      timeErrorsByTypeAndDuration?.ppsIn?.[selectedTimeErrorDuration] ??
      viewModel.localTimeReferenceErrors[SYNC_SOURCE_NAMES.ppsIn];
    const gnssValue =
      timeErrorsByTypeAndDuration?.gnss?.[selectedTimeErrorDuration] ??
      viewModel.localTimeReferenceErrors[SYNC_SOURCE_NAMES.gnss];
    const ptp1Value =
      timeErrorsByTypeAndDuration?.ptp?.[selectedTimeErrorDuration]?.[SYNC_SOURCE_NAMES.ptp1] ??
      viewModel.localTimeReferenceErrors[SYNC_SOURCE_NAMES.ptp1];
    const ptp2Value =
      timeErrorsByTypeAndDuration?.ptp?.[selectedTimeErrorDuration]?.[SYNC_SOURCE_NAMES.ptp2] ??
      viewModel.localTimeReferenceErrors[SYNC_SOURCE_NAMES.ptp2];
    try {
      const newPredictions = predictTimeErrors({
        ...{
          ...currentFormValues,
          calculationMethod: mode === 'link-spread' ? 'eliminateLinkSpread' : currentFormValues.calculationMethod,
          localTimeReferenceSelections: {
            // only use pps and gnss & ptp for prediction
            [SYNC_SOURCE_NAMES.ppsIn]: G.isNotNullable(ppsInValue),
            [SYNC_SOURCE_NAMES.gnss]: G.isNotNullable(gnssValue),
            [SYNC_SOURCE_NAMES.ptp1]: G.isNotNullable(ptp1Value),
            [SYNC_SOURCE_NAMES.ptp2]: G.isNotNullable(ptp2Value),
            custom: false,
          },
        },
        ...{
          ...viewModel,
          inLinks: mode === 'link-spread' ? inLinks : viewModel.inLinks,
          localTimeReferenceErrors: {
            ...viewModel.localTimeReferenceErrors,
            [SYNC_SOURCE_NAMES.ppsIn]: ppsInValue ?? 0,
            [SYNC_SOURCE_NAMES.gnss]: gnssValue ?? 0,
            [SYNC_SOURCE_NAMES.ptp1]: ptp1Value ?? 0,
            [SYNC_SOURCE_NAMES.ptp2]: ptp2Value ?? 0,
          },
        },
      });
      setPrediction(newPredictions);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.warn(error.message);
    }
  }, 1000);

  const formStyles = useFormStyles();

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)} className={formStyles.formContainer}>
        {mode === 'node-time' && G.isNotNullable(timeErrorsByTypeAndDuration) ? (
          <LocalTimeErrorSelector
            nodeId={viewModel.nodeId}
            data={timeErrorsByTypeAndDuration}
            control={control}
            selectedDuration={selectedTimeErrorDuration}
            onValueSelected={handleTimeErrorSelected}
            permission={permission}
          />
        ) : null}
        <div className={formStyles.formRow}>
          {mode === 'node-time' ? (
            <CheckboxField
              label="Adjust time without affecting downstream nodes"
              id="node-calibration-calculation-method-checkbox"
              fieldProps={calculationMethodCheckboxProps}
              fromValue={value => value === CalculationMethods.adjustTimeOnly}
              toValue={checked => (checked ? CalculationMethods.adjustTimeOnly : CalculationMethods.eliminateTimeError)}
            />
          ) : (
            <LinkSpreadDurationSelector
              duration={selectedLinkTimeErrorDuration}
              onDurationChanged={handleLinkTimeErrorDurationChanged}
              isLoading={isLoadingLinkTimeErrors}
            />
          )}
          <Button
            {...buttonProps}
            type="button"
            data-testid="btn-calculate"
            id="node-calibration-calculate-button"
            color="default"
            variant="contained"
            onClick={handleCalculateClick}
            disabled={
              buttonProps.disabled || (mode === 'link-spread' && isLoadingLinkTimeErrors) || viewModel.isHeadEndNode
            }
          >
            Calculate
          </Button>
        </div>
        {mode === 'node-time' ? (
          <LocalTimeErrorPrediction nodeId={viewModel.nodeId} prediction={predictions.localTimeReferenceErrors} />
        ) : null}
        <LinkCalibrationTable mode={mode} type="inLinks" data={inLinks} control={control} predictions={predictions} />
        {mode === 'node-time' ? (
          <LinkCalibrationTable
            mode={mode}
            type="outLinks"
            data={viewModel.outLinks}
            control={control}
            predictions={predictions}
          />
        ) : null}
        <div className={formStyles.formRow}>
          <Button
            data-testid="btn-submit"
            id="node-calibration-submit-button"
            {...buttonProps}
            disabled={buttonProps.disabled || viewModel.isHeadEndNode}
          />
          {viewModel.isHeadEndNode ? (
            <InputTooltip text="There are no incoming links to calibrate on this node, please navigate to any of the downstream nodes to do so." />
          ) : null}
        </div>

        <LinkCalibrationTable
          mode={mode}
          type="unusedLinks"
          data={viewModel.unusedLinks}
          control={control}
          predictions={predictions}
        />
      </form>
    </FormProvider>
  );
};
