import { useCallback } from 'react';
import StatusCodes from 'http-status-codes';
import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sNodeApiRef, k8sTTLinksApiRef } from '@netinsight/management-app-common-react';

export const useGraphData = (config?: SWRConfiguration) => {
  const nodeApi = useApi(k8sNodeApiRef);
  const ttLinksApi = useApi(k8sTTLinksApiRef);

  const getConfig = useCallback(async () => {
    const [nodesResult, linksResult] = await Promise.all([nodeApi.listNodes({}), ttLinksApi.listTimeTransferLinks({})]);

    if (nodesResult.status !== StatusCodes.OK) {
      throw errorFromWrappedError(nodesResult.status, nodesResult.body);
    }
    if (linksResult.status !== StatusCodes.OK) {
      throw errorFromWrappedError(linksResult.status, linksResult.body);
    }

    return { nodes: nodesResult.body.items, links: linksResult.body.links };
  }, [nodeApi, ttLinksApi]);

  return useSwr('useGraphData', getConfig, config);
};
