import React, { FunctionComponent, PropsWithChildren, useCallback, useContext, useMemo, useRef } from 'react';
import { useForm, FormProvider, useController, SubmitHandler } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, makeStyles, Theme } from '@material-ui/core';
import { F } from '@mobily/ts-belt';
import { DEFAULT_LINK_OPTIONS } from '@netinsight/crds-timetransfer';
import { FieldErrors, PersistedSyncLink, PersistedSyncLinkSchema } from '@netinsight/management-app-common-api';
import {
  CheckboxField,
  convertZodSchemaToWebFormSchema,
  isNullableOrEmpty,
  PermissionResult,
  useFormStyles,
  useSubmitButtonProps,
} from '@netinsight/management-app-common-react';
import { LinkDetailContext } from '../../../constants/time-transfer';
import { LinkEndpointsFormContent, LinkName } from '../common';
import { generateLinkName } from '../../../utils/time-transfer';

const defaultEndpointConfig = {
  iface: '',
  node: '',
  port: undefined,
  vlanId: undefined,
  dscp: undefined,
  selectable: true,
};

const LinkEndpointsFormSchema = z.object({
  endpointA: PersistedSyncLinkSchema.shape.endpointA,
  endpointB: PersistedSyncLinkSchema.shape.endpointB,
  name: PersistedSyncLinkSchema.shape.name,
  tcpServer: PersistedSyncLinkSchema.shape.tcpServer,
  autoCalibration: PersistedSyncLinkSchema.shape.autoCalibration,
});

type LinkEndpointsFormData = z.infer<typeof LinkEndpointsFormSchema>;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  syncIcon: {
    alignSelf: 'center',
    transform: 'rotate(90deg)',
  },
}));

type LinkEndpointsFormProps = {
  onSubmit: (
    values: Omit<PersistedSyncLink, 'id'>,
    onServerError: (errors: FieldErrors) => void,
    skipNavigation?: boolean,
  ) => Promise<void>;
  onCancel: VoidFunction;
  permission: PermissionResult;
};

export const LinkEndpointsForm: FunctionComponent<PropsWithChildren<LinkEndpointsFormProps>> = ({
  onSubmit = F.ignore,
  onCancel = F.ignore,
  children,
  permission,
}) => {
  const linkDetailContextValue = useContext(LinkDetailContext);
  const DefaultFormValues: LinkEndpointsFormData = useMemo(
    () => ({
      endpointA: { ...defaultEndpointConfig },
      endpointB: { ...defaultEndpointConfig },
      autoCalibration:
        linkDetailContextValue?.globalLinkOptions?.autoCalibration ?? DEFAULT_LINK_OPTIONS.autoCalibration,
    }),
    [linkDetailContextValue?.globalLinkOptions],
  );
  const formProps = useForm({
    defaultValues: DefaultFormValues,
    mode: 'onChange',
    resolver: zodResolver(convertZodSchemaToWebFormSchema(LinkEndpointsFormSchema)),
  });
  const { control, handleSubmit, setError, formState } = formProps;

  const autoCalibrationProps = useController({
    control,
    name: 'autoCalibration',
    defaultValue: DefaultFormValues.autoCalibration,
  });

  const styles = useStyles();
  const formStyles = useFormStyles();
  const skipNavigationRef = useRef(false);
  const buttonProps = useSubmitButtonProps({ permission, formState });
  const handleCreateOrUpdate = useCallback(
    async ({ name: linkName, ...formData }: LinkEndpointsFormData) => {
      const submitData: Omit<PersistedSyncLink, 'id'> = {
        ...formData,
        name: isNullableOrEmpty(linkName)
          ? generateLinkName(formData.endpointA, formData.endpointB, linkDetailContextValue?.nodeNameMap)
          : linkName,
        profiles: [],
      };

      await onSubmit(
        submitData,
        serverErrors => {
          Object.entries(serverErrors).forEach(([name, error]) => setError(name as keyof LinkEndpointsFormData, error));
        },
        skipNavigationRef.current,
      );
      skipNavigationRef.current = false;
    },
    [linkDetailContextValue, onSubmit, setError],
  );

  const handleCreateContinue = useCallback(async () => {
    skipNavigationRef.current = true;
    await handleSubmit(handleCreateOrUpdate as SubmitHandler<LinkEndpointsFormData>)();
  }, [handleSubmit, handleCreateOrUpdate]);

  return (
    <FormProvider {...formProps}>
      <form
        onSubmit={handleSubmit(handleCreateOrUpdate as SubmitHandler<LinkEndpointsFormData>)}
        className={styles.container}
        data-testid="link-config-form"
      >
        <LinkEndpointsFormContent />
        <Divider />
        <LinkName permission={permission} />
        <CheckboxField
          fieldProps={autoCalibrationProps}
          label="Enable auto-calibration"
          description={PersistedSyncLinkSchema.shape.autoCalibration.description ?? ''}
        />
        <div className={formStyles.buttonContainer}>
          <Button {...buttonProps} size="medium" data-testid="btn-save-link">
            Create
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={handleCreateContinue}
            data-testid="btn-save-link-continue"
            {...buttonProps}
            startIcon={skipNavigationRef.current === true ? buttonProps.startIcon : null}
          >
            Create and continue
          </Button>
          <Button
            variant="outlined"
            color="default"
            size="small"
            type="button"
            data-testid="btn-cancel-link"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
        {children}
      </form>
    </FormProvider>
  );
};
