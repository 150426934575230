/* eslint-disable no-nested-ternary */
import { Theme } from '@material-ui/core';
import { NodeGraphHighlight } from '@netinsight/management-app-common-api';
import { EdgeSingular, StylesheetCSS } from 'cytoscape';

type NodeGraphStyleOptions = {
  arrowShape?: cytoscape.Css.ArrowShape;
  showNodeLabel?: boolean;
  showParentNodes?: boolean;
  showSelectedEdgeLabel?: boolean;
  bundleEdge?: boolean;
  isTracing?: boolean;
  highlight?: NodeGraphHighlight;
  nodeSize?: number;
  nodeBackgroundColor?: string;
  nodeShape?: cytoscape.Css.NodeShape;
  nodeLabelFontSize?: number;
  nodeLabelBackgroundColor?: string;
  nodeLabelColor?: string;
  nodeOpacity?: number;
  edgeOpacity?: number;
  edgeColor?: string;
  edgeLabelFontSize?: number;
  edgeThickness?: number;
  edgeLineStyle?: cytoscape.Css.LineStyle;
  edgeArrowScale?: number;
  selectedColor?: string;
  selectedNeighborhoodColor?: string;
  labelOpacity?: number;
  traceEndpointsColor?: string;
};

export const useGraphStyles = (
  theme: Theme,
  {
    showNodeLabel,
    showParentNodes,
    showSelectedEdgeLabel = true,
    bundleEdge,
    isTracing,
    nodeSize = 24,
    nodeShape = 'round-rectangle',
    nodeBackgroundColor = theme.palette.type === 'dark' ? theme.palette.grey[300] : theme.palette.grey[800],
    nodeLabelColor = theme.palette.text.primary,
    nodeLabelBackgroundColor = theme.palette.type === 'dark'
      ? theme.palette.background.default
      : theme.palette.background.paper,
    nodeLabelFontSize = 16,
    edgeOpacity = 0.625,
    edgeColor = theme.palette.type === 'dark' ? theme.palette.grey[300] : theme.palette.grey[800],
    edgeLabelFontSize = 16,
    edgeThickness = 1.5,
    edgeArrowScale = 1.5,
    labelOpacity = 0.9,
    nodeOpacity = theme.palette.type === 'dark' ? 1 : 0.9,
    arrowShape = 'triangle',
    edgeLineStyle = 'solid',
    selectedColor = theme.palette.primary.dark,
    traceEndpointsColor = theme.palette.secondary.main,
  }: NodeGraphStyleOptions = {},
): StylesheetCSS[] => {
  return [
    // NODES
    {
      selector: 'node',
      css: {
        ...(showNodeLabel
          ? {
              label: 'data(name)',
            }
          : {}),
        width: nodeSize,
        height: nodeSize,
        backgroundColor: nodeBackgroundColor,
        color: nodeLabelColor,
        shape: nodeShape,
        opacity: nodeOpacity,
        'font-size': nodeLabelFontSize,
        'text-opacity': labelOpacity,
        'text-background-color': nodeLabelBackgroundColor,
        'text-background-opacity': labelOpacity,
        'text-background-padding': '4px',
        'text-background-shape': 'roundrectangle',
        'text-margin-y': -8,
        'z-index': 1,
      },
    },
    {
      selector: 'node.nobg:unselected',
      css: {
        'background-opacity': 0,
      },
    },
    {
      selector: 'node:childless:unselected',
      css: {
        opacity: isTracing ? nodeOpacity * 0.25 : nodeOpacity,
      },
    },
    {
      selector: 'node:childless:selected',
      css: {
        label: 'data(name)',
        backgroundColor: selectedColor,
        color: selectedColor,
        opacity: 1,
        'text-opacity': 1,
        'background-opacity': 1,
        'z-index': 9999,
      },
    },
    {
      selector: 'node.nobg:selected',
      css: {
        'outline-width': 4,
        'outline-style': 'solid',
        'outline-color': selectedColor,
      },
    },
    {
      selector: 'node.trace-active:selected',
      css: {
        'outline-color': traceEndpointsColor,
      } as any,
    },
    // PARENT NODES
    {
      selector: 'node:parent',
      css: {
        'background-opacity': 0,
        ...(showParentNodes
          ? {
              label: 'data(name)',
              'border-color': nodeBackgroundColor,
              'border-opacity': edgeOpacity,
              'text-opacity': isTracing ? nodeOpacity * 0.25 : nodeOpacity,
              events: 'yes',
            }
          : {
              'border-opacity': 0,
              'text-opacity': 0,
              events: 'no',
            }),
      },
    },
    {
      selector: 'node:parent:selected',
      css: {
        ...(showParentNodes
          ? {
              backgroundColor: theme.palette.background.paper,
              'border-color': selectedColor,
              color: selectedColor,
              'z-index': 9999,
              'text-opacity': 1,
              opacity: 1,
              'background-opacity': 0.25,
            }
          : {}),
      },
    },
    // EDGES
    {
      selector: 'edge',
      css: {
        'curve-style': bundleEdge ? 'straight' : 'bezier',
        width: edgeThickness,
        color: nodeLabelColor,
        'line-opacity': isTracing ? edgeOpacity * 0.25 : edgeOpacity,
        'line-color': edgeColor,
        'font-size': edgeLabelFontSize,
        'text-opacity': labelOpacity,
        'text-margin-y': -16,
        'arrow-scale': edgeArrowScale,
        'mid-target-arrow-color': edgeColor,
        'mid-source-arrow-color': edgeColor,
        'line-style': edgeLineStyle,
        'text-background-color': nodeLabelBackgroundColor,
        'text-background-opacity': labelOpacity,
        'text-background-padding': '2px',
        'text-background-shape': 'roundrectangle',
      },
    },
    {
      selector: 'edge[usage < 2]',
      css: {
        'line-opacity': edgeOpacity * 0.5,
      },
    },
    {
      selector: 'edge[stability < 2]',
      css: {
        'line-opacity': edgeOpacity * 0.5,
        'line-style': 'dashed',
      },
    },
    {
      selector: 'edge[usage >= 2]',
      css: {
        'line-opacity': isTracing ? edgeOpacity * 0.5 : edgeOpacity,
        'mid-target-arrow-shape': arrowShape,
      },
    },
    {
      selector: 'edge[parallelEdgesCount > 1]',
      css: {
        'mid-target-arrow-shape': bundleEdge ? 'circle' : undefined,
      },
    },
    {
      selector: 'edge[usage >= 2][parallelEdgesCount > 1]',
      css: {
        'mid-target-arrow-shape': bundleEdge ? 'circle' : arrowShape,
      },
    },
    {
      selector: 'edge:selected',
      css: {
        label: showSelectedEdgeLabel ? 'data(name)' : undefined,
        color: selectedColor,
        'line-opacity': 1,
        'line-color': selectedColor,
        'text-wrap': 'wrap',
        'text-outline-color': nodeLabelBackgroundColor,
        'text-outline-width': 1,
        'text-opacity': 1,
        'font-size': edgeLabelFontSize,
        'mid-target-arrow-color': selectedColor,
        'z-index': 99999,
      },
    },
    {
      selector: 'edge[parallelEdgesCount > 1]:selected',
      css: {
        label: (el: EdgeSingular) => {
          const label = bundleEdge ? `${el.data('parallelEdgesCount')} Links` : el.data('name');
          return showSelectedEdgeLabel ? label : undefined;
        },
      },
    },
  ];
};
