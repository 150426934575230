import { useEffect } from 'react';
import { usePrevious } from 'react-use';
import { F } from '@mobily/ts-belt';

export const useUpdate = <T>(newVal: T, updateFn: (val: T, oldVal?: T) => void) => {
  const prevVal = usePrevious(newVal);
  useEffect(() => {
    if (!F.equals(newVal, prevVal)) {
      updateFn(newVal, prevVal);
    }
  });
};
