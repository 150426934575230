/* eslint-disable no-nested-ternary */
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useController, useFormContext, useFormState, useWatch } from 'react-hook-form';
import { FeatureFlagged } from '@backstage/core-app-api';
import { Button, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/InfoRounded';
import { G } from '@mobily/ts-belt';
import {
  GnssConfig,
  GNSSControllerConfigSchema,
  GNSSPositionSchema,
  isInvalidLatLon,
} from '@netinsight/crds-timetransfer';
import {
  buttonPropsFromPermission,
  CheckboxField,
  DrawerPanel,
  FeatureFlags,
  PermissionResult,
  TextField,
  useFormStyles,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import { GnssSupportedSystemsPanel, SystemNames } from './GnssSupportedSystemsPanel';
import { useGnssPositionMetrics } from '../../../../../../hooks/sync';

export type GnssConfigFormProps = {
  permission: PermissionResult;
  nodeId: string;
};

export const GnssControllerConfigForm: FunctionComponent<GnssConfigFormProps> = ({ permission, nodeId }) => {
  const { control } = useFormContext<GnssConfig>();
  const styles = useFormStyles();
  const {
    data: position,
    isLoading: isLoadingPosition,
    error: positionError,
  } = useGnssPositionMetrics(nodeId, { refreshInterval: 10_000 });
  const [isSupportedGnssPanelOpen, setSupportedGnssPanelOpen] = useState(false);
  const buttonProps = buttonPropsFromPermission(permission);

  const useBeidouProps = useController({
    control,
    name: 'controller.useBeidou',
  });

  const useGlonassProps = useController({
    control,
    name: 'controller.useGLONASS',
  });

  const useGpsProps = useController({
    control,
    name: 'controller.useGPS',
  });

  const useGalileoProps = useController({
    control,
    name: 'controller.useGalileo',
  });

  const useQzssProps = useController({
    control,
    name: 'controller.useQZSS',
  });

  const useSbasProps = useController({
    control,
    name: 'controller.useSBAS',
  });

  const enableTraimProps = useController({
    control,
    name: 'controller.enableTraim',
  });

  const enableTraimAdaptiveProps = useController({
    control,
    name: 'controller.enableTraimAdaptive',
  });

  // GAS-5715 - Hide masking angle for current release since we have issues
  // const { field: maskingAngleProps } = useTextFieldController({
  //   control,
  //   name: 'controller.maskingAngle',
  //   label: 'Masking angle',
  //   schema: GNSSControllerConfigSchema.shape.maskingAngle,
  // });

  const maskPositionProps = useController({
    control,
    name: 'controller.maskPosition',
  });

  const maskPosition = useWatch({ control, name: 'controller.maskPosition' });

  const { field: traimThresholdProps } = useTextFieldController({
    control,
    name: 'controller.traimThresholdNs',
    label: 'T-RAIM threshold (ns)',
    schema: GNSSControllerConfigSchema.shape.traimThresholdNs,
  });

  const { field: positionLatProps } = useTextFieldController({
    control,
    name: 'controller.position.lat',
    label: 'Latitude',
    schema: GNSSPositionSchema.shape.lat.optional(),
    numberPrecision: 7,
  });

  const { field: positionLonProps } = useTextFieldController({
    control,
    name: 'controller.position.lon',
    label: 'Longitude',
    schema: GNSSPositionSchema.shape.lon.optional(),
    numberPrecision: 7,
  });

  const { field: positionAltMslProps } = useTextFieldController({
    control,
    name: 'controller.position.altMSL',
    label: 'Altitude (m)',
    schema: GNSSPositionSchema.shape.altMSL.optional(),
    numberPrecision: 4,
  });

  const { errors } = useFormState({ control });
  const { setValue } = useFormContext<GnssConfig>();

  const hasLocationInGnssMetrics =
    !isLoadingPosition &&
    G.isNullable(positionError) &&
    G.isNotNullable(position) &&
    G.isNotNullable(position.lat) &&
    G.isNotNullable(position.lon) &&
    !(isInvalidLatLon(position.lat) && isInvalidLatLon(position.lon));

  const handleUseGnssStatusLocation = useCallback(() => {
    if (!hasLocationInGnssMetrics) {
      return;
    }
    setValue('controller.position', {
      lat: position.lat!,
      lon: position.lon!,
      altMSL: position.altMsl ?? 0,
    });
  }, [position, setValue, hasLocationInGnssMetrics]);

  const handleSystemSelection = useCallback(
    (flags: SystemNames) => {
      setValue('controller.useBeidou', flags.includes('Beidou'));
      setValue('controller.useGalileo', flags.includes('Galileo'));
      setValue('controller.useGLONASS', flags.includes('GLONASS'));
      setValue('controller.useGPS', flags.includes('GPS'));
      setValue('controller.useQZSS', flags.includes('QZSS'));
      setSupportedGnssPanelOpen(false);
    },
    [setValue],
  );

  return (
    <>
      <Paper className={styles.formSection} component="fieldset">
        <Typography component="h6" variant="h6">
          GNSS selection
        </Typography>
        {errors.controller?.root ? <Alert severity="error">{errors.controller.root.message}</Alert> : null}
        <div className={styles.formGrid}>
          <CheckboxField
            label="Beidou"
            description={GNSSControllerConfigSchema.shape.useBeidou.description}
            fieldProps={useBeidouProps}
          />
          <CheckboxField
            label="Galileo"
            description={GNSSControllerConfigSchema.shape.useGalileo.description}
            fieldProps={useGalileoProps}
          />
          <CheckboxField
            label="GLONASS"
            description={GNSSControllerConfigSchema.shape.useGLONASS.description}
            fieldProps={useGlonassProps}
          />
          <CheckboxField
            label="GPS"
            description={GNSSControllerConfigSchema.shape.useGPS.description}
            fieldProps={useGpsProps}
          />
          <CheckboxField
            label="QZSS"
            description={GNSSControllerConfigSchema.shape.useQZSS.description}
            fieldProps={useQzssProps}
          />
          <CheckboxField
            label="SBAS"
            description={GNSSControllerConfigSchema.shape.useSBAS.description}
            fieldProps={useSbasProps}
          />
        </div>
        <div className={styles.formRow}>
          <Button
            variant="outlined"
            startIcon={<InfoIcon />}
            color="primary"
            onClick={() => setSupportedGnssPanelOpen(true)}
          >
            Supported combinations
          </Button>
        </div>
      </Paper>
      <Paper className={styles.formSection} component="fieldset">
        <Typography component="h6" variant="h6">
          Accuracy settings
        </Typography>
        <div className={styles.formGrid}>
          {/* GAS-5715 - Hide masking angle for current release since we have issues */}
          {/* <TextField fullWidth {...maskingAngleProps} /> */}
          <CheckboxField
            label="Enable T-RAIM"
            description={GNSSControllerConfigSchema.shape.enableTraim.description}
            fieldProps={enableTraimProps}
          />
          <TextField fullWidth {...traimThresholdProps} />

          <FeatureFlagged with={FeatureFlags.ShowAdvancedSettings}>
            <CheckboxField
              label="Enable adaptive T-RAIM"
              description={GNSSControllerConfigSchema.shape.enableTraimAdaptive.description}
              fieldProps={enableTraimAdaptiveProps}
            />
          </FeatureFlagged>
        </div>
      </Paper>

      <Paper className={styles.formSection} component="fieldset">
        <Typography component="h6" variant="h6">
          Nominal antenna location
        </Typography>
        <div className={styles.formRow}>
          <Button
            type="button"
            variant="outlined"
            color="default"
            size="small"
            disabled={buttonProps.disabled || !hasLocationInGnssMetrics || maskPosition}
            title={
              buttonProps.disabled === true
                ? buttonProps.title
                : !hasLocationInGnssMetrics
                  ? 'Available once the node has reported its gnss location'
                  : undefined
            }
            onClick={handleUseGnssStatusLocation}
          >
            Use location from GNSS status
          </Button>
        </div>
        <div className={styles.formGrid}>
          <TextField
            fullWidth
            {...positionLatProps}
            InputProps={{
              ...positionLatProps.InputProps,
              readOnly: maskPosition,
              type: maskPosition ? 'password' : 'number',
            }}
          />
          <TextField
            fullWidth
            {...positionLonProps}
            InputProps={{
              ...positionLonProps.InputProps,
              readOnly: maskPosition,
              type: maskPosition ? 'password' : 'number',
            }}
          />
          <TextField
            fullWidth
            {...positionAltMslProps}
            InputProps={{
              ...positionAltMslProps.InputProps,
              readOnly: maskPosition,
              type: maskPosition ? 'password' : 'number',
            }}
          />
        </div>
      </Paper>
      <Paper className={styles.formSection} component="fieldset">
        <Typography component="h6" variant="h6">
          Confidentiality
        </Typography>
        <CheckboxField
          label="Mask position"
          description={GNSSControllerConfigSchema.shape.maskPosition.description}
          fieldProps={maskPositionProps}
        />
      </Paper>
      <DrawerPanel
        open={isSupportedGnssPanelOpen}
        onCloseClick={() => setSupportedGnssPanelOpen(false)}
        onClose={() => setSupportedGnssPanelOpen(false)}
      >
        <GnssSupportedSystemsPanel onSystemSelected={handleSystemSelection} />
      </DrawerPanel>
    </>
  );
};
