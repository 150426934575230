/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';
import { z } from 'zod';
import { InfoCard } from '@backstage/core-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, LinearProgress, Paper, Typography } from '@material-ui/core';
import { F } from '@mobily/ts-belt';
import { DEFAULT_LINK_OPTIONS, DynamicLinkNoiseParametersSchema } from '@netinsight/crds-timetransfer';
import {
  CheckboxField,
  getTooltipForWrappedBooleanSchema,
  NetiHeadingTypographyProps,
  usePermission,
  useFormStyles,
  useGlobalSettings,
  validateRequireAllFieldsOrNone,
  useSubmitButtonProps,
} from '@netinsight/management-app-common-react';
import { GlobalSettings, GlobalSettingsSchema } from '@netinsight/management-app-common-api';
import { useGlobalSettingsUpdate } from '../../../hooks/settings';
import { LinkOptionsFormContent } from '../../TimeNetworkPage/common';

type GlobalLinkOptionsFormValues = { options: GlobalSettings['linkOptions'] };

const GlobalLinkOptionSchema = GlobalSettingsSchema.shape.linkOptions.unwrap();
const LinkOptionsSchema = GlobalLinkOptionSchema.omit({
  dynamicLinkNoise: true,
}).extend({
  dynamicLinkNoise: DynamicLinkNoiseParametersSchema.optional().superRefine(
    validateRequireAllFieldsOrNone(DynamicLinkNoiseParametersSchema.shape),
  ),
});

export const GlobalLinkOptionsForm = () => {
  const formStyles = useFormStyles();
  const { data = {}, isLoading } = useGlobalSettings();
  const initialValues = useMemo(() => ({ options: data.linkOptions ?? {} }), [data]);
  const { trigger: update, permission: updatePermission } = useGlobalSettingsUpdate();
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updatePermission);
  const formProps = useForm<GlobalLinkOptionsFormValues>({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: zodResolver(
      z.object({
        options: LinkOptionsSchema,
      }),
    ),
  });
  const handleSubmitCallback = useCallback(
    async (submittedData: GlobalLinkOptionsFormValues) => {
      await update({ ...data, linkOptions: submittedData.options ?? {} });
    },
    [update, data],
  );
  const { handleSubmit, reset, control, formState } = formProps;
  const buttonProps = useSubmitButtonProps({ permission, formState });
  const prevInitialValues = usePrevious(initialValues);
  useEffect(() => {
    if (!F.equals(prevInitialValues, initialValues)) {
      reset(initialValues);
    }
  }, [initialValues, prevInitialValues, reset]);

  const autoCalibrationCheckboxProps = useController({
    control,
    name: 'options.autoCalibration',
    defaultValue: DEFAULT_LINK_OPTIONS.autoCalibration,
  });
  const deleteProfileOnChangeCheckboxProps = useController({
    control,
    name: 'options.deleteProfilesOnChange',
    defaultValue: DEFAULT_LINK_OPTIONS.deleteProfilesOnChange,
  });
  const deleteProfileOnAutoCalibrationCheckboxProps = useController({
    control,
    name: 'options.autoCalibrationDeletesAllProfiles',
    defaultValue: DEFAULT_LINK_OPTIONS.autoCalibrationDeletesAllProfiles,
  });

  if (isLoading || isLoadingPermission) {
    return <LinearProgress />;
  }

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(handleSubmitCallback)} className={formStyles.formContainer}>
        <InfoCard
          title="Global link defaults"
          titleTypographyProps={NetiHeadingTypographyProps.h2}
          cardClassName={formStyles.formContainer}
        >
          <Paper elevation={2} component="fieldset" className={formStyles.formSection}>
            <Typography {...NetiHeadingTypographyProps.h3}>Link profiles</Typography>
            <div className={formStyles.formGrid}>
              <CheckboxField
                fieldProps={autoCalibrationCheckboxProps}
                label="Auto-calibration"
                description={
                  getTooltipForWrappedBooleanSchema(
                    GlobalLinkOptionSchema.shape.autoCalibration,
                    DEFAULT_LINK_OPTIONS.autoCalibration,
                  ) ?? ''
                }
                fromValue={val => val ?? false}
                toValue={checked => checked ?? false}
              />
              <CheckboxField
                fieldProps={deleteProfileOnChangeCheckboxProps}
                label="Delete profiles on change"
                description={
                  getTooltipForWrappedBooleanSchema(
                    GlobalLinkOptionSchema.shape.deleteProfilesOnChange,
                    DEFAULT_LINK_OPTIONS.deleteProfilesOnChange,
                  ) ?? ''
                }
                fromValue={val => val ?? false}
                toValue={checked => checked ?? false}
              />
              <CheckboxField
                fieldProps={deleteProfileOnAutoCalibrationCheckboxProps}
                label="Delete profiles on auto-calibration"
                description={
                  getTooltipForWrappedBooleanSchema(
                    GlobalLinkOptionSchema.shape.autoCalibrationDeletesAllProfiles,
                    DEFAULT_LINK_OPTIONS.autoCalibrationDeletesAllProfiles,
                  ) ?? ''
                }
                fromValue={val => val ?? false}
                toValue={checked => checked ?? false}
              />
            </div>
          </Paper>
          <Paper elevation={2} component="fieldset" className={formStyles.formSection}>
            <Typography {...NetiHeadingTypographyProps.h3}>Link options</Typography>
            <LinkOptionsFormContent defaultOptions={DEFAULT_LINK_OPTIONS} headingLevel={4} />
          </Paper>
        </InfoCard>
        <div className={formStyles.buttonContainer}>
          <Button {...buttonProps} data-testid="btn-save-global-link-settings" />
        </div>
      </form>
    </FormProvider>
  );
};
