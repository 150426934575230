/* eslint-disable no-nested-ternary */
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import React, { useCallback, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { InfoCard, Progress, ResponseErrorPanel } from '@backstage/core-components';
import { Button, Typography } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useNodeNameMap, usePermission, useTimeNetworkConfigs } from '@netinsight/management-app-common-react';
import { getNodeCalibrationViewModel } from './helper';
import { CalibrationEditModel, CalibrationMode, CalibrationPersistedState, CalibrationViewModel } from './types';
import { useCalibrationMetrics, useNodeLinksUpdate } from './hooks';
import { NodeCalibrationForm } from './NodeCalibrationForm';
import { useLocalStorage } from 'react-use';
import { useNodeLinksByDirection } from '../../../../../hooks/links';

const driverObj = driver({
  showProgress: true,
  steps: [
    {
      element: '#node-calibration-local-time-errors-selector-table',
      popover: {
        description:
          'Current node time error compared to the reference. A positive value means the node is ahead of the reference and needs to slow down. Press USE in one of the columns to copy the current average time error for use in the calculation.',
      },
    },
    {
      element: '#node-calibration-custom-time-error-textfield',
      popover: {
        description:
          'The time error used as input for for calculating new path diff values. The purpose of the calculation is to determine new path diff values so that a node time error with the value used here is eliminated. For a positive time error, which means that the node is ahead of its reference, the node time will be decreased. For a negative time error, the node time will be increased.',
      },
    },
    {
      element: '#node-calibration-calculation-method-checkbox',
      popover: {
        description:
          'When this box is not checked, the calculated path diff values will affect the time of downstream nodes.​When this box is checked, the path diff values are calculated so that the time of downstream nodes is not affected. In both cases, the path diff values are calculated to eliminate the node time error on this node.',
      },
    },

    {
      element: '#node-calibration-calculate-button',
      popover: {
        description:
          'When Calculate is pressed, the new path diffs are filled with suggested values according to the selected method.',
      },
    },
    {
      element: '#node-calibration-local-time-error-prediction-table',
      popover: {
        description:
          'Predicted node time error, based on the current node time error and the new path diff values in the incoming links table.',
      },
    },
    {
      element: '#node-calibration-link-path-diffs-inLinks',
      popover: { description: 'The path diff values can also be adjusted manually for incoming links.' },
    },
    {
      element: '#node-calibration-link-path-diffs-outLinks',
      popover: { description: 'Same for outgoing links' },
    },
    {
      element: '#node-calibration-submit-button',
      popover: {
        description:
          'Write the new path diff values to the profiles. Note that there may be a delay before the values are used on the node (up to tens of seconds) and the control has converged (minutes and longer, depending on the control parameters).',
      },
    },
  ],
});

export const NodeCalibration = ({ nodeId, mode = 'node-time' }: { nodeId: string; mode: CalibrationMode }) => {
  const { data: linkConfigs, isLoading: isLoadingConfig, error: ttLinkError } = useNodeLinksByDirection(nodeId);
  const {
    data: clusterTimeTransferConfig,
    isLoading: isLoadingTimeTransferConfig,
    error: ttConfigError,
  } = useTimeNetworkConfigs();
  const [initialNodeCalibrationState, setInitialNodeCalibrationState] =
    useLocalStorage<CalibrationPersistedState>('node-calibration-state');
  const { data: metrics, isLoading: isLoadingMetrics } = useCalibrationMetrics(
    linkConfigs?.nodeIds ?? [],
    linkConfigs?.linkIds ?? [],
    {
      refreshInterval: 10_000,
    },
  );

  const { trigger: updateLinks, permission } = useNodeLinksUpdate(nodeId);
  const { data: nodeNameMap } = useNodeNameMap();
  const { isLoading: isLoadingPermission, ...canUpdatePermission } = usePermission(permission);
  const viewModel = useMemo<CalibrationViewModel>(
    () =>
      getNodeCalibrationViewModel({
        nodeId,
        inLinkTargets: linkConfigs?.inLinks ?? [],
        outLinkTargets: linkConfigs?.outLinks ?? [],
        unusedLinkTargets: linkConfigs?.unusedLinks ?? [],
        nodeNameMap: nodeNameMap ?? {},
        metrics,
        clusterTimeTransferConfig: clusterTimeTransferConfig ?? {},
      }),
    [
      nodeId,
      linkConfigs?.inLinks,
      linkConfigs?.outLinks,
      linkConfigs?.unusedLinks,
      nodeNameMap,
      metrics,
      clusterTimeTransferConfig,
    ],
  );

  const handleSubmit = useCallback(
    async (submittedData: CalibrationEditModel) => {
      await updateLinks({ submittedData: submittedData.linkTimeErrorsByLinkIdAndProfileIndex });
    },
    [updateLinks],
  );

  const handlePersistedStateChanged = useCallback(
    (newState: Partial<CalibrationPersistedState>) => {
      setInitialNodeCalibrationState({ ...initialNodeCalibrationState, ...newState });
    },
    [initialNodeCalibrationState, setInitialNodeCalibrationState],
  );

  const handleTourStartClick = useCallback(() => {
    driverObj.drive();
  }, []);

  const isLoading = isLoadingConfig || isLoadingTimeTransferConfig || isLoadingMetrics || isLoadingPermission;
  const loadError = ttLinkError ?? ttConfigError;
  return (
    <>
      <InfoCard
        title={mode === 'node-time' ? 'Node time calibration' : 'Link spread calibration'}
        titleTypographyProps={{ variant: 'h5' }}
        subheader={
          <div>
            {mode === 'node-time' ? (
              <>
                <Typography variant="body2">
                  This page is used for doing local node calibration. Local node calibration means that only the links
                  around the selected node are adjusted, as opposed to global calibration which changes all links.
                </Typography>
                <br />
                <Button
                  size="small"
                  type="button"
                  variant="outlined"
                  endIcon={<PlayArrowIcon />}
                  onClick={handleTourStartClick}
                >
                  Show me how
                </Button>
              </>
            ) : (
              <Typography variant="body2">
                This page is used for doing local link spread calibration. Link spread calibration aims to adjust the
                path diffs on incoming links so that the variation in link error for these links is reduced.
              </Typography>
            )}
          </div>
        }
      >
        {isLoading ? (
          <Progress />
        ) : loadError ? (
          <ResponseErrorPanel error={loadError} />
        ) : (
          <NodeCalibrationForm
            mode={mode}
            viewModel={viewModel}
            onSubmit={handleSubmit}
            permission={canUpdatePermission}
            persistedState={initialNodeCalibrationState ?? {}}
            onPersistedStateChanged={handlePersistedStateChanged}
          />
        )}
      </InfoCard>
      <Outlet />
    </>
  );
};
