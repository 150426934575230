import React, { FunctionComponent, PropsWithChildren } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { G } from '@mobily/ts-belt';
import { TTLinkEndpoint } from '@netinsight/crds-timetransfer';
import { OperStatus } from '@netinsight/node-manager-schema';
import { Link } from '@backstage/core-components';
import { LinkEndpointStableIcon } from './LinkEndpointStableIcon';
import { formatInterfaceName } from '../../../utils/nodes';

const useStyles = makeStyles((_: Theme) => ({
  item: {
    whiteSpace: 'normal',
    borderTopStyle: 'none',
  },
}));

export const LinkDetailEndpointStatus: FunctionComponent<
  PropsWithChildren<{
    label: string;
    endpoint: TTLinkEndpoint;
    ifaceName?: string;
    nodeNameMap: Record<string, string>;
    isStable?: boolean;
    operStatus?: OperStatus;
  }>
> = ({ label, ifaceName, endpoint, nodeNameMap, isStable, operStatus }) => {
  const styles = useStyles();
  const nodeName = nodeNameMap[endpoint.node] ?? endpoint.node;
  const port = G.isNotNullable(endpoint.port) ? `:${endpoint.port}` : '';
  const vlan = G.isNotNullable(endpoint.vlanId) ? `.${endpoint.vlanId}` : '';
  return (
    <div className={styles.item}>
      <Typography component="span">
        {label}
        {': '}
      </Typography>
      <Typography component="span" variant="body1" color="textPrimary">
        <LinkEndpointStableIcon status={isStable} />
        <Typography variant="body1" component={Link} to={`/nodes/info/${endpoint.node}`}>
          {nodeName ?? endpoint.node}
        </Typography>
        {' ('}
        <LinkEndpointStableIcon
          // eslint-disable-next-line no-nested-ternary
          status={operStatus === OperStatus.Up ? true : operStatus === OperStatus.Down ? false : undefined}
        />
        <Typography
          variant="body1"
          component={Link}
          to={`/nodes/info/${endpoint.node}/networks/interfaces#${formatInterfaceName(endpoint.iface, endpoint.vlanId)}`}
        >
          {`${ifaceName ?? endpoint.iface}${vlan}${port}`}
        </Typography>
        {') '}
      </Typography>
    </div>
  );
};
