import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { InfoCard, Progress, ResponseErrorPanel } from '@backstage/core-components';
import {
  NetiHeadingTypographyProps,
  usePermission,
  useFormStyles,
  useGlobalSettings,
  useSubmitButtonProps,
} from '@netinsight/management-app-common-react';
import { F } from '@mobily/ts-belt';
import { usePrevious } from 'react-use';
import { SSHKeysConfigForm } from './SSHKeysConfigForm';
import { GlobalSettings } from '@netinsight/management-app-common-api';
import { useGlobalSettingsUpdate } from '../../../hooks/settings';

type GlobalSSHConfigFormValues = Pick<GlobalSettings, 'sshKeys'>;

export const GlobalSSHKeys = () => {
  const { data, isLoading, error } = useGlobalSettings();
  const defaultValues: GlobalSSHConfigFormValues = useMemo(() => ({ sshKeys: data?.sshKeys ?? [] }), [data]);
  const formMethods = useForm<GlobalSSHConfigFormValues>({
    mode: 'onChange',
    defaultValues: { sshKeys: data?.sshKeys },
  });
  const { reset: resetForm, handleSubmit, formState } = formMethods;
  const prevDefaultFormValues = usePrevious(defaultValues);
  useEffect(() => {
    if (!F.equals(prevDefaultFormValues, defaultValues)) {
      resetForm(defaultValues);
    }
  }, [resetForm, defaultValues, prevDefaultFormValues]);

  const { trigger: updateGlobalSettings, permission: updatePermission } = useGlobalSettingsUpdate();
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updatePermission);
  const buttonProps = useSubmitButtonProps({ permission, formState });

  const submitNewSshConfig = useCallback(
    async (newSshKeyConfig: GlobalSSHConfigFormValues) => {
      return updateGlobalSettings({ ...(data ?? {}), ...newSshKeyConfig });
    },
    [updateGlobalSettings, data],
  );

  const styles = useFormStyles();

  if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  if (isLoading || isLoadingPermission) {
    return <Progress />;
  }

  return (
    <InfoCard title="Global SSH Keys" titleTypographyProps={NetiHeadingTypographyProps.h2}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitNewSshConfig)} noValidate className={styles.formContainer}>
          <SSHKeysConfigForm permission={permission} />
          <div className={styles.buttonContainer}>
            <Button {...buttonProps} data-testid="btn-submit-authentication-form" />
            <Button
              color="default"
              variant="outlined"
              onClick={() => resetForm(defaultValues)}
              data-testid="btn-cancel-authentication-form"
              disabled={buttonProps.disabled || !formState.isDirty}
            >
              Reset
            </Button>
          </div>
        </form>
      </FormProvider>
    </InfoCard>
  );
};
