import React, { FunctionComponent } from 'react';
import { Control, useFormState, useWatch } from 'react-hook-form';
import { G } from '@mobily/ts-belt';
import { LEGACY_PTP_CLOCK_ID } from '@netinsight/crds-timetransfer';
import {
  DurationFormatter,
  StatusBox,
  StatusBoxSection,
  booleanScale,
  isNullableOrEmpty,
} from '@netinsight/management-app-common-react';
import {
  PortStates,
  clockAccuracyScale,
  portStateScale,
  ptpReceiverName,
  timeSourceScale,
} from '../../../../../../utils/sync/ptpUtils';
import type { PtpReceiverConfigFormValues } from './PtpReceiverConfigForm';
import { UsePtpReceiverStatusMetricsResultData } from '../../../../../../hooks/sync';

const Unavailable = 'N/A';

export const PtpReceiverStatusBox: FunctionComponent<{
  clockId?: string;
  index: number;
  control: Control<PtpReceiverConfigFormValues>;
  metrics?: UsePtpReceiverStatusMetricsResultData;
}> = ({ clockId, index, control, metrics }) => {
  const formState = useFormState({ control });
  const ifaceName = useWatch({ control, name: `instances.${index}.interface` });
  const isDirty = formState.dirtyFields.instances?.[index]?.interface ?? false;

  if (isNullableOrEmpty(ifaceName) || isDirty || G.isNullable(metrics?.[ptpReceiverName(index)])) {
    return null;
  }

  const receiverMetrics = metrics[ptpReceiverName(index)];

  return (
    <StatusBox
      statuses={[
        ['State', portStateScale(receiverMetrics.portState)],
        ['Clock ID', clockId ?? LEGACY_PTP_CLOCK_ID],
        [
          'Offset from transmitter',
          receiverMetrics.portState === PortStates.TimeReceiver
            ? DurationFormatter.fromSeconds(receiverMetrics.currOffsetFromTransmitter).toMicroSeconds(3)
            : Unavailable,
        ],
        [
          'Mean path delay',
          receiverMetrics.portState === PortStates.TimeReceiver
            ? DurationFormatter.fromSeconds(receiverMetrics.currMeanPathDelay).toMicroSeconds(3)
            : Unavailable,
        ],
        [
          'Steps removed',
          receiverMetrics.portState === PortStates.TimeReceiver ? receiverMetrics.currStepsRemoved : Unavailable,
        ],
      ]}
      showToggle={false}
    >
      <StatusBoxSection
        key="time-properties"
        summary="Time properties"
        statuses={[
          [
            'Time source',
            `${timeSourceScale(receiverMetrics.timePropsTimeSource)} (0x${receiverMetrics.timePropsTimeSource.toString(16)})`,
          ],
          ['Time traceable', booleanScale(receiverMetrics.timePropsTimeTraceable)],
          ['Frequency traceable', booleanScale(receiverMetrics.timePropsFrequencyTraceable)],
        ]}
        open
      />
      <StatusBoxSection
        key="parent-dataset"
        summary="Parent dataset"
        statuses={[
          [
            'Transmitter clock ID',
            receiverMetrics.portState === PortStates.TimeReceiver ? receiverMetrics.transmitterClockId : Unavailable,
          ],
          [
            'GM clock ID',
            receiverMetrics.portState === PortStates.TimeReceiver ? receiverMetrics.gmClockId : Unavailable,
          ],
          [
            'GM clock class',
            receiverMetrics.portState === PortStates.TimeReceiver ? receiverMetrics.parentGmClockClass : Unavailable,
          ],
          [
            'GM clock accuracy',
            receiverMetrics.portState === PortStates.TimeReceiver
              ? clockAccuracyScale(receiverMetrics.parentGmAccuracy)
              : Unavailable,
          ],
          [
            'GM clock variance',
            receiverMetrics.portState === PortStates.TimeReceiver ? receiverMetrics.parentGmVariance : Unavailable,
          ],
          [
            'GM prio 1',
            receiverMetrics.portState === PortStates.TimeReceiver ? receiverMetrics.parentGmPrio1 : Unavailable,
          ],
          [
            'GM prio 2',
            receiverMetrics.portState === PortStates.TimeReceiver ? receiverMetrics.parentGmPrio2 : Unavailable,
          ],
        ]}
        open
      />
    </StatusBox>
  );
};
