import classNames from 'classnames';
import React, { FunctionComponent, useCallback, useContext } from 'react';
import { FieldArrayWithId, useController, useFormContext } from 'react-hook-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/CloseOutlined';
import { G } from '@mobily/ts-belt';
import {
  BasePtpTransmitterInstanceSchema,
  PtpProfiles,
  PtpProfileLabels,
  PTP_UNICAST_TTL_DEFAULT,
  PTP_MULTICAST_TTL_DEFAULT,
  PTP_PROFILES_WITH_MULTICAST,
  PTP_PROFILES_WITH_UNICAST,
  SMPTEProfilePtpTransmitterInstanceSchema,
  PtpSystemFrameRateSchema,
  PTP_SYSTEM_FRAME_RATE_DEFAULT,
} from '@netinsight/crds-timetransfer';
import {
  buttonPropsFromPermission,
  CheckboxField,
  ConfirmButton,
  getOptionsAndDescriptionsFromSchema,
  getSelectOptionFromPair,
  getSelectOptionsFromSchema,
  PermissionResult,
  TextField,
  useFormStyles,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import { ProfileSchema } from './constants';
import { PtpContext } from './PtpContext';
import { PtpInterfaceSelect } from './PtpInterfaceSelect';
import { PtpTransmitterEntryHeader } from './PtpTransmitterEntryHeader';
import { PtpTransmitterConfigFormValues } from './PtpTransmitterConfigForm';
import { PtpTransmitterIntervals } from './PtpTransmitterIntervals';
import { InterfaceInfo } from '../../../../../../types/nodes';

type PtpTransmitterConfigEntryProps = {
  index: number;
  field: FieldArrayWithId<PtpTransmitterConfigFormValues, 'instances'>;
  onRemoveClick: (index: number) => void;
  permission: PermissionResult;
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    debugContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
    withFixedTooltip: { paddingRight: theme.spacing(4), position: 'relative' },
    fixedTooltip: {
      position: 'absolute',
      top: '16px',
      right: '0',
    },
    accordionDetail: {
      flexDirection: 'column',
      gap: theme.spacing(3),
    },
  }),
  { name: 'Neti' },
);

export const PtpTransmitterConfigEntry: FunctionComponent<PtpTransmitterConfigEntryProps> = ({
  index,
  field,
  onRemoveClick,
  permission,
}) => {
  const styles = useStyles();
  const formStyles = useFormStyles();
  const { maxConnectionsTotal } = useContext(PtpContext);
  const { control } = useFormContext<PtpTransmitterConfigFormValues>();
  const handleRemove = useCallback(() => {
    onRemoveClick(index);
  }, [index, onRemoveClick]);

  const { field: domainInputProps } = useTextFieldController({
    control,
    name: `instances.${index}.domain`,
    schema: ProfileSchema[field.profile].shape.domain,
    label: 'Domain',
    defaultValue: field.domain,
  });

  const { field: priority1InputProps } = useTextFieldController({
    control,
    name: `instances.${index}.priority1`,
    label: 'Priority 1',
    defaultValue: field.priority1,
    valueAsNumber: true,
    description: ProfileSchema[field.profile]?.shape.priority1.description,
  });
  const isPriority1Readonly = field.profile !== PtpProfiles.Default1588 && field.profile !== PtpProfiles.G8265_1;

  const { field: priority2InputProps } = useTextFieldController({
    control,
    name: `instances.${index}.priority2`,
    label: 'Priority 2',
    defaultValue: field.priority2,
    schema: BasePtpTransmitterInstanceSchema.shape.priority2,
  });

  const { field: clockClassFieldProps } = useTextFieldController({
    control,
    defaultValue: field.clockClassSync,
    name: `instances.${index}.clockClassSync`,
    label: 'Clock Class (Sync)',
    placeholder: 'Auto',
    schema: BasePtpTransmitterInstanceSchema.shape.clockClassSync.unwrap().min(0).optional(), // exclude -1 used for backward compat in the schema
  });

  const { field: clockAccuracySelectProps } = useTextFieldController({
    control,
    name: `instances.${index}.clockAccuracy`,
    label: 'Clock Accuracy',
    defaultValue: field.clockAccuracy,
    schema: BasePtpTransmitterInstanceSchema.shape.clockAccuracy,
  });

  const { field: clockVarianceInputProps } = useTextFieldController({
    control,
    name: `instances.${index}.clockVariance`,
    label: 'Clock Variance',
    placeholder: 'Auto',
    defaultValue: field.clockVariance,
    schema: BasePtpTransmitterInstanceSchema.shape.clockVariance,
  });

  const { field: timeSourceSelectProps } = useTextFieldController({
    control,
    name: `instances.${index}.timeSource`,
    label: 'Time Source',
    schema: BasePtpTransmitterInstanceSchema.shape.timeSource,
    defaultValue: field.timeSource,
  });

  const oneStepCheckboxProps = useController({
    control,
    name: `instances.${index}.oneStep`,
    defaultValue: field.oneStep ?? true,
  });

  const { field: unicastMaxConnections } = useTextFieldController({
    control,
    name: `instances.${index}.unicastMaxConnections`,
    label: 'Max Connections',
    schema: BasePtpTransmitterInstanceSchema.shape.unicastMaxConnections,
    valueAsNumber: true,
    description:
      field.profile === PtpProfiles.G8275_1
        ? 'Only one connection is allowed for G.8275.1 profile'
        : `Maximum number of connections that can be established to this PTP instance out of total connections available (${maxConnectionsTotal})`,
  });

  const { field: unicastTTLProps } = useTextFieldController({
    control,
    name: `instances.${index}.unicastTTL`,
    label: 'Unicast TTL',
    schema: BasePtpTransmitterInstanceSchema.shape.unicastTTL,
    placeholder: PTP_UNICAST_TTL_DEFAULT.toString(),
    defaultValue: field.unicastTTL,
  });

  const { field: multicastTTLProps } = useTextFieldController({
    control,
    name: `instances.${index}.multicastTTL`,
    label: 'Multicast TTL',
    schema: BasePtpTransmitterInstanceSchema.shape.multicastTTL,
    placeholder: PTP_MULTICAST_TTL_DEFAULT.toString(),
    defaultValue: field.multicastTTL,
  });

  const { field: systemFrameRateProps } = useTextFieldController({
    control,
    name: `instances.${index}.systemFrameRate`,
    label: 'System frame rate',
    schema: SMPTEProfilePtpTransmitterInstanceSchema.shape.systemFrameRate,
    defaultValue:
      field.profile === PtpProfiles.SMPTE
        ? (field.systemFrameRate ?? PTP_SYSTEM_FRAME_RATE_DEFAULT)
        : PTP_SYSTEM_FRAME_RATE_DEFAULT,
  });

  const dropFrameProps = useController({
    control,
    name: `instances.${index}.dropFrame`,
  });

  const colorFrameProps = useController({
    control,
    name: `instances.${index}.colorFrame`,
  });

  const shouldDisableInterface = useCallback(
    (iface: InterfaceInfo) => {
      const isLayer2 = field.profile === PtpProfiles.G8275_1;
      return isLayer2 ? G.isNotNullable(iface.vlanId) : G.isNullable(iface.ip);
    },
    [field.profile],
  );

  return (
    <Paper className={formStyles.formSection} component="fieldset" aria-labelledby={`fieldset-title-${index}`}>
      <PtpTransmitterEntryHeader index={index} control={control} />
      <Box component="div" className={formStyles.formGrid}>
        <TextField
          label="Profile"
          description={ProfileSchema[field.profile]?.shape.profile.description}
          disabled
          fullWidth
          value={PtpProfileLabels[field.profile] ?? field.profile}
        />
        <PtpInterfaceSelect
          index={index}
          shouldDisable={shouldDisableInterface}
          description={BasePtpTransmitterInstanceSchema.shape.interface.description}
        />
        <TextField fullWidth {...domainInputProps} />
        <TextField fullWidth {...unicastMaxConnections} disabled={field.profile === PtpProfiles.G8275_1} />
        <CheckboxField
          fieldProps={oneStepCheckboxProps}
          label="One step"
          description={BasePtpTransmitterInstanceSchema.shape.oneStep.description}
        />
        <PtpTransmitterIntervals index={index} field={field} />
        {PTP_PROFILES_WITH_MULTICAST.includes(field.profile) ? <TextField fullWidth {...multicastTTLProps} /> : null}
        {PTP_PROFILES_WITH_UNICAST.includes(field.profile) ? <TextField fullWidth {...unicastTTLProps} /> : null}
      </Box>
      {field.profile === PtpProfiles.SMPTE ? (
        <Paper variant="elevation" className={formStyles.formSection}>
          <Typography variant="subtitle1" style={{ width: '100%', fontWeight: 'bold' }}>
            {PtpProfileLabels.smpte} configuration
          </Typography>
          <div className={formStyles.formGrid}>
            <TextField fullWidth {...systemFrameRateProps}>
              {getSelectOptionsFromSchema(PtpSystemFrameRateSchema.optional(), PTP_SYSTEM_FRAME_RATE_DEFAULT)}
            </TextField>
            <CheckboxField
              fieldProps={dropFrameProps}
              label="Drop frame"
              fromValue={v => v ?? false}
              description={SMPTEProfilePtpTransmitterInstanceSchema.shape.dropFrame.description}
            />
            <CheckboxField
              fieldProps={colorFrameProps}
              label="Color frame"
              fromValue={v => v ?? false}
              description={SMPTEProfilePtpTransmitterInstanceSchema.shape.colorFrame.description}
            />
            <TextField fullWidth label="Next jam mode" InputProps={{ readOnly: true }} disabled value="Disabled" />
          </div>
        </Paper>
      ) : null}
      <Accordion style={{ width: '100%' }} variant="elevation">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">Fixed Quality</Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetail}>
          <Typography variant="subtitle1">
            Configure fixed clock quality parameters to prevent automatic quality detection according to the current
            synchronization state of the device.
          </Typography>
          <Box component="div" className={formStyles.formGrid} style={{ width: '100%' }}>
            <TextField
              {...priority1InputProps}
              className={classNames({
                [formStyles.readonlyInput]: isPriority1Readonly,
              })}
              fullWidth
              InputProps={{
                ...priority1InputProps.InputProps,
                readOnly: isPriority1Readonly,
              }}
            />
            <TextField fullWidth {...priority2InputProps} />
            <TextField fullWidth {...clockClassFieldProps} />
            <TextField fullWidth {...clockAccuracySelectProps}>
              {getOptionsAndDescriptionsFromSchema(BasePtpTransmitterInstanceSchema.shape.clockAccuracy)
                .filter(([val]) => val !== null && (val >= 0x20 || val === -1)) // hide clock accuracy values in the 2019 spec but not in the 2008 spec from UI https://netinsight.atlassian.net/browse/GAS-3915
                .map(getSelectOptionFromPair)}
            </TextField>
            <TextField fullWidth {...clockVarianceInputProps} />
            <TextField fullWidth {...timeSourceSelectProps}>
              {getOptionsAndDescriptionsFromSchema(BasePtpTransmitterInstanceSchema.shape.timeSource)
                .filter(([val]) => val !== null && val !== 0x39) // hide serial time code on ui https://netinsight.atlassian.net/browse/GAS-3625
                .map(getSelectOptionFromPair)}
            </TextField>
          </Box>
        </AccordionDetails>
      </Accordion>
      <ConfirmButton
        onClick={handleRemove}
        color="default"
        variant="outlined"
        startIcon={<RemoveIcon />}
        data-testid="btn-remove-ptp-config-entry"
        confirmation="This PTP instance will be removed, do you want to continue?"
        {...buttonPropsFromPermission(permission)}
      >
        Remove instance
      </ConfirmButton>
    </Paper>
  );
};
