import React, { useCallback, useMemo, useState } from 'react';
import {
  buttonPropsFromPermission,
  usePermission,
  useSnackbarHelper,
  useTableState,
} from '@netinsight/management-app-common-react';
import { ResponseErrorPanel, Table, TableColumn, TableProps } from '@backstage/core-components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import { DrawerPanel } from '@netinsight/management-app-common-react';
import { makeStyles, Typography, IconButton } from '@material-ui/core';
import { Options } from '@material-table/core';
import { DateTime } from 'luxon';

import { useAlarmSubscriptionDelete, useAlarmSubscriptions } from '../../../hooks';
import { AlarmSubscription } from '@netinsight/management-app-common-api';
import { AlarmSubscriptionDrawerContent } from './AlarmSubscriptionsDrawerContent';

const DRAWER_TRANSITION_DURATION = 300;

const useStyles = makeStyles(theme => ({
  empty: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  cellIcon: {
    width: '1.25rem',
    height: '1.25rem',
  },
  deleteIcon: {
    fill: theme.palette.error.main,
  },
}));

const cellStyle = {
  padding: '0.5rem 0.5rem',
};

interface SubscriptionRow {
  id: string;
  name: string;
  purpose?: string;
  target: string;
  format: string;
  status?: boolean;
  reason: string;
  lastTransitionTime: DateTime | null;
}

const toRow = (model: AlarmSubscription): SubscriptionRow => {
  const condition = model.status.conditions?.find((c: any) => c.type === 'Connected');
  return {
    id: model.id,
    name: model.spec.name,
    purpose: model.spec.purpose,
    target: model.spec.target,
    format: model.spec.format,
    status: condition?.status ? condition?.status === 'True' : undefined,
    reason: condition?.reason ?? 'Unknown status',
    lastTransitionTime: condition?.lastTransitionTime ? DateTime.fromISO(`${condition.lastTransitionTime}`) : null,
  };
};

const resolveColor = (status?: boolean) => {
  if (status === undefined) return 'disabled';
  return status ? 'success' : 'error';
};

export const AlarmSubscriptionsList = () => {
  const classes = useStyles();

  const { data, error, isLoading } = useAlarmSubscriptions();
  const { trigger: deleteSubscription, permission: deleteSubscriptionPermission } = useAlarmSubscriptionDelete();
  const { isLoading: isLoadingPermission, ...permission } = usePermission(deleteSubscriptionPermission);

  const rows = useMemo(() => data?.map(toRow) ?? [], [data]);
  const { snackbar } = useSnackbarHelper();
  const [open, setOpen] = useState<boolean>(false);

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        await deleteSubscription(id);
        snackbar.info('Subscription deleted');
      } catch (_err) {
        snackbar.error('Subscription deletion failed');
      }
    },
    [deleteSubscription, snackbar],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const addSubscription = useCallback(() => {
    setOpen(true);
  }, []);

  const actions: TableProps<SubscriptionRow>['actions'] = [
    {
      icon: () => <AddCircleIcon />,
      tooltip: 'Add subscription',
      isFreeAction: true,
      onClick: () => addSubscription(),
    },
  ];

  const showPagination = data && data.length > 20;

  const tableOptions: Options<any> = useMemo(
    () => ({
      paging: showPagination,
      pageSize: 20,
      actionsColumnIndex: -1,
      loadingType: 'linear',
      showEmptyDataSourceMessage: !isLoading,
      padding: 'dense',
      pageSizeOptions: [20, 50, 100],
      thirdSortClick: false,
    }),
    [showPagination, isLoading],
  );

  const tableColumns: TableColumn<SubscriptionRow>[] = useMemo(
    () => [
      {
        title: 'Status',
        field: 'status',
        width: '1rem',
        headerStyle: cellStyle,
        cellStyle: { padding: '0.25rem 0.25rem 0.25rem 1.5rem' },
        render: row => {
          return <CircleIcon color={resolveColor(row.status)} fontSize="small" data-testid="status" />;
        },
      },
      { title: 'Name', field: 'name', width: '12rem', headerStyle: cellStyle, cellStyle },
      { title: 'Purpose', field: 'purpose', width: '12rem', headerStyle: cellStyle, cellStyle },
      { title: 'Reason', field: 'reason', headerStyle: cellStyle, cellStyle },
      { title: 'Target', field: 'target', headerStyle: cellStyle, cellStyle },
      { title: 'Format', field: 'format', width: '12.5rem', hidden: true, headerStyle: cellStyle, cellStyle },
      {
        title: 'Last Status Change',
        field: 'lastTransitionTime',
        render: (rowData: any) => rowData.lastTransitionTime?.toRelative(),
        customSort: (a: SubscriptionRow, b: SubscriptionRow) =>
          a.lastTransitionTime && b.lastTransitionTime ? a.lastTransitionTime.diff(b.lastTransitionTime).valueOf() : 0,
        headerStyle: cellStyle,
        cellStyle,
      },
      {
        title: '',
        width: '2.5rem',
        sorting: false,
        render: row => (
          <IconButton
            {...buttonPropsFromPermission(permission)}
            onClick={() => handleDelete(row.id)}
            data-testid="btn-delete-subscription"
            style={{ padding: '0.125rem' }}
          >
            <DeleteIcon />
          </IconButton>
        ),
        headerStyle: { padding: 0 },
        cellStyle: { padding: '0.25rem', textAlign: 'center' },
      },
    ],
    [handleDelete, permission],
  );

  const tableStates = useTableState<SubscriptionRow>(tableColumns, tableOptions, 'alarm-subscription-table');

  return (
    <>
      {error && <ResponseErrorPanel error={error} />}
      {data && (
        <Table<SubscriptionRow>
          isLoading={isLoading || isLoadingPermission}
          title="Alarm subscriptions"
          data={rows}
          actions={actions}
          emptyContent={<Typography className={classes.empty}>No subscriptions present</Typography>}
          {...tableStates}
        />
      )}
      <DrawerPanel
        open={open}
        onCloseClick={handleClose}
        onClose={handleClose}
        transitionDuration={DRAWER_TRANSITION_DURATION}
      >
        <AlarmSubscriptionDrawerContent onClose={handleClose} />
      </DrawerPanel>
    </>
  );
};
