import StatusCodes from 'http-status-codes';
import { ReactNode, useCallback } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { A, G } from '@mobily/ts-belt';
import { k8sConfigApiRef, k8sNodeApiRef, k8sTTLinksApiRef } from '@netinsight/management-app-common-react';
import { getConfigWarnings } from '../../utils/nodes';
import { NodeManagerInterfacesFormValue } from '../../types/nodes';

export const useNodeManagerConfigUpdateConfirmationCallback = ({
  nodeId,
  render,
  noop,
}: {
  nodeId: string;
  render: (warnings: Exclude<ReturnType<typeof getConfigWarnings>, undefined>) => ReactNode;
  noop?: boolean;
}) => {
  const configApi = useApi(k8sConfigApiRef);
  const ttLinksApi = useApi(k8sTTLinksApiRef);
  const nodeApi = useApi(k8sNodeApiRef);

  return useCallback(
    async (updatedConfig: NodeManagerInterfacesFormValue) => {
      if (noop === true) {
        return undefined;
      }

      const [
        nodeManagerConfigResponse,
        nodeTimeTransferConfigResponse,
        nodeLinkTargetsResponse,
        clusterNetworkResponse,
      ] = await Promise.all([
        configApi.getNodeManagerConfig({ params: { nodeId } }),
        configApi.getNodeTimeTransferConfig({ params: { nodeId } }),
        ttLinksApi.getNodeLinkTargets({ params: { nodeId } }),
        nodeApi.getClusterNetwork(),
      ]);

      if (
        nodeManagerConfigResponse.status !== StatusCodes.OK ||
        G.isNullable(nodeManagerConfigResponse.body.config) ||
        nodeTimeTransferConfigResponse.status !== StatusCodes.OK ||
        G.isNullable(nodeTimeTransferConfigResponse.body.config) ||
        nodeLinkTargetsResponse.status !== StatusCodes.OK ||
        G.isNullable(nodeLinkTargetsResponse.body.config?.targets) ||
        clusterNetworkResponse.status !== StatusCodes.OK ||
        G.isNullable(clusterNetworkResponse.body)
      ) {
        // skip confirmation if we failed to fetch any part of the data
        return undefined;
      }

      const warnings = getConfigWarnings({
        currentNodeId: nodeId,
        updatedConfig,
        currentConfig: nodeManagerConfigResponse.body.config,
        timeTransferConfig: nodeTimeTransferConfigResponse.body.config,
        linkTargets: nodeLinkTargetsResponse.body.config.targets,
        clusterNetwork: clusterNetworkResponse.body,
      });
      return G.isNotNullable(warnings) && A.isNotEmpty(warnings) ? render(warnings) : undefined;
    },
    [nodeId, configApi, ttLinksApi, nodeApi, render, noop],
  );
};
