import React, { PropsWithChildren, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ResponseErrorPanel } from '@backstage/core-components';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { DrawerPanel, useTimeTransferConfig } from '@netinsight/management-app-common-react';
import { GnssMetricsTable } from './GnssMetricsTable';
import { useGnssMetrics, useGnssPositionMetrics } from '../../../../../../hooks/sync';

const DRAWER_TRANSITION_DURATION = 300;

const useStyles = makeStyles(() => ({
  drawer: {
    width: '48rem',
  },
}));

export const GnssMetricsDrawer = ({ nodeId }: PropsWithChildren<{ nodeId: string }>) => {
  const navigate = useNavigate();
  const [isDrawerOpen, setDrawerOpen] = useState(true);
  const handleClose = useCallback(() => {
    setDrawerOpen(false);
    setTimeout(() => {
      navigate(-1);
    }, DRAWER_TRANSITION_DURATION);
  }, [navigate]);

  const {
    data: metrics,
    isLoading: isLoadingMetrics,
    error: metricsError,
  } = useGnssMetrics(nodeId, { refreshInterval: 10_000 });
  const {
    data: position,
    isLoading: isLoadingPosition,
    error: positionError,
  } = useGnssPositionMetrics(nodeId, { refreshInterval: 10_000 });
  const { data: timeTransferConfig, isLoading: isLoadingTimeTransferConfig } = useTimeTransferConfig(nodeId);
  const styles = useStyles();
  const error = metricsError ?? positionError;
  const isLoading = isLoadingMetrics || isLoadingPosition || isLoadingTimeTransferConfig;
  return (
    <DrawerPanel
      className={styles.drawer}
      open={isDrawerOpen}
      onCloseClick={handleClose}
      onClose={handleClose}
      transitionDuration={DRAWER_TRANSITION_DURATION}
    >
      {error ? <ResponseErrorPanel error={error} /> : null}
      {isLoading ? <LinearProgress /> : null}
      {metrics && position && timeTransferConfig && !isLoading ? (
        <GnssMetricsTable
          metrics={metrics}
          position={position}
          maskPosition={timeTransferConfig.gnss?.controller?.maskPosition ?? false}
        />
      ) : null}
    </DrawerPanel>
  );
};
