import cytoscape, {
  Core as CytoscapeCore,
  EventHandler as CytoscapeEventHandler,
  NodeSingular,
  Singular,
} from 'cytoscape';
import cyCanvas, { CanvasInstance } from 'cytoscape-canvas';
// @ts-expect-error there is no corresponding typing for this package
import cytoscapeDomNode from 'cytoscape-dom-node';
import klay from 'cytoscape-klay';
import React, {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
// @ts-expect-error there is no corresponding typing for this package
import CytoscapeComponent from 'react-cytoscapejs';
import { useTheme } from '@material-ui/core';
import { A, D, F, G, pipe } from '@mobily/ts-belt';
import { NODE_GRAPH_TOGGLES, NodeGraphState, PersistedSyncLink, TimeNode } from '@netinsight/management-app-common-api';
import { isNullableOrEmpty, useResizeObserver } from '@netinsight/management-app-common-react';

import { NodeGraphContext } from './context';
import { cleanupHighlight, restoreNodePosition, restoreNodePositions } from './helpers';
import { getInitialNodeGraphState, reducer } from './reducer';
import { useStyles } from './styles';
import { useGraphStyles } from './graph-styles';
import { merge } from 'ts-deepmerge';
import { useUpdate } from '../../../hooks/node-graph';
import {
  LinkDirectionMap,
  LinkStabilityMap,
  NodeMetricsViewModel,
  NodeSyncMetricsViewModel,
} from '../../../types/node-graph';
import {
  getGraphElements,
  getTimeNodeDom,
  GraphEdgeData,
  GraphNodeData,
  updateHighlightedNodes,
} from '../../../utils/node-graph';
import { DEFAULT_GRAPH_HEIGHT, DEFAULT_GRAPH_WIDTH, NODE_GRAPH_LAYOUTS } from '../../../constants/node-graph';

cytoscape.use(klay);
cytoscape.use(cyCanvas);
cytoscape.use(cytoscapeDomNode);

type NodeGraphProps = PropsWithChildren<{
  graphId: string;
  nodes: TimeNode[];
  links: PersistedSyncLink[];
  linkDirectionMap: LinkDirectionMap;
  linkStabilityMap: LinkStabilityMap;
  syncRegionLookup: (nodeId: string) => string;
  clusteringEnabled?: boolean;
  initialState: NodeGraphState;
  nodeMetrics?: NodeMetricsViewModel;
  nodeSyncMetrics?: NodeSyncMetricsViewModel;
}>;

export const NodeGraph: FunctionComponent<NodeGraphProps> = memo(function NodeGraphComp({
  graphId,
  nodes,
  links,
  linkDirectionMap,
  linkStabilityMap,
  initialState: initialDbState,
  syncRegionLookup,
  nodeMetrics,
  nodeSyncMetrics,
  children,
}) {
  const initialState = useMemo(() => merge(getInitialNodeGraphState(), initialDbState), [initialDbState]);
  const [state, dispatch] = useReducer(reducer, initialState);

  const canvasSize = useMemo(() => ({ width: state.width, height: state.height }), [state.width, state.height]);
  const theme = useTheme();
  const graphStyles = useGraphStyles(theme, {
    showNodeLabel: state.toggles?.[NODE_GRAPH_TOGGLES.NodeLabels] === true,
    showParentNodes: state.toggles?.[NODE_GRAPH_TOGGLES.ParentNodes] === true,
    showSelectedEdgeLabel: state.toggles?.[NODE_GRAPH_TOGGLES.SelectedEdgeLabels] === true,
    bundleEdge: state.toggles?.[NODE_GRAPH_TOGGLES.EdgeBundling] === true,
    isTracing: !isNullableOrEmpty(state.tracingTargetNodeId),
    highlight: state.highlight,
  });
  const styles = useStyles();

  const cyRef = useRef<CytoscapeCore>();
  const cyDomRef = useRef<any>();
  const handleRef = useCallback((cy: any) => {
    cyRef.current = cy;
    if (G.isFunction(cy.domNode) && G.isNullable(cyDomRef.current)) {
      cyDomRef.current = cy.domNode();
    }
  }, []);

  const graphElements = useMemo(
    () =>
      getGraphElements({
        nodes,
        links,
        linkDirectionMap,
        linkStabilityMap,
        syncRegionLookup,
        getTimeNodeDom,
        selectedSyncRegions: state.syncRegions ?? [],
      }),
    [nodes, links, linkDirectionMap, linkStabilityMap, syncRegionLookup, state.syncRegions],
  );

  // #region STATE RESTORATION
  useUpdate(initialDbState, async newDbState => {
    if (G.isNotNullable(newDbState) && D.isNotEmpty(newDbState)) {
      // TODO: see if we can salvage 1.2 graph states here
      dispatch({
        type: 'load',
        state: {
          ...newDbState,
          // update w/h since graph could have been saved under a different viewport
          width: state.width !== DEFAULT_GRAPH_WIDTH ? state.width : newDbState.width,
          height: state.height !== DEFAULT_GRAPH_HEIGHT ? state.height : newDbState.height,
        },
        id: graphId,
      });
      const cy = cyRef.current;
      if (!cy) {
        return;
      }

      if (G.isNotNullable(newDbState.zoom) && G.isNotNullable(newDbState.pan)) {
        cy.viewport({
          zoom: newDbState.zoom,
          pan: newDbState.pan,
        });
      }
      if (G.isNullable(newDbState.nodePositions)) {
        cyRef.current?.layout(NODE_GRAPH_LAYOUTS.default).run();
      }
    }
  });

  useUpdate(
    { graphId, nodePositions: state.nodePositions, stateGraphId: state.id },
    async ({ nodePositions: newNodePositions, stateGraphId, graphId: graphId2 }) => {
      const cy = cyRef.current;
      if (
        G.isNullable(cy) ||
        G.isNullable(newNodePositions) ||
        D.isEmpty(newNodePositions) ||
        stateGraphId !== graphId2
      ) {
        return;
      }
      await restoreNodePositions(cy, newNodePositions);
    },
  );
  // #endregion

  // #region SETUP & TEARDOWN ON NODE FILTERING/LOADING
  const handleNodePositionChanged = useCallback(
    (evt: cytoscape.EventObject) => {
      const target = evt.target as NodeSingular;
      dispatch({ type: 'node-position', nodeId: target.id(), position: target.position() });
    },
    [dispatch],
  );
  const handleAddedElemPosition: CytoscapeEventHandler = useCallback(
    evt => {
      if (G.isNullable(evt?.target) || G.isNullable(state.nodePositions) || graphId !== state.id) {
        return;
      }
      const elem = evt.target as Singular;
      restoreNodePosition(elem, state.nodePositions);
    },
    [state.nodePositions, graphId, state.id],
  );
  const handleRemovedElem = useCallback((evt: any) => {
    if (G.isNullable(evt?.target)) {
      return;
    }
    const cyDom = cyDomRef.current;
    if (!cyDom) {
      return;
    }
    const elem = evt.target as Singular;
    if (elem.isNode() && elem.isChildless()) {
      const removedNodeId = elem.id();
      const nodeDom = cyDom._node_dom[removedNodeId];
      if (nodeDom) {
        delete cyDom._node_dom[removedNodeId];
        cyDom._resize_observer.unobserve(nodeDom);
        cyDom._nodes_dom_container.removeChild(nodeDom);
      }
    }
  }, []);
  useEffect(() => {
    cyRef.current?.on('position', 'node:childless', handleNodePositionChanged);
    cyRef.current?.on('add', 'node:childless', handleAddedElemPosition);
    cyRef.current?.on('remove', 'node:childless', handleRemovedElem);

    return () => {
      cyRef.current?.off('position', 'node:childless', handleNodePositionChanged);
      cyRef.current?.off('add', 'node:childless', handleAddedElemPosition);
      cyRef.current?.off('remove', 'node:childless', handleRemovedElem);
    };
  }, [handleNodePositionChanged, handleAddedElemPosition, handleRemovedElem]);
  // #endregion

  // #region RESIZING
  const updateCanvasSize = useMemo(
    () =>
      F.throttle((_, entry: ResizeObserverEntry) => {
        const { blockSize: height, inlineSize: width } = entry.contentBoxSize[0];
        dispatch({ type: 'resize', width, height });
        return Promise.resolve().then(() => cyRef.current?.resize());
      }, 200),
    [dispatch],
  );
  const resizeObserverRef = useResizeObserver<HTMLDivElement>(updateCanvasSize);
  // #endregion

  // #region BACKGROUND RENDERING
  const backgroundImageRef = useRef<HTMLImageElement>();
  const bottomLayer = useRef<CanvasInstance>();
  const bottomLayerCanvasElem = useRef<HTMLCanvasElement>();
  const draw = useCallback(() => {
    if (!backgroundImageRef.current || !bottomLayer.current || !bottomLayerCanvasElem.current) {
      return;
    }
    const ctx = bottomLayerCanvasElem.current.getContext('2d');
    if (ctx) {
      bottomLayer.current.resetTransform(ctx);
      bottomLayer.current.clear(ctx);
      bottomLayer.current.setTransform(ctx);
      ctx.save();
      if (state.toggles?.[NODE_GRAPH_TOGGLES.BackgroundScaling] === true) {
        const sW = backgroundImageRef.current.naturalWidth;
        const sH = backgroundImageRef.current.naturalHeight;
        const dW = bottomLayerCanvasElem.current.width;
        const dH = (sH * dW) / sW;
        ctx.drawImage(backgroundImageRef.current, 0, 0, sW, sH, 0, 0, dW, dH);
      } else {
        ctx.drawImage(backgroundImageRef.current, 0, 0);
      }
    }
  }, [state.toggles]);
  useEffect(() => {
    const cy = cyRef.current;
    if (cy && !isNullableOrEmpty(state.backgroundImageUrl) && state.toggles?.[NODE_GRAPH_TOGGLES.Background] === true) {
      backgroundImageRef.current = new Image();
      backgroundImageRef.current.onload = () => {
        bottomLayer.current = cy.cyCanvas({ zIndex: -1 });
        bottomLayerCanvasElem.current = bottomLayer.current.getCanvas();
        draw();
        cy.on('render cyCanvas.resize', draw);
      };
      backgroundImageRef.current.src = state.backgroundImageUrl;
    }

    return () => {
      // clear previous background
      if (bottomLayerCanvasElem.current && bottomLayer.current) {
        const ctx = bottomLayerCanvasElem.current.getContext('2d');
        if (ctx) {
          bottomLayer.current.clear(ctx);
        }
        bottomLayerCanvasElem.current?.remove();
      }
      cyRef.current?.off('render cyCanvas.resize', draw);
    };
  }, [state.backgroundImageUrl, draw, state.toggles]);
  // #endregion

  // #region HIGHLIGHTING
  useUpdate(
    [state.highlight, nodeMetrics, nodeSyncMetrics] as const,
    async ([newHighlight, newNodeMetrics, newNodeSyncMetrics]) => {
      await Promise.resolve()
        .then(() => {
          const cy = cyRef.current;
          const cyDom = cyDomRef.current;
          if (!cyDom || !cy) {
            return;
          }
          updateHighlightedNodes(cy, cyDom, {
            mode: newHighlight,
            nodeMetrics: newNodeMetrics,
            nodeSyncMetrics: newNodeSyncMetrics,
          });
          cy.panBy({ x: 0.1, y: 0.1 }); // trigger reset of positions
        })
        .catch(F.ignore);
    },
  );
  useUpdate(state.syncRegions, async (newSyncRegions, oldSyncRegions) => {
    if (
      G.isNullable(newSyncRegions) ||
      newSyncRegions.length === 0 ||
      (G.isNotNullable(newSyncRegions) &&
        G.isNotNullable(oldSyncRegions) &&
        A.difference(newSyncRegions, oldSyncRegions).length > 0)
    ) {
      await Promise.resolve()
        .then(() => {
          const cyDom = cyDomRef.current;
          const cy = cyRef.current;
          if (!cyDom || !cy) {
            return;
          }
          updateHighlightedNodes(cy, cyDom, { mode: state.highlight, nodeMetrics, nodeSyncMetrics });
          cy.panBy({ x: 0.1, y: 0.1 }); // trigger reset of positions
        })
        .catch(F.ignore);
    }
  });
  useUpdate(
    { nodeMetrics, nodeSyncMetrics },
    ({ nodeMetrics: updatedNodeMetrics, nodeSyncMetrics: updatedNodeSyncMetrics }) => {
      const cy = cyRef.current;
      const cyDom = cyDomRef.current;
      if (!cyDom || !cy) {
        return;
      }
      updateHighlightedNodes(cy, cyDom, {
        mode: state.highlight,
        nodeMetrics: updatedNodeMetrics,
        nodeSyncMetrics: updatedNodeSyncMetrics,
      });
    },
  );
  // #endregion

  // #region NODES/EDGES SELECTION
  const handleSelection = useCallback(
    (evt: cytoscape.EventObject) => {
      const cy = evt.cy;
      cy.batch(() => {
        cleanupHighlight(cy);
        const selectedElements = cy.$('node:childless:selected, edge:selected');
        const selectedNodes = selectedElements.filter('node');
        const selectedEdges = selectedElements.filter('edge');
        const selectedNodesData: GraphNodeData[] = selectedNodes.map(n => n.data());
        const selectedEdgesData: GraphEdgeData[] = [];

        selectedEdges.forEach(selectedEdge => {
          if (
            state.toggles?.[NODE_GRAPH_TOGGLES.EdgeBundling] === true &&
            isNullableOrEmpty(state.tracingTargetNodeId) &&
            selectedEdge.data('parallelEdgesCount') > 1
          ) {
            selectedEdgesData.push(...selectedEdge.parallelEdges().map(e => e.data()));
          } else {
            selectedEdgesData.push(selectedEdge.data());
          }
        });

        dispatch({
          type: 'select',
          nodes: selectedNodesData,
          edges: pipe(
            selectedEdgesData,
            A.uniqBy(e => e.id),
            F.toMutable,
          ),
        });
      });
    },
    [dispatch, state.toggles, state.tracingTargetNodeId],
  );
  const handleParentSelection = useCallback((evt: cytoscape.EventObject) => {
    const target = evt.target as NodeSingular;
    const targetChildren = target.children('node:childless');
    targetChildren.filter(':unselected').select();
    targetChildren.edgesWith(targetChildren).filter(':unselected').select();
  }, []);
  useEffect(() => {
    cyRef.current?.on('select unselect', 'node:childless, edge', handleSelection);
    cyRef.current?.on('select', 'node:parent', handleParentSelection);
    return () => {
      cyRef.current?.off('select unselect', 'node:childless, edge', handleSelection);
      cyRef.current?.off('select', 'node:parent', handleParentSelection);
    };
  }, [handleSelection, handleParentSelection]);
  // #endregion

  return (
    <div className={styles.rootContainer}>
      <div className={styles.container} ref={resizeObserverRef}>
        <CytoscapeComponent
          elements={graphElements}
          stylesheet={graphStyles}
          style={canvasSize}
          cy={handleRef}
          className={styles.hightlightContainer}
          boxSelectionEnabled
        />
      </div>
      <NodeGraphContext.Provider value={{ state, dispatch, cyRef }}>{children}</NodeGraphContext.Provider>
    </div>
  );
});
