import type { Core, ElementDefinition, NodeSingular, Position, Singular } from 'cytoscape';
import { F, G } from '@mobily/ts-belt';

export const cleanupHighlight = (cy: Core) => {
  cy.$('.highlight').removeClass('highlight');
};

export const addProxyNodeForClusterParents = (cy: Core) => {
  cy.nodes(':parent').forEach(elem => {
    const { x1, x2, y1, y2 } = elem.boundingBox({ includeEdges: false, includeNodes: true, includeLabels: false });
    const proxyNode: ElementDefinition = {
      group: 'nodes',
      data: { ...elem.data(), id: `${elem.id()}-proxy`, parent: elem.id(), isProxy: true },
      position: { x: x1 + (x2 - x1) / 2, y: y1 + (y2 - y1) / 2 },
    };
    cy.add(proxyNode);
  });
};

export const handleZoomToNodeLevel = (cy: Core, nodeLevel: number) => {
  cy.batch(() => {
    cy.nodes(`[level = ${nodeLevel}]:childless`).removeClass('zoomHidden');
    cy.nodes(`[level = ${nodeLevel}]:parent`).removeClass('zoomShrink');
    cy.nodes(`[level != ${nodeLevel}]:childless`).addClass('zoomHidden');
    cy.nodes(`[level != ${nodeLevel}]:parent`).addClass('zoomShrink');
    // todo: animate child node position to that of the parent-proxy and collapse parent node to that
  });
};

export const restoreNodePosition = (elem: Singular | NodeSingular, nodePositions: Record<string, Position>) => {
  if (elem.isNode() && elem.isChildless()) {
    const prevElemPosition = nodePositions[elem.id()];
    if (G.isNotNullable(prevElemPosition)) {
      elem.position(prevElemPosition);
    }
  }
};

export const restoreNodePositions = (cy: cytoscape.Core, newNodePositions: Record<string, Position>) => {
  return Promise.resolve()
    .then(() => {
      // when a graph have different sync-region filtering than the previous one, then the elements need to be rendered once to become consistent.
      cy.batch(() => {
        cy.nodes(':childless').forEach(elem => {
          // setting a compound node's position causes its children to shift, so avoid that
          const prevElemPosition = newNodePositions[elem.id()];
          if ((G.isNotNullable(prevElemPosition) && !F.equals(elem.position()), prevElemPosition)) {
            elem.position(prevElemPosition);
          }
        });
      });
    })
    .catch(F.ignore); // the component is unmounted, ignore this error
};

const isNotVisited = (nodeId: string, path: string[]) => !path.includes(nodeId);

export const findPaths = (srcId: string, destId: string, cy: cytoscape.Core) => {
  const result: cytoscape.SingularElementArgument[] = [];

  const queue: Array<Array<string>> = [];
  let path: Array<string> = [];
  path.push(srcId);
  queue.push(path);
  while (queue.length > 0) {
    path = queue.shift()!;
    const last = path[path.length - 1];
    if (last === destId) {
      let prevNodeId: string | undefined;
      for (const nodeId of path) {
        if (typeof prevNodeId !== 'undefined') {
          const edges = cy.$id(nodeId).edgesTo(cy.$id(prevNodeId)).filter('[usage >= 2][stability >= 2]');
          if (edges.length > 0) {
            result.push(cy.$id(nodeId), ...edges.toArray());
          }
        } else {
          result.push(cy.$id(nodeId));
        }
        prevNodeId = nodeId;
      }
    }
    const incomingEdges = cy.$id(last).incomers('edge[usage >= 2][stability >= 2]');
    const incomingNodes = incomingEdges.sources();
    // eslint-disable-next-line no-loop-func
    incomingNodes.forEach(incomerNode => {
      if (isNotVisited(incomerNode.id(), path)) {
        const newPath = [...path];
        newPath.push(incomerNode.id());
        queue.push(newPath);
      }
    });
  }

  return result;
};
