import React from 'react';
import { NodeManagerError, ErrorSeverity, SystemError } from '@netinsight/node-manager-schema';
import { Alert, AlertProps, Color } from '@material-ui/lab';
import { G } from '@mobily/ts-belt';

function getSeverity(error: NodeManagerError): Color {
  switch (error.severity) {
    case ErrorSeverity.Critical:
      return 'error';
    case ErrorSeverity.Major:
      return 'warning';
    case ErrorSeverity.Minor:
      return 'info';
    default:
      return 'info';
  }
}

function getErrorText(error: NodeManagerError) {
  switch (error.errorType) {
    case SystemError.ConfigurationApplyError:
      return 'Failed to apply configuration';
    case SystemError.ConfigurationInvalid:
      return 'Invalid configuration';
    case SystemError.ConfigurationVersionInvalid:
      return 'Invalid configuration version';
    default:
      return 'Unknown error';
  }
}

export const NodeError = ({ error, ...otherProps }: { error: NodeManagerError } & AlertProps) => {
  return (
    <Alert severity={getSeverity(error)} {...otherProps}>
      {G.isString(error.message) ? error.message : getErrorText(error)}
    </Alert>
  );
};
