import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import { S, G } from '@mobily/ts-belt';
import { NodeState } from '@netinsight/node-manager-schema';
import { Alert } from '@material-ui/lab';
import { InterfaceInfo } from '../../../../../../types/nodes';

const useStyles = makeStyles(theme => ({
  container: {
    margin: 0,
  },
  routingStateTable: {
    width: '100%',
    borderCollapse: 'collapse',
    ['& th']: {
      textAlign: 'left',
    },
    ['& th, td']: {
      border: '1px solid',
      fontSize: '1rem',
      borderColor: theme.palette.divider,
      padding: theme.spacing(1, 2),
    },

    ['& caption']: {
      fontWeight: 'bold',
      textAlign: 'left',
      fontSize: '1.125rem',
      marginBottom: theme.spacing(1),
    },
  },
  infoBoxIcon: {
    alignSelf: 'flex-start',
    paddingTop: theme.spacing(1.25),
  },
}));

export const NodeManagerRoutingState: FunctionComponent<{
  routingState: ReadonlyArray<NodeState.RouteInfo>;
  index: number;
  interfaceInfosMap: Record<string, InterfaceInfo>;
}> = ({ routingState, index, interfaceInfosMap }) => {
  const styles = useStyles();
  return routingState && routingState.length > 0 ? (
    <Alert
      variant="standard"
      severity="info"
      className={styles.container}
      classes={{
        icon: styles.infoBoxIcon,
      }}
    >
      <table className={styles.routingStateTable} data-testid={`namespace-${index}-routing-table-state`}>
        <caption>Routing table</caption>
        <thead>
          <tr>
            <th>Network</th>
            <th>Gateway</th>
            <th>Interface</th>
          </tr>
        </thead>
        <tbody>
          {routingState.map((route, routeIndex) => (
            <tr key={routeIndex}>
              <td>{route.network}</td>
              <td>{G.isNullable(route.gateway) || S.isEmpty(route.gateway) ? '-' : route.gateway}</td>
              <td>{interfaceInfosMap[route.interface]?.displayName ?? route.interface}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Alert>
  ) : null;
};
