import { PersistedSyncLink } from '@netinsight/management-app-common-api';
import { useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { equalValues, getDelayToAndFromRemote, getRoundTripTimeAndDelayDifference } from '../../utils/time-transfer';

export const useLinkProfileCalculation = ({ index }: { index: number }) => {
  const isSettingFieldValuesRef = useRef(false);
  const { control, setValue } = useFormContext<PersistedSyncLink>();
  const formValues = {
    roundtripTime: useWatch({ control, name: `profiles.${index}.roundtripTime` }),
    delayDifference: useWatch({ control, name: `profiles.${index}.delayDifference` }),
    delayFromRemote: useWatch({ control, name: `profiles.${index}.delayFromRemote` }),
    delayToRemote: useWatch({ control, name: `profiles.${index}.delayToRemote` }),
  };
  useEffect(() => {
    if (isSettingFieldValuesRef.current) {
      return;
    }
    isSettingFieldValuesRef.current = true;
    const { delayFromRemote, delayToRemote } = getDelayToAndFromRemote(formValues);
    if (!equalValues(delayFromRemote, formValues.delayFromRemote)) {
      setValue(`profiles.${index}.delayFromRemote`, delayFromRemote);
    }
    if (!equalValues(delayToRemote, formValues.delayToRemote)) {
      setValue(`profiles.${index}.delayToRemote`, delayToRemote);
    }
    setTimeout(() => (isSettingFieldValuesRef.current = false), 25);
    // Ignore dependency on formValues since only used for comparing form data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.roundtripTime, formValues.delayDifference, index, setValue, isSettingFieldValuesRef]);

  useEffect(() => {
    if (isSettingFieldValuesRef.current) {
      return;
    }
    isSettingFieldValuesRef.current = true;
    const { roundtripTime, delayDifference } = getRoundTripTimeAndDelayDifference(formValues);
    if (!equalValues(roundtripTime, formValues.roundtripTime)) {
      setValue(`profiles.${index}.roundtripTime`, roundtripTime);
    }
    if (!equalValues(delayDifference, formValues.delayDifference)) {
      setValue(`profiles.${index}.delayDifference`, delayDifference);
    }
    setTimeout(() => (isSettingFieldValuesRef.current = false), 25);
    // Ignore dependency on formValues since only used for comparing form data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.delayToRemote, formValues.delayFromRemote, index, setValue, isSettingFieldValuesRef]);
};
