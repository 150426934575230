import { scaleOrdinal } from 'd3-scale';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Control } from 'react-hook-form';
import { Link } from '@backstage/core-components';
import { IconButton, Paper, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  DurationFormatter,
  InputTooltip,
  SyncActiveStatusIcon,
  TextField,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import {
  LinkCalibrationViewModel,
  CalibrationEditModel,
  CalibrationLinkType,
  TimeReferenceErrors,
  CalibrationPredictionViewModel,
  TimeReferenceSelections,
  TimeReferenceType,
  CalibrationMode,
} from './types';
import { useStyles } from './styles';
import { getScalarTimeReferenceError, roundToPrecision } from './helper';
import { TimeReferenceDisplayNames, TimeReferenceSelectionOrder } from './constants';
import { G } from '@mobily/ts-belt';

export const LinkCalibrationHeader: FunctionComponent<{ type: CalibrationLinkType; mode: CalibrationMode }> = ({
  type,
  mode,
}) => {
  const styles = useStyles();
  return (
    <thead className={styles.header}>
      <tr>
        <th>Link</th>
        <th>Peer node</th>
        <th className="numeric">Profile index</th>
        <th className="numeric">
          RTT <InputTooltip text="Round-trip time in the calibration profile." />
        </th>
        <th className="numeric">
          Current path diff{' '}
          <InputTooltip text="Path diff in the current profile. Links have an A/B direction. The sign of the path diff is adjusted to reflect how the profile is seen from the current node, regardless of which node is A and B." />
        </th>
        {type !== 'unusedLinks' ? (
          <th>
            New path diff (μs){' '}
            <InputTooltip text="The new path diff, with the same sign convention as in Current path diff. Prefilled with the path diff from the profile and updated when pressing Calculate. Can also be modified manually. The predicted time errors will be updated to see the effect. Note that path diff must always be less in magnitude than RTT." />
          </th>
        ) : null}

        <th className="numeric">
          Link time error{' '}
          <InputTooltip
            text={
              mode === 'node-time'
                ? 'The link time error as experienced by the current node. A positive value means the node time is ahead, according to this link and its profile path diff.'
                : 'The link time error as experienced by the current node. A positive value means the node time is ahead, according to this link and its profile path diff. The link time error shown here is averaged over the period specified in the input field above.'
            }
          />
        </th>
        {type === 'inLinks' ? (
          <th className="numeric">
            Predicted link time error{' '}
            <InputTooltip text="The predicted link time error after the new path diff values have been applied. The sign is the same as link time error." />
          </th>
        ) : null}
        <th className="numeric">
          Peer time error{' '}
          <InputTooltip
            text={`The node time error on the ${
              type === 'outLinks' ? 'downstream' : 'upstream'
            } node, according to its reference. Positive means the ${
              type === 'outLinks' ? 'downstream' : 'upstream'
            } node is ahead compared to its reference.`}
          />
        </th>
        {type === 'outLinks' ? (
          <th className="numeric">
            Predicted peer time error{' '}
            <InputTooltip text="The predicted node time error on the downstream nodes, after the new path diff values have been applied. The sign is the same as Peer time error." />
          </th>
        ) : null}
      </tr>
    </thead>
  );
};

export const PeerTimeReferenceRow: FunctionComponent<{
  nodeId: string;
  type: TimeReferenceType;
  linkType: CalibrationLinkType;
  timeError?: number;
  predictedTimeError?: number;
  useAsReference?: boolean;
}> = ({ nodeId, type, linkType, timeError, predictedTimeError, useAsReference }) => {
  return (
    <tr>
      <th>
        <Typography
          variant="body1"
          component={Link}
          target="_blank"
          to={`/nodes/info/${nodeId}/inputs/${type === 'gnss' ? type : 'pps'}`}
        >
          {TimeReferenceDisplayNames[type]}
        </Typography>
      </th>
      <td className="numeric value">{DurationFormatter.fromSeconds(timeError ?? NaN).toMicroSeconds(3) ?? '-'}</td>
      {linkType === 'outLinks' ? (
        <td className="numeric value prediction">
          {DurationFormatter.fromSeconds(predictedTimeError ?? NaN).toMicroSeconds(3) ?? '-'}
        </td>
      ) : null}
      <td className="icon value">{useAsReference ? <CheckIcon /> : null}</td>
    </tr>
  );
};

export const PeerNodeTimeReferencesTable: FunctionComponent<{
  nodeId: string;
  nodeName: string;
  linkType: CalibrationLinkType;
  selections: TimeReferenceSelections;
  timeErrors: TimeReferenceErrors;
  predictedTimeErrors?: TimeReferenceErrors;
  expanded: boolean;
}> = ({ nodeId, nodeName, linkType, selections, timeErrors, predictedTimeErrors, expanded }) => {
  const styles = useStyles();
  if (!expanded) {
    return null;
  }
  return (
    <tr>
      <td colSpan={12} className={styles.subTable}>
        <table className={styles.table} data-testid="local-input-calibration-table">
          <caption>{nodeName} time inputs</caption>
          <thead className={styles.header}>
            <tr>
              <th style={{ textAlign: 'left' }}>Reference</th>
              <th style={{ textAlign: 'right' }}>Time error (μs)</th>
              {linkType === 'outLinks' ? <th style={{ textAlign: 'right' }}>Predicted time error</th> : null}
              <th style={{ textAlign: 'center' }}>Used as reference</th>
            </tr>
          </thead>
          <tbody className={styles.body}>
            {TimeReferenceSelectionOrder.map(timeReferenceType => (
              <PeerTimeReferenceRow
                key={timeReferenceType}
                type={timeReferenceType}
                linkType={linkType}
                nodeId={nodeId}
                timeError={timeErrors[timeReferenceType]}
                predictedTimeError={predictedTimeErrors?.[timeReferenceType] ?? NaN}
                useAsReference={selections[timeReferenceType]}
              />
            ))}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

export const LinkCalibrationRow: FunctionComponent<{
  type: CalibrationLinkType;
  index: number;
  data: LinkCalibrationViewModel;
  predictedLinkTimeError?: number;
  predictedPeerTimeReferenceErrors?: TimeReferenceErrors;
  control: Control<CalibrationEditModel>;
  onToggleExpansion: (id: string) => void;
  expanded: boolean;
}> = ({
  data,
  control,
  type,
  predictedLinkTimeError,
  predictedPeerTimeReferenceErrors,
  expanded,
  onToggleExpansion,
}) => {
  const { field: newPathDiffFieldProps, fieldState } = useTextFieldController({
    control,
    name: `linkTimeErrorsByLinkIdAndProfileIndex.${data.linkId}.${data.profileIndex!}.newPathDiff`,
    numberPrecision: 3,
    defaultValue: 0 as any,
    valueAsNumber: true,
    showInlineError: false,
    rules: {
      validate: (newPathDiffValue: any) => {
        if (G.isNullable(newPathDiffValue) || newPathDiffValue === 0 || G.isNullable(data.currentRtt)) {
          return true;
        }
        const currentRtt = roundToPrecision(data.currentRtt, 3);
        return Math.abs(newPathDiffValue) > currentRtt
          ? 'The absolute path diff value must be smaller than round-trip time'
          : undefined;
      },
    },
  });

  const handleToggleExpansionClick = useCallback(() => {
    onToggleExpansion?.(data.linkId);
  }, [data.linkId, onToggleExpansion]);

  const { error: predictedPeerTimeReferenceError } = getScalarTimeReferenceError(
    predictedPeerTimeReferenceErrors,
    data.peerTimeReferenceSelection,
  );

  return (
    <>
      <tr>
        <th>
          <IconButton
            size="small"
            aria-label="Toggle row details"
            type="button"
            onClick={handleToggleExpansionClick}
            style={{ marginRight: '0.5rem' }}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          <Link to={`/network/links/${data.linkId}`} target="_blank">
            {data.name ?? data.linkId}
          </Link>
        </th>
        <th>
          <Link to={`/nodes/info/${data.peerNodeId}/calibration`} target="_blank">
            {data.peerNodeName}
          </Link>
        </th>
        <td className="numeric" style={{ width: '2rem' }}>
          {data.profileIndex ?? '-'}
        </td>
        <td className="numeric value">
          {DurationFormatter.fromMicroseconds(data.currentRtt ?? NaN).toMicroSeconds(3) ?? '-'}
        </td>
        <td className="numeric value" data-testId="current-path-diff">
          {DurationFormatter.fromMicroseconds(data.currentPathDiff ?? NaN).toMicroSeconds(3) ?? '-'}
        </td>
        {type !== 'unusedLinks' ? (
          <td className="value error-container" data-testId="new-path-diff">
            <TextField
              fullWidth
              style={{ width: '100%' }}
              {...newPathDiffFieldProps}
              inputProps={{ ...newPathDiffFieldProps.inputProps, type: 'number', min: -1e4, max: 1e4, step: 1e-3 }}
            />
            {G.isNotNullable(fieldState.error?.message) ? (
              <div className="cell-error" title={fieldState.error?.message}>
                !
              </div>
            ) : null}
          </td>
        ) : null}

        <td className="numeric value">
          {DurationFormatter.fromSeconds(data.linkTimeError ?? NaN).toMicroSeconds(3) ?? '-'}
        </td>
        {type === 'inLinks' ? (
          <td className="numeric value prediction">
            {DurationFormatter.fromSeconds(predictedLinkTimeError ?? NaN).toMicroSeconds(3) ?? '-'}
          </td>
        ) : null}
        <td className="numeric value">
          <span>{DurationFormatter.fromSeconds(data.peerTimeError ?? NaN).toMicroSeconds(3) ?? '-'}</span>
          {data.peerTimeErrorType ? (
            <>
              <br />
              <SyncActiveStatusIcon label={TimeReferenceDisplayNames[data.peerTimeErrorType]} showIcon={false} />
            </>
          ) : null}
        </td>
        {type === 'outLinks' ? (
          <td className="numeric value prediction">
            {DurationFormatter.fromSeconds(predictedPeerTimeReferenceError ?? NaN).toMicroSeconds(3) ?? '-'}
          </td>
        ) : null}
      </tr>
      <PeerNodeTimeReferencesTable
        nodeId={data.peerNodeId}
        nodeName={data.peerNodeName}
        linkType={type}
        selections={data.peerTimeReferenceSelection}
        timeErrors={data.peerTimeReferenceErrors}
        predictedTimeErrors={predictedPeerTimeReferenceErrors}
        expanded={expanded}
      />
    </>
  );
};

const linkTypeScale = scaleOrdinal<CalibrationLinkType, string>(
  ['inLinks', 'outLinks', 'unusedLinks'],
  ['incoming links', 'outgoing links', 'unused links'],
).unknown('Unknown');

export const LinkCalibrationTable: FunctionComponent<{
  type: CalibrationLinkType;
  mode: CalibrationMode;
  data: LinkCalibrationViewModel[];
  control: Control<CalibrationEditModel>;
  predictions?: CalibrationPredictionViewModel;
}> = ({ mode, type, data, control, predictions }) => {
  const styles = useStyles();
  const [expandedRowId, setExpandedRowId] = useState<string>();
  const handleExpandRow = useCallback(
    (rowId: string) => {
      setExpandedRowId(currentId => (currentId === rowId ? undefined : rowId));
    },
    [setExpandedRowId],
  );

  return (
    <Paper className={styles.container} component="fieldset" id={`node-calibration-link-path-diffs-${type}`}>
      <Typography component="h6" variant="h6" style={{ textTransform: 'capitalize' }}>
        {linkTypeScale(type)}
      </Typography>
      <div className={styles.tableContainer}>
        <table className={styles.table} data-testid={`${type}-links-calibration-table`}>
          <LinkCalibrationHeader type={type} mode={mode} />
          <tbody className={styles.body}>
            {data.map((row, index) => (
              <LinkCalibrationRow
                key={row.linkId}
                data={row}
                predictedLinkTimeError={predictions?.inLinkTimeErrorsByLinkId?.[row.linkId]}
                predictedPeerTimeReferenceErrors={predictions?.outlinkPeerTimeReferenceErrorsByNodeId?.[row.peerNodeId]}
                type={type}
                index={index}
                control={control}
                onToggleExpansion={handleExpandRow}
                expanded={expandedRowId === row.linkId}
              />
            ))}
            {(data.length ?? 0) === 0 ? (
              <tr>
                <td colSpan={12} style={{ textAlign: 'center' }}>
                  {`No ${linkTypeScale(type)} with adjustable profiles`}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </Paper>
  );
};
