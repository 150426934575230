import { CONTROL_STATES } from '@netinsight/management-app-common-api';

/**
 * Maps control state values to corresponding readable labels.
 * If the state is not recognized, it defaults to "Unknown".
 */
const controlStatesMap: Record<string, string> = {
  [CONTROL_STATES.Holdover]: 'Holdover',
  [CONTROL_STATES.AbsTimeSymmetric]: 'Abstime Symmetric',
  [CONTROL_STATES.RelTime]: 'Reltime',
  [CONTROL_STATES.AbsTime]: 'Abstime',
};

export const controlStateScale = (state: string): string => {
  return controlStatesMap[state] || 'Unknown';
};
