import { FunctionComponent } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { PtpTransmitterConfigFormValues } from './PtpTransmitterConfigForm';
import { TextField, useTextFieldController } from '@netinsight/management-app-common-react';
import {
  PTP_ANNOUNCE_INTERVAL_DEFAULT,
  PTP_ANNOUNCE_INTERVAL_MAX,
  PTP_ANNOUNCE_INTERVAL_MIN,
  PTP_DELAY_REQUEST_INTERVAL_DEFAULT,
  PTP_DELAY_REQUEST_INTERVAL_MAX,
  PTP_DELAY_REQUEST_INTERVAL_MIN,
  PTP_SYNC_INTERVAL_DEFAULT,
  PTP_SYNC_INTERVAL_MAX,
  PTP_SYNC_INTERVAL_MIN,
  PtpProfiles,
  SMPTEProfilePtpTransmitterInstanceSchema,
} from '@netinsight/crds-timetransfer';
import React from 'react';
import { A } from '@mobily/ts-belt';
import { formatPtpInterval } from '../../../../../../utils/sync/ptpUtils';

type PtpTransmitterIntervalsProps = {
  index: number;
  field: FieldArrayWithId<PtpTransmitterConfigFormValues, 'instances'>;
};

const generatePtpIntervalOptions = (min: number, max: number, defaultValue: number) =>
  A.range(min, max).map(val => (
    <option value={val} key={val}>
      {formatPtpInterval(val)}
      {val === defaultValue ? ' (default)' : ''}
    </option>
  ));

export const PtpTransmitterIntervals: FunctionComponent<PtpTransmitterIntervalsProps> = ({ index, field }) => {
  const { control } = useFormContext<PtpTransmitterConfigFormValues>();

  const { field: announceIntervalInputProps } = useTextFieldController({
    control,
    name: `instances.${index}.announceInterval`,
    label: 'Announce interval',
    schema: SMPTEProfilePtpTransmitterInstanceSchema.shape.announceInterval,
    defaultValue: PTP_ANNOUNCE_INTERVAL_DEFAULT,
  });

  const { field: syncIntervalInputProps } = useTextFieldController({
    control,
    name: `instances.${index}.syncInterval`,
    label: 'Sync interval',
    schema: SMPTEProfilePtpTransmitterInstanceSchema.shape.announceInterval,
    defaultValue: PTP_SYNC_INTERVAL_DEFAULT,
  });

  const { field: delayRequestIntervalInputProps } = useTextFieldController({
    control,
    name: `instances.${index}.delayRequestInterval`,
    label: 'Delay request interval',
    schema: SMPTEProfilePtpTransmitterInstanceSchema.shape.delayRequestInterval,
    defaultValue: PTP_DELAY_REQUEST_INTERVAL_DEFAULT,
  });

  if (field.profile !== PtpProfiles.SMPTE) {
    return null;
  }

  return (
    <>
      <TextField
        fullWidth
        {...announceIntervalInputProps}
        select
        SelectProps={{ native: true }}
        description={SMPTEProfilePtpTransmitterInstanceSchema.shape.announceInterval.description}
      >
        {generatePtpIntervalOptions(
          PTP_ANNOUNCE_INTERVAL_MIN,
          PTP_ANNOUNCE_INTERVAL_MAX,
          PTP_ANNOUNCE_INTERVAL_DEFAULT,
        )}
      </TextField>
      <TextField
        fullWidth
        {...syncIntervalInputProps}
        select
        SelectProps={{ native: true }}
        description={SMPTEProfilePtpTransmitterInstanceSchema.shape.syncInterval.description}
      >
        {generatePtpIntervalOptions(PTP_SYNC_INTERVAL_MIN, PTP_SYNC_INTERVAL_MAX, PTP_SYNC_INTERVAL_DEFAULT)}
      </TextField>
      <TextField
        fullWidth
        {...delayRequestIntervalInputProps}
        select
        SelectProps={{ native: true }}
        description={SMPTEProfilePtpTransmitterInstanceSchema.shape.delayRequestInterval.description}
      >
        {generatePtpIntervalOptions(
          PTP_DELAY_REQUEST_INTERVAL_MIN,
          PTP_DELAY_REQUEST_INTERVAL_MAX,
          PTP_DELAY_REQUEST_INTERVAL_DEFAULT,
        )}
      </TextField>
    </>
  );
};
