import React from 'react';
import { DurationFormatter, useGroupCalibrationDetailedResult } from '@netinsight/management-app-common-react';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import {
  CalibrationSidecarProblem,
  CalibrationSidecarStatus,
  GroupCalibrationLinkId,
  GroupCalibrationDetailedResult,
} from '@netinsight/group-calibration-api';
import { PersistedSyncLink } from '@netinsight/management-app-common-api';
import WarningIcon from '@material-ui/icons/Warning';
import { useTTLinks } from '../../../../hooks/time-transfer';

const formatTime = (seconds: number): string => DurationFormatter.fromSeconds(seconds).toMicroSeconds(3) ?? '-';

interface DetailPaneProps {
  jobId: number;
}

type DetailRow = {
  linkId: string;
  linkName: string;
  node: string;
  peer: string;
  profileIndex: number | 'NaN';
  nodeTimeError: number;
  rtt: number;
  pathDiffBefore: number;
  pathDiffAfter: number;
  notices: string | undefined;
};

type UigDetailRow = {
  linkId: string;
  linkName: string;
  node: string;
  peer: string;
  profileIndex: number | 'NaN';
  linkError: number;
  rtt: number;
  pathDiffBefore: number;
  pathDiffAfter: number;
  notices: string | undefined;
};

const makeNotices = (status: CalibrationSidecarStatus, index: number): string => {
  const ret = [];
  if (status.delay_difference_limited[index] > 0.1) ret.push('Adjustment limited.');
  if (status.delay_difference_rtt_limited[index] > 0.1) ret.push('Adjustment limited to RTT.');
  if (status.profile_index_changed[index] > 0.1) {
    ret.push(
      'The profile index changed during the calibration interval, or the profile existed only during parts of the calibration interval.',
    );
  }
  return ret.join(' ');
};

const timeErrorForLink = (link: GroupCalibrationLinkId, problem: CalibrationSidecarProblem): number => {
  for (let i = 0; i < problem.nodenames!.length; i++) {
    if (problem.nodenames![i] === link.nodename) {
      return problem.time_error![i];
    }
  }
  return 0;
};

const makeRows = (result: GroupCalibrationDetailedResult, links: PersistedSyncLink[]) => {
  try {
    if (result.success !== true) return undefined;
    const linkName = (id: string) => links.find((link: PersistedSyncLink) => link.id === id)?.name ?? id;
    const problem = result.data?.problem;
    const solution = result.data?.result;
    const status = result.data?.status;
    if (!problem || !solution) return [];
    return problem.links
      ?.filter(link => link.time_transfer === true)
      .map((link: GroupCalibrationLinkId): DetailRow => {
        const originalIndex = problem.links!.findIndex(l => l.link_id === link.link_id);
        return {
          linkId: link.link_id,
          linkName: linkName(link.link_id),
          node: link.nodename,
          peer: link.peer,
          profileIndex: problem.profile_index?.at(originalIndex) ?? 'NaN',
          nodeTimeError: timeErrorForLink(link, problem),
          rtt: solution!.round_trip_time?.at(originalIndex) ?? 0,
          pathDiffBefore: problem!.profile_path_diff?.at(originalIndex) ?? 0,
          pathDiffAfter: solution!.delay_difference?.at(originalIndex) ?? 0,
          notices: status ? makeNotices(status, originalIndex) : undefined,
        };
      });
  } catch (_err) {
    return undefined;
  }
};

const makeUigRows = (result: GroupCalibrationDetailedResult, links: PersistedSyncLink[]) => {
  try {
    if (result.success !== true) return undefined;
    const linkName = (id: string) => links.find((link: PersistedSyncLink) => link.id === id)?.name ?? id;
    const problem = result.data?.problem;
    const solution = result.data?.result;
    const status = result.data?.status;
    if (!problem || !solution) return [];
    return problem.links
      ?.filter(link => link.time_transfer === false)
      .map((link: GroupCalibrationLinkId): UigDetailRow => {
        // find the original link index in problem links by link_id
        const originalIndex = problem.links!.findIndex(l => l.link_id === link.link_id);
        return {
          linkId: link.link_id,
          linkName: linkName(link.link_id),
          node: link.nodename,
          peer: link.peer,
          profileIndex: problem.profile_index?.at(originalIndex) ?? 'NaN',
          linkError: problem.link_error?.at(originalIndex) ?? 0,
          rtt: solution!.round_trip_time?.at(originalIndex) ?? 0,
          pathDiffBefore: problem!.profile_path_diff?.at(originalIndex) ?? 0,
          pathDiffAfter: solution!.delay_difference?.at(originalIndex) ?? 0,
          notices: status ? makeNotices(status, originalIndex) : undefined,
        };
      });
  } catch (_err) {
    return undefined;
  }
};

const makeCommitMessage = (commit: boolean | undefined, success: boolean | undefined) => {
  if (commit === undefined || success !== true) return 'Calibration failed, no changes applied.';
  if (commit) return 'Calibration results have been committed to link profiles.';
  return 'Calibration results were not committed to link profiles.';
};

export default (props: DetailPaneProps) => {
  const { data: links, isLoading: isLinksLoading } = useTTLinks({ revalidateOnMount: true });
  const { data: result, isLoading } = useGroupCalibrationDetailedResult(props.jobId, {
    revalidateOnMount: true,
    refreshInterval: 6_000,
  });

  const [messages, setMessages] = React.useState<string[]>([]);
  const [rows, setRows] = React.useState<DetailRow[]>([]);
  const [uigRows, setUigRows] = React.useState<UigDetailRow[]>([]);

  React.useEffect(() => {
    setMessages([
      makeCommitMessage(result?.options?.commit, result?.success),
      ...(result?.errorMessage ? result.errorMessage.split('\n') : []),
    ]);

    if (!result) return;
    if (!links) return;

    const newRows = makeRows(result, links);
    if (newRows !== undefined) setRows(newRows);
    const newUigRows = makeUigRows(result, links);
    if (newUigRows !== undefined) setUigRows(newUigRows);
  }, [result, links]);

  const hasData = (rows?.length ?? 0) > 0;
  const hasUigData = (uigRows?.length ?? 0) > 0;

  if (isLoading || isLinksLoading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box margin={1}>
      {messages && (
        <List dense>
          {messages.map(message => (
            <ListItem key={message}>
              <ListItemText primary={message} />
            </ListItem>
          ))}
        </List>
      )}
      {hasData && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Link</TableCell>
              <TableCell>Node</TableCell>
              <TableCell>Peer</TableCell>
              <TableCell>Profile Index</TableCell>
              <TableCell align="right">Node Time Error (before)</TableCell>
              <TableCell align="right">RTT</TableCell>
              <TableCell align="right">Path Diff (before)</TableCell>
              <TableCell align="right">Path Diff (after)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: DetailRow) => (
              <TableRow key={row.linkId}>
                <TableCell component="th" scope="row">
                  {row.linkName}
                  {row.notices && (
                    <Tooltip title={row.notices}>
                      <IconButton>
                        <WarningIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>{row.node}</TableCell>
                <TableCell>{row.peer}</TableCell>
                <TableCell>{row.profileIndex}</TableCell>
                <TableCell align="right">{formatTime(row.nodeTimeError)}</TableCell>
                <TableCell align="right">{formatTime(row.rtt)}</TableCell>
                <TableCell align="right">{formatTime(row.pathDiffBefore)}</TableCell>
                <TableCell align="right">{formatTime(row.pathDiffAfter)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {hasUigData && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Unused Link</TableCell>
              <TableCell>Node</TableCell>
              <TableCell>Peer</TableCell>
              <TableCell>Profile Index</TableCell>
              <TableCell align="right">Link Error (before)</TableCell>
              <TableCell align="right">RTT</TableCell>
              <TableCell align="right">Path Diff (before)</TableCell>
              <TableCell align="right">Path Diff (after)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uigRows.map((row: UigDetailRow) => (
              <TableRow key={row.linkId}>
                <TableCell component="th" scope="row">
                  {row.linkName}
                  {row.notices && (
                    <Tooltip title={row.notices}>
                      <IconButton>
                        <WarningIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>{row.node}</TableCell>
                <TableCell>{row.peer}</TableCell>
                <TableCell>{row.profileIndex}</TableCell>
                <TableCell align="right">{formatTime(row.linkError)}</TableCell>
                <TableCell align="right">{formatTime(row.rtt)}</TableCell>
                <TableCell align="right">{formatTime(row.pathDiffBefore)}</TableCell>
                <TableCell align="right">{formatTime(row.pathDiffAfter)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};
