/* eslint-disable no-nested-ternary */
import { alpha, makeStyles } from '@material-ui/core';
import { NetiColors } from '@netinsight/management-app-common-react';

export const useStyles = makeStyles(
  theme => ({
    rootContainer: {
      height: '100%',
      maxHeight: 'calc(100vh - 5.5rem - 4.25rem)',
      width: '100%',
      paddingBottom: 0,
      display: 'grid',
      gridTemplateColumns: 'minmax(0, auto) 1fr minmax(0, auto)',
      gridTemplateRows: '4rem 1fr',
      overflow: 'hidden',
      position: 'relative',
      ['&::before']: {
        content: '" "',
        display: 'block',
        width: '100%',
        height: '4px',
        top: '-4px',
        left: 0,
        right: 0,
        position: 'absolute',
        boxShadow: theme.shadows[2],
        zIndex: theme.zIndex.mobileStepper + 2,
      },
    },
    container: {
      gridRow: '2 / -1',
      gridColumn: '1 / -1',
      flex: 1,
      overflow: 'hidden',
      width: '100%',
    },
    menuContainer: {
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    menuItem: {
      appearance: 'none',
      borderColor: theme.palette.divider,
      borderRadius: 0,
      borderWidth: '1px',
      borderStyle: 'solid',
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.grey[300],
      color: theme.palette.textContrast,
      ...theme.typography.body2,
      padding: theme.spacing(0.5, 1),
      width: '100%',
    },
    hightlightContainer: {
      ['& .tn:empty']: {
        width: '1.5rem',
        height: '1.5rem',
      },
      ['& .tn:not(:empty)']: {
        overflow: 'hidden',
        ['& .tn-outlined']: {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: theme.palette.type === 'dark' ? theme.palette.grey[200] : theme.palette.grey[500],
        },
      },
      ['& .tn-list']: {
        margin: 0,
        padding: 0,
        ['& > li']: {
          listStyle: 'none',
          margin: 0,
        },
        ['&> li + li']: {
          borderTopWidth: '0px',
        },
      },

      ['& .tn-input']: {
        display: 'grid',
        gridTemplateColumns: '1.5rem 3.5rem 2rem',
        gridTemplateRows: '2rem 1.5rem 1.5rem',
        alignItems: 'center',
        gap: theme.spacing(0),
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.infoBackground : theme.palette.background.paper,
        ['&-type, &-priority']: {
          fontWeight: theme.typography.fontWeightBold,
          height: '100%',
          lineHeight: '2rem',
          color:
            theme.palette.type === 'dark'
              ? theme.palette.getContrastText(theme.palette.info.light)
              : theme.palette.infoText,
          backgroundColor: theme.palette.type === 'dark' ? theme.palette.info.light : theme.palette.infoBackground,
        },
        ['&-type']: {
          gridColumn: '1 / 3',
          textAlign: 'left',
          padding: theme.spacing(0, 0.5),
        },
        ['&-priority']: {
          gridColumn: '3 / 4',
          textAlign: 'right',
          paddingRight: theme.spacing(0.5),
        },
        ['&-status-icon, &-status-text']: {
          color: theme.palette.type === 'dark' ? theme.palette.grey[500] : theme.palette.text.disabled,
          ['&[data-value="true"]']: {
            color: theme.palette.type === 'dark' ? theme.palette.success.dark : theme.palette.success.main,
          },
        },
        ['&-status-icon']: {
          gridColumn: '1 / 2',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',

          ['& .tn-icon::before']: {
            width: '1rem',
            height: '1rem',
          },
        },
        ['&-status-text']: {
          gridColumn: '2 / 4',
          fontWeight: theme.typography.fontWeightBold,
          lineHeight: '1.5rem',
        },
      },

      ['& .tn-chip']: {
        display: 'flex',
        gap: theme.spacing(1),
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.info.light : theme.palette.infoBackground,
        color:
          theme.palette.type === 'dark'
            ? theme.palette.getContrastText(theme.palette.info.light)
            : theme.palette.infoText,

        ['&-icon']: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
        ['&-text']: {
          fontWeight: theme.typography.fontWeightBold,
          padding: theme.spacing(1, 0),
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
          minWidth: '3.5rem',
          textAlign: 'left',
          ['&:only-child']: {
            textAlign: 'center',
            minWidth: '100%',
          },
        },
        ['&-value']: {
          whiteSpace: 'nowrap',
          padding: theme.spacing(1, 0),
          minWidth: '4rem',
          textAlign: 'right',
        },
      },

      ['& .tn-icon']: {
        ['&::before']: {
          mask: 'var(--icon-path)',
          ['mask-size']: '100% 100%',
          ['-webkit-mask']: 'var(--icon-path) no-repeat',
          ['-webkit-mask-size']: '100% 100%',
          backgroundColor: 'currentColor',
          content: '""',
          display: 'block',
          height: '2em',
          width: '2em',
        },
        ['&-check-circle::before']: {
          ['--icon-path']: `url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='1em' height='1em'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M8 12.5L10.6667 15L16 10M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z'/%3E%3C/svg%3E") no-repeat`,
        },
        ['&-circle::before']: {
          ['--icon-path']: `url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='1em' height='1em'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z'/%3E%3C/svg%3E") no-repeat`,
        },
        ['&-exclamation-circle::before']: {
          ['--icon-path']: `url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='1em' height='1em'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 7L12 13M12 17L12 17'/%3E%3C/svg%3E") no-repeat`,
        },
        ['&-clock::before']: {
          ['--icon-path']: `url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='1em' height='1em'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 7L12 12L14 14'/%3E%3C/svg%3E") no-repeat`,
        },
        ['&-exclamation-triangle::before']: {
          ['--icon-path']: `url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='1em' height='1em'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 17C3 16 10 5 10 5C11 3 13 3 14 5C14 5 21 16 21 17C21 17 21 19 19 19L5 19C3 19 3 17 3 17ZM12 9L12 12M12 15L12 15'/%3E%3C/svg%3E") no-repeat`,
        },
      },

      ['& .tn-chip-alarms']: {
        ['&-critical']: {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.common.white,
        },
        ['&-major']: {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.common.white,
        },
        ['&-minor']: {
          backgroundColor: NetiColors.yellow.main,
          color: theme.palette.common.white,
        },
        ['&-warning']: {
          backgroundColor: theme.palette.info.main,
          color: theme.palette.common.white,
        },
        ['&-unknown']: {
          backgroundColor: theme.palette.grey.A400,
          color: theme.palette.common.white,
        },
      },

      ['& .tn-chip-control-states']: {
        ['&-unknown']: {
          backgroundColor: alpha(theme.palette.grey[800], 0.25),
        },
        ['&-0']: {
          backgroundColor: theme.palette.error.dark,
          color: theme.palette.getContrastText(theme.palette.error.dark),
        },
        ['&-1']: {
          backgroundColor: theme.palette.warning.dark,
          color: theme.palette.getContrastText(theme.palette.warning.dark),
        },
        ['&-2']: {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.getContrastText(theme.palette.warning.main),
        },
        ['&-3']: {
          backgroundColor: theme.palette.success.dark,
          color: theme.palette.getContrastText(theme.palette.success.dark),
        },
      },

      ['& .tn-chip-node-stability']: {
        ['&-stable']: {
          backgroundColor: theme.palette.success.dark,
          color: theme.palette.getContrastText(theme.palette.success.dark),
        },
        ['&-unstable']: {
          backgroundColor: theme.palette.error.dark,
          color: theme.palette.getContrastText(theme.palette.error.dark),
        },
        ['&.tn-node-unknown']: {
          backgroundColor: alpha(theme.palette.grey[800], 0.25),
          color: theme.palette.getContrastText(theme.palette.error.dark),
        },
      },

      ['& .tn-chip-time-offsets-error']: {
        color: theme.palette.type === 'dark' ? theme.palette.error.dark : theme.palette.error.main,
      },
    },
  }),
  { name: 'Neti' },
);
