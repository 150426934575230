import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import {
  buttonPropsFromPermission,
  PermissionResult,
  TextField,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import { configSchema } from '@netinsight/node-manager-schema';
import type { NetworkNamespaceConfig } from '../../../../../../types/nodes';

const { network, gateway } = configSchema.properties.routes.items.properties;
const NETWORK_PATTERN = new RegExp(network.pattern);
const GATEWAY_PATTERN = new RegExp(gateway.pattern);

interface NodeManagerRoutesEntryProps {
  namespaceIndex: number;
  index: number;
  onRemove: (index: number) => void;
  permission: PermissionResult;
}

export const NodeManagerRoutesEntry = ({
  namespaceIndex,
  index,
  onRemove,
  permission,
}: NodeManagerRoutesEntryProps) => {
  const { control } = useFormContext<NetworkNamespaceConfig>();
  const { field: networkFieldProps } = useTextFieldController({
    control,
    name: `networks.${namespaceIndex}.routes.${index}.network`,
    label: null,
    placeholder: '0.0.0.0/00',
    rules: {
      required: {
        value: true,
        message: 'This field is required',
      },
      pattern: {
        value: NETWORK_PATTERN,
        message: 'Invalid IP CIDR',
      },
    },
  });
  const { field: gatewayFieldProps } = useTextFieldController({
    control,
    name: `networks.${namespaceIndex}.routes.${index}.gateway`,
    label: null,
    placeholder: '0.0.0.0',
    rules: {
      required: {
        value: true,
        message: 'This field is required',
      },
      pattern: {
        value: GATEWAY_PATTERN,
        message: 'Invalid IP',
      },
    },
  });
  const handleRemove = useCallback(() => onRemove(index), [onRemove, index]);
  return (
    <>
      <TextField
        fullWidth
        {...networkFieldProps}
        inputProps={{
          ...networkFieldProps.inputProps,
          'aria-labelledby': `route-table-${namespaceIndex}-label-network`,
        }}
      />
      <TextField
        fullWidth
        {...gatewayFieldProps}
        inputProps={{
          ...gatewayFieldProps.inputProps,
          'aria-labelledby': `route-table-${namespaceIndex}-label-gateway`,
        }}
      />
      <div>
        <Button
          variant="outlined"
          color="default"
          size="small"
          startIcon={<DeleteIcon />}
          onClick={handleRemove}
          style={{ alignSelf: 'baseline' }}
          data-testid="btn-remove-route"
          {...buttonPropsFromPermission(permission)}
        >
          Remove
        </Button>
      </div>
    </>
  );
};
