import { useCallback } from 'react';
import useSwr from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { metricsApiRef } from '@netinsight/management-app-common-react';
import { A, D, flow, G, O } from '@mobily/ts-belt';
import { CounterKey, CounterKeys } from '../../constants/ptp';
import { getPtpTransmitterServiceName } from '../../utils/sync/ptpUtils';
import { isFulfilledVectorResult } from '@netinsight/management-app-common-api';
import { PtpMetricMetadata } from '../../types/sync';

export const MetricPrefix = 'neti_ptp_counters_';

export type PtpMetrics = Partial<Record<CounterKey, number>>;

export type PtpMetricsByInterface = Record<string, PtpMetrics>;

const getMetricRecordKey = (metric: PtpMetricMetadata<CounterKey>) =>
  metric.__name__?.replace(MetricPrefix, '') as CounterKey | undefined;

export const groupMetricsByServiceName = flow(
  A.filter(isFulfilledVectorResult),
  A.map(x => x.value.data.result),
  A.deepFlat,
  A.groupBy(x => getPtpTransmitterServiceName(x.metric)),
  D.map(
    flow(
      O.fromNullable,
      O.map(
        flow(
          A.map(({ metric, value: [, val] }) => [getMetricRecordKey(metric), parseFloat(val)] as [CounterKey, number]),
          A.filter<[CounterKey, number]>(flow(A.head, G.isNotNullable)),
          D.fromPairs,
        ),
      ),
      O.getWithDefault<PtpMetrics>({}),
    ),
  ),
);

type UsePtpMetricsProps = {
  nodeId: string;
  counterKeys?: CounterKey[];
  interval?: number;
};

export const usePtpCountersMetrics = ({ nodeId, counterKeys = CounterKeys, interval = 15000 }: UsePtpMetricsProps) => {
  const metricsApi = useApi(metricsApiRef);

  const fetchMetrics = useCallback(
    async ([nodeIdParam]: [string]) => {
      const results = await Promise.allSettled(
        counterKeys.map(key => metricsApi.instantQuery({ query: `${MetricPrefix}${key}{nodeid="${nodeIdParam}"}` })),
      );
      return groupMetricsByServiceName(results) as PtpMetricsByInterface;
    },
    [metricsApi, counterKeys],
  );

  return useSwr([nodeId, 'ptp_counters_metrics'], fetchMetrics, { refreshInterval: interval });
};
