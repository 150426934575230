import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { metricsApiRef } from '@netinsight/management-app-common-react';
import { groupMetricsFromPromise, groupMetricsFromPromiseBy2Levels } from '@netinsight/management-app-common-api';
import { linkDirectionMetaMapper } from '../../utils/links';

export const useSyncRegionStatusMetrics = (swrConfig?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);
  const fetcher = useCallback(async () => {
    const [syncSourceAvailableResult, syncSourceSelectionResult, linkSyncSourceSelectedResult, nodeStabilityResult] =
      await Promise.allSettled([
        metricsApi.instantQuery({
          query: `last_over_time(neti_hicc_syncsource_available{type!="LINK",type="EXTERNAL"}[30s])`,
        }),
        metricsApi.instantQuery({
          query: `last_over_time(neti_hicc_syncsource_is_selected{type!="LINK"}[30s])`,
        }),
        metricsApi.instantQuery({
          query: `last_over_time(neti_hicc_syncsource_is_selected{type="LINK"}[30s])`,
        }),
        metricsApi.instantQuery({ query: `neti_hicc_nodestability_is_stable` }),
      ]);

    return {
      syncSourceAvailability: groupMetricsFromPromiseBy2Levels(
        syncSourceAvailableResult,
        m => m.nodeid,
        m => m.name,
        v => Boolean(parseInt(v, 10)),
      ),
      syncSourceSelection: groupMetricsFromPromiseBy2Levels(
        syncSourceSelectionResult,
        m => m.nodeid,
        m => m.name,
        v => Boolean(parseInt(v, 10)),
      ),
      linkSelection: groupMetricsFromPromiseBy2Levels(
        linkSyncSourceSelectedResult,
        m => m.nodeid,
        m => m.name,
        linkDirectionMetaMapper,
      ),
      nodeStability: groupMetricsFromPromise(
        nodeStabilityResult,
        m => m.nodeid,
        v => Boolean(parseInt(v, 10)),
      ),
    };
  }, [metricsApi]);
  return useSwr('useSyncRegionStatusMetrics', fetcher, swrConfig);
};
