import React, { FunctionComponent, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  TextField,
  makeStyles,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TimeIcon from '@material-ui/icons/Schedule';
import { Autocomplete } from '@material-ui/lab';
import { PermissionResult, useFormStyles } from '@netinsight/management-app-common-react';
import { AdminStatus, NodeState } from '@netinsight/node-manager-schema';
import type { InterfaceInfo, NetworkNamespaceConfig } from '../../../../../../types/nodes';
import { DEFAULT_NAMESPACES, MANAGEMENT_IFACE } from '../../../../../../constants/nodes';
import { NodeManagerRoutingState } from './NodeManagerRoutingState';
import { NodeManagerRoutesConfig } from './NodeManagerRoutesConfig';
import { G } from '@mobily/ts-belt';

export type NodeManagerNamespaceProps = {
  index: number;
  displayName: string;
  name: string;
  routingState: ReadonlyArray<NodeState.RouteInfo>;
  interfaceInfos: InterfaceInfo[];
  permission: PermissionResult;
};

const useStyles = makeStyles(theme => ({
  defaultNsContainer: { flex: 1 },
  defaultNsLabel: { opacity: 0.7 },
  defaultNsChipContainer: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.125, 0),
  },
  defaultNsChip: { margin: theme.spacing(0.5) },
  title: { textTransform: 'capitalize' },
}));

export const NodeManagerNamespace: FunctionComponent<NodeManagerNamespaceProps> = ({
  index,
  name,
  displayName,
  routingState,
  interfaceInfos,
  permission,
}) => {
  const formStyles = useFormStyles();
  const interfaceInfosMap = useMemo(
    () => Object.fromEntries(interfaceInfos.map(iface => [iface.id, iface])),
    [interfaceInfos],
  );
  const selectableInterfaces = useMemo(
    () =>
      interfaceInfos
        .filter(iface => iface.adminStatus === AdminStatus.Up && iface.name !== MANAGEMENT_IFACE)
        .map(iface => iface.id)
        .sort(),
    [interfaceInfos],
  );
  const allInterfaces = useMemo(() => interfaceInfos.map(iface => iface.id).sort(), [interfaceInfos]);
  const { setValue, control } = useFormContext<NetworkNamespaceConfig>();
  const styles = useStyles();

  return (
    <Accordion defaultExpanded variant="elevation" data-testid={`namespace-${index}-container`}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div>
          <Typography variant="h5" className={styles.title} id={name || 'default'}>
            {displayName}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Network scope
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={formStyles.formContainer}>
        <NodeManagerRoutingState routingState={routingState} index={index} interfaceInfosMap={interfaceInfosMap} />
        <Controller
          name={`networks.${index}.interfaces`}
          control={control}
          render={({ field }) =>
            name === DEFAULT_NAMESPACES.default ? (
              <div className={styles.defaultNsContainer}>
                <Typography variant="caption" className={styles.defaultNsLabel}>
                  Interfaces
                </Typography>
                <ul className={styles.defaultNsChipContainer} data-testid={`namespace-${index}-interfaces-list`}>
                  {field.value.map(val => (
                    <Chip
                      key={val}
                      component="li"
                      label={interfaceInfosMap[val]?.displayName ?? val}
                      variant="outlined"
                      className={styles.defaultNsChip}
                      icon={interfaceInfosMap[val]?.usage.ptpTransmitter ? <TimeIcon /> : undefined}
                      data-testid="interface-item"
                    />
                  ))}
                </ul>
              </div>
            ) : (
              <Autocomplete
                {...field}
                multiple
                disableClearable
                id={`networks-${index}-interfaces-id`}
                options={selectableInterfaces}
                disabled={!permission.isAllowed}
                onChange={(_evt, values) => {
                  field.onChange(values);
                  const unassignedIfaces = allInterfaces.filter(iface => !values.includes(iface));
                  const defaultNsFieldName = `networks.0.interfaces`;
                  setValue(defaultNsFieldName, unassignedIfaces);
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, optionIndex) => {
                    const { onDelete, ...tagProps } = getTagProps({ index: optionIndex }) as any;
                    const isUsed = interfaceInfosMap[option]?.usage.ptpTransmitter;
                    return (
                      <Chip
                        label={interfaceInfosMap[option]?.displayName ?? option}
                        variant="outlined"
                        icon={isUsed ? <TimeIcon /> : undefined}
                        disabled={interfaceInfosMap[option]?.adminStatus === AdminStatus.Down}
                        onDelete={!isUsed && permission.isAllowed ? onDelete : undefined}
                        data-testid="interface-item"
                        {...tagProps}
                      />
                    );
                  })
                }
                getOptionLabel={option => interfaceInfosMap[option]?.displayName ?? option}
                getOptionDisabled={option => G.isNotNullable(interfaceInfosMap[option]?.usage.ptpTransmitter)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Interfaces"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Select interfaces"
                  />
                )}
                data-testid={`namespace-${index}-interfaces-list`}
              />
            )
          }
        />
        <NodeManagerRoutesConfig index={index} name={name} permission={permission} />
      </AccordionDetails>
    </Accordion>
  );
};
