import React, { FunctionComponent, useCallback, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/SaveAlt';
import AddBackgroundIcon from '@material-ui/icons/AddPhotoAlternate';
import RemoveBackgroundIcon from '@material-ui/icons/HighlightOff';
import CloseIcon from '@material-ui/icons/Close';

import { NodeGraphContext } from './context';
import {
  PopButton,
  isNullableOrEmpty,
  useFormStyles,
  useSnackbarHelper,
} from '@netinsight/management-app-common-react';
import { getInitialNodeGraphState } from './reducer';
import { NodeSingular } from 'cytoscape';
import { F } from '@mobily/ts-belt';
import {
  DEFAULT_GRAPH_ID,
  MAXIMUM_ALLOWED_IMAGE_SIZE_IN_BYTES,
  MAXIMUM_ALLOWED_IMAGE_SIZE_IN_MB,
} from '../../../constants/node-graph';
import {
  useGraphStateCreate,
  useGraphStateDelete,
  useGraphStateList,
  useGraphStateUpdate,
} from '../../../hooks/node-graph';
import { generateNewGraphName } from '../../../utils/node-graph';

const useStyles = makeStyles(
  theme => ({
    menuPanel: {
      width: '20rem',
      gridRow: '2 / -1',
      gridColumn: '1 / 2',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflowY: 'scroll',
      overflowX: 'auto',
      zIndex: theme.zIndex.mobileStepper - 1,
      boxShadow: theme.shadows[4],
    },
    currentMapForm: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      alignItems: 'stretch',
      padding: theme.spacing(3, 2, 2, 2),
      borderBottomColor: theme.palette.border,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
    },
    listContainer: {
      flex: 1,
      maxHeight: 'calc(100% - 16rem)',
      overflowY: 'scroll',
    },
    listHeader: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      textTransform: 'uppercase',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      gap: theme.spacing(1),
      alignItems: 'stretch',
    },
    createButtonContainer: {
      padding: theme.spacing(2),
      borderTopColor: theme.palette.border,
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
    },
  }),
  { name: 'Neti' },
);

export const NodeGraphListMenu: FunctionComponent<{ graphId: string }> = ({ graphId }) => {
  const { state, dispatch, cyRef } = useContext(NodeGraphContext);
  const styles = useStyles();
  const formStyles = useFormStyles();
  const { snackbar } = useSnackbarHelper();

  // BACKGROUND IMAGE
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const handleImageUpload = useCallback(() => {
    const file = fileUploadRef.current?.files?.[0];
    if (!file) {
      return;
    }

    // if file size bigger than 5MB show error
    if (file.size > MAXIMUM_ALLOWED_IMAGE_SIZE_IN_BYTES) {
      snackbar.error(`Image size shall be less than ${MAXIMUM_ALLOWED_IMAGE_SIZE_IN_MB}mb`);
      return;
    }

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        if (typeof reader.result === 'string') {
          dispatch({ type: 'background', imageUrl: reader.result });
        }
      },
      false,
    );

    reader.readAsDataURL(file);
  }, [dispatch, snackbar]);

  // LISTINGS
  const navigate = useNavigate();
  const { data: nodeGraphs } = useGraphStateList();

  // CREATING
  const { trigger: createGraph, isMutating: isCreatingGraph } = useGraphStateCreate();
  const handleCreateNewGraph = useCallback(async () => {
    const result = await createGraph({
      state: {
        ...getInitialNodeGraphState(),
        name: generateNewGraphName(nodeGraphs),
      },
    });
    const newId = result?.[0];
    if (!isNullableOrEmpty(newId)) {
      dispatch({ type: 'menu', open: false });
      navigate(`/network/graph/${newId}`);
    }
  }, [dispatch, navigate, createGraph, nodeGraphs]);

  // UPDATING
  const { trigger: updateGraphState, isMutating: isUpdatingGraph } = useGraphStateUpdate();
  const handleSaveGraph = useCallback(() => {
    const cy = cyRef.current;
    if (!cy) {
      return;
    }
    const currentNodePositions = Object.fromEntries(
      cy.nodes(':childless').map((elem: NodeSingular) => [elem.id(), elem.position()] as [string, cytoscape.Position]),
    );

    updateGraphState({
      id: graphId,
      state: {
        name: state.name ?? graphId,
        backgroundImageUrl: state.backgroundImageUrl,
        toggles: state.toggles,
        highlight: state.highlight,
        syncRegions: state.syncRegions,
        width: cy.width(),
        height: cy.height(),
        pan: cy.pan(),
        zoom: cy.zoom(),
        nodePositions: currentNodePositions,
      },
    }).catch(F.ignore); // ignore errors
  }, [graphId, state, cyRef, updateGraphState]);

  // DELETING
  const { trigger: deleteGraphState, isMutating: isDeletingGraphState } = useGraphStateDelete();
  const handleDeleteGraph = useCallback(async () => {
    await deleteGraphState(graphId);
    dispatch({ type: 'menu', open: false });
    navigate(`/network/graph/${DEFAULT_GRAPH_ID}`);
  }, [graphId, deleteGraphState, dispatch, navigate]);

  if (!state.leftBarOpened) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={() => dispatch({ type: 'menu', open: false })}>
      <div className={styles.menuPanel}>
        <div className={styles.currentMapForm}>
          <TextField
            fullWidth
            variant="outlined"
            label="Map name"
            InputLabelProps={{ shrink: true }}
            value={state.name}
            onChange={evt => {
              dispatch({ type: 'name', name: evt.target.value });
            }}
            error={isNullableOrEmpty(state.name)}
            helperText={isNullableOrEmpty(state.name) ? 'Required' : undefined}
          />
          {isNullableOrEmpty(state.backgroundImageUrl) ? (
            <Button
              startIcon={<AddBackgroundIcon />}
              component="label"
              variant="outlined"
              color="default"
              title="Add background"
            >
              <span>Add background</span>
              <input
                accept="image/*"
                type="file"
                onChange={handleImageUpload}
                ref={fileUploadRef}
                className={formStyles.visuallyHiddenInput}
              />
            </Button>
          ) : null}
          {!isNullableOrEmpty(state.backgroundImageUrl) ? (
            <Button
              onClick={() => dispatch({ type: 'background', imageUrl: undefined })}
              startIcon={<RemoveBackgroundIcon />}
              variant="outlined"
              color="default"
              title="Remove background"
            >
              Remove background
            </Button>
          ) : null}
          <div className={styles.buttonsContainer}>
            <Button
              fullWidth
              variant="contained"
              size="medium"
              startIcon={isUpdatingGraph ? <CircularProgress size="1rem" /> : <SaveIcon />}
              color="primary"
              disabled={isUpdatingGraph || isNullableOrEmpty(state.name)}
              onClick={handleSaveGraph}
            >
              Save
            </Button>
            <PopButton
              label="Remove"
              placement="bottom-end"
              fullWidth
              variant="outlined"
              size="medium"
              color="secondary"
              startIcon={<CloseIcon />}
              title={graphId === DEFAULT_GRAPH_ID ? 'The default map cannot be removed' : 'Remove map'}
              disabled={graphId === DEFAULT_GRAPH_ID || isDeletingGraphState}
            >
              <Typography variant="body1">This map will be removed, do you want to continue?</Typography>
              <div className={styles.buttonsContainer}>
                <Button
                  onClick={handleDeleteGraph}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  size="medium"
                  startIcon={isDeletingGraphState ? <CircularProgress size="1rem" /> : <CloseIcon />}
                >
                  Remove
                </Button>
              </div>
            </PopButton>
          </div>
        </div>
        <List
          className={styles.listContainer}
          subheader={<ListSubheader className={styles.listHeader}>Available maps</ListSubheader>}
        >
          {nodeGraphs
            ?.sort(({ name: name1, id: id1 }, { name: name2, id: id2 }) => (name1 ?? id1).localeCompare(name2 ?? id2))
            .map(({ id, name, timestamp }) => (
              <ListItem
                key={id}
                button
                component="li"
                onClick={() => {
                  if (id !== graphId) {
                    dispatch({ type: 'menu', open: false });
                    navigate(`/network/graph/${id}`);
                  }
                }}
                selected={id === graphId}
                alignItems="center"
              >
                <ListItemText
                  primary={name}
                  secondary={typeof timestamp === 'number' ? new Date(timestamp).toISOString() : undefined}
                  style={{ textTransform: 'capitalize' }}
                />
              </ListItem>
            ))}
        </List>
        <div className={styles.createButtonContainer}>
          <Button
            fullWidth
            variant="outlined"
            size="medium"
            startIcon={isCreatingGraph ? <CircularProgress size="1rem" /> : <AddIcon />}
            color="primary"
            disabled={isCreatingGraph}
            onClick={handleCreateNewGraph}
          >
            Create new map
          </Button>
        </div>
      </div>
    </ClickAwayListener>
  );
};
