import React, { PropsWithChildren } from 'react';
import { InfoCard } from '@backstage/core-components';
import { PPSIn } from './PPSIn';

export const PPSInConfigCard = ({ nodeId }: PropsWithChildren<{ nodeId: string }>) => {
  return (
    <InfoCard title="PPS-in" titleTypographyProps={{ variant: 'h5' }}>
      <PPSIn nodeId={nodeId} />
    </InfoCard>
  );
};
