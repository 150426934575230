import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      padding: theme.spacing(2),
      border: 'none',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '100%',
      gap: theme.spacing(3),
      overflow: 'hidden',
      position: 'relative',
    },
    tableContainer: {
      width: '100%',
      maxWidth: '100%',
      overflowX: 'scroll',
    },
    table: {
      width: '100%',
      ['border-collapse']: 'collapse',
      [' & td, & th']: {
        ...theme.typography.body2,
        border: '1px solid',
        borderColor: theme.palette.divider,
      },
    },
    header: {
      ['& th']: {
        fontWeight: 'bold',
        padding: theme.spacing(1, 1),
        ['&.numeric']: {
          textAlign: 'right',
        },
      },
    },
    body: {
      ['& th']: {
        fontWeight: 'bold',
        padding: theme.spacing(1, 1),
        textAlign: 'left',
        width: '8rem',
      },
      ['& td']: {
        textAlign: 'left',
        padding: theme.spacing(1, 1),
        fontVariantNumeric: 'tabular-nums',
      },
      ['& td.value']: {
        minWidth: '6rem',
        width: '6rem',
      },
      ['& td.numeric']: {
        textAlign: 'right',
      },
      ['& td.icon']: {
        textAlign: 'center',
      },
      ['& td.action']: {
        textAlign: 'center',
        width: '3rem',
        padding: theme.spacing(0, 0),
      },
      ['& td.button-container']: {
        textAlign: 'right',
      },
      ['& td.button-container > * + *']: {
        marginLeft: theme.spacing(1),
      },
      ['& td.error-container']: {
        position: 'relative',
      },
      ['& td.error-container > .cell-error']: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 0,
        height: 0,
        borderTop: `1.25rem solid ${theme.palette.error.main}`,
        borderLeft: `1.25rem solid transparent`,
        color: theme.palette.error.contrastText,
      },
      ['& td.prediction']: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.type === 'dark' ? theme.palette.warning.contrastText : theme.palette.text.primary,
      },
    },
    refreshButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    subTable: {
      backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
    },
  }),
  { name: 'Neti' },
);
