import { useCallback } from 'react';
import { mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { inventoryApiRef, useSnackbarHelper } from '@netinsight/management-app-common-react';
import { GRAPH_STATE_CACHE_KEY, GRAPH_STATE_LIST_CACHE_KEY } from '../../constants/node-graph';

export const useGraphStateDelete = () => {
  const inventoryApi = useApi(inventoryApiRef);
  const { snackbar } = useSnackbarHelper();

  const remove = useCallback(
    async (_: any, { arg: idToRemove }: { arg: string }) => {
      await inventoryApi.deleteNodeGraphState(idToRemove);
      return idToRemove;
    },
    [inventoryApi],
  );

  return useSWRMutation(GRAPH_STATE_CACHE_KEY, remove, {
    onSuccess: async removedId => {
      await mutate([GRAPH_STATE_CACHE_KEY, removedId], undefined);
      await mutate(GRAPH_STATE_LIST_CACHE_KEY);
      snackbar.notifySuccess('Delete');
    },
    onError: error => {
      snackbar.notifyError('Delete', error.response, null);
    },
  });
};
