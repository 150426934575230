export const NODE_GRAPH_LAYOUTS = {
  klay: {
    name: 'klay',
    nodeDimensionsIncludeLabels: true,
    fit: true,
    padding: 32,
    spacing: 48,
    klay: {
      direction: 'RIGHT',
      edgeRouting: 'ORTHOGONAL',
      spacing: 48,
    },
  },
  default: {} as cytoscape.LayoutOptions,
};

NODE_GRAPH_LAYOUTS.default = NODE_GRAPH_LAYOUTS.klay;

export const LAST_GRAPH_ID_STORAGE_KEY = 'neti:node-graph-last-graph-id';
export const GRAPH_STATE_CACHE_KEY = 'inventory-node-graph-state';
export const GRAPH_STATE_LIST_CACHE_KEY = 'inventory-node-graph-state-list';
export const DEFAULT_GRAPH_ID = 'main';
export const DEFAULT_GRAPH_WIDTH = 1280;
export const DEFAULT_GRAPH_HEIGHT = 1024;
export const TIME_OFFSET_WARNING_THRESHOLD_SECONDS = 1e-7;
export const MAXIMUM_ALLOWED_IMAGE_SIZE_IN_MB = 5;
export const MAXIMUM_ALLOWED_IMAGE_SIZE_IN_BYTES = MAXIMUM_ALLOWED_IMAGE_SIZE_IN_MB * 1024 * 1024;

export const SYNC_OUTPUT_LABELS = {
  ptp: 'PTP',
  ppsOut: 'PPS',
  freqOut: '10M/Sync',
  synce: 'SyncE',
} as const;

export const SYNC_OUTPUT_CONFIG_URLS = {
  ptp: (nodeId: string) => `/nodes/info/${nodeId}/outputs/ptp`,
  ppsOut: (nodeId: string) => `/nodes/info/${nodeId}/outputs/pps-out`,
  freqOut: (nodeId: string) => `/nodes/info/${nodeId}/outputs/sync-out`,
  synce: (nodeId: string) => `/nodes/info/${nodeId}/outputs/synce`,
};
