import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { ptpClockIdFromMac } from '@netinsight/crds-timetransfer';
import {
  errorFromWrappedError,
  isNullableOrEmpty,
  k8sNodeApiRef,
  k8sOnboardingApiRef,
} from '@netinsight/management-app-common-react';

export const usePtpClockId = (nodeId: string, swrConfig?: SWRConfiguration) => {
  const onboardingApi = useApi(k8sOnboardingApiRef);
  const nodeApi = useApi(k8sNodeApiRef);

  const fetcher = useCallback(
    async ([nodeIdParam]: [string, string]) => {
      const nodeResponse = await nodeApi.getNode({ params: { nodeId: nodeIdParam } });
      if (nodeResponse.status !== StatusCodes.OK) {
        throw errorFromWrappedError(nodeResponse.status, nodeResponse.body);
      }
      const requestId = nodeResponse.body.status?.onboardingRequestId;
      if (isNullableOrEmpty(requestId)) {
        return undefined;
      }
      const onboardingRequestResponse = await onboardingApi.getOnboardingRequest({ params: { requestId } });
      if (onboardingRequestResponse.status !== StatusCodes.OK) {
        return undefined;
      }
      return ptpClockIdFromMac(onboardingRequestResponse.body.spec?.chassis?.mac);
    },
    [nodeApi, onboardingApi],
  );

  return useSwr([nodeId, 'usePtpClockId'], fetcher, swrConfig);
};
