import { SYNC_SOURCE_NAMES } from '@netinsight/management-app-common-api';
import {
  CalibrationEditModel,
  CalibrationPredictionViewModel,
  TimeReferenceErrors,
  TimeReferenceSelections,
  TimeReferenceType,
} from './types';
import { SYNC_SOURCE_LABELS } from '../../../../../constants/sync';

export const CalculationMethods = {
  eliminateTimeError: 'eliminateTimeError',
  eliminateLinkSpread: 'eliminateLinkSpread',
  adjustTimeOnly: 'adjustTimeOnly',
};

export const TimeReferenceSelectionOrder = [
  SYNC_SOURCE_NAMES.ppsIn,
  SYNC_SOURCE_NAMES.gnss,
  SYNC_SOURCE_NAMES.ptp1,
  SYNC_SOURCE_NAMES.ptp2,
] as const;

export const TimeReferenceDisplayNames: Record<TimeReferenceType, string> = SYNC_SOURCE_LABELS;

export const DefaultTimeReferenceSelection: TimeReferenceSelections = {
  [SYNC_SOURCE_NAMES.ppsIn]: false,
  [SYNC_SOURCE_NAMES.gnss]: false,
  [SYNC_SOURCE_NAMES.ptp1]: false,
  [SYNC_SOURCE_NAMES.ptp2]: false,
};

export const DefaultTimeReferenceErrors: TimeReferenceErrors = {
  [SYNC_SOURCE_NAMES.ppsIn]: undefined,
  [SYNC_SOURCE_NAMES.gnss]: undefined,
  [SYNC_SOURCE_NAMES.ptp1]: undefined,
  [SYNC_SOURCE_NAMES.ptp2]: undefined,
};

export const DefaultPrediction: CalibrationPredictionViewModel = {
  inLinkTimeErrorsByLinkId: {},
  outlinkPeerTimeReferenceErrorsByNodeId: {},
  localTimeReferenceErrors: {
    ...DefaultTimeReferenceErrors,
    custom: undefined,
  },
};

export const DefaultEditModel: CalibrationEditModel = {
  calculationMethod: 'adjustTimeOnly',
  customTimeReferenceError: 0,
  linkTimeErrorsByLinkIdAndProfileIndex: {},
  localTimeReferenceSelections: { ...DefaultTimeReferenceSelection, custom: false },
};

export const PredefinedTimeErrorsDurations = {
  '30s': '30 seconds',
  '3m': '3 minutes',
  '10m': '10 minutes',
  '60m': '60 minutes',
} as const;

export const DefaultTimeErrorsDuration = '30s';
export const DefaultLinkSpreadDuration = '3m';

export const NanosecondToSecond = 1e-9;
