import { A, F, G, pipe } from '@mobily/ts-belt';
import { PersistedSyncLink, TimeNode } from '@netinsight/management-app-common-api';
import { DEFAULT_SYNC_REGION } from '@netinsight/crds';
import { LinkDirectionMap, LinkStabilityMap } from '../../types/node-graph';

const getLinkDirection = (linkId: string, linkDirectionMap: LinkDirectionMap) => {
  const source = linkDirectionMap[linkId]?.peerNodeId;
  const target = linkDirectionMap[linkId]?.nodeId;
  return {
    source,
    target,
    usage: Number(G.isNotNullable(source)) + Number(G.isNotNullable(target)),
    clockNodeId: linkDirectionMap[linkId]?.clockNodeId,
  };
};

const getLinkStability = (linkId: string, source: string, target: string, linkStabilityMap: LinkStabilityMap) => {
  const sourceIsStable = linkStabilityMap[linkId]?.[source]?.isStable;
  const targetIsStable = linkStabilityMap[linkId]?.[target]?.isStable;
  return {
    stability: Number(sourceIsStable ?? false) + Number(targetIsStable ?? false),
  };
};

const areLinkEndpointsValid = (sourceNodeId: string, targetNodeId: string, existingNodeIds: Set<string>) => {
  if (G.isNullable(sourceNodeId) || G.isNullable(targetNodeId)) {
    return true;
  }
  return existingNodeIds.has(sourceNodeId) && existingNodeIds.has(targetNodeId);
};

const areSyncRegionsIncluded = (
  sourceNodeSyncRegion: string,
  targetNodeSyncRegion: string,
  selectedSyncRegions: string[],
) => {
  return (selectedSyncRegions.length ?? 0) === 0
    ? true
    : selectedSyncRegions.includes(sourceNodeSyncRegion) || selectedSyncRegions.includes(targetNodeSyncRegion);
};

const getGraphNodes = ({
  nodes,
  selectedSyncRegions,
  getTimeNodeDom,
}: {
  nodes: TimeNode[];
  selectedSyncRegions: string[];
  getTimeNodeDom: (nodeId: string) => HTMLElement;
}) => {
  const graphNodeIds = new Set<string>();
  const graphNodeSyncRegions = new Set<string>();
  const graphNodes = pipe(
    nodes ?? [],
    selectedSyncRegions.length > 0
      ? A.filter(({ spec: { syncRegion = DEFAULT_SYNC_REGION } }) => selectedSyncRegions.includes(syncRegion))
      : F.identity,
    A.map(({ id, spec: { name, syncRegion = DEFAULT_SYNC_REGION } }) => {
      graphNodeIds.add(id);
      graphNodeSyncRegions.add(syncRegion);
      return {
        data: {
          id,
          name: name ?? id,
          parent: syncRegion as string | undefined,
          dom: getTimeNodeDom(id) as HTMLElement | undefined,
        },
      };
    }),
    ns =>
      A.concat(
        ns,
        [...graphNodeSyncRegions.values()].map(sr => ({
          data: {
            id: sr ?? DEFAULT_SYNC_REGION,
            name: sr ?? DEFAULT_SYNC_REGION,
            parent: undefined,
            dom: undefined,
          },
        })),
      ),
    F.toMutable,
  );
  return { graphNodes, graphNodeIds };
};

const combinedNodeKeys = (nodeA: string, nodeB: string) => [nodeA, nodeB].sort((a, b) => a.localeCompare(b)).join('::');

const getGraphEdges = ({
  graphNodeIds,
  links,
  linkDirectionMap,
  linkStabilityMap,
  selectedSyncRegions,
  syncRegionLookup,
}: {
  graphNodeIds: Set<string>;
  links: PersistedSyncLink[];
  linkDirectionMap: LinkDirectionMap;
  linkStabilityMap: LinkStabilityMap;
  selectedSyncRegions: string[];
  syncRegionLookup: (nodeId: string) => string;
}) => {
  const parallelEdgeCountLookup = pipe(
    links,
    A.map(l => [combinedNodeKeys(l.endpointA.node, l.endpointB.node), l.id] as [string, string]),
    A.reduce({} as Record<string, number>, (lookup, [nodeIds]) => ({
      ...lookup,
      [nodeIds]: (lookup[nodeIds] ?? 0) + 1,
    })),
  );

  return pipe(
    links ?? [],
    A.filter(({ id, endpointA, endpointB }) => {
      const { source = endpointA.node, target = endpointB.node } = getLinkDirection(id, linkDirectionMap);
      const sourceSyncRegion = syncRegionLookup(source) ?? DEFAULT_SYNC_REGION;
      const targetSyncRegion = syncRegionLookup(target) ?? DEFAULT_SYNC_REGION;
      return (
        areLinkEndpointsValid(source, target, graphNodeIds) &&
        areSyncRegionsIncluded(sourceSyncRegion, targetSyncRegion, selectedSyncRegions)
      );
    }),
    A.map(({ id, name, endpointA, endpointB }) => {
      const {
        source = endpointA.node,
        target = endpointB.node,
        ...directionMetadata
      } = getLinkDirection(id, linkDirectionMap);
      return {
        data: {
          id,
          name,
          source,
          target,
          parallelEdgesCount: parallelEdgeCountLookup[combinedNodeKeys(source, target)] ?? 0,
          ...directionMetadata,
          ...getLinkStability(id, source, target, linkStabilityMap),
        },
      };
    }),
    F.toMutable,
  );
};

export const getGraphElements = ({
  nodes,
  links,
  linkDirectionMap,
  linkStabilityMap,
  selectedSyncRegions,
  syncRegionLookup,
  getTimeNodeDom,
}: {
  nodes: TimeNode[];
  links: PersistedSyncLink[];
  linkDirectionMap: LinkDirectionMap;
  linkStabilityMap: LinkStabilityMap;
  selectedSyncRegions: string[];
  syncRegionLookup: (nodeId: string) => string;
  getTimeNodeDom: (nodeId: string) => HTMLElement;
}) => {
  const { graphNodes, graphNodeIds } = getGraphNodes({
    nodes,
    getTimeNodeDom,
    selectedSyncRegions,
  });
  const graphEdges = getGraphEdges({
    graphNodeIds,
    links,
    linkDirectionMap,
    linkStabilityMap,
    selectedSyncRegions,
    syncRegionLookup,
  });
  return [...graphNodes, ...graphEdges];
};

export type GraphNodeData = ReturnType<typeof getGraphNodes>['graphNodes'][number]['data'];
export type GraphEdgeData = ReturnType<typeof getGraphEdges>[number]['data'];
